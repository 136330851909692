import React from 'react';
import { Component } from 'react';

import {Input} from 'antd';

import {
  MATH_FUNCTIONS,
  MATH_OPERATIONS,
  MATH_OPERATION_TYPES
} from '../../../config/constants';

import './Calculator.scss';
import {energyAnalysisActions} from "../../../actions";
import {connect} from "react-redux";

const TextArea = Input.TextArea;

class Calculator extends Component {
    
    render() {

        let inputValue = '' ;

        return(
            <div className="calculator-wrapper">
                <TextArea
                    type="text"
                    value={inputValue}
                    autosize={{ minRows: 4, maxRows: 4 }}
                    onChange={event => {
                    }
                    }
                />
                <div className="calculator-shorcuts-wrapper">
                    {MATH_OPERATIONS.map((func, index) => (
                        <div
                            className={
                                func.type === MATH_OPERATION_TYPES.ADDITION
                                    ? 'selected-calcuator-probe-color'
                                    : 'calculator-function-wrapper'
                            }
                            key={index}
                            onClick={() => {

                            }}
                        >
                            {func.displayValue}
                        </div>
                    ))}
                    {MATH_FUNCTIONS.map((func, index) => (
                        <div
                            className="calculator-function-wrapper"
                            key={index}
                            onClick={() => {

                            }}
                        >
                            {func.displayValue}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    selectedAnalysisIndex : state.energyAnalysis.selectedAnalysisIndex,
    energyAnalysisList    : state.energyAnalysis.energyAnalysisList,
    arrayOfCheckedNodes   : state.tree.arrayOfCheckedNodes
});

const mapDispatchToProps = dispatch => ({
    updateAnalysis        : (analysisList,analysis,index) => dispatch(energyAnalysisActions.updateAnalysis(analysisList,analysis,index))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Calculator);