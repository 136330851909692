import React, { Component }                     from 'react';
import { connect }                              from 'react-redux';

import {Button, Checkbox, Icon, Pagination, Tooltip, notification} from "antd";

import { Probe }                                from './Probe/Probe';
import CreateNewDataLoggerModal                          from "../../CreateNewDataLoggerModal/CreateNewDataLoggerModal";
import UploadModal                              from "../../../UploadModal/UploadModal";

import { globalSettingsActions }                from '../../../../actions';
import { uploadProbe }                          from '../../../../actions/uploadProbe';
import { handleDownloadProps, TranslateText }   from "../../../../config/constants";
import Translate from "../../../Translate/Translate";
import './GlobalSettingsProbeSetup.scss';

class GlobalSettingsProbeSetup extends Component {

    constructor(props) {
        super(props);      
        this.state = {
            probesList: [],
            shown: false,
            modal: null,
            visible: false,
            prevObjFromState: null,
            tagInputValues: {}
        };
    }

    componentDidMount() {

        const { GetAllDataLoggers,GetLevels,GetProbesForFolder, selectedId } = this.props;

        GetAllDataLoggers();

        if (selectedId) {
            GetProbesForFolder(selectedId);
            GetLevels(selectedId);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { props:{GetAllFilterParams, probes}} = this;
        if(probes !== prevProps.probes){
            this.setProbesList();
            GetAllFilterParams();
        }
        if(this.props.probeFilter !== prevProps.probeFilter){
            this.props.UnCheckAllCheckedProbes()
        }
    };

    render() {
        const{
            props :{ probes, checkedProbes, metaDataForProbePagination, allDataLoggers, CreateDataLogger, folderLevels, DownloadTemplate},
            state:{ probesList}
        } = this;
        let needSaveNewProbe =false;
        if(localStorage.getItem("dataInProbeSetupIsChanged") === "true"){
            needSaveNewProbe=true;
        };
        const checkedAll = probes.every((probe)=> checkedProbes.includes(probe.id)) ;
        const hasNewProbes = probesList.filter(probe => probe && !probe.id).length;

        return (
            metaDataForProbePagination &&  folderLevels ?
            <div className={'probe-setup-container'}>
                {
                    this.state.modal &&
                    <CreateNewDataLoggerModal
                        onClose={this.handleModalClose}
                        createDataLogger={CreateDataLogger}
                    />
                }
                <div className={'header'}>
                    <Checkbox
                        style={{ marginLeft: '17px' }}
                        onChange={event => this.props.HandleCheckAllProbes(event.target.checked)}
                        checked={checkedAll}
                        disabled={this.props.isApplyDisabled}
                    />
                    <span  style={{marginLeft:"10px"}}><Translate string={"GLOBAL.SELECT_ALL"}/></span>
                    {checkedProbes.length > 0 &&  <span style={{marginLeft:"10px"}}>Selected {checkedProbes.length} - {metaDataForProbePagination.totalRecords}</span>}
                    {/* <span className="showing-per-page">

                        <Translate string={"GLOBAL.SHOWING"}/>{metaDataForProbePagination.totalRecords < 10 ? metaDataForProbePagination.totalRecords
                            :
                        (
                            metaDataForProbePagination.perPage * metaDataForProbePagination.previousPage === probesList.length ?
                                metaDataForProbePagination.perPage * metaDataForProbePagination.previousPage : metaDataForProbePagination.perPage * metaDataForProbePagination.previousPage + probesList.length )}
                        - {metaDataForProbePagination.totalRecords}
                    </span> */}
                </div>
                <div className={'main-content'} id={'main-content-position'}>
                    {

                        probesList.map((probe, index) => {
                            const checked = this.defaultChecked(probe.id);
                            return(
                            <Probe
                                   key   = {index}
                                   probe = {probe}

                                   defaultChecked = {checked}
                                   allDataLoggers    = {allDataLoggers}
                                   filterParams   = {this.props.allFilterParams}
                                   expand         = {this.state.shown[index]}
                                   folderLevels   = {folderLevels}
                                   tagInputValues = {this.state.tagInputValues[probe.id]}
                                   isCheckboxDisabled = {this.props.isApplyDisabled}

                                   handleCheckedProbe        = {(e) => this.props.HandleCheckedProbe(probe.id, e.target.checked)}
                                   onChangeSelectOptionProbe = {(e, options)=> this.selectOptionOnProbe(e,options,index, probe.dlId)}
                                   updateNewTags             = {(e) => this.updateNewTags(e, probe.id)}
                                   handleClose               = {(e)=> this.handleCloseTag(e, index, probe.id)}
                                   openModal                 = {()=>this.setState({modal:true})}
                                   onDelete                  = {() => this.handleDeleteProbe(index)}
                                   onChangeProbe             = {(e) => this.onChange(e, index)}
                                   onBlurProbe               = {(e) => this.probeValueHandler(e, index)}
                                   onToggle                  = {() => this.toggle(index)}
                            />
                            )
                        })

                    }
                </div>
                <div className={'footer'}>
                    <div className={'footer-download-upload-buttons'}>
                        <Button
                            type='button'
                            className='btn btn-border-green downlaod-button'
                            onClick={()=> this.handleDownloadProbesToCSV()}
                        >
                            <Icon type="arrow-down" style={{ fontSize: '17px'}} />
                            <Translate string={"GLOBAL.DOWNLOAD"}/>
                        </Button>
                        <Button type='button'
                                className='btn btn-border-green'
                                onClick={event => {
                                    event.preventDefault();
                                    this.toggleUploadModal();
                                }}
                        >
                            <Icon type="cloud-upload" style={{ fontSize: '17px'}} />
                            <Translate string={"GLOBAL.UPLOAD"}/>
                        </Button>
                        <Button
                            onClick={() => DownloadTemplate(require('../../../../assets/template/ProbesUploadTemplate.csv'))}
                            className={"btn btn-border-green"}
                            icon={"cloud-download"}
                        >
                            <Translate string={"GLOBAL.TEMPLATE"}/>
                        </Button>
                    </div>
                    <div className={'footer-pagination'}>
                        {!needSaveNewProbe && <Pagination
                            defaultCurrent={1}
                            total={metaDataForProbePagination.totalRecords}
                            onChange={this.onChangePage}
                            size={'small'}
                            current={metaDataForProbePagination.currentPage}
                        />
                        }
                    </div>
                    <div className={'footer-save-add-buttons'}>
                        <Tooltip placement="top" title={probes.length === 0  ? <Translate string={"GLOBAL_SETTINGS.ADD_OR_IMPORT_PROBES_MESSAGE"}/> : needSaveNewProbe ?  <Translate string={"GLOBAL.SAVE_PROBE"}/> : ""}>
                            <Button size={"small"} className={probes.length === 0 || needSaveNewProbe ? "btn btn-border-green disable-next-button" : "btn btn-border-green"} onClick={this.handleNextButton} name={"nextButton"}
                                    disabled={probes.length === 0 || needSaveNewProbe}
                                    >
                                    <Icon type="right" theme="outlined"/><Translate string={"GLOBAL.NEXT"}/>
                            </Button>
                        </Tooltip>
                        <Button
                            onClick={this.handleSaveProbe}
                            type='button'
                            className='btn btn-border-green'
                            hidden={!hasNewProbes}
                        >
                            <Icon type="save"  style={{ fontSize: '17px'}}/>
                            <Translate string={"GLOBAL.SAVE"}/>
                        </Button>
                        <Tooltip placement="topRight" title={needSaveNewProbe ? <Translate string={"GLOBAL.SAVE_PROBE"}/> : <Translate string={"GLOBAL_SETTINGS.ADD_NEW_PROBES"}/>}>
                        <Button className={needSaveNewProbe ? 'add-button btn btn-border-green disable-add-button' : "add-button btn btn-border-green"}
                                disabled={needSaveNewProbe}
                                type='button'
                                icon={'plus'}
                                onClick={this.addNewProbe}
                        >
                        </Button>
                        </Tooltip>
                    </div>
                    <UploadModal
                        title={<Translate string={"GLOBAL.UPLOAD_PROBE"}/>}
                        uploadFlag={"probeSetup"}
                        visible={this.state.visible}
                        uploadFiles={this.props.uploadProbe}
                        callBack={this.toggleUploadModal}
                    />
                </div>
            </div> : null
        )
    };

    toggleUploadModal = () => {
        this.setState({
            visible: !this.state.visible
        });
    };

    onChange = (e, index) => {

        const newProbesList = [...this.state.probesList];

        let objField = e.target.name === 'sourceType' ? {sourceType : { type : e.target.value}} : {[e.target.name] : e.target.value} ;

        if ( objField.sourceType ) {
            const sourceType = objField.sourceType;
            objField.connectionType = sourceType.type === 'csv' ? 'manual' : sourceType.type;
        }
        newProbesList[index] = {...newProbesList[index], ...objField};
        this.setState((state, props) => {
            return {probesList: newProbesList,
                    prevObjFromState: state.probesList[index]}
        });
    };

    updateNewTags = (values, id) => {
        let newTags = this.state.tagInputValues;
        newTags[id] = values;
        this.setState({tagInputValues: newTags})
    }
    
    selectOptionOnProbe = (name,value,index, dlnm) => {
        const event = {
            target : {
                name : name,
                value: value
            }
        };
        this.probeValueHandler(event,index);
        this.onChange(event,index)
        if(dlnm && ((name === 'sourceType' && (value === 'mqtt' || value === 'ftp')) || (name === 'dlId' && (this.state.probesList[index].connectionType === 'mqtt' || this.state.probesList[index].connectionType === 'ftp')) )) {
            let dlname = '';
            let sourceValue = value;
            if(name === 'sourceType') {
                dlname = this.props.allDataLoggers.filter(dl => dl.id === dlnm)[0].name;
            } else {
                dlname = this.props.allDataLoggers.filter(dl => dl.id === value)[0].name;
                sourceValue = this.state.probesList[index].connectionType;
            }
            notification.success({
                key: index,
                message: TranslateText("GLOBAL_SETTINGS.PLEASE_RESTART_CONSUMER"),
                description: `${TranslateText("GLOBAL.DATA_LOGGER")}: ${dlname}.  ${TranslateText("GLOBAL.SOURCE_TYPE")}: ${sourceValue}`,
                style: {
                    width: 400,
                    wordBreak: "break-word"
                },
                duration: 0
            });
        }
    };

    handleCloseTag = (selectedTag, index, id) => {
        const { state: { probesList } } = this;
        const data = [...probesList];

        data[index].tags = data[index].tags.filter(tag =>  tag !== selectedTag);
        const newTags = data[index].tags;
        const listOfNewTags = {
            tags: newTags
        };

        const newInputTags = this.state.tagInputValues[id] && this.state.tagInputValues[id].filter(tag => tag !== selectedTag)
        let newInputTagsAll = this.state.tagInputValues;
        newInputTagsAll[id] = newInputTags;

        this.setState({ 
            probesList: data,
            tagInputValues: newInputTagsAll
        });
        this.updateProbe(listOfNewTags, id);
    };

    toggle(panelNumber) {
        this.setState({
            shown: {
                ...this.state.shown,
                [panelNumber]: !this.state.shown[panelNumber]
            }
        });
    }

    handleModalClose = () => {
        this.setState({modal : false})
    };

    probeValueHandler = (e, index) => {

        const { state:{probesList, prevObjFromState}, updateProbe } = this;
        const currentProbe = {...probesList[index]};

        const previousProbe = {...prevObjFromState};

        const objField = e.target.name === 'sourceType' ? {sourceType : { type : e.target.value}} : {[e.target.name] : e.target.value} ;
        const modifiedProbe = {...currentProbe,...objField};

        if(modifiedProbe && modifiedProbe.id) {

            let payload = e.target.name === 'sourceType' ? {sourceType : { type : e.target.value}} : {[e.target.name] : e.target.value} ;
            if (payload.sourceType) {
                const sourceType = payload.sourceType;
                payload.connectionType = sourceType.type === 'csv' ? 'manual': sourceType.type;
            }
            let previousInput = previousProbe[e.target.name] ;

            if(previousInput === undefined && e.target.name !== "dlId"){
                previousInput = modifiedProbe[e.target.name]
            }

            if(previousInput !== e.target.value && e.target.name !== "dlId") {
                this.setState({prevObjFromState : modifiedProbe});
                updateProbe(payload, modifiedProbe.id);
            }
            if(["dlId","tags","installedAt","folderId"].includes(e.target.name)) {
                updateProbe(payload, modifiedProbe.id);
            }

        }

    };

    updateProbe = (payload,probeId) => {
        const { UpdateProbe } = this.props ;
        UpdateProbe(payload,probeId);
    };

    addNewProbe = () => {
        const {state:{probesList}, props: {updateApply}} = this;

        const newProbe = {
            installedAt: null,
            folderId : this.props.selectedId,
            saved : false,
            folderRootId:this.props.selectedId,
            sourceType : { type : 'csv'}
        };

        this.setState({
            probesList: [newProbe,...probesList]
        });

        localStorage.setItem("dataInProbeSetupIsChanged","true");
        updateApply(true);
    };

    onChangePage = pageNumber => {
        const { FilterProbes,probeFilter} = this.props
        FilterProbes(probeFilter,pageNumber)
        
    };

    handleSaveProbe = (event) => {
        event.preventDefault();

        const { props : { CreateProbes, updateApply }, state : { probesList }} = this;
        const { target: { name } } = event;

        let probes = [...probesList] ;

        const unsavedProbes = probes.filter(item => item.saved !== true && !item.id );

        probes.map(item => item.saved = true);

        if(unsavedProbes.length)
            CreateProbes(unsavedProbes);

        this.setState({
            probesList : probes
        });
        localStorage.setItem("dataInProbeSetupIsChanged", "false");
        updateApply(false);
        if (name && name === "nextButton" && probes.length !==0) {
            this.props.history.push("/global-settings/acquisition-setup");
        }
    };

    handleNextButton = (event) => {
        event.preventDefault();
        const { state : { probesList }} = this;
        const { target: { name } } = event;

        let probes = [...probesList] ;

        localStorage.setItem("dataInProbeSetupIsChanged", "false");
        if (name && name === "nextButton" && probes.length !==0) {
            this.props.history.push("/global-settings/acquisition-setup");
        }
    };

    setProbesList = () => {
        const { props : { probes } } = this ;
        this.setState({
            probesList : probes || []
        })
    };
    defaultChecked = probeId => {
        const { props: { checkedProbes } } = this ;
        return checkedProbes.includes(probeId);
    };
    handleDeleteProbe = probeIndex => {
        let { state : { probesList }, props :{ DeleteProbe, updateApply, isApplyDisabled } } = this ;
        const probe = probesList.splice(probeIndex,1);

        localStorage.setItem("dataInAppIsChanged","false");
        localStorage.setItem("dataInProbeSetupIsChanged", "false");

        if(probeIndex === 0 && isApplyDisabled){
            updateApply(false);
        }

        if(probe[0].id){
            DeleteProbe(probe[0].id,probesList);
        }
        this.setState({
            probesList:probesList
        })
    };

    handleDownloadProbesToCSV = () => {

        const { props : { ExportSelectedProbes, checkedProbes, selectedId, ExportAllProbes } } = this;

        let data = {
            folderId : selectedId,
            name     : 'probeExport'
        };

        if(checkedProbes && checkedProbes.length > 0){
            handleDownloadProps("probeExport",ExportSelectedProbes,checkedProbes)
        }
        else
            ExportAllProbes(data)
    }
}

const mapStateToProps = state => ({
    metaDataForProbePagination : state.globalSettings.metaDataForProbePagination,
    checkedProbes              : state.globalSettings.checkedProbes,
    probes                     : state.globalSettings.probes,
    allDataLoggers             : state.globalSettings.allDataLoggers,
    allFilterParams            : state.globalSettings.distinctFilterParams,
    selectedId                 : state.globalSettings.selectedId,
    folderLevels               : state.globalSettings.folderLevels,
    isApplyDisabled            : state.globalSettings.isApplyDisabled,
    probeFilter                : state.globalSettings.probeFilter
    
});

const mapDispatchToProps = dispatch => ({
    DownloadTemplate                : (pathFile)        => dispatch(globalSettingsActions.DownloadTemplate(pathFile)),
    UpdateProbe                     : (probe,probeId)   => dispatch(globalSettingsActions.UpdateProbe(probe,probeId)),
    CreateProbes                    : (payload)         => dispatch(globalSettingsActions.CreateProbes(payload)),
    GetAllDataLoggers               : ()                => dispatch(globalSettingsActions.GetAllDataLoggers()),
    Paginate                        : (page,folderId)   => dispatch(globalSettingsActions.Paginate(page,folderId)),
    GetProbesForFolder              : (folderId)        => dispatch(globalSettingsActions.GetProbesForFolder(folderId)),
    HandleCheckedProbe              : (probeId,checked) => dispatch(globalSettingsActions.HandleCheckedProbe(probeId,checked)),
    HandleCheckAllProbes            : (checkAll)        => dispatch(globalSettingsActions.HandleCheckAllProbes(checkAll)),
    CreateDataLogger                : (payload)         => dispatch(globalSettingsActions.CreateDataLogger(payload)),
    DeleteProbe                     : (probeId,probeList)         => dispatch(globalSettingsActions.DeleteProbe(probeId,probeList)),
    ExportSelectedProbes            : (exportName)      => dispatch(globalSettingsActions.ExportSelectedProbes(exportName)),
    GetAllFilterParams              : ()                => dispatch(globalSettingsActions.GetAllFilterParams()),
    GetLevels                       : (folderId)        => dispatch(globalSettingsActions.GetFolderPaths(folderId)),
    ExportAllProbes                 : (data)            => dispatch(globalSettingsActions.ExportAllProbes(data)),
    uploadProbe                     : (fileList,flag)        => dispatch(uploadProbe(fileList,flag)),
    updateApply                     : (isDisabled)      => dispatch(globalSettingsActions.updateApply(isDisabled)),
    FilterProbes                    : (probeFilter,page)=> dispatch(globalSettingsActions.FilterProbes(probeFilter,page)),
    UnCheckAllCheckedProbes          :()                 => dispatch(globalSettingsActions.UnCheckAllCheckedProbes())
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSettingsProbeSetup);
