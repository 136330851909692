import React, { Component }     from "react";
import { connect }              from "react-redux";

import {Icon}                   from 'antd';

import { treeActions }          from "../../../actions";
import * as type                from "../../../actions/actionTypes";

import "react-contexify/dist/ReactContexify.min.css";

class AnalysisTitle extends Component {

  render() {
      const isVisibleAnalysis = this.props.loadedAnalysis.includes(this.props.id);
      return (
          <span
              className="tree-node-child"
          >
              <span className="tree-node-child-title">{this.props.name}</span>
              <span>
                  <Icon
                      type="eye"
                      onClick={ () => { this.handleOnEyeClick(isVisibleAnalysis,this.props.id); } }
                      className={isVisibleAnalysis ? "eye-normal" : "eye-icon-disabled"}
                      theme={isVisibleAnalysis ? "filled" : "outlined"}
                      style={ { fontSize: "14px" } }
                  />
              </span>
          </span>
      );
  }
  handleOnEyeClick = (isVisibleAnalysis,analysisId) => {

      const loadedAnalysis = this.props.loadedAnalysis;
      if (isVisibleAnalysis) {
          this.props.removeAnalysisById(analysisId, loadedAnalysis);
      } else {
          this.props.getAnalysisById(analysisId);
      }
      if(this.props.history.location.pathname === "/data-source"){
          this.props.history.push('/')
      }
  };
}

const mapDispatchToProps = dispatch => ({

    getAnalysisById      : (id)                 => dispatch({type: type.GET_ANALYSIS_BY_ID, id:id}),
    removeAnalysisById   : (id, loadedAnalysis) => dispatch(treeActions.removeAnalysisById(id, loadedAnalysis))

});

const mapStateToProps = state => ({
    loadedAnalysis    : state.chart.loadedAnalysis
  });

export default connect(
    mapStateToProps,
  mapDispatchToProps
)(AnalysisTitle);
