import React       from 'react';
import SidebarTree from "../../../Tree/TreeV2";

import './ViewerDetailsLeftSidebar.scss';

const ViewerDetailsLeftSidebar = (props) => {
    
    const {
        loadedProbesId = [] , loadedFiltersId = [] ,probeStyles , isFetching = {},
        removeFilter = () => {} , updatedProbe = () => {} , removeProbe = () => {} , addProbe = () => {}, disableProbes
    } = props ;
    return(
        <React.Fragment>
            <div className="viewer-sidebar-container bg-white">
                <SidebarTree
                    selectable={false}
                    loadedProbesId={loadedProbesId}
                    addProbe={addProbe}
                    removeProbe={removeProbe}
                    updatedProbe={updatedProbe}
                    loadedFiltersId={loadedFiltersId}
                    addFilter={props.addFilter}
                    removeFilter={removeFilter}
                    probeStyles={probeStyles}
                    isFetching={isFetching}
                    showDeleteIcon={false}
                    disableProbes={disableProbes}
                />
            </div>
        </React.Fragment>
    )
}
export default ViewerDetailsLeftSidebar;
