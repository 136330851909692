import Paho                                     from 'paho-mqtt';
import { notification }                         from 'antd';
import moment                                   from 'moment';


import * as types                               from './actionTypes';
import {showNotification, analysisLink, TranslateText} from '../config/constants';
import instance                                 from '../config/axiosConf';
import { config as getConfig }                  from "../config/config";
import {handleTreeData} from "./tree";

const baseUrl = getConfig().SOCKET_HOST;

export const addAnalysis = (analysis) =>{
  return({
    type                  : types.ADD_ANALYSIS,
    newEnergyAnalysis     : analysis
});
}

export const updateAnalysis = (analysisList,updatedAnalysis,analysisIndex) =>{
  const updatedList = analysisList.map(
      (analysis, index) => index === analysisIndex ? updatedAnalysis : analysis);
  return ({
    type                    : types.UPDATE_ANALYSIS,
    energyAnalysisList      : updatedList,
    selectedAnalysisIndex   : analysisIndex
});
}
export const removeAnalysis = (analysisList,analysisIndex) => {
  const newList = analysisList.filter((analysis, index) => index !== analysisIndex);

  return({
    type                    : types.REMOVE_ANALYSIS,
    selectedAnalysisIndex   : newList.length-1,
    energyAnalysisList      : newList
  });
}
export const selectAnalysis = analysisIndex => {
  return({
    type                    : types.SELECT_ANALYSIS,
    selectedAnalysisIndex   : analysisIndex
  });
}
export const createEnergyAnalysis = (history) => async (dispatch,getState) => {
  const {
            energyAnalysis: {
                                energyAnalysisList,
                                energyAnalysisName,
                                yAxisName,
                                dataType,
                                unit,
                                energyVec,
                                persistent
            },
            tree:           {
                                structure ,
                                arrayOfCheckedNodes
            }
  } = getState();

  if (!structure) {
    showNotification("error",TranslateText("GLOBAL.ERROR"),TranslateText("ENERGY_ANALYSIS.UPLOAD_FIRST_DATA_SOURCE"));
  }

  let probeIds = arrayOfCheckedNodes.map( node => node.probeId );
  let formulas = energyAnalysisList.map(element => (
                    {
                            startTime               : element.dateRange[0].format('YYYY-MM-DD'),
                            endTime                 : element.dateRange[1].format('YYYY-MM-DD'),
                            probeIds                : probeIds,
                            interval                : element.selectedTimeResolution,
                            type                    : element.type,
                            aggregatorFunc          : element.aggregatorFunc || '',
                            percentileThreshold     : element.percentileThreshold,
                            comment                 : element.comment,
                            x                       : element.inputDefineX,
                            value                   : element.value,
                            displayName             : element.displayName
                    }
  ));

  const formData = {
                            name                    : energyAnalysisName,
                            persistent              : persistent,
                            formulas                : formulas,
                            dataType                : dataType,
                            unit                    : unit,
                            y                       : yAxisName,
                            energyVector            : energyVec
  };

  let userInfo = localStorage.getItem("userInfo");
  let userInfoData = JSON.parse(userInfo);

  const rabbitUrl = `${baseUrl}`;
  const port = getConfig().PORT;
  const wsProtocol = getConfig().WS_PROTOCOL;

    const client = new Paho.Client(
      rabbitUrl,
      port,
      "/ws",
      "myclientid_" + parseInt(Math.random() * 100, 10)
  );
  client.onConnectionLost = responseObject => {
    console.log(responseObject);
  };

  await instance.post(`/api/analysis`,JSON.stringify(formData),{

      onUploadProgress: progressEvent => {
          if(progressEvent){
              const onSuccessfulConnection = () => {
                  let analysisName = formData.name.split(' ').join('_').toLowerCase();
                  let topic = `analysis_${userInfoData.username}_${analysisName}`;
                  client.subscribe(`/topic/${topic}`, {qos: 1});
              };

              let options = {
                  timeout: 15000,
                  userName: userInfoData.code + ":" + userInfoData.username,
                  password: userInfoData.rmqPassword,
                  keepAliveInterval: 36000,
                  useSSL: wsProtocol === "wss",
                  reconnect: true,
                  onSuccess: onSuccessfulConnection,
                  onFailure: message => {
                      console.log("CONNECTION FAILURE - " + message.errorMessage);
                  }
              };

              client.onMessageArrived = message => {
                  const parseMessage = JSON.parse(message.payloadString);
                  if (parseMessage) {
                      notification[parseMessage.status]({
                          key: parseMessage,
                          message: parseMessage.status,
                          description: parseMessage.status === 'success' ?
                              analysisLink(`/energy-analysis?id=${parseMessage.analysisId}`,history,"Analysis " + parseMessage.name + " is finished.") :
                              parseMessage.message,
                          style: {
                              width: 400
                          },

                          duration:0
                      });

                      dispatch(handleTreeData())
                  }
              };
              client.connect(options);
          }
      }
  });
};
export const populateAnalysisView = (analysisId) => async (dispatch) => {

  dispatch(
      {
          type                     : types.GET_ANALYSIS_BY_ID,
          id                       : analysisId
      }
  );

  dispatch(
      {
          type: types.HANDLE_SELECT_ANALYSIS_MENU
      }
      );

  let analysisResponse = await instance.get(`/api/analysis/${analysisId}`);
  const analysis = analysisResponse.data;

  const energyAnalysisList = analysis.formulas.map(formula => (
      {
          type                    : formula.type,
          value                   : formula.value,
          displayName             : formula.displayName,
          name                    : formula.type,
          percentileThreshold     : formula.percentileThreshold,
          inputDefineX            : formula.x,
          comment                 : formula.comment,
          selectedTimeResolution  : formula.interval,
          isActive                : true,
          dateRange               : [moment(formula.startTime, 'YYYY-MM-DD'),moment(formula.endTime, 'YYYY-MM-DD')],
          aggregatorFunc          : formula.aggregatorFunc

      }
      )
  );
  dispatch (
      {
          type                    : types.POPULATE_ANALYSIS_PARAMETERS,
          energyAnalysisName      : analysis.name,
          yAxisName               : analysis.y,
          dataType                : analysis.dataType,
          unit                    : analysis.unit,
          energyVec               : analysis.energyVector,
          energyAnalysisList      : energyAnalysisList,
          persistent              : analysis.persistent
      }
      )
};