import React, {useState, useEffect, useCallback} from 'react';
import {Icon, Button, Tooltip, Modal} from "antd";
import Translate from "../../../Translate/Translate"

import {connect} from "react-redux";
import {dashboard} from "../../../../actions";
import html2canvas from "html2canvas/dist/html2canvas.min";
import jsPDF from "jspdf/dist/jspdf.min";

import {
    LAYOUT,
    ArrowLeftIcon,
    FullScreenIcon,
    LayoutIcon,
    SaveIcon,
    ArrowRightIcon, showNotification, isJson, TranslateText,
    RefreshIcon, closeModalMessage, resetParamsForConfirmMessage
} from "../../../../config/constants";
import {DashboardViewTemplate} from "../DashboardViewLayoutTemplates/DashboardViewLayoutTemplates";

import "./DashboardViewHeader.scss";
import instance from "../../../../config/axiosConf";
import DateRangeSlider from "../../../DateRangeSlider/DateRangeSlider";

const confirm= Modal.confirm;
const DashboardViewHeader = (props) => {

    const {defaultFrom, defaultTo, handleGlobalDateRangeChange, from, to, setDashboardLayoutIsChanged, dashboardLayoutIsChanged, setDashboard, handleLeftSidebar, isVisibleLeftSidebar, setVisibleOrderingModal, setStaticLayout, staticLayout, isClickOnBlack, setIsClickOnBlack, dashboardSliderIsChanged, dashboardWidgetIsClosed} = props;
    const [isExporting, setExporting] = useState(false);
    const [isCloning, setCloning] = useState(false);
    const [activeLayout,setActiveLayout] = useState(null);


    useEffect(() => {
        props.HandleDashboardChanges(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Modal Toggle
    const showModalInfoOfDashboard = () => {
        props.ModalForInfoOfSelectedDashboard()
    };

    //Set dashboard layout
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const activeTemplateLayout = id => {
        const {dashboard:{options:{layoutId}}} = props
        setDashboardLayoutIsChanged(layoutId !== id );
        setActiveLayout(id);
        props.SetDashboardViewLayout(id);
    };

    const handleDateRangeChange = (arg) => {

        const [from, to] = arg;

        const payload = {
            from,
            to
        };

        if(arg[0] !== props.from || arg[1] !== props.to){
            handleGlobalDateRangeChange(payload)
        }
    };

    const handleGlobalDateRangeUpdate = () => {
        const {dashboard, setOnFalseAllBooleanVariables} = props;

        if (dashboard) {
            let dashboardOptions = isJson(dashboard.options) ? JSON.parse(dashboard.options) : dashboard.options ? dashboard.options : {};
            dashboardOptions = {
                ...dashboardOptions,
                layoutId : activeLayout ? activeLayout : (dashboardOptions && dashboardOptions.layoutId) ? dashboardOptions.layoutId : 1
            };

            const payload = {
                id: dashboard.id,
                name: dashboard.name,
                from: from,
                to: to,
                options : JSON.stringify(dashboardOptions)
            };
            setOnFalseAllBooleanVariables();
            props.updateDashboard(payload)
                .then(response => {
                showNotification('success',TranslateText("GLOBAL.SUCCESS"), TranslateText("DASHBOARD.SAVED"));
                localStorage.setItem("dashboardViewerIsChanged","false");
                setStaticLayout(dashboardOptions);
                props.DestroyBackdropModalAndAllModal();

                let newDashboardObject= {...response.data.data};
                newDashboardObject.options=JSON.parse(newDashboardObject.options);
                setDashboard(newDashboardObject);
                props.clearActiveLayout()
            });
            detachViewersFromDashboard();
            props.updateGlobalDateRange(payload);
        }
    };

    const detachViewersFromDashboard = () => {
        const viewersForDetach = props.viewersForDetach;
        viewersForDetach.forEach(object => props.detachViewerFromDashboard(object.viewId, object.dashId));
    };

    const handleDashboardExport = () => {
        setExporting(true);
        const filename = props.dashboard.name + "_export";
        const promises = [];
        const collection = document.getElementsByClassName("dashboard-widget");
        const collectionOfCharts = document.getElementsByClassName('dashboard-view-plot-chart');
        const collectionOfDates = document.getElementsByClassName('ant-calendar-picker-input');

        if (!collection.length) {
            setExporting(false);
            return; 
        }


        let pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
        });
        
        pdf.setFontSize(20);
        var textWidth = pdf.getStringUnitWidth(props.dashboard.name) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        var textOffset = (pdf.internal.pageSize.width - textWidth) / 2;

        pdf.text(`${props.dashboard.name}`, textOffset, 40);
        pdf.setFontSize(14);
        var splitDescription = pdf.splitTextToSize(props.dashboard.description, pdf.internal.pageSize.width - 40);
        pdf.text(splitDescription, 20, 65);
        pdf.addPage('a4', 'landscape');

        for (let a = 0; a < collection.length; a++) {
            promises.push(new Promise((resolve, reject) => {
                const svg = collection[a];
                const chartPhoto = collectionOfCharts[a];
                const dateLeft = collectionOfDates[2*a+2];
                const dateRight = collectionOfDates[2*a+3];
                html2canvas(chartPhoto, {scale: 6})
                    .then(canvas => {
                        pdf.text(20, 25, svg.innerText.split('-')[0]);
                        pdf.setFontSize(12);
                        pdf.text(20, 35, `${dateLeft.value} - ${dateRight.value}`);
                        pdf.setFontSize(14);
                        pdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 15, 40, 250, 150);
                        a < collection.length-1 && pdf.addPage('a4', 'landscape');
                        resolve(true);
                    })
            }));
        }

        Promise.all(promises)
            .then(() => {
                pdf.save(filename);
            })
            .then(() => {
                setExporting(false);
            });
    };

    const handleClone = () => {
        setCloning(true);
        instance.put(`api/dashboards/clone/${props.dashboard.id}`)
            .then(response => {
            if (response.data) {
                setTimeout(() => {
                    setCloning(false);
                    showNotification(
                        "success",
                        <Translate string={"GLOBAL.DASHBOARD_CLONED"} />,
                        <Button onClick={() => window.location.replace(`/dashboard-view?dashId=${response.data.id}`)}><Translate string={"GLOBAL.GO_TO"} /> {response.data.name} </Button>
                    )
                }, 400);
            }
        });
    };

    const handleLayoutConfirmMessage = useCallback(
        (name) => {
            setIsClickOnBlack(false);
            if (dashboardLayoutIsChanged === true) {
                return confirm({
                    title: closeModalMessage.title,
                    content: closeModalMessage.content,
                    okText: closeModalMessage.okText,
                    okType: 'danger',
                    cancelText: closeModalMessage.cancelText,
                    onOk: () => {
                        resetParamsForConfirmMessage();
                        localStorage.setItem("dashboardViewerIsChanged","false")
                        props.DestroyBackdropModalAndAllModal();
                        activeTemplateLayout(staticLayout.layoutId);
                    }
                });
            }
            if(name === "clickOnIcon") {
                props.ModalForSelectLayout();
                return;
            }
            props.DestroyBackdropModalAndAllModal();
        },
        [dashboardLayoutIsChanged, props, setIsClickOnBlack, activeTemplateLayout, staticLayout.layoutId],
    )

    useEffect(() => {
        if(isClickOnBlack) {
            handleLayoutConfirmMessage()
        }
    },[isClickOnBlack,handleLayoutConfirmMessage]);

    let hasViewers = props.dashboardViewers.some(viewer => !viewer.type);

    let isChangesMade = props.isDashboardChanged;

    return (
            <div className="dashboard-view-header grid">
                <div className={'dashboard-view-header-sidebar-button'}>
                    {isVisibleLeftSidebar ?
                        <Button type={'button'} onClick={handleLeftSidebar}>
                            <ArrowLeftIcon/><ArrowLeftIcon/><Translate string={"GLOBAL.HIDE_PROBES"} />
                        </Button> :
                        <Button type={'button'} onClick={handleLeftSidebar}>
                            <Translate string={"GLOBAL.SHOW_PROBES"} /> <ArrowRightIcon/><ArrowRightIcon/>
                        </Button>}
                </div>
                <div className='dashboard-view-info flex flex-align-items-center js-modal-dashboard-info'>
                    <Tooltip placement="top" title={<Translate string={"GLOBAL.DESCRIPTION"} />}>
                        <Button
                            id={"dashboard-info"}
                            onClick={showModalInfoOfDashboard}
                            disabled={props.IsVisibleModalForSelectLayout}>
                            <Icon type="info-circle"/>
                        </Button>
                    </Tooltip>

                    <p className={'dashboard-name-dashboard'}>
                        {props.dashboard.name}
                    </p>
                    <div className={props.ModalForInfoOfDashboard ? "dashboard-view-info-description dashboard-view-info-show": "dashboard-view-info-description"}>
                        <Button type={'button'} onClick={props.DestroyBackdropModalAndAllModal} className={'dashboard-view-info-close-button'}>
                            <Icon type={'close'}/>
                        </Button>
                        <div className="dashboard-view-info-description-content">
                           <h3>{props.dashboard.description}</h3> 
                        </div>
                    </div>
                </div>

                <DateRangeSlider
                    from={defaultFrom}
                    to={defaultTo}
                    showTimeShortcuts={true}
                    onChange={handleDateRangeChange}
                />

                <div className="flex flex-align-items-center dashboard-view-cta">
                    {!props.fullStatus ?
                        <>
                            <div className="dashboard-view-cta-save">
                                <Tooltip placement="top" title={<Translate string={"GLOBAL.SAVE"}/>}>
                                    <Button onClick={handleGlobalDateRangeUpdate}>
                                        <SaveIcon/>
                                    </Button>
                                </Tooltip>
                            </div>
                            <Tooltip placement="top" title={<Translate string={"GLOBAL.REFRESH"}/>}>
                                <Button onClick={()=>props.setRefreshSeries(true)}>
                                    <RefreshIcon/>
                                </Button>
                            </Tooltip>

                            <div
                                className={props.IsVisibleModalForSelectLayout ? "dashboard-view-cta-select-layout js-modal--select-layout" : "dashboard-view-cta-select-layout"}>
                                <Tooltip placement="top" title={isChangesMade || dashboardSliderIsChanged || dashboardWidgetIsClosed ? <Translate string={"GLOBAL.UNSAVED_CHANGE"}/> : <Translate string={"GLOBAL.LAYOUT"}/>}>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        !props.IsVisibleModalForSelectLayout  
                                            ? props.ModalForSelectLayout()
                                            : handleLayoutConfirmMessage();
                                        }}
                                        id={'select-layout'}
                                        disabled={isChangesMade || dashboardSliderIsChanged || dashboardWidgetIsClosed}>
                                        <LayoutIcon/>
                                    </Button>
                                </Tooltip>
                                <div className={"modal-for-select-layout"}>
                                    <h5><Translate string={"DASHBOARD.SELECT_LAYOUT"}/></h5>
                                    <p><Translate string={"DASHBOARD.SELECT_LAYOUT_DESCRIPTION"}/></p>
                                    <div className="dashboard-view-template">
                                        {LAYOUT.map((layout, index) => (
                                            <DashboardViewTemplate
                                                key={index}
                                                activeLayout={() => activeTemplateLayout(layout.id)}
                                                status={layout.id === (props.activeLayout.length > 0 && props.activeLayout[0].id)}
                                                data={layout}
                                            />
                                        ))}
                                    </div>
                                    <div className={"modal-for-select-layout-footer"}>
                                        <Button type="button" className={"cancel-button-footer-layout"} onClick={handleLayoutConfirmMessage}> <Translate string={"GLOBAL.MODALS_CANCEL"}/></Button>
                                        <Button type="button" className={"btn btn-border-green"} onClick={handleGlobalDateRangeUpdate}><Translate string={"GLOBAL.SAVE"}/></Button>
                                    </div>
                                </div>
                            </div>

                            <Tooltip placement="top" title={isChangesMade || dashboardSliderIsChanged || dashboardWidgetIsClosed ? <Translate string={"GLOBAL.UNSAVED_CHANGE"}/> : <Translate string={"GLOBAL.REARRANGE_WIDGETS"}/>}>
                                <Button onClick={() => setVisibleOrderingModal(true)} disabled={isChangesMade || dashboardSliderIsChanged || dashboardWidgetIsClosed}>
                                    {
                                            <Icon type={"build"} style={{color: '#5b9dc8', fontSize: '1.2em'}}/>
                                    }
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={<Translate string={"GLOBAL.FULL_SCREEN"}/>}>
                                <Button onClick={() => props.setFullScreen()}>
                                    <FullScreenIcon/>
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={hasViewers ? <Translate string={"GLOBAL.EXPORT_DATA"}/> : <Translate string={"DASHBOARD.CANT_EXPORT_EMPTY_DASHBOARD"}/>}>
                                <Button onClick={handleDashboardExport} disabled={isExporting || !hasViewers}>
                                    {
                                        !isExporting ?
                                            <Icon type="download" style={{color: '#5b9dc8', fontSize: '1.5em'}}/>
                                            :
                                            <Icon type="loading" style={{color: '#5b9dc8', fontSize: '1.5em'}}/>
                                    }
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={<Translate string={"GLOBAL.CLONE"}/>}>
                                <Button onClick={handleClone} disabled={isCloning}>
                                    {
                                        !isCloning ?
                                            <Icon type={"copy"} style={{color: '#5b9dc8', fontSize: '1.2em'}}/>
                                            :
                                            <Icon type={"loading"} style={{color: '#5b9dc8', fontSize: '1.2em'}}/>
                                    }
                                </Button>
                            </Tooltip>

                        </> :
                        <Tooltip placement="top" title={<Translate string={"GLOBAL.EXIT_FULL_SCREEN"}/>}>
                            <Button onClick={() => props.setFullScreen()}>
                                <FullScreenIcon/>
                            </Button>
                        </Tooltip>
                    }

                </div>
            </div>
    )
};

const mapStateToProps = state => ({
    ModalForInfoOfDashboard: state.dashboard.ModalForInfoOfDashboard,
    isDashboardChanged: state.dashboard.isDashboardChanged
});
const mapDispatchToProps = dispatch => ({
    ModalForInfoOfSelectedDashboard: () => dispatch(dashboard.ModalForInfoOfSelectedDashboard()),
    ModalForSelectLayout: () => dispatch(dashboard.ModalForSelectLayout()),
    DestroyBackdropModalAndAllModal: () => dispatch(dashboard.DestroyBackdropModalAndAllModal()),
    handleLeftSidebarOnDashboardView: () => dispatch(dashboard.handleLeftSidebarOnDashboardView()),
    SetDashboardViewLayout: (id) => dispatch(dashboard.SetDashboardViewLayout(id)),
    updateGlobalDateRange: (payload) => dispatch(dashboard.UpdateDashboard(payload)),
    updateViewersPosition: (payload) => dispatch(dashboard.updateViewersPosition(payload)),
    detachViewerFromDashboard: (viewerId, dashboardId) => dispatch(dashboard.DetachViewerFromDashboard(viewerId, dashboardId)),
    clearViewerDetachList: () => dispatch(dashboard.ClearViewerDetachList()),
    updateDashboard     : (payload) => dashboard.updateDashboard(payload),
    clearActiveLayout     : () => dispatch(dashboard.ClearActiveLayout()),
    HandleDashboardChanges: (newState) => dispatch(dashboard.HandleDashboardChanges(newState))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardViewHeader);
