import jwtDecode from 'jwt-decode';

export const parseAnalysisFilters = filters =>
  JSON.stringify(
    filters.map(filter => ({
      type: filter.type,
      min: parseFloat(filter.minValue),
      max: parseFloat(filter.maxValue)
    }))
  );

export const changeProbeNameWithId = (data, inputValue) => {
  data.length &&
    data.forEach(dataSource => {
      if (dataSource.children) {
        inputValue = changeProbeNameWithId(dataSource.children, inputValue);
      }
      {
        const regExp = new RegExp(dataSource.title, 'g');

        inputValue = inputValue.replace(regExp, `$probeid=${dataSource.id}$`);
      }
    });

  return inputValue;
};

export const jwtDecoder = token => jwtDecode(token);

export const isTokenExpired = expiryDate =>
  expiryDate < Math.round(new Date().getTime() / 1000);

export const getRandomColor = () => {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
