import React, { Component } from "react";
import { LocaleContext } from "./locale-context";

import en from "./Language/en.json";
import it from "./Language/it.json";


export default class Translate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            langs: {
                en,
                it
            }
        };
    }
    render() {
        const {langs} = this.state;
        const {string} = this.props;
        return (
            <LocaleContext.Consumer>
                {
                    value => {
                        let key = value ? value : localStorage.getItem("local");
                        return langs[key][string] !== undefined && langs[key][string].length !== 0 ? langs[key][string] : langs.en[string]
                    }

                }
            </LocaleContext.Consumer>
        );
    }
}

