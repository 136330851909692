import * as types from '../actions/actionTypes';

import moment from 'moment';


const initialState = {
    HandleModalForCreateNewDashboard: false,
    ModalForInfoOfDashboard: false,
    DashboardBackdrop: false,
    updateCalendarUsingSlider: null,
    HandleModalForSelectLayout: false,
    activeDashboardViewLayout: [],
    startGlobalDate: null,
    endGlobalDate: null,
    startGlobalUnixDate: null,
    endGlobalUnixDate: null,
    handleSidebarOnDashboardView: false,
    dashboardsList: [],
    viewerList : [],
    ModalDateRange  : [moment().subtract(5, 'month'), moment()],
    viewersForDetach: [],
    isDashboardChanged: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.HANDLE_MODAL_FOR_CREATE_NEW_DASHBOARD:
            return {
                ...state,
                HandleModalForCreateNewDashboard: !state.HandleModalForCreateNewDashboard,
                ModalDateRange: state.ModalDateRange
            };
        case types.HIDE_MODAL_FOR_DASHBOARD_ON_BACK_BUTTON:
            return {...state,HandleModalForCreateNewDashboard: action.HandleModalForCreateNewDashboard};
        case types.HANDLE_MODAL_FOR_INFO_OF_SELECTED_DASHBOARD:
            return {
                ...state,
                ModalForInfoOfDashboard: !state.ModalForInfoOfDashboard,
                DashboardBackdrop: !state.DashboardBackdrop
            };
        case types.HANDLE_MODAL_FOR_SELECT_LAYOUT_ON_DASHBOARD:
            return {
                ...state,
                HandleModalForSelectLayout: !state.HandleModalForSelectLayout,
                DashboardBackdrop: !state.DashboardBackdrop
            };
        case types.HANDLE_SELECTED_DASHBOARD_VIEW:
            return {
                ...state,
                activeDashboardViewLayout: action.activeDashboardViewLayout
            };
        case types.HANDLE_SIDEBAR_FOR_PROBES_ON_DASHBOARD_VIEW:
            return {
                ...state,
                handleSidebarOnDashboardView: !state.handleSidebarOnDashboardView
            };
        case types.UPDATE_SELECTED_DASHBOARD_CALENDAR_DATE:
            return {
                ...state,
                updateCalendarUsingSlider: action.updateCalendarUsingSlider
            };
        case types.UPDATE_GLOBAL_SLIDER_AND_CALENDAR_ON_DASHBOARD:
            return {
                ...state,
                startGlobalDate: action.startGlobalDate,
                endGlobalDate: action.endGlobalDate,
                startGlobalUnixDate: action.startGlobalUnixDate,
                endGlobalUnixDate: action.endGlobalUnixDate,
            };
        case types.DESTROY_BACKDROP_AND_ALL_MODAL:
            return {
                ...state,
                ModalForInfoOfDashboard: false,
                HandleModalForSelectLayout: false,
                DashboardBackdrop: false
            };
        case types.GET_ALL_DASHBOARDS :
            return{
                ...state,
                dashboardsList: action.dashboardsList
            };
        case types.UPDATE_DASHBOARDS_LIST :
            return {
                ...state,
                dashboardsList : action.dashboardsList
            }
        case types.GET_ALL_DASHBOARD_LAYOUTS :
            return {
                ...state,
                activeDashboardViewLayout: action.activeDashboardViewLayout
            }
        case types.GET_ALL_VIEWERS_FOR_DASHBOARD:
            return {
                ...state,
                viewerList : action.viewers
            }
        case types.UPDATE_DASHBOARD_VIEWER:
            return {
                ...state,
                viewerList : action.viewers
            };
        case types.UPDATE_DASHBOARD_VIEWER_LIST:
            return {
                ...state,
                viewerList: action.viewers
            };
        case types.UPDATE_DASHBOARD_VIEWER_LIST_USE_DND:
            return {
                ...state,
                viewerList: action.viewerList
            };
        case types.LOCAL_DETACH_VIEWER:
            return {
                ...state,
                viewerList: action.viewers,
                viewersForDetach: action.viewersForDetach
            };
        case types.HANDLE_DASHBOARD_CHANGES:
            return {
                ...state,
                isDashboardChanged: action.isDashboardChanged
            }
        default:
            return state;
    }
};