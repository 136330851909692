import axios from 'axios';
import * as userAction from "../actions/user";
import {config as getConfig} from "../config/config";
import {showNotification, TranslateText} from "./constants";

const baseUrl = getConfig().API_HOST;

const instance = axios.create({
    baseURL: baseUrl,
});
instance.defaults.headers.common['Accept'] = "application/json";
instance.defaults.headers.post['Content-Type'] = "application/json, charset=utf-8";
instance.defaults.adapter = require('axios/lib/adapters/http');

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    if(token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    if(`${baseUrl}/api/oauth/token` === config.url || "/api/oauth/token" === config.url){
        return config;
    }
    return userAction.checkToken(config)
},  (error) => {
    return Promise.reject(error);
});
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    const errorResponse = error.response;

    if (!errorResponse) {
        showNotification('error', TranslateText("GLOBAL.ERROR"), TranslateText("GLOBAL.ERROR_MESSAGE"));
        return Promise.reject(error);
    }

    //Refresh token has expired redirect to login page
    if(((errorResponse && errorResponse.status === 401) || (errorResponse.data.message && errorResponse.data.message.includes('401'))) && window.location.pathname !== "/login") {
      let selectedLanguage = localStorage.getItem("local");
      localStorage.clear();
      localStorage.setItem("local",selectedLanguage);
      window.location.href = "/";
    }

    if(errorResponse && errorResponse.status === 404) {
        showNotification('error', TranslateText("GLOBAL.ERROR_NOT_FOUND"), TranslateText("GLOBAL.ERROR_MESSAGE_NOT_FOUND"));
        return Promise.reject(error);
    }

    errorResponse && errorResponse.data.message ?
        showNotification('error', TranslateText("GLOBAL.ERROR"), errorResponse.data.message)
        :
        showNotification('error', TranslateText("GLOBAL.ERROR"), TranslateText("GLOBAL.ERROR_MESSAGE"));

    return Promise.reject(error);
});

export default instance;
