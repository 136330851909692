import React from "react";
import {connect} from 'react-redux';
import './Datalogger.scss'
import DataLoggerItem from "./DataLogerItem/DataLogerItem";
import * as globalSettingActions from "../../../../../actions/globalSettings"
import {Button, Icon, Pagination, Tooltip} from "antd";

import EditDataloggerModal from "./EditDataLoggerModal/EditDataLoggerModal";
import CreateNewDataLoggerModal from "../../../CreateNewDataLoggerModal/CreateNewDataLoggerModal";
import {resetParamsForConfirmMessage} from "../../../../../config/constants";
import Translate from "../../../../Translate/Translate";


class Datalogger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenuItem: "",
            selectedItem: props.selectedDataLogger ? props.selectedDataLogger : null,
            arrayDataLoggerIndex: null,
            allowSave: false,
            newDataloggerModal: false,
            editDataloggerModal: props.openEditDL ? props.openEditDL : false,
        };
    };

    onChange = pageNumber => {
        console.log('page number:', pageNumber)
    };

    handleRemoveItem = (id) => {
        this.props.DeleteDataLoggerById(id);
    };
    componentDidMount() {
        const {dataLoggers, GetAllDataLoggers} = this.props;
        if (dataLoggers) {
            GetAllDataLoggers();
        }
    }

    render() {
        const {props: {dataLoggers,
            mqttIdInEditDl,
            CreateDataLogger,
            paginationDataLogger,
            CreateMqttConnection,
            CreateFtpConnection,
            DeleteMqttConnection,
            DeleteFtpConnection,
            UpdateDataLogger,
            UpdateMqtt,
            UpdateFtp,
            GetMqttById,
            GetFtpById,
            ExportDataLogger
        },
            state: {selectedItem}} = this;
        return (
            paginationDataLogger ?
            <React.Fragment>
                <div className="data-logger-wrap flex flex-direction-column">
                    <div className="data-logger-wrap-content">
                        {dataLoggers && dataLoggers.map((item, index) => (
                            <DataLoggerItem key={index}
                                            id={item.id}
                                            name={item.name}
                                            where={item.where}
                                            connection={item.connections}
                                            serialNumber={item.serialNumber}
                                            responsibility={item.responsibility}
                                            description={item.description}
                                            when={item.when}
                                            onRemove={()=>this.handleRemoveItem(item.id)}
                                            brand={item.brand}
                                            dropdownIndex={item.id}
                                            getMqtt={GetMqttById}
                                            getFtp={GetFtpById}
                                            openModal={() => {this.openModal("editDataloggerModal",item)}}
                                            handleDownloadDataLoggers={() => ExportDataLogger(item.id)}
                            >
                            </DataLoggerItem>
                        ))}
                    </div>
                    <div className='footer flex flex-justify-content-center flex-align-items-center'>
                        <div className={'footer-pagination'}>
                            <Pagination
                                defaultCurrent={1}
                                total={paginationDataLogger.totalRecords}
                                onChange={this.onChangePage}
                                size={'small'}
                                current={paginationDataLogger.currentPage} />
                        </div>
                        <div className={'footer-save-add-buttons'}>
                            <Tooltip placement="topRight" title={<Translate string={"GLOBAL.ADD_NEW_DATA_LOGGER"}/>}>
                            <Button className='btn btn-green'
                                    type='button'
                                    onClick={() => this.openModal("newDataloggerModal")}
                            >
                                <Icon type="plus" style={{ fontSize: '17px'}} />
                            </Button>
                            </Tooltip>
                        </div>

                    </div>
                </div>
                {
                    this.state.newDataloggerModal &&
                    <CreateNewDataLoggerModal
                        onClose={() => this.closeModal("newDataloggerModal")}
                        createDataLogger={CreateDataLogger}
                    />
                }
                {
                    this.state.editDataloggerModal && <EditDataloggerModal
                        onClose={() => this.closeModal("editDataloggerModal")}
                        mqttTabKey = {mqttIdInEditDl}
                        dataModal={selectedItem}
                        createMqttConnection={CreateMqttConnection}
                        createFtpConnection={CreateFtpConnection}
                        deleteMqqtConnection={DeleteMqttConnection}
                        deleteFtpConnection={DeleteFtpConnection}
                        updateDataLogger={UpdateDataLogger}
                        updateMqtt={UpdateMqtt}
                        updateFtp={UpdateFtp}
                    />
                }
            </React.Fragment> : null
        )
    }
    closeModal = (modalTag) => {
        resetParamsForConfirmMessage();
        if(modalTag === "newDataloggerModal"){
            this.setState({newDataloggerModal : false})
        }
        else if(modalTag === "editDataloggerModal"){
            this.setState({editDataloggerModal : false})
        }
    };

    openModal = (modalTag,selectedItem) => {
        if(modalTag === "newDataloggerModal") {
            this.props.ResetVariableCreatedDatalogger();
            this.setState({newDataloggerModal: true})
        }
        else if("editDataloggerModal") {
            this.setState({editDataloggerModal: true, selectedItem:selectedItem})
        }
    };
    onChangePage = pageNumber => {
       
       const { FilterDataLogger ,dataLoggerFilterObject } = this.props
       FilterDataLogger(dataLoggerFilterObject,pageNumber)
    };
}

const mapStateToProps = (state) => ({
    dataLoggers: state.globalSettings.dataLoggers,
    paginationDataLogger : state.globalSettings.paginationDataLogger,
    closeModalForNewDataLogger: state.globalSettings.closeModalForNewDataLogger,
    dataLoggerFilterObject: state.globalSettings.dataLoggerFilterObject
});
const mapDispatchToProps = dispatch => ({
    GetAllDataLoggers: () => dispatch(globalSettingActions.GetAllDataLoggers()),
    DeleteDataLoggerById: (id) => dispatch(globalSettingActions.DeleteDataLoggerById(id)),
    UpdateDataLogger: (itemObj) => dispatch(globalSettingActions.UpdateDataLogger(itemObj)),
    CreateMqttConnection: (payload) => dispatch(globalSettingActions.CreateMqttConnection(payload)),
    CreateFtpConnection: (payload) => dispatch(globalSettingActions.CreateFtpConnection(payload)),
    DeleteMqttConnection: (payload) => dispatch(globalSettingActions.DeleteMqttConnection(payload)),
    DeleteFtpConnection: (payload) => dispatch(globalSettingActions.DeleteFtpConnection(payload)),
    CreateDataLogger     : (payload) => dispatch(globalSettingActions.CreateDataLogger(payload)),
    PaginateDataLogger   : (pageNumber) => dispatch(globalSettingActions.PaginateDataLogger(pageNumber)),
    GetMqttById   : (mqttId) => dispatch(globalSettingActions.GetMqttById(mqttId)),
    GetFtpById   : (ftpId) => dispatch(globalSettingActions.GetFtpById(ftpId)),
    UpdateMqtt   : (mqtt) => dispatch(globalSettingActions.UpdateMqtt(mqtt)),
    UpdateFtp   : (ftp) => dispatch(globalSettingActions.UpdateFtp(ftp)),
    ExportDataLogger : (dataLoggerId) => dispatch(globalSettingActions.ExportDataLogger(dataLoggerId)),
    ResetVariableCreatedDatalogger : () => dispatch(globalSettingActions.ResetVariableCreatedDatalogger()),
    FilterDataLogger : (dataLoggerFilterObject,page) => dispatch(globalSettingActions.FilterDataLogger(dataLoggerFilterObject,page))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Datalogger);
