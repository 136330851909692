import React, {useState, useEffect} from 'react' ;

import FilterContainer from "../RightSidebarFilter/FilterContainer/FilterContainer";
import FilterTypeSelector from "../RightSidebarFilter/FilterTypeSelector/FilterTypeSelector";
import SelectedFilterContainer from "../RightSidebarFilter/SelectedFilterContainer/SelectedFilterContainer";
import {handleClickOnCreateFilter, handleClickOnTestFilter} from '../../../actions/filterAnalyses';
import {Button, Checkbox, message} from "antd";
import './FilterRightSideBar.scss';
import {InputText, DataPickerInput} from "../../FormInput/FormInput";
import {TranslateText} from "../../../config/constants";

const FilterRightSideBar = (props) => {

    const [filterTypesList, setFilterTypesList] = useState([]);
    const [currentFilterIndex, setCurrentFilterIndex] = useState(0);
    const [filterName, setFilterName] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [persistentFilter, setPersistentFilter] = useState(false);
    const [makingFilter, setMakingFilter] = useState(false)

    useEffect(() => {
        setCurrentFilterIndex(filterTypesList.length - 1);
    }, [filterTypesList.length]);

    const handleFilterType = (filterType) => {
        setFilterTypesList([...filterTypesList, filterType]);
    };
    const handleSelectFilterType = (filterTypeIndex) => {
        setCurrentFilterIndex(filterTypeIndex);
    };
    const handleRemoveFilterType = (filterTypeIndex) => {
        setFilterTypesList(filterTypesList.filter((_, index) => index !== filterTypeIndex));
    };
    const filterTypeParametersHandler = (event) => {
        event.preventDefault();
        const newList = filterTypesList.map((filterType, index) => {
            if (currentFilterIndex === index)
                return {...filterTypesList[currentFilterIndex], [event.target.name]: event.target.value};
            return filterType;
        });
        setFilterTypesList(newList);
    };
    const handleClickOnFilterTest = () => {
        setMakingFilter(true);
        const payload = {
            filtersList: filterTypesList,
            filterName: filterName,
            parentId: props.checkedProbe.id,
            startTime: fromDate,
            endTime: toDate
        };
        let minIsHiger=[];
        if((filterName && filterName.length !== 0) && (fromDate && fromDate.length !== 0) && (toDate && toDate.length !== 0)) {
            // eslint-disable-next-line
            payload.filtersList.map((filterItem,index)=> {
                if(filterItem.minValue.length === 0){
                    filterItem.minValue = "0";
                }
                if(filterItem.maxValue.length === 0){
                    filterItem.maxValue = "0";
                }
                if(parseFloat(filterItem.maxValue) <= parseFloat(filterItem.minValue)){
                    minIsHiger.push(true);
                }
            });

            if(minIsHiger.length === 0) {
                handleClickOnTestFilter(payload).then(response => {
                    const filter = response.data.filterData;
                    filter.folderId = props.checkedProbe.folderRootId;
                    filter.test = true;
                    filter.name = filterName;
                    filter.series = response.data.seriesData;
                    props.addFilterToTree([filter]);
                    message.success(TranslateText("FILTERING.SAVED"));
                })
                .then(() => {
                    setMakingFilter(false)
                });
                return
            }
            message.error(TranslateText("FILTERING.MIN_MAX_ERROR"));
            setMakingFilter(false);
            return
        }
        message.error(TranslateText("FILTERING.ERROR_MESSAGE_FILTER_NAME"));
        setMakingFilter(false);
    };
    const handleClickOnFilterCreate = () => {
        setMakingFilter(true);
        const payload = {
            filtersList: filterTypesList,
            filterName: filterName,
            persistentFilter: persistentFilter,
            parentId: props.checkedProbe.id,
            startTime: fromDate,
            endTime: toDate,
            parentName: props.checkedProbe.name
        };
        let minIsHiger=[];
        if((filterName && filterName.length !== 0) && (fromDate && fromDate.length !== 0) && (toDate && toDate.length !== 0)) {
            // eslint-disable-next-line
            payload.filtersList.map((filterItem,index)=> {
                if(filterItem.minValue.length === 0){
                   filterItem.minValue = "0";
                }
                if(filterItem.maxValue.length === 0){
                    filterItem.maxValue = "0";
                }
                if(parseFloat(filterItem.maxValue) <= parseFloat(filterItem.minValue)){
                   minIsHiger.push(true);
                }
            });

            if(minIsHiger.length === 0) {
                handleClickOnCreateFilter(payload).then(response => {
                    const filterData = response.data.filterData;
                    filterData.folderId = props.checkedProbe.folderRootId;
                    props.addFilterToTree([filterData]);
                    message.success(TranslateText("FILTERING.SAVED"));
                })
                .then(() => {
                    setMakingFilter(false);
                });
                return
            }
            message.error(TranslateText("FILTERING.MIN_MAX_ERROR"));
            setMakingFilter(false);
            return
        }
        message.error(TranslateText("FILTERING.ERROR_MESSAGE_FILTER_NAME"));
        setMakingFilter(false);
    };
    const handlePersistentCheckbox = (event) =>{
        setPersistentFilter(event.target.checked)
    }
    return (
        <div className={'filter-right-sidebar-wrap'}>
            <div className={'filter-container-wrapper'} style={{height :'100%', position:"absolute",overflowY: 'auto', width:"100%", padding: "10px"}} >
                <div className={"right-sidebar-title"}>
                    <div
                        className={"flex flex-align-items-center flex-justify-content-space-between right-sidebar-title-header"}>
                        <h2>Filter</h2>
                    </div>
                    <div className="right-sidebar-filters-body sidebar-filters-body">
                        <InputText
                            label={'Filter name'}
                            name={'name'}
                            handleOnChange={event => setFilterName(event.target.value)}
                            value={filterName}
                        />
                        <DataPickerInput
                            label={"From"}
                            name={"from"}
                            onChangeData = {(event,stringDate) => {setFromDate(stringDate)}}
                        />
                        <DataPickerInput
                            label={"To"}
                            name={"to"}
                            onChangeData = {(event,stringDate) => {setToDate(stringDate)}}
                        />
                    </div>
                </div>
                <div className={"right-sidebar-probes-styles"}>
                    <div
                        className={"flex flex-align-items-center flex-justify-content-space-between right-sidebar-title-header"}>
                    </div>
                </div>

                <div className={"right-sidebar-filter-container"}>
                    <div
                        className={"flex flex-align-items-center flex-justify-content-space-between right-sidebar-title-header"}>
                        <FilterTypeSelector handleFilterType={handleFilterType} checkedProbe={props.checkedProbe}/>
                    </div>
                    <div
                        className={"flex flex-align-items-center flex-justify-content-space-between right-sidebar-title-header"}>
                        <FilterContainer filtersList={filterTypesList} selectedFilterIndex={currentFilterIndex}
                                         selectFilterType={handleSelectFilterType}
                                         removeFilterType={handleRemoveFilterType}/>
                    </div>
                    <SelectedFilterContainer
                        selectedFilterType={filterTypesList[currentFilterIndex]}
                        selectedFilterIndex={currentFilterIndex}
                        getParametersValue={filterTypeParametersHandler}
                    />
                </div>
                <div className={"flex-align-items flex-direction-column"}>
                    <Checkbox
                        checked={persistentFilter}
                        onChange={handlePersistentCheckbox}
                    >
                        {TranslateText("GLOBAL.PERSISTENT")}
                    </Checkbox>
                    <div className={'filter-footer-buttons'}>
                        <Button
                            onClick={handleClickOnFilterTest}
                            disabled={!filterTypesList.length || !props.checkedProbe || makingFilter || persistentFilter}
                            className={'test-button'}
                        >Test</Button>
                        <Button
                            onClick={handleClickOnFilterCreate}
                            disabled={!filterTypesList.length || !props.checkedProbe || makingFilter}
                            className={'create-button'}
                        >Create</Button>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default FilterRightSideBar;
