import * as types from '../actions/actionTypes';

const initialState = {
  email: '',
  username: '',
  isAuthorize: !!localStorage.getItem("accessToken")
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USERNAME:
        return {...state,username: action.username}
    case types.SET_EMAIL:
        return {...state,email: action.email}
    case types.HANDLE_USER_AUTHORIZATION:
      return {...state, isAuthorize: action.isAuthorize};

    default:
      return state;
  }
};

