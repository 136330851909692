import React, {Component} from 'react'
import './GlobalSettingsHeader.scss'

class GlobalSettingsHeader extends Component{

    render() {
        const {title,treeName} = this.props;
        return(
            <div className="global-settings-header-container">
                <div className="row flex flex-justify-content-space-between">
                    <div className="flex">
                        <div className="global-settings-header-title flex flex-direction-column">
                            <h4>{title}</h4>
                            <div>{treeName}</div>
                        </div>
                        <div className="global-settings-header-options"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default GlobalSettingsHeader;