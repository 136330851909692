import React  from 'react';
import { Route, Redirect } from 'react-router-dom';
import {jwtDecoder,isTokenExpired} from "../../config/utils"

export const PrivateRoute = ({ component: Component, ...rest }) => {
    let isAuthorize=false;
    const refreshToken = localStorage.getItem('refreshToken');
        let decodedReFreshToken= null;
        if(refreshToken !== null){
            decodedReFreshToken=jwtDecoder(refreshToken)
        }
    if(decodedReFreshToken && !isTokenExpired(decodedReFreshToken.exp)){
        isAuthorize=!isAuthorize;
    }
    return (
        <Route {...rest} render={(props) => {
          return ( isAuthorize === true
                ? <Component {...props} />
                : <Redirect to={{pathname:'/login', notificationShow:true, pathFromFiredAction: props.location.pathname}} />
        )}}/>
    );
}

