import React , { Component }           from 'react' ;
import {Button, Icon, Modal, Upload}   from 'antd';
import Translate from "../Translate/Translate";
import propTypes                       from 'prop-types';

import './UploadModal.scss';

class UploadModal extends Component {

    state = {
        file: [],
        loading : false
    };

    render() {

        const {
            visible
        } = this.props;

        return (
            <Modal
                title={this.props.title}
                visible={visible}
                closable={true}
                centered={true}
                onCancel={this.handleCancel}
                className={"data-logger-upload-csv"}
                bodyStyle={
                    {
                        height: '100%'
                    }
                }
                footer={[
                    <Button
                    key="uploadBtn"
                    icon={"cloud-upload"}
                    onClick={() => this.uploadFile()}
                    className={this.state.file.length ? "btn btn-border-green" : "hidden"}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    >
                     <Translate string={"GLOBAL.UPLOAD"} />
                    </Button>
                ]}
                >
                <div  className='upload-modal-content'>
                    { this.state.file.length > 0 &&

                            <div className="list-wrap flex" key={this.state.file[0].uid}>
                                <div className="list-item">
                                    <div className="list-item-header flex flex-justify-content-space-between flex-align-items-center">
                                        <h5>{this.state.file[0].name}</h5>
                                        <span>{this.bytesToSize(this.state.file[0].size)}</span>
                                    </div>

                                    <div className="list-item-description flex">

                                        <span className="item-type">{this.state.file[0].type}</span>  {this.state.file[0].uid}
                                    </div>
                                    <div className="item-close">
                                        <Button onClick={() => this.onRemove()}><Icon type="close"/></Button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                <Upload
                    name="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    customRequest={this.customRequest}
                    fileList={this.state.file}
                    multiple={false}
                >
                    <UploadContent hasFiles={this.state.file.length}/>
                </Upload>
            </Modal>
        );
    }

    onRemove = () => {

        this.setState({...this.state, file:[]});

    };
    bytesToSize = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
        return (Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]);
    }
    customRequest = ({file}) => {
        this.setState({
            ...this.state,
            file : [file]
        })
    }
    uploadFile = () => {
        const {
            uploadFiles,
            uploadFlag
        }
         = this.props;

        uploadFiles(this.state.file,uploadFlag);
        this.setState({
            ...this.state,
            loading:true
        })
        setTimeout(() => {
            this.handleCancel();
        }, 2500);
    };
    handleCancel = (e) => {
        const {
            callBack
        } = this.props ;
        this.setState({
            ...this.state,
            file:[],
            loading:false
        });
        callBack();
    };
}
const UploadContent = ({hasFiles}) => (
    <div className={hasFiles ? 'upload-content-with-files' : 'upload-content'}>
        <Icon type="cloud-upload" />
        <p><Translate string={"GLOBAL.DROP_YOUR_CSV_FILE"}/></p>
    </div>
);

UploadModal.propTypes = {
    visible     : propTypes.bool,
    uploadFiles : propTypes.func,
    callBack    : propTypes.func,
    DownloadTemplate: propTypes.func
};
export default UploadModal;

