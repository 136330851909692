import React from 'react';

import {Component} from 'react';

import {Input} from 'antd';
import './EnergyAnalysisNameSection.scss';
import * as type from "../../../actions/actionTypes";
import Translate from "../../Translate/Translate";

import {connect} from "react-redux";


class EnergyAnalysisNameSection extends Component{

  render() {
    const {
      energyAnalysisName,
      handleNewEnergyAnalysisName
    } = this.props
    return(
          <div className="flex flex-align-items-center name-container border-bottom  border-top">
              <p className="name-label"><Translate string={"GLOBAL.NAME"}/>:</p>
              <Input
                  type="text"
                  value={energyAnalysisName}
                  className="analysis-name"
                  onChange={event => handleNewEnergyAnalysisName(event.target.value)}
              />
          </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  handleNewEnergyAnalysisName : name => dispatch({type: type.CHANGE_ANALYSIS_NAME,energyAnalysisName:name})
});
const mapStateToProps = state => ({
  energyAnalysisName: state.energyAnalysis.energyAnalysisName
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnergyAnalysisNameSection);