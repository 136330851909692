import * as types from '../actions/actionTypes';

const initialState = {
  fileList: [],
  uploading: false,
  csvList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_FILE_LIST:
      return { ...state, fileList: action.fileList };
    case types.REMOVE_FILE_LIST:
      return { ...state, fileList: action.fileList };
    case types.HANDLE_UPLOADING_FILELIST:
      return { ...state, uploading: !state.uploading };
    case types.HANDLE_MULTI_UPLOAD:
      return {...state, csvList: action.csvList }
    default:
      return state;
  }
};
