import React from 'react';
import {Icon} from "antd";
import {SortableElement, SortableHandle} from "react-sortable-hoc";
import Translate from "../../../../Translate/Translate";

import {areaChart, barChart, lineChart, scatterChart} from "../../../../../config/constants";

import './WidgetRepresentation.scss';
import {isJson} from "../../../../../config/constants";


const DragHandle = SortableHandle(( {name, place, options}) => {
    const chartTypes = {
        scatter : scatterChart,
        area    : areaChart,
        column  : barChart,
        line    : lineChart
    };

    const parseOptions = isJson(options) ? JSON.parse(options) : null;


    return (
        <div className={name ? `layout-col-${place}-6 has-content`:`layout-col-${place}-6 `} style={{zIndex: 999999}}>
           <div className="widget-card-container flex flex-align-items-center flex-justify-content-center">
               <div className="widget-card-wrap flex flex-direction-column flex-align-items-center flex-justify-content-center">
                   {parseOptions ? <Icon className={'chart-icon'} component={chartTypes[parseOptions.chartType]}/> :
                       '' }
                   <h4> {name ? name : <Translate string={"DASHBOARD.EMPTY_WIDGET"}/>}  </h4>
               </div>
           </div>
        </div>
    )
});

const WidgetRepresentation = (props) => {
    return <DragHandle name={props.name} place={props.place} options={props.options} />;
};

export default SortableElement(WidgetRepresentation);
