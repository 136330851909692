import React, { Component } from "react";

import Translate from "../../../Translate/Translate";
import "./ProbeContextMenu.css";
import {connect} from 'react-redux';
import { Modal, Tabs, Radio, Input } from "antd";
import { Menu, Item, Separator } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { ChromePicker } from "react-color";
import {treeActions} from "../../../../actions";

const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const confirm = Modal.confirm;

class ProbeContextMenu extends Component {
  state = {
    visible: false,
    chartColor: "",
    probeName: []
  };

  render() {
    const {
      state: { visible, chartColor, probeName },
        props: {probeValues},
      showModal,
      handleOk,
      handleCancel,
      handleColor
    } = this;

    return (
      <div>
        <Menu id="synProbeContextMenu">
          <Item
            onClick={e => {
              showModal(e.props.probeName);
            }}
          >
            <Translate string={"GLOBAL.EDIT"}/>
          </Item>
          <Separator />
          <Item onClick={() => this.showConfirm(probeValues.id, probeValues.name)}><Translate string={"GLOBAL.DELETE"}/></Item>
        </Menu>
        <Modal
          title={<Translate string={"GLOBAL.EDIT"}/> - probeName}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width="50%"
          height="50%"
        >
          <Tabs
            defaultActiveKey="1"
            tabPosition="left"
            style={{ height: 400 }}
            className="tabs"
          >
            <TabPane tab={<Translate string={"GLOBAL.SETTINGS"}/>} key="1" className="tabs-left-content">
              <TextArea value={probeName} disabled autosize={true} />
              <Input className="input-space" placeholder="Change probe name" />
            </TabPane>
            <TabPane tab={<Translate string={"GLOBAL.CHART_SETTINGS"}/>} key="2" className="tabs-left-content">
              <ChromePicker
                className="chrome-picker"
                onChange={handleColor}
                color={chartColor}
                disableAlpha={true}
              />
              <div>
                <Radio.Group defaultValue="line" buttonStyle="solid">
                  <Radio.Button value="line"><Translate string={"GLOBAL.LINE"}/></Radio.Button>
                  <Radio.Button value="bar"><Translate string={"GLOBAL.BAR"}/></Radio.Button>
                  <Radio.Button value="pie"><Translate string={"GLOBAL.PIE"}/></Radio.Button>
                </Radio.Group>
              </div>
            </TabPane>
          </Tabs>
        </Modal>
      </div>
    );
  }

  showModal = probeName => {
    this.setState({
      visible: true,
      probeName
    });
  };

  handleOk = () => {
    this.setState({
      visible: false
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  handleColor = color => {
    this.setState({
      chartColor: color
    });
  };

  showConfirm = (id, name) => {
    const {deleteSelectedProbe} = this.props;

    confirm({
      title: 'Are you sure you want to delete this ?',
      okText: 'Yes',
      okType: 'danger',
      onOk() {
        deleteSelectedProbe(id, name)
      },
      onCancel() {
      },
    });
  }

}


const mapStateToProps = state => ({
  loadingSpinner: state.tree.loadingSpinner,
  probeValues: state.tree.probeValues
});

const mapDispatchToProps = dispatch => ({
  deleteSelectedProbe: (id, name) => dispatch(treeActions.deleteSelectedProbe(id,name))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProbeContextMenu);
