import React, { Component }                                     from 'react';
import { connect }                                              from "react-redux";
import SortableTree, { changeNodeAtPath }                       from 'react-sortable-tree';
import { NavLink }                                              from "react-router-dom";

import {Icon, Popconfirm, Tooltip, Input }                      from 'antd'

import Modal                                                    from '../../../Modal/Modal'
import UploadModal                                              from "../../../UploadModal/UploadModal";

import { globalSettingsActions, treeActions, uploadCSVActions } from '../../../../actions';
import * as types                                               from '../../../../actions/actionTypes'

import 'react-sortable-tree/style.css';
import '../../GlobalSettings.scss';
import instance from "../../../../config/axiosConf";


import Translate from "../../../Translate/Translate";
import {TranslateText, confirmMessage}  from '../../../../config/constants';
const maxDepth = 7;

class GlobalSettingsTreeSetup extends Component {

        state = {
            isOpen: false,
            visible: false,
            isVisibleInfoDescription: false,
            isEditingDescription: false,
            descriptionState: '',
            nameState: '',
            descriptionValue: ''
        };

    componentDidMount() {
        let name, description = ''
        const selectedTreeId = localStorage.getItem('selectedTree');

        if(selectedTreeId) {
            let filteredData = this.props.sortData.filter( item => item.id !== selectedTreeId );
            name = filteredData.length ? filteredData[0].name : <Translate string={"GLOBAL_SETTINGS.NO_SELECTED_FOLDER"}/>;
            description = !filteredData.length ? <Translate string={"GLOBAL_SETTINGS.NO_SELECTED_FOLDER"}/> : filteredData[0].description && filteredData[0].description.length ? filteredData[0].description : '' ;
            this.setState({
                nameState: name,
                descriptionValue: description,
                descriptionState: description
            })
        } else {
            name = this.props.sortData.length > 0 ? this.props.sortData[0].name : '';
            description = this.props.sortData.length > 0 ? this.props.sortData[0].description : <Translate string={"GLOBAL_SETTINGS.NO_DESCRIPTION"}/>;
            this.setState({
                nameState: name,
                descriptionValue: description,
                descriptionState: description
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let name, description = ''
        const selectedTreeId = localStorage.getItem('selectedTree');

        if(Number.isInteger(Number(selectedTreeId)) && this.props.selectedId !== prevProps.selectedId) {
            let filteredData = this.props.sortData.filter( item => item.id !== selectedTreeId );
            name = filteredData.length ? filteredData[0].name : <Translate string={"GLOBAL_SETTINGS.NO_SELECTED_FOLDER"}/>;
            description = filteredData.length ? filteredData[0].description : <Translate string={"GLOBAL_SETTINGS.NO_SELECTED_FOLDER"}/>;
            this.setState({
                nameState: name,
                descriptionValue: description,
                descriptionState: description
            })
        }
    }

    render() {
        const {
            props: { handleModalNewTree, handleSidebar, sortData, uploadFileList, DownloadTemplate },
            state: { clone, visible, isVisibleInfoDescription, descriptionState, nameState, descriptionValue },
            toggleUploadModal, toggleModal, handleTreeOnChange, onMoveNode, generateNodeProps, addNewBranch } = this;
       
        return (
            <div
                className={
                    handleSidebar ?
                        "bg-white tree-view-container-wrap js-content"
                        :
                        "bg-white tree-view-container"
                }
                style={{margin:'auto', height:'100%'}}
            >
                <span
                    onClick={() => this.handleDescriptionModal()}
                    className={(isVisibleInfoDescription) ? 'tree-setup-view-container-info-modal' : "tree-setup-view-container-info-modal js-tree-setup-view-container-info-hide"}
                />
                <Modal show={handleModalNewTree}
                       onClose={toggleModal}
                       icon={'plus'}
                       status={<Translate string={"GLOBAL.NEW_TREE_STRUCTURE"}/>}
                       clone={clone}
                />
                {sortData && sortData.length > 0 ?

                    <div className="tree-view-sort-container" style={{width:'100%'}}>
                        <div className="tree-view-sort">
                            <div className="header-view-sort">
                                <h2>{nameState}</h2>
                                <Tooltip placement="top" title={<Translate string={"GLOBAL.DESCRIPTION"}/>}>
                                <Icon className={"description-icon-info"} type="info-circle" onClick={() => this.handleDescriptionModal()}/>
                                </Tooltip>
                                <div className={isVisibleInfoDescription ? "tree-setup-info-description js-tree-setup-info-description-show" : "tree-setup-info-description"}>
                                    <div className={"tree-setup-header-description-info"}>
                                        <h3><Translate string={"GLOBAL.DESCRIPTION"}/></h3>
                                    </div>
                                    <div className={descriptionValue ? "tree-setup-info-description-content": "tree-setup-info-description-content-no-data"}>
                                        { this.state.isEditingDescription 
                                            ?  <Input.TextArea
                                                placeholder={ TranslateText("GLOBAL.ENTER_DESCRIPTION")}
                                                name='description'
                                                onChange={event => this.setState({descriptionState: event.target.value})}
                                                rows='6'
                                                value={descriptionState}
                                                maxLength={1000}
                                            />
                                            : descriptionValue.length !== 0
                                            ? <p>{descriptionValue}</p>
                                            : <div className={'tree-setup-no-description'}>
                                            <img alt="No Data"
                                                 src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxlbGxpcHNlIGZpbGw9IiNGNUY1RjUiIGN4PSIzMiIgY3k9IjMzIiByeD0iMzIiIHJ5PSI3Ii8+CiAgICA8ZyBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0Q5RDlEOSI+CiAgICAgIDxwYXRoIGQ9Ik01NSAxMi43Nkw0NC44NTQgMS4yNThDNDQuMzY3LjQ3NCA0My42NTYgMCA0Mi45MDcgMEgyMS4wOTNjLS43NDkgMC0xLjQ2LjQ3NC0xLjk0NyAxLjI1N0w5IDEyLjc2MVYyMmg0NnYtOS4yNHoiLz4KICAgICAgPHBhdGggZD0iTTQxLjYxMyAxNS45MzFjMC0xLjYwNS45OTQtMi45MyAyLjIyNy0yLjkzMUg1NXYxOC4xMzdDNTUgMzMuMjYgNTMuNjggMzUgNTIuMDUgMzVoLTQwLjFDMTAuMzIgMzUgOSAzMy4yNTkgOSAzMS4xMzdWMTNoMTEuMTZjMS4yMzMgMCAyLjIyNyAxLjMyMyAyLjIyNyAyLjkyOHYuMDIyYzAgMS42MDUgMS4wMDUgMi45MDEgMi4yMzcgMi45MDFoMTQuNzUyYzEuMjMyIDAgMi4yMzctMS4zMDggMi4yMzctMi45MTN2LS4wMDd6IiBmaWxsPSIjRkFGQUZBIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K"/>
                                            <p><Translate string={"GLOBAL_SETTINGS.NO_DESCRIPTION"}/></p>
                                            </div>
                                         }
                                    </div>
                                    <button className="btn btn-border-green tree-setup-description-edit-button" onClick={this.handleEditDescription}>
                                        {this.state.isEditingDescription
                                            ? <Icon type="save" theme="outlined"/>
                                            : <Icon type="edit" theme="outlined"/>
                                        }
                                    </button>
                                </div>
                            </div>
                            <SortableTree
                                treeData={sortData}
                                onChange={handleTreeOnChange}
                                onMoveNode={onMoveNode}
                                maxDepth={maxDepth}
                                canDrag={({node}) => !node.noDragging}
                                canDrop={({nextParent}) => nextParent != null}
                                isVirtualized={true}
                                generateNodeProps={ rowInfo => generateNodeProps(rowInfo)}
                            />
                        </div>
                        <div className="tree-view-actions">
                            <div
                                className="tree-view-actions-container flex flex-justify-content-space-between">
                                <div className="tree-view-actions-left">
                                    <button className="btn btn-border-green"
                                            onClick={ event => {
                                                event.preventDefault();
                                                toggleModal({clone:true})
                                            }
                                            }
                                    >
                                        <Icon type="copy" theme="outlined"/><Translate string={"GLOBAL.CLONE"}/>
                                    </button>
                                    <button className="btn btn-border-green" onClick={() => toggleUploadModal()}>
                                        <Icon type="upload" theme="outlined"/><Translate string={"GLOBAL.IMPORT"}/>
                                    </button>
                                    <button onClick={() => DownloadTemplate(require("../../../../assets/template/ProbesWithSeriesTemplate.csv"))} className={"btn btn-border-green"}>
                                        <Icon type="cloud-download" theme="outlined"/><Translate string={"GLOBAL.TEMPLATE"}/>
                                    </button>
                                </div>
                                <div className="tree-view-actions-right">
                                    <NavLink to="/global-settings/probe-setup" className="btn btn-border-green btn-simulate">
                                        <Icon type="right" theme="outlined"/><Translate string={"GLOBAL.NEXT"}/>
                                    </NavLink>

                                    <Tooltip placement="topRight" title={<Translate string={"GLOBAL_SETTINGS.ADD_NEW_FOLDER"}/>}>
                                    <button className="btn btn-green" onClick={addNewBranch}>
                                        <Icon type="plus" theme="outlined"/>
                                    </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div> : <div
                        className={handleSidebar ? "global-settings-content bg-white js-modal-animation" : "global-settings-content bg-white"}>
                        {!handleModalNewTree &&
                        <div
                            className="global-settings-content-no-data-screen flex flex-justify-content-center flex-align-items-center flex-direction-column">
                            <img src={require('../../../../assets/img/tree-structure.png')}
                                 alt="Tree Structure"/>
                            <div className={'content'}>
                                <button type='button' className='btn btn-green' onClick={event => {
                                    event.preventDefault();
                                    toggleModal();
                                }}>
                                    <Icon type="plus" theme="outlined"/><Translate string={"GLOBAL.NEW_TREE_STRUCTURE"}/>
                                </button>
                                <button type='button' className='btn btn-green' onClick={event => {
                                    event.preventDefault();
                                    toggleUploadModal();
                                }}>
                                    <Icon type="cloud-upload" theme="outlined"/> <Translate string={"GLOBAL_SETTINGS.UPLOAD_FILE"}/>
                                </button>
                            </div>
                        </div>
                        }
                    </div>

                }
                <UploadModal
                    title={<Translate string={"GLOBAL_SETTINGS.UPLOAD_TREE_STRUCTURE"}/>}
                    uploadFlag={"treeSetup"}
                    visible={visible}
                    uploadFiles={uploadFileList}
                    callBack={toggleUploadModal}
                />
            </div>
        );
    };

    handleEditDescription = async () => {
        if(this.state.isEditingDescription && this.state.descriptionState !== this.state.descriptionValue) {
            const response = await instance.put(`/api/folders/${this.props.sortData[0].id}`, {
                description: this.state.descriptionState
            });
            if (response.status && response.status >= 200 && response.status < 300) {
                this.setState({descriptionValue: response.data.data.description, descriptionState: response.data.data.description});
            }
        }
        this.setState({isEditingDescription: !this.state.isEditingDescription});
    }

    generateNodeProps = (rowInfo) => {
        const { onDeleteNode, onBlurBranchName, onChangeBranchName } = this ;
        return {
            buttons: [
                <Popconfirm placement="left" title={<div><b><p><Translate string={"GLOBAL_SETTINGS.DELETE_CONFIRM_TITLE"}/></p></b>
                                                            <p><Translate string={"GLOBAL_SETTINGS.DELETE_CONFIRM_CONTENT"}/></p></div>}
                                                            okText={<Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"}/>}
                                                            cancelText={<Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>}
                                                            onConfirm={() => onDeleteNode(rowInfo)}>
                    <Icon
                        type="close"
                        className='btn-tree-delete'
                        theme="outlined"/>
                </Popconfirm>
            ],
            title: (
                <input
                    value={rowInfo.node.name || ''}
                    type="text"
                    placeholder={<Translate string={"GLOBAL.ENTER_THE_NAME"}/>}
                    onBlur={event => onBlurBranchName(event,rowInfo)}
                    onChange={event => onChangeBranchName(event,rowInfo)}
                />
            )
        }

    };

    toggleModal = (props) => {

        const {state: { isOpen }, props: { HideGlobalSettingsContent, setClone,removeClone } } = this;

        if(props){
            setClone();
        }
        else
            removeClone();
            this.setState({
                isOpen: !isOpen
        });
        HideGlobalSettingsContent();
    };

    toggleUploadModal = () => {
        this.setState({
            visible: !this.state.visible
        });
    };

    handleTreeOnChange = treeData => {
        const { props: { ChangeTree } } = this ;
        ChangeTree(treeData);
    };

    onMoveNode = async event => {
        const { props: { OnMoveNode } } = this ;
        OnMoveNode(event);
    };

    onDeleteNode  =  (rowInfo) => {

        const { props: { DeleteNode } } = this ;
        const getNodeKey = ({ treeIndex }) => treeIndex;

            DeleteNode(rowInfo,getNodeKey);
    };

    onBlurBranchName = (event, rowInfo) => {

        const { props: { ChangeFolderName } } = this ;
        ChangeFolderName(event,rowInfo);
    };

    onChangeBranchName = (event,rowInfo) => {

        const { props: { ChangeBranchName, sortData } } = this ;
        const name = event.target.value;
        const path = rowInfo.path;
        const getNodeKey = ({ treeIndex }) => treeIndex ;

        const newSortData = changeNodeAtPath({
                treeData:sortData,
                path,
                getNodeKey,
                newNode: {...rowInfo.node, name}
            });

        ChangeBranchName(newSortData) ;
    };

    addNewBranch = () => {
        const { props: { AddNewBranch } } = this ;
        AddNewBranch();
    };
    handleDescriptionModal = () => {
        if(this.state.isEditingDescription && this.state.descriptionState !== this.state.descriptionValue) {
            confirmMessage(() => this.setState({
                isEditingDescription: false,
                isVisibleInfoDescription: false,
                descriptionState: this.state.descriptionValue
            }))
        } else {
            this.setState({
                isEditingDescription: false,
                isVisibleInfoDescription: !this.state.isVisibleInfoDescription
            });
        }

        
    }
}

const mapStateToProps = state => ({
    handleModalNewTree  : state.globalSettings.handleModalNewTree,
    handleSidebar       : state.globalSettings.handleSidebar,
    sortData            : state.dataStorage.sortData,
    selectedId          : state.globalSettings.selectedId
});

const mapDispatchToProps = dispatch => ({
    HideGlobalSettingsContent   : ()                   => dispatch(globalSettingsActions.HideGlobalSettingsContent()),
    exportData                  : (id, name)           => dispatch(treeActions.exportData(id, name)),
    uploadFileList              : (fileList)           => dispatch(uploadCSVActions.uploadCSVFile(fileList)),
    OnMoveNode                  : event                => globalSettingsActions.OnMoveNode(event),
    DownloadTemplate            : (pathFile)           => dispatch(globalSettingsActions.DownloadTemplate(pathFile)),
    DeleteNode                  : (rowInfo,getNodeKey) => dispatch(globalSettingsActions.DeleteNode(rowInfo,getNodeKey)),
    ChangeBranchName            : (newSortData)        => dispatch(globalSettingsActions.ChangeBranchName(newSortData)),
    ChangeFolderName            : (event , rowInfo)    => dispatch(globalSettingsActions.ChangeFolderName(event,rowInfo)),
    AddNewBranch                : ()                   => dispatch(globalSettingsActions.AddNewBranch()),
    ChangeTree                  : sortData             => dispatch({ type : types.CHANGE_SORT_DATA , sortData: sortData}),
    setClone                    : ()                   => dispatch({ type : types.SET_CLONE_FOLDER}),
    removeClone                 : ()                   => dispatch({ type : types.REMOVE_CLONE_FOLDER}),
    downloadTreeStructure       : ()                   => dispatch(globalSettingsActions.ExportFolder())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalSettingsTreeSetup) ;
