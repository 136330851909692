import React, {useState, useEffect} from 'react';
import validator from './validationScheme';

const useForm = (callback, state) => {

    const [value, setValue] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const errorsList = Object.keys(errors);
        if ((errorsList.findIndex(el => el === 'name') < 0) && ((state.email.length === 0 && !errors.phone) || (!errors.email && !errors.phone) || (!errors.email && state.phone.length === 0) ) && isSubmitting) {
            callback();
            setErrors({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isSubmitting]);

    useEffect(() => {
        const key = Object.keys(value).filter(k => value[k] !== state[k]);
        const fieldsForValidation = Object.keys(validator);

        if (key.length > 0) {

            if(isSubmitting){
                setIsSubmitting(false);
            }

            key.forEach(k => {
                let property = k;
                let keyIndex = fieldsForValidation.indexOf(property);

                if(keyIndex >= 0) {
                    validator[property].errors = [];
                    validator[property].invalid = false;
                    delete errors[property];
                    setErrors(errors => ({...errors}));
                    setValue(value => ({...value, [property]: state[property]}))
                }
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, state]);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        Object.keys(state).map(key => updateValidators(event, key, state[key]));
        setValue(state);
        setIsSubmitting(true);
    };


    const updateValidators = (event, fieldName, value) => {
        event.persist();
        if (validator[fieldName]) {
            validator[fieldName].errors = [];
            validator[fieldName].state = value;
            validator[fieldName].valid = true;
            validator[fieldName].rules.forEach((rule) => {
                if (rule.test instanceof RegExp) {
                    if (rule.test.test(value)) {
                        validator[fieldName].errors = [rule.message];
                        validator[fieldName].valid = false;
                    }
                } else if (typeof rule.test === 'function') {
                    if (!rule.test(value)) {
                        validator[fieldName].errors = [rule.message];
                        validator[fieldName].valid = false;
                    }
                }
            });
            handleMessage(fieldName)
        }
    };

    const handleMessage = (fieldName) => {

        const validate = validator[fieldName];

        if (validate && !validate.valid) {
            const errorsMessage = validate.errors.map((item, index) => {
                return <span className="error" key={index}>* {item}</span>;
            });

            let errorWrap = (
                <span className={'error-wrap'}>
                    {errorsMessage}
                </span>
            );

            setErrors(errors => ({...errors, [fieldName]: {errorComponent: errorWrap, invalid: !validate.invalid}}))
        }
    };

    return {
        handleSubmit,
        errors,
    }
};

export default useForm;
