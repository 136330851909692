import React from 'react';
import {Menu, Button, Dropdown, Modal} from 'antd';
import Translate from '../../../../Translate/Translate';

import './TriggersItem.scss';

const TriggersItem = (props) => {
    const menu = (e) => {
        return (<Menu>
            <Menu.Item>
                <Button type='button' onClick={() => props.editTrigger(props)}>
                    <Translate string={"GLOBAL.EDIT"}/>
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='button' className={'text-red'} onClick={showDeleteConfirm}><Translate string={"GLOBAL.DELETE"}/></Button>
            </Menu.Item>
        </Menu>)
    };

    const showDeleteConfirm = () => {

        Modal.confirm({
            title: <Translate string={"ALERTING.DELETE_TRIGGER_CONFIRM_TITLE"} />,
            content: <Translate string={"ALERTING.DELETE_TRIGGER_CONFIRM_CONTENT"} />,
            okText: <Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"} />,
            okType: 'danger',
            cancelText: <Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>,
            onOk() {
                props.onDelete(props.id)
            },
            onCancel() {
            },
        });
    };
    return (
        <div className="triggers-item">
            <div className="triggers-item-wrap flex">
                <div className="triggers-item-content flex">

                    <div className="triggers-item-action" id={`area${props.itemIndex}`}>
                        <Dropdown trigger={["click"]} overlay={() => menu(props)}
                                    getPopupContainer={() => document.getElementById(`area${props.itemIndex}`)} placement="bottomLeft">
                            <button className={'button-icon'}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </Dropdown>
                    </div>

                    <div className="triggers-item-data">
                        <div className="triggers-group flex flex-direction-column">
                            <label><Translate string={"GLOBAL.NAME"}/></label>
                            <p className='strong-text'>{props.name}</p>
                        </div>
                        <div className="triggers-group flex flex-direction-column">
                            <label><Translate string={"ALERTING.NODES"}/></label>
                            <p className='strong-text'>{props.selectedNodes.map(node =>
                                 `${node.triggerable_name} `)}</p>
                        </div>
                        <div className="triggers-group flex flex-direction-column">
                            <label><Translate string={"ALERTING.CONDITION"}/></label>
                        <p className='strong-text'>{props.triggerConditionText ? props.triggerConditionText : props.condition}</p>
                        </div>
                        <div className="triggers-group flex flex-direction-column">
                            <label><Translate string={"ALERTING.TRESHOLD"}/></label>
                            <p className='strong-text'>{props.value}</p>
                        </div>
                        <div className="triggers-group flex flex-direction-column">
                            <label><Translate string={"ALERTING.TIMED"}/></label>
                            <p className='strong-text'>{props.timed ? "YES" : "NO"}</p>
                        </div>
                        <div className="triggers-group flex flex-direction-column">
                            <label><Translate string={"ALERTING.DURATION"}/></label>
                            <p className='strong-text'>{props.interval ? props.interval : ''}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TriggersItem;