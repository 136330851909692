import React, { useState }                         from 'react';
import { Input, Select, DatePicker, Popover, Tooltip } from "antd";
import { ChromePicker } from "react-color";

import './FormInput.scss';
import moment from "moment";
import Translate from "../Translate/Translate";

const { Option } = Select;
const {RangePicker}  = DatePicker;

export const InputText = (props) => {
    const { handleOnPressEnter = () => {}, style ,name , handleOnChange = () => {}, value,handleOnBlur = () => {} } = props;
    return(
        <div className='input-filter'>
            <div>
                <Input
                    style = {style}
                    autoComplete='off'
                    type={'text'}
                    name={props.name}
                    className={'form-input-text'}
                    suffix={<span>{props.label}</span>}
                    onPressEnter={
                        (event) => {
                            event.preventDefault();
                            handleOnPressEnter(name,event.target.value)}
                    }
                    value={value}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
            </div>
        </div>
    );
};
export const ColorPickerInput = (props) => {
    const {label, handleColorOnColordataPicker = () => {} , hexColor } = props;
    return(
        <div className="input-filter input-select-filter flex flex-align-items-center">
            <Popover
                content={
                    <div>
                        <ChromePicker
                        onChangeComplete={handleColorOnColordataPicker}
                        color={hexColor || "#22194D"}
                        disableAlpha={true}
                        />
                    </div>
                }
                title={<Translate string={"FORM_INPUT.CHOOSE_COLOR"}/>}
                trigger="click"
            >
                {hexColor ?
                    <p className={"flex flex-align-items-center popupParagraf"}><i className="dot" style={{"backgroundColor":hexColor}}/> {hexColor}</p>
                    :
                    <p className={"flex flex-align-items-center popupParagraf"}/>
                }
            </Popover>
            <span>{label}</span>
        </div>
    )
}
export const InputSelect = (props) => {
    const { onChangeSelect = () => {}, optionValues, label, mode, name, value, disabled} = props;
    return(

        <div className="input-filter input-select-filter flex flex-align-items-center">
            <Tooltip placement="top" title={disabled ? <Translate string={"GLOBAL.UNAVAILABLE_FOR_SCATTER_TYPE"}/> : ''}>
                <Select
                    name={name}
                    showSearch
                    allowClear={true}
                    mode={mode ? mode : 'default'}
                    showArrow={false}
                    className={"form-input-select"}
                    onChange={selectedValue => onChangeSelect(name,selectedValue)}
                    value={value}
                    disabled={disabled || false}
                >
                    {optionValues ? props.optionValues.map(item => {
                        return <Option value={item.id || item} key={"key-" + (item.id || item)}>{item.path || item.name || item}</Option>
                    }) : null}
                </Select>
            </Tooltip>
                <span>{label}</span>
        </div>
    );
};

export const DataPickerInput = (props) => {
    const [isDateSelected, setIsDateSelected] = useState(false);

    const { label, onChangeData, name} = props ;
    return(
        <div className="input-filter input-date-filter">
            <div className="date-filter-wrap flex flex-align-items-center">
                <DatePicker
                    format="YYYY MMM DD, HH:mm:ss"
                    onChange={(date,stringDate) => {
                        onChangeData(name,date.format("YYYY-MM-DDTHH:mm:ssZ"))
                        setIsDateSelected(true);
                    }}
                    placeholder={''}
                    allowClear={false}
                    showTime
                />
                <span>{isDateSelected ? '' : label}</span>
            </div>
        </div>
    );
};
export const RangePickerInput = (props) => {
    const { label, onChangeData = () => {}, name, showTime, disabledTime, format} = props ;
    return(
        <div className="input-filter input-date-filter">
            <div
                className="date-picker-filter-wrap no-margin"
            >
                <RangePicker
                    onChange={(_,stringDate) => onChangeData(name, stringDate)}
                    placeholder={''}
                    allowClear={true}
                    disabledDate={current => {
                        return current && current >= moment().endOf('day');
                    }}
                    defaultPickerValue={[null, moment()]}
                    disabledTime={disabledTime}
                    showTime={showTime}
                    format={format}
                />
                <span className={"span-range-picker"}>{label}</span>
            </div>
        </div>
    );
};
