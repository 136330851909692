import React, {Component} from 'react';

import {connect} from "react-redux";

import {DashboardCard, NewDashboardCard} from "../DashboardCards/DashboardCards";
import {dashboard} from "../../../actions";

import {Icon } from 'antd';

import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';

import "./DashboardHome.scss";
import arrayMove from "array-move";
import {DashboardModal} from "../DashboardModal/DashboardModal";

const DragHandle = sortableHandle(() => <span className={'dashboard-drag-handle'}>
    <Icon style={{pointerEvents:"stroke"}} type='drag'/></span>);


const SortableItem = SortableElement(({value, index, history, HandleOnPropertiesClick, HandleOnDelete, HandleOnDuplicateClick}) => (
    <DashboardCard
        dashboard={value}
        key={`dashboard-${index}`}
        onClick={() => history.push({
            pathname: "dashboard-view",
            search: `?dashId=${value.id}`,
            state: {value}
        })}
        DragHandles={<DragHandle/>}
        dragIndex={index}
        onPropertiesClick={HandleOnPropertiesClick}
        onDelete={HandleOnDelete}
        onDuplicate={HandleOnDuplicateClick}
    />
));

const DashboardList = SortableContainer(({items, openModal, cardHistory, onPropertiesClick, onDelete, onDuplicate}) => {
    return (
        <div className={'dashboard-list-grid'}>

            <NewDashboardCard onCreate={openModal}/>
            {
                items.map((value, index) => (
                    <SortableItem key={`item-${index}`} history={cardHistory} index={index} value={value}
                                  HandleOnPropertiesClick={onPropertiesClick}
                                  HandleOnDelete={onDelete}
                                  HandleOnDuplicateClick={onDuplicate}
                    />
                ))
            }
        </div>
    )
});

class DashboardHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDashboardId: null,
            update: false,
            items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
            dashboard: null
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }


    componentDidMount() {
        const {props: {GetAllDashboards}} = this;
        GetAllDashboards();

    }


    onDragEnd = (result) => {

        const {props: {dashboardsList, UpdateDashboardPosition}} = this;
        const {newIndex, oldIndex} = result;
        let updateIndexDashboardsList = [];

        let payload = arrayMove(dashboardsList, oldIndex, newIndex);

        let isChangedArr = dashboardsList && payload && dashboardsList.filter((item,index) => item.id !== payload[index].id);

        if (isChangedArr.length !== 0) {
            payload.map((item, index) => updateIndexDashboardsList.push({dashId: item.id, dashIndex: index}));
            UpdateDashboardPosition(updateIndexDashboardsList, payload);
        }


    };

    render() {

        const {
            props: {dashboardsList, history},
            HandleOpenModal, HandleOnDelete, HandleOnDuplicateClick, HandleOnPropertiesClick,
            onDragEnd
        } = this;

        return (
            <React.Fragment>
                <div className="main-viewer dashboard flex" id={"dashboard-modal"}>
                    <div className="main-viewer-wrap dashboard-modal-holder">
                        <DashboardModal
                            isVisible={this.props.HandleModalForCreateNewDashboard}
                            ModalForCreateNewDashboard={this.props.ModalForCreateNewDashboard}
                            HandleSubmitDashboardForm={this.HandleSubmitDashboardForm}
                            dashboard={this.state.dashboard}
                            isUpdate={this.state.update}
                            lastIndex={this.props.dashboardsList.length}
                            ClearDashbaordHomeState={this.ClearState}
                        />
                        <div className="bg-white dashboard-container">
                            <div className="dashboard-list-wrap">
                                <DashboardList axis={'xy'}
                                               useDragHandle
                                               items={dashboardsList}
                                               cardHistory={history}
                                               onSortEnd={onDragEnd}
                                               openModal={HandleOpenModal}
                                               onPropertiesClick={HandleOnPropertiesClick}
                                               onDelete={HandleOnDelete}
                                               onDuplicate={HandleOnDuplicateClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    HandleOpenModal = () => {
        const {props: {ModalForCreateNewDashboard}} = this;
        ModalForCreateNewDashboard();
    };

    HandleCloseModal = () => {
        const {props: {ModalForCreateNewDashboard}, ClearState} = this;
        const labelMessage = document.getElementById('display-error-message');
        const inputToAddName = document.getElementsByName('name');
        labelMessage.innerHTML = '';
        inputToAddName[0].classList.remove('error-input');
        ClearState();
        ModalForCreateNewDashboard()
    };

    HandleSubmitDashboardForm = (dashboard) => {
        const {
            props: {CreateNewDashboard, UpdateDashboard},
            state: {update},
            ClearState, HandleCloseModal
        } = this;

            update ? UpdateDashboard(dashboard) : CreateNewDashboard(dashboard);
            ClearState();
            HandleCloseModal();

    };
    HandleOnDelete = (dashboardId) => {
        const {props: {DeleteDashBoard}} = this;
        DeleteDashBoard(dashboardId);
    };
    HandleOnPropertiesClick = (dashboard) => {
        const {HandleOpenModal} = this;
        HandleOpenModal();
        this.setState({
            dashboard:dashboard,
            update :true
        })
    };
    HandleOnDuplicateClick = (dashboard) => {
        const {props: {CloneDashboard}} = this;
        CloneDashboard(dashboard.id);
    };

    ClearState = () => {
        this.setState({
            currentDashboardId: null,
            update: false,
            dashboard : null
        })
    };
};

const mapStateToProps = state => ({
    HandleModalForCreateNewDashboard: state.dashboard.HandleModalForCreateNewDashboard,
    dashboardsList: state.dashboard.dashboardsList,
    ModalDateRange: state.dashboard.ModalDateRange
});

const mapDispatchToProps = dispatch => ({
    ModalForCreateNewDashboard: () => dispatch(dashboard.ModalForCreateNewDashboard()),
    GetAllDashboards: () => dispatch(dashboard.GetAllDashboards()),
    CreateNewDashboard: (payload) => dispatch(dashboard.CreateNewDashboard(payload)),
    CloneDashboard : (dashId) => dispatch(dashboard.CloneDashboard(dashId)),
    DeleteDashBoard: (dashboardId) => dispatch(dashboard.DeleteDashboard(dashboardId)),
    UpdateDashboard: (payload) => dispatch(dashboard.UpdateDashboard(payload)),
    UpdateDashboardPosition: (payload, updatedList) => dispatch(dashboard.UpdateDashboardPosition(payload, updatedList))
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardHome);
