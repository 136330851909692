import React from 'react';

import {Icon, Dropdown, Menu, Modal} from "antd";
import Translate from "../../Translate/Translate";
import './ViewerCards.scss';
import {areaCard, barCard, isJson, lineCard, scatterCard} from "../../../config/constants";

const chartTypes = {
    scatter : scatterCard,
    area    : areaCard,
    column  : barCard,
    line    : lineCard
};
export const NewViewerCard = (props) => {
    return (
        <div className="item item-new-viewer">
            <div className="item-wrap flex flex-justify-content-center flex-align-items-center"
                 onClick={props.onCreate}>
                <div
                    className="item-title flex flex-direction-column flex-justify-content-center flex-align-items-center">
                    <Icon type="plus" theme="outlined"/>
                    <Translate string={"GLOBAL.NEW_VIEWER"}/>
                </div>
            </div>
        </div>
    );
};
export const ViewerCard = (props) => {

    const { viewer, index, onPropertiesClick, onDuplicateClick, onCardClick, DragHandles } = props ;
    const showDeleteConfirm = () => {
        const {onDelete} = props;
        Modal.confirm({
            title: <Translate string={"VIEWERS.DELETE_CONFIRM_TITLE"} />,
            okText: <Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"} />,
            okType: 'danger',
            cancelText: <Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"} />,
            onOk() {
                onDelete(viewer.id)
            },
            onCancel() {},
        });
    };

    const menu = () => {
        return (<Menu>
            <Menu.Item key="1" onClick={() => onPropertiesClick(viewer)}><Translate string={"GLOBAL.PROPERTIES"}/></Menu.Item>
            <Menu.Item key="2" onClick={() => onDuplicateClick(viewer)}><Translate string={"GLOBAL.DUPLICATE"}/></Menu.Item>
            <Menu.Item key="3" ><Translate string={"GLOBAL.PERMISSION"}/></Menu.Item>
            <Menu.Item key="4" className={'text-red'} onClick={showDeleteConfirm}><Translate string={"GLOBAL.DELETE"}/></Menu.Item>
        </Menu>)
    };
    const chartTypeIcon = isJson(viewer.options) ? JSON.parse(viewer.options).chartType : viewer.options.chartType;
    const area = `area-${index}-${viewer.id}`;
    return (
        <div className={props.status ? "item viewer-structure selected" : "item viewer-structure"}>
            <div className="item-wrap flex flex-direction-column flex-justify-content-center">
                <div className="item-drag">
                    {DragHandles}
                </div>
                <div className="item-options" id={area}>
                    <Dropdown trigger={["click"]} overlay={()=>menu()}
                              getPopupContainer={() => document.getElementById(area)} placement="bottomLeft">
                        <button type={'button'} className='icon-wrap'>
                            <span/>
                            <span/>
                            <span/>
                        </button>
                    </Dropdown>
                </div>
                <div onClick={() => onCardClick(viewer.id)}>
                    <div className="item-viewer-title flex flex-align-items-center flex-justify-content-center" style={{WebkitBoxOrient: 'vertical', MozBoxOrient: 'vertical'}}>
                        {chartTypeIcon && <Icon component={chartTypes[chartTypeIcon]}/> }
                        <p>{viewer.name}</p>
                    </div>
                    <div className="item-description">
                        {viewer.description && viewer.description.length > 80 ? viewer.description.substring(0,80) + '...': viewer.description}
                    </div>
                </div>
            </div>
        </div>
    );
};
