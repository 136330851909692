import React, { useContext } from "react";

import { TabBarContext } from "./TabBarContext";

export const Tab = ({ tab, tag, className = "", children, ...props }) => {
    const tabBarContext = useContext(TabBarContext);
    const Tag = tag || "div";
    const handleClick = () => tabBarContext.setTab(tab);
    let isActive = tabBarContext.activeTab === tab;
    if (isActive) {
        className += "active";
    }
    return (
        <Tag onClick={handleClick} className={className} {...props}>
            {children}
        </Tag>
    );
};
