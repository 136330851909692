import * as types from '../actions/actionTypes';

const initialState = {
    handleModalNewTree: false,
    handleSidebar: false,
    handleLoader: false,
    selectedTreeForDelete: null,
    startAppFirstTime: false,
    statusOfFolderCreator: [],
    globalSettingFinished : false,
    createNewCard: false,
    handleCloneModal: false,

    dataLoggers : [],
    allDataLoggers:[],
    createdDatalogger: null,
    editMqtt: null,
    editFtp: null,
    metaDataForProbePagination:null,
    checkedProbes : [],
    probes : [],
    selectedId: null,
    distinctFilterParams:null,
    filterParams : null,
    folderLevels:null,

    probeFilter:{},
    dataLoggerFilterObject:{},
    ftpFilterObject:{},
    csvFilterObject:{},
    mqttFilterObject:{},

    csvProbes: null,
    mqttProbes: null,
    ftpProbes : null,

    checkedCsvProbes: [],
    checkedMqttProbes: [],
    checkedFtpProbes: [],

    csvProbesMetaData: null,
    mqttProbesMetaData: null,
    ftpProbesMetaData: null,
    paginationDataLogger:null,
    closeModalForNewDataLogger: false,
    tabKey: 'datalogger',
    creatingData : false,
    dataCreated  : false,
    newAddedProbeIsSave: null,
    chanelMessage: null,
    isApplyDisabled: false,
    isGettingProbeSeries: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.HIDE_MODAL_NEW_FOLDER:
            return {...state, handleModalNewTree: !state.handleModalNewTree};
        case types.HIDE_MODAL_FOR_TREE_ON_BACK_BUTTON:
            return {...state, handleModalNewTree: action.handleModalNewTree};
        case types.HANDLE_GLOBAL_SETTINGS_SIDEBAR:
            return {...state, handleSidebar: action.handleSidebar};
        case types.HANDLE_LOADER_ON_DELETE:
            return {...state, handleLoader: !state.handleLoader, selectedTreeForDelete: action.selectedTreeForDelete};
        case types.MODAL_SUCCESS_MESSAGE:
            return {...state, statusOfFolderCreator: action.statusOfFolderCreator};
        case types.APP_IS_STARTED_FIRST_TIME:
            return {...state, startAppFirstTime: !state.startAppFirstTime};
        case types.FINISH_GLOBAL_SETTINGS :
            return {...state,globalSettingFinished: action.globalSettingFinished};
        case types.HANDLE_DATALOGGER_MODAL:
            return {...state, dataLoggerModalVisible: action.dataLoggerModalVisible};
        case types.HANDLE_START_CREATE_NEW_CARD:
            return {...state, createNewCard: !state.createNewCard};
        case types.SET_CLONE_FOLDER:
            return {...state, handleCloneModal: true};
        case types.REMOVE_CLONE_FOLDER:
            return {...state, handleCloneModal: false};
        case types.CLOSE_MODAL_FOR_NEW_DATA_LOGGER:
            return {...state, closeModalForNewDataLogger: !state.closeModalForNewDataLogger};
        case types.GET_ALL_DATALOGGERS:
            return {
                ...state,
                dataLoggers : action.dataLoggers,
                allDataLoggers:action.allDataLoggers,
                paginationDataLogger : action.paginationDataLogger
            };
        case types.HANDLE_PROBE_PAGINATION:
            return {
                ...state,
                metaDataForProbePagination: action.metaDataForProbePagination,
                probes : action.probes
            };
        case types.HANDLE_CHECKED_PROBES_IN_PROBE_SETUP:
            return {
                ...state,
                checkedProbes: action.checkedProbes
            };
        case types.HANDLE_CHECK_ALL_PROBES_IN_PROBE_SETUP:
            return {
                ...state,
                checkedProbes: action.checkedProbes
            };
        case types.LOAD_PROBES_FOR_SELECTED_FOLDER:
            return {
                ...state,
                metaDataForProbePagination: action.metaDataForProbePagination,
                probes : action.probes,

            };
        case types.KEEP_SELECTED_ID:
            return {...state, selectedId: action.selectedId};
        case types.HANDLE_DELETE_DATALOGGER:
            return {...state, dataLoggers: state.dataLoggers.filter((item)=> item.id !== action.id)}
        case types.GET_ALL_DISTINCT_FILTER_PARAMS:
            return{
                ...state,
                distinctFilterParams: action.distinctFilterParams
            };
        case types.CREATE_NEW_DATA_LOGGER:
            return {
                ...state,
                dataLoggers: action.dataLoggers
            };
        case types.CREATE_FILTER_OBJECT:
            return{
                ...state,
                filterParams: action.filterParams
        };
        case types.GET_CSV_PROBES:
            return {...state, csvProbes: action.csvProbes,
                csvProbesMetaData: action.csvProbesMetaData};
        case types.GET_MQTT_PROBES:
            return {...state, mqttProbes: action.mqttProbes,
                mqttProbesMetaData: action.mqttProbesMetaData};
        case types.GET_FTP_PROBES:
            return {
                ...state,
                ftpProbes : action.ftpProbes,
                ftpProbesMetaData: action.ftpProbesMetaData
            };
        case types.LOAD_FOLDER_PATHS:
            return {
                ...state,
                folderLevels: action.folderLevels
            };
        case types.CREATE_PROBE_FILTER:
            return {
                ...state,
                probeFilter: action.filterParams,
                probes : action.probes,
                metaDataForProbePagination: action.metaDataForProbePagination
            }
        case types.CREATE_DATA_LOGGER_FILTER:
            return {
                ...state,
                dataLoggerFilterObject : action.filterParams,
                dataLoggers: action.dataLoggers,
                paginationDataLogger:action.paginationDataLogger
            };
        case types.CREATE_FTP_FILTER:
            return {
                ...state,
                ftpFilterObject : action.filterParams,
                ftpProbes : action.ftpProbes,
                ftpProbesMetaData: action.ftpProbesMetaData
                
            };
        case types.CREATE_CSV_FILTER:
            return {
                ...state,
                csvFilterObject: action.filterParams,
                csvProbes: action.csvProbes,
                csvProbesMetaData:action.csvProbesMetaData
            };
        case types.CREATE_MQTT_FILTER:
            return {
                ...state,
                mqttFilterObject: action.filterParams,
                mqttProbes: action.mqttProbes,
                mqttProbesMetaData: action.mqttProbesMetaData
            };
        case types.UPDATE_PROBE:
            return {
                ...state,
                probes: action.probesList
            };
        case types.UPDATE_ACQUSTION_SETUP_MQTT_PROBE:
            return {
                ...state,
                mqttProbes: action.mqttProbes
            };
        case types.UPDATE_ACQUISTION_SETUP_FTP_PROBE:
            return  {
                ...state,
                ftpProbes: action.ftpProbes
            };
        case types.UPDATE_MQTT_PASSWORD:
            return {
                ...state,
                editMqtt : action.mqttItem
            };
        case types.UPDATE_FTP_PASSWORD:
            return {
                ...state,
                editFtp : action.ftpItem
        };
        case types.CREATE_NEW_PROBE:
            return {
                ...state,
                probes : action.probesList
            };
        case types.DELETE_PROBE:
            return {
                ...state,
                probes:action.probesList
            };
        case types.HANDLE_DATA_LOGGERS_PAGINATION:
            return {
                ...state,
                dataLoggers : action.dataLoggers,
                paginationDataLogger : action.paginationDataLogger
            };
        case types.HANDLE_CHECKED_CSV_PROBES:
            return {...state, checkedCsvProbes: action.checkedCsvProbes};
        case types.HANDLE_CHECKED_MQTT_PROBES:
            return {...state, checkedMqttProbes: action.checkedMqttProbes};
        case types.HANDLE_CHECKED_FTP_PROBES:
            return {...state, checkedFtpProbes: action.checkedFtpProbes};
        case types.HANDLE_CHECK_ALL_CSV_PROBES:
            return {...state, checkedCsvProbes: action.checkedCsvProbes};
        case types.HANDLE_CHECK_ALL_MQTT_PROBES:
            return {...state, checkedMqttProbes: action.checkedMqttProbes};
        case types.HANDLE_CHECK_ALL_FTP_PROBES:
            return {...state, checkedFtpProbes: action.checkedFtpProbes};
        case types.HANDLE_CSV_PAGINATION:
            return {
                ...state,
                csvProbes : action.csvProbes,
                csvProbesMetaData: action.csvProbesMetaData
            };
        case types.HANDLE_MQTT_PAGINATION:
            return {
                ...state,
                mqttProbes : action.mqttProbes,
                mqttProbesMetaData: action.mqttProbesMetaData
            };
        case types.HANDLE_FTP_PAGINATION:
            return {
                ...state,
                ftpProbes : action.ftpProbes,
                ftpProbesMetaData: action.ftpProbesMetaData
            };
        case types.GET_ACQUISITION_SIDEBAR_TAB_KEY:
            return {...state, tabKey: action.tabKey};
        case types.GET_DATALOGGER_MQTT_TAB_BY_ID:
            return {...state, editMqtt: action.editMqtt}
        case types.GET_DATALOGGER_FTP_TAB_BY_ID:
            return {...state, editFtp: action.editFtp}
        case types.UPDATE_DATALOGGER:
            return {...state, dataLoggers: action.dataLoggers};
        case types.CLEAR_ALL_CHECKED_PROBES :
            return {
                ...state,
                checkedCsvProbes: [],
                checkedMqttProbes: [],
                checkedFtpProbes: [],
                checkedProbes : []
            };
        case types.LAST_CREATED_DATALOGGER:
            return {
                ...state,
                createdDatalogger : action.datalogger
            };
        case types.HANDLE_IS_ADD_NEW_PROBE_IN_PROBE_SETUP:
            return {
                ...state,
                newAddedProbeIsSave: action.added
            };
        case types.UPDATE_APPLY: 
            return {
                ...state,
                isApplyDisabled: action.isDisabled
            };
        case types.HANDLE_GETING_SERIES_STATE:
            return {
                ...state,
                isGettingProbeSeries: action.isGettingProbeSeries
            }
        default:
            return state;
    }
};
