import * as types from "./actionTypes";
import instance from "../config/axiosConf";
import {message} from "antd"
import { TranslateText} from "../config/constants";

export const getAllViewers = () => async dispatch => {

    const response = await instance.get('/api/views',{
        params : {
            perPage : 1000
        }
    });

    if(response && response.data){
        dispatch({
            type    : types.GET_ALL_VIEWERS,
            viewers : response.data.data
        });
        dispatch({
            type: types.SET_CURRENT_VIEWER,
            currentSelectedViewer : null
        })
    }
};

export const createViewer = (payload) => async (dispatch,getState) => {

    const { viewer : { viewers } } = getState();
    const response = await instance.post('/api/views/chart',payload);

    if(response && response.data){
        const newViewers = [response.data.data,...viewers];
        dispatch({
            type    : types.CREATE_NEW_VIEWER,
            viewers : newViewers
        })
        message.success(TranslateText("VIEWERS.SAVED"));

        //send sort viewers
        let newUpdatedPayload = [];
        newViewers.map((item, index) => newUpdatedPayload.push({viewId: item.id, viewIndex: index, viewType: 'chart'}))
        instance.put(`/api/views/sort`,{data: newUpdatedPayload});
    }
};

export const cloneViewer = (viewId) => async (dispatch,getState) => {

    const { viewer : { viewers } } = getState();

   const response = await instance.put(`api/views/clone/${viewId}`);

    if(response && response.data){
        const newViewers = [response.data,...viewers];
        dispatch({
            type    : types.CREATE_NEW_VIEWER,
            viewers : newViewers
        })
        message.success(TranslateText("GLOBAL.VIEWER_CLONED"));

        //send sort viewers
        let newUpdatedPayload = [];
        newViewers.map((item, index) => newUpdatedPayload.push({viewId: item.id, viewIndex: index, viewType: 'chart'}))
        instance.put(`/api/views/sort`,{data: newUpdatedPayload});
    }
};

export const UpdateViewerPosition = (payload, updatedList ) => async (dispatch) =>{

    dispatch({
        type    : types.GET_ALL_VIEWERS,
        viewers : updatedList
    });

     await instance.put(`/api/views/sort`,{data: payload});
     message.success(TranslateText("GLOBAL.VIEWER_CHANGE_POSITION"));


};

export const updateChartViewer = (payload ) => async ( dispatch, getState ) => {
    const { viewer : { viewers }} = getState() ;
    const response = await instance.put(`/api/views/chart/${payload.id}`,JSON.stringify(payload));
    if(response && response.data){
        const updatedArray = [...viewers];
        const updatedView = response.data.data;
        const index = viewers.findIndex(viewer => viewer.id === payload.id);
        updatedArray[index] = updatedView ;
        dispatch({
            type : types.UPDATE_VIEWER,
            viewers : updatedArray
        });

        message.success(TranslateText("GLOBAL.UPDATE_VIEWER"));
        return updatedView;
    }
};
export const deleteViewer = (viewerId) => async (dispatch,getState) => {
    const {viewer : { viewers } } = getState() ;

    const viewerIndex = [];

    await instance.delete(`/api/views/chart/${viewerId}`);


    let updatedList = viewers.filter(viewer => viewer.id !== viewerId);
    updatedList.map((item, index)=>viewerIndex.push({viewId: item.id, viewIndex: index, viewType: 'chart'}));

    dispatch({type: types.DELETE_VIEWER , viewers:updatedList});
    message.success(TranslateText("GLOBAL.VIEWER_DELETED"));



};
