import * as types from './actionTypes';
import instance from "../config/axiosConf";
import {ARRAY_ACTIONS} from '../config/constants';
import {parseAnalysisFilters} from '../config/utils';

export const handleFilterAnalysis = (
  filter,
  filterIndex = null,
  type,
) => (dispatch, getState) => {
  let {
    filterAnalyses: { filtersList, selectedFilter }
  } = getState();

  switch (type) {
    case ARRAY_ACTIONS.ADD: {
      filtersList = [...filtersList, filter];
      dispatch({
        type: types.ADD_FILTER_ANALYSES,
        selectedFilter: filtersList.length - 1,
        filtersList
      });
      break;
    }
    case ARRAY_ACTIONS.DELETE: {
      filtersList = filtersList.filter((_, index) => index !== filterIndex);
      dispatch({
        type: types.ADD_FILTER_ANALYSES,
        selectedFilter: filtersList.length - 1,
        filtersList
      });
      break;
    }
    case ARRAY_ACTIONS.EDIT: {
      filtersList = filtersList.map(
        (filterFromList, index) =>
          index === selectedFilter ? filter : filterFromList
      );
      dispatch({
        type: types.ADD_FILTER_ANALYSES,
        selectedFilter,
        filtersList
      });
      break;
    }
    case ARRAY_ACTIONS.SELECT: {
      if (selectedFilter !== filterIndex) {
        dispatch({
          type: types.HANDLE_PERSISTENT_SELECTED_FITLER,
          selectedFilter: filterIndex
        });
      }
      break;
    }
    default:
      break;
  }
};

export const handlePersistentCheckbox = () => {
  return{
    type: types.CHANGE_FILTER_PERSISTENT_VALUE
  };
};

export const handleClickOnTestFilter = (payload) => {

  const parsedFilters = parseAnalysisFilters(payload.filtersList);

  return instance.get('/api/filters', {
    params: {
      startTime : payload.startTime,
      endTime   : payload.endTime,
      parentId  : payload.parentId,
      filters   : parsedFilters
    }
  });
};

export const handleClickOnCreateFilter = (payload) => {
  const parsedFilters = parseAnalysisFilters(payload.filtersList);
  const newFilterName = payload.filterName;
  const formData = {
    name       : newFilterName,
    filters    : parsedFilters,
    persistent : payload.persistentFilter,
    parentId   : payload.parentId,
    startTime  : payload.startTime,
    endTime    : payload.endTime
  };
    return instance.post(`/api/filters`, JSON.stringify(formData));
};

export const fetchFilterSeries = (payload) => {
  return instance.get('/api/filters', {
    params : {
      name:payload.name,
      startTime : payload.startTime,
      endTime : payload.endTime,
      parentId : payload.parentId,
      filters : payload.filters
    }
  });

};
