import React from "react";
import "./NotFoundPage.scss"
import noFound from '../../assets/img/404.png';
import Translate from "../Translate/Translate"
import {NavLink} from "react-router-dom";


export const NotFoundPage = () => {
    return (
        <div className={"flex flex-justify-content-center flex-align-items-center not-found-wrapper"}>
            <div className='flex'>
                <div className='no-found-action'>
                    <h1>
                        <Translate string={"NOT_FOUND_PAGE.PAGE_NOT_FOUND"}/>
                    </h1>
                    <h4><b><Translate string={""}/><Translate string={"GLOBAL.ERROR"}/>Error</b> <span>404</span></h4>
                    <p><Translate string={"NOT_FOUND_PAGE.TEXT"}/><br/><Translate string={"NOT_FOUND_PAGE.TEXT1"}/></p>

                    <NavLink to="/" className='go-home-button'>
                        <Translate string={"NOT_FOUND_PAGE.GO_HOME"}/>
                    </NavLink>
                </div>
                <div className='no-found-graph'>
                    <img src={noFound} alt=""/>
                </div>
            </div>
        </div>)
};