import React     from "react";
import { Icon }  from 'antd';
import propTypes from "prop-types";

const ProbeTitle = (props) => {

    const {
        probe,isFetching,
        isLoaded = () => {},removeProbeFromParent = () => {}, addProbeToParent = () => {},
        readOnly,
        disableProbes
        // probe:{existsInDashboard = false}
    } = props;

    const handleOnEyeClick = () => {
        if( isLoaded(probe.id) ){
            removeProbeFromParent(probe);
        }
        else {
            addProbeToParent(props.probe);
        }
    };

    const style = {};
    if (probe && probe.options && probe.options.lineColor){
        style.color = probe.options.lineColor
    }
    if ((isFetching && isFetching.fetching) || disableProbes ) {
        style.cursor = 'not-allowed'
    }
    return (
        <span className="tree-node-child" >
           <div> <span className="tree-node-child-title">
           <input type="text" defaultValue={props.probe.name} 
           style={{border:'none',outline:'none',overflowX:'hidden',backgroundColor:"inherit"}} 
           onKeyPress={(e)=>e.preventDefault()} className="tree-node-child-title"/>
           </span> </div>
            {readOnly
                ?
                <span className="tree-node-eye">
                     {
                        probe.existsInDashboard &&
                           <Icon
                                type={"eye"}
                                className={"eye"}
                                theme={"filled"}
                                style={{fontSize: "14px", ...style}}
                            />
                    }
                </span>
                :
                <span className="tree-node-eye">
                {
                    isFetching && isFetching.fetching && isFetching.id === props.probe.id ?
                        <Icon
                            type="loading"
                            style={{fontSize: "14px", ...style}}
                        />
                        :
                        <Icon
                            type={isLoaded(props.probe.id) ? "eye" : "eye-invisible"}
                            onClick={ (isFetching && isFetching.fetching) || disableProbes ? null : handleOnEyeClick}
                            className={isLoaded(props.probe.id) ? "eye" : "eye-invisible"}
                            theme={isLoaded(props.probe.id) ? "filled" : "outlined"}
                            style={{fontSize: "14px", ...style}}
                        />
                }
            </span>
            }
        </span>
    );
};

ProbeTitle.propTypes = {
    probe                 : propTypes.object,
    isFetching            : propTypes.object,
    isLoaded              : propTypes.func,
    removeProbeFromParent : propTypes.func,
    addProbeToParent      : propTypes.func
};
export default ProbeTitle;
