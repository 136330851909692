import React, { Component }               from 'react';
import { connect }                        from 'react-redux';
import { Redirect }                       from "react-router-dom"

import { Select, Tooltip }                from 'antd';

import SidebarProbeFilter                 from "./SidebarFilters/SidebarProbeFilter/SidebarProbeFilter"
import SidebarAcquisitionFtpFilter        from "./SidebarFilters/SidebarAcquisitionFilter/SidebarAcquisitionFtpFilter/SidebarAcquisitionFtpFilter";
import SidebarAcquisitionCsvFilter        from "./SidebarFilters/SidebarAcquisitionFilter/SidebarAcquisitionCsvFilter/SidebarAcquisitionCsvFilter";
import SidebarAcquisitionMqttFilter       from "./SidebarFilters/SidebarAcquisitionFilter/SidebarAcquisitionMqttFilter/SidebarAcquisitionMqttFilter";
import SidebarAcquisitionDataLoggerFilter from "./SidebarFilters/SidebarAcquisitionFilter/SidebarAcquisitionDataloggerFilter/SidebarAcquisitionDataloggerFilter";
import { Indicator }                      from "../../Indicator/Indicator";

import { globalSettingsActions }          from '../../../../actions';
import * as types                         from "../../../../actions/actionTypes";

import {onClickDataInAppIsChanged}        from  "../../../../config/constants";
import Translate                          from "../../../Translate/Translate";

import "./GlobalSettingsSidebar.scss";

const { Option }  = Select;

class GlobalSettingsSidebar extends Component {

    componentWillUnmount() {
        const { props: { DispatchTabKey } } = this;
        DispatchTabKey('datalogger')
    }
    componentDidMount() {
        const { props: { GetProbesForFolder, selectedId } } = this;
        GetProbesForFolder(selectedId);
    }

    render() {
        const {
            props: { distinctFilterParams, selectedId, probes, tabKey, history, newAddedProbeIsSave , NewProbeIsAddInProbeSetupToogle, isApplyDisabled},
            handleSelectChange, renderSideBar, treeList
        } = this ;

        const storedFolderId = localStorage.getItem('selectedTree') ;
        const defaultV       = !storedFolderId ? selectedId : parseInt( storedFolderId );

        return (
            distinctFilterParams ?

            <div className="global-settings-sidebar">
                {
                    window.location.pathname === "/global-settings/acquisition-setup" && probes.length === 0 ? <Redirect to={{pathname:'/global-settings/acquisition-setup', notificationShow:true}}/> : null
                }
                <div className="sidebar-tree-select">
                    <Tooltip placement="top" title={isApplyDisabled ? <Translate string={"GLOBAL.VIEW_UNSAVE"}/> : ''}>
                        <Select size={'large'}
                                disabled={isApplyDisabled}
                                showSearch
                                optionFilterProp="children"
                                className={'sidebar-select-tree'}
                                value={defaultV}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                onChange={value => handleSelectChange(value)}>
                                { treeList() }
                        </Select>
                    </Tooltip>
                </div>
                <div className="sidebar-filters">
                    <div className="sidebar-filter-form-wrap">
                        <div className="sidebar-filter-form">
                                {
                                    renderSideBar(tabKey)
                                }
                        </div>
                    </div>
                </div>
                <div className="sidebar-indicator-wrap">
                    <Indicator
                        history = { history }
                        hasProbes = { probes }
                        newAddedProbeIsSave={newAddedProbeIsSave}
                        NewProbeIsAddInProbeSetupToogle={NewProbeIsAddInProbeSetupToogle}
                        checkIsAddedProbeSaved={onClickDataInAppIsChanged}/>
                </div>
            </div> : null
        )
    };

    treeList = () => {
        const { props: { structure } } = this;
        if ( structure && structure.length > 0 ) {
            return structure.map((item, index) => {
                return  <Option value={item.id} key={index} >{item.name}</Option>
            })
        }

    };
    handleSelectChange = (value) => {
        const { props :{ GetProbesForFolder, HandleSelectedFolder ,GetLevels ,HandleDataStructure} } = this;

        localStorage.setItem('selectedTree', value);
        localStorage.setItem("dataInProbeSetupIsChanged","false");
        HandleDataStructure(value);
        HandleSelectedFolder(value);
        GetProbesForFolder(value);
        GetLevels(value);
    };

    renderSideBar = ( tabKey ) => {
        const { props : { history } } = this ;
        const { location: { pathname } }  = history ;

        const probePath = '/global-settings/probe-setup';

        if (pathname === probePath ) {
            return <SidebarProbeFilter/>
        } else {
            switch (tabKey) {
                case 'datalogger': {
                    return <SidebarAcquisitionDataLoggerFilter/>
                }
                case 'ftp': {
                    return <SidebarAcquisitionFtpFilter/>
                }
                case 'csv': {
                    return <SidebarAcquisitionCsvFilter/>
                }
                case 'mqtt': {
                    return <SidebarAcquisitionMqttFilter/>
                }
                default:
                    return <SidebarProbeFilter/>
            }
        }
    }

}

const mapStateToProps = state => ({
    structure            : state.tree.structure,
    selectedId           : state.globalSettings.selectedId,
    distinctFilterParams : state.globalSettings.distinctFilterParams,
    tabKey               : state.globalSettings.tabKey,
    probes               : state.globalSettings.probes,
    newAddedProbeIsSave  : state.globalSettings.newAddedProbeIsSave,
    isApplyDisabled      : state.globalSettings.isApplyDisabled
});

const mapDispatchToProps = dispatch => ({
    GetProbesForFolder          : (folderId)   => dispatch(globalSettingsActions.GetProbesForFolder(folderId)),
    HandleSelectedFolder        : (folderId)   => dispatch({type: types.KEEP_SELECTED_ID, selectedId: folderId}),
    GetLevels                   : (folderId)   => dispatch(globalSettingsActions.GetFolderPaths(folderId)),
    DispatchTabKey              : (dataLogger) => dispatch(globalSettingsActions.DispatchTabKey(dataLogger)),
    NewProbeIsAddInProbeSetupToogle : (isAdd) => dispatch(globalSettingsActions.NewProbeIsAddInProbeSetupToogle(isAdd)),
    HandleDataStructure         : (id)       => dispatch(globalSettingsActions.HandleDataStructure(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSettingsSidebar);
