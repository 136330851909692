import React from 'react';
import {Button, Dropdown, Menu, Modal} from "antd";
import Translate from "../../../../Translate/Translate";

import {ArrowDownAlerts, ArrowTopAlerts} from "../../../../../config/constants";
import './StatusItem.scss';

const StatusItem = (props) => {

    const menu = (e) => {

        return (<Menu>
            <Menu.Item>
                <Button type='button' onClick={()=>props.editStatus(props)}><Translate string={"GLOBAL.EDIT"}/></Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='button' onClick={showDeleteConfirm}><Translate string={"GLOBAL.DELETE"}/></Button>
            </Menu.Item>
        </Menu>)
    };

    const showDeleteConfirm = () => {

        Modal.confirm({
            title: <Translate string={"ALERTING.DELETE_STATUS_CONFIRM_TITLE"} />,
            content: <Translate string={"ALERTING.DELETE_STATUS_CONFIRM_CONTENT"} />,
            okText: <Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"} />,
            okType: 'danger',
            cancelText: <Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>,
            onOk() {
                props.onDelete(props.id)
            },
            onCancel() {
            },
        });
    };

    return (
        <div className='alerts-item flex'>
            <div className="alerts-action" id={`area${props.itemIndex}`}>
                <Dropdown trigger={["click"]} overlay={() => menu(props)}
                          getPopupContainer={() => document.getElementById(`area${props.itemIndex}`)}
                          placement="bottomLeft">
                    <button className={'button-icon'}>
                        <span/>
                        <span/>
                        <span/>
                    </button>
                </Dropdown>
            </div>
            <div className="alerts-data">
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.SEVERITY"}/></label>
                    <div className='flex flex-align-items-center'>
                        {props.severity+1 === props.total ? <ArrowDownAlerts className={'alerts-arrow-disabled'} /> : <ArrowDownAlerts onClick={()=>props.onPossitionChange(props.severity + 1)}/>}
                        <p className='alerts-position'> {props.severity}</p>
                        {props.severity === 0 ? <ArrowTopAlerts className={'alerts-arrow-disabled'} /> : <ArrowTopAlerts onClick={()=>props.onPossitionChange(props.severity - 1)}/>}
                    </div>
                </div>
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.STATUS_NAME"}/></label>
                    <p className={'alerts-status'}> {props.name}</p>
                </div>
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.COLOR"}/></label>
                    <p><span className={'colorPreview'} style={{backgroundColor: `${props.color}`}}/></p>
                </div>
            </div>
        </div>
    );
};

export default StatusItem;