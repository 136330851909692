import React, {useEffect, useState, useReducer, useRef} from 'react';
import moment from 'moment';

import ViewsDetailsLeftSideBar from './ViewerDetailsLeftSideBar/ViewerDetailsLeftSidebar';
import ViewersDetailsHeader from './ViewersDetailsHeader/ViewersDetailsHeader';
import ViewersDetailsRightSideBar from './ViewersDetailsRightSideBar/ViewersDetailsRightSideBar';
import instance from '../../../config/axiosConf';
import Chart from '../../Chart/Chart';
import {isJson, showNotification, TranslateText} from '../../../config/constants';
import './ViewerDetails.scss';
import ReportData from "../../ReportData/ReportData";
import ViewerDetailsReducer from './ViewerDetailsReducer';
import {TOGGLE_LEFT_SIDEBAR, TOGGLE_RIGHT_SIDEBAR} from './ViewerDetailsType';
import {fetchFilterSeries} from "../../../actions/filterAnalyses";

const ViewerDetails = (props) => {

    const [viewer, setViewer] = useState({});
    const [from, setFrom] = useState(moment().subtract(1,'month').toISOString());
    const [to, setTo] = useState(moment().toISOString());
    const [isVisibleLeftSidebar, setVisibleLeftSidebar] = useState(false);
    const [isVisibleRightSidebar, setVisibleRightSidebar] = useState(false);
    const [probesList, setProbesList] = useState([]);
    const [loadedProbesId, setLoadedProbesId] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [isUpdatedChartData, setUpdatedChartData] = useState(false);
    const [reportData, setReportData] = useState([]);
    const search = props.history.location.search;
    const getParameter = Number(search.toString().split('=')[1]);
    const [updatedProbe,setUpdatedProbe] = useState(null)
    const [probeConfiguration,setProbeConfiguration] = useState([]);
    const [isFetching,setFetching] = useState({});
    const [chartType,setChartType] = useState(null);
    const [propsViewerProbes,setPropsViewerProbes] = useState({});
    const [titleRightSideBar,setTitleRightSideBar] = useState(false);
    const [probeStyleRightSideBar,setProbeStyleRightSideBar] = useState(false);
    const [treeLeftSideBar,setTreeLeftSideBar] = useState(false);
    const [headerViewDetails,setHeaderViewDetails] = useState(false);
    const [probeListStatus,setProbeListStatus] = useState(false);
    const [areDimensionsChanged,setAreDimensionsChanged] = useState({isVisibleLeftSidebar: false,isVisibleRightSidebar: false});
    const [isLoading, setLoading] = useState(false);
    const [responseHaveCome,setResponseHaveCome]= useState(false);

    const [loadedFilterIds, setLoadedFilterIds] = useState([]);
    const [loadedFilters, setLoadedFilters] = useState([]);

    const [state, dispatch] = useReducer(ViewerDetailsReducer, isVisibleLeftSidebar);

    const [isAxisSplit,setAxisSplit] = useState(false);
    const [isHideYAxis,setHideYAxis] = useState(true);
    const ref = useRef(null);
    const [disableProbes, setDisableProbes] = useState(true);

    const [viewerTitle, setViewerTitle] = useState('');
    const [viewerDescription, setViewerDescription] = useState('');

    useEffect(() => {
        probesList.length > 0 ? setProbeListStatus(true) : setProbeListStatus(false);
        chartType === 'scatter' && scatterTypeLineColor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [probesList]);

    /**
     * componentDidMount
     */
    useEffect(() => {
        let urlParams = new URLSearchParams();
        urlParams.append("include", "probes");

        instance.get(`/api/views/chart/${getParameter}?`, {
            params: urlParams
        }).then(response => {
            
            if (response && response.data) {
                const probesConfigurationNew = [] ;
                const fetchedViewer = response.data.data;
                let viewerProbes = fetchedViewer.probes;

                fetchedViewer.options = isJson(fetchedViewer.options) ? JSON.parse(fetchedViewer.options): fetchedViewer.options;

                viewerProbes = viewerProbes && viewerProbes.map(viewerProbe => {
                    const loadedProbesOptions = fetchedViewer.options.probeConfig && fetchedViewer.options.probeConfig.find(probeOption => probeOption.id === viewerProbe.id);
                    probesConfigurationNew.push({folderRootId: viewerProbe.folderRootId,...loadedProbesOptions});
                    viewerProbe.options = loadedProbesOptions ? loadedProbesOptions.options : {yAxis : {name : viewerProbe.unit}} ;
                    return viewerProbe;
                });

                if (!fetchedViewer.from || !fetchedViewer.to) {
                    fetchedViewer.from = moment().subtract(1,'month').toISOString();
                    fetchedViewer.to = moment().toISOString();
                }

                setChartType(fetchedViewer.options.chartType);
                setProbeConfiguration(probesConfigurationNew);
                fetchedViewer.probes = viewerProbes || [];
                const initSeriesForViewer = initFetchSeries(fetchedViewer);
                initSeriesForViewer ? initSeriesForViewer.probePromises[0].then(response => {
                    const keys = Object.keys(response.data);
                    const probeSeriesData = [];
                    const listOfProbes = fetchedViewer.probes;
                    keys.forEach(key => {
                        const probeData = listOfProbes.find(probe => probe.id === parseInt(key));
                        if (probeData && probeData.options) {
                            probeData.options = isJson(probeData.options) ? JSON.parse(probeData.options) : probeData.options;
                            if(response.data[key] === null) {
                                response.data[key] = [];
                            }
                            if(response.data[key].length < 1000) {
                                response.data[key].unshift([Number(moment(fetchedViewer.from).format("x")), null]);
                                response.data[key].push([Number(moment(fetchedViewer.to).format("x")), null]);
                            }
                            probeSeriesData.push({...probeData, series: response.data[key]});
                        }
                    });
                    if (probeSeriesData.length === 0) {
                        setResponseHaveCome(true);
                    }
                    setChartData(probeSeriesData);
                    setUpdatedChartData(true);
                }) 
                : setDisableProbes(false);
                initFetchReports(fetchedViewer.probes,fetchedViewer.from,fetchedViewer.to);
                if(fetchedViewer && fetchedViewer.probes &&fetchedViewer.probes.length !== 0) {
                    let ids=fetchedViewer.probes.map(probe => probe.id);
                    setLoadedFilterIds(ids);
                    setLoadedProbesId(ids);
                }
                setViewer(fetchedViewer);
                setViewerTitle(fetchedViewer.name);
                setViewerDescription(fetchedViewer.description);
                setAxisSplit(fetchedViewer.options.isAxisSplit || false);
                setHideYAxis(fetchedViewer.options.isHideYAxis !== undefined ? fetchedViewer.options.isHideYAxis : true);
                setPropsViewerProbes(response.data.data);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getParameter]);

    /**
     * componentDidUpdate - viewer probes list is updated
     */
    useEffect(() => {
        viewer.probes && setProbesList(viewer.probes);
    }, [viewer,setProbesList]);

    /**
     * componentDidUpdate - viewer date range is changed
     */
    useEffect(() => {
        viewer.from && setFrom(viewer.from);
        viewer.to && setTo(viewer.to);
    }, [viewer.from, viewer.to, setFrom, setTo]);

    /**
     *     componentDidUpdate - local probesList is updated
    //  */

    const handleChangeViewerProps = (event) => {
        event.preventDefault();
        const newViewer = {...viewer, [event.target.name]: event.target.value, changed: true};

            if(newViewer.name !== propsViewerProbes.name || newViewer.description !== propsViewerProbes.description) {
                if(treeLeftSideBar === false && headerViewDetails === false && probeStyleRightSideBar === false) {
                    localStorage.setItem("dataInAppIsChanged", "true");
                }
                setTitleRightSideBar(true);
            }else {
                if(treeLeftSideBar === false && headerViewDetails === false && probeStyleRightSideBar === false) {
                    localStorage.setItem("dataInAppIsChanged", "false");
                }
                setTitleRightSideBar(false);
            }
        event.target.name === 'name' ? setViewerTitle(event.target.value) : setViewerDescription(event.target.value);
    };

    const addFilterToList = (filter) => {
        setLoadedFilterIds([...loadedFilterIds, filter.probeId]);
        loadedFilters.push(filter);
        fetchFilterSeriesData(filter);
        filter.id = filter.probeId;
        setProbesList([...probesList, filter]);
        isChangeSelectedProbeList([...probesList, filter]);
        filter.persistent && fetchNewReport(filter.probeId);
    };
    const removeFilterFromList = (filterId) => {
        setProbesList(probesList.filter(probe => probe.id !== filterId));
        setLoadedFilterIds(loadedFilterIds.filter(id => id !== filterId));
        setLoadedFilters(loadedFilters.filter(filter => filter.probeId !== filterId));
        removeFilterSeries(filterId);
        removeReport(filterId);
    };

    const fetchFilterSeriesData = (filter) => {
        filter.endTime = to;
        filter.startTime = from;
        setFetching({id: filter.probeId, fetching: true});
        setLoading(true);
        fetchFilterSeries(filter).then(response => {
            if(response.data.seriesData === null) {
                response.data.seriesData = [];
            }
            if(response.data.seriesData.length < 1000) {
                response.data.seriesData.unshift([Number(moment(filter.startTime).format("x")), null]);
                response.data.seriesData.push([Number(moment(filter.endTime).format("x")), null]);
            }
            setChartData([...chartData, {id: filter.probeId, name: filter.name, series: response.data.seriesData}]);
            setTimeout(() => setFetching({id: filter.probeId, fetching: false}), 300);
            setUpdatedChartData(true);
        });
    };
    const removeFilterSeries = (filterId) => {
        setChartData(chartData.filter(line => line.id !== filterId));
        setUpdatedChartData(true);
    };

    const updateViewer = () => {

        const requests = [];
        let updatedViewer = {...viewer};

        probesList.forEach(probe => {

            if (probe.changed) {

                updatedViewer.options = isJson(updatedViewer.options) ? JSON.parse(updatedViewer.options) : updatedViewer.options;
                updatedViewer.options.probeConfig = updatedViewer.options.probeConfig ? [...updatedViewer.options.probeConfig] : [];

                const indexOfConfig = updatedViewer.options.probeConfig.findIndex(probeConfig => probeConfig.id === probe.id);

                if(indexOfConfig >= 0)
                    updatedViewer.options.probeConfig[indexOfConfig] = {id:probe.id,options:probe.options};

                updatedViewer.options.probeConfig.push({id:probe.id,options:probe.options});
                delete probe.changed;
            }

        });
        updatedViewer.name = viewerTitle;
        updatedViewer.description = viewerDescription;

        updatedViewer.options['isAxisSplit'] = isAxisSplit;
        updatedViewer.options['isHideYAxis'] = isHideYAxis;

        requests.push(instance.put(`/api/views/chart/${updatedViewer.id}`, {
            name: updatedViewer.name,
            description: updatedViewer.description,
            from,
            to,
            options:JSON.stringify(updatedViewer.options)
        }));

        const attachData = [] ;
        probesForAttach().forEach(idForAttach => attachData.push({
            probeId: idForAttach,
            viewType: "chart",
            viewId: updatedViewer.id
        }));

        const detachData = [] ;
        probesForDetach().forEach(idForDetach =>
            detachData.push({
                probeId: idForDetach,
                viewType: "chart",
                viewId: updatedViewer.id
            })
        );

        if(detachData.length) {
            requests.push(instance.put(`/api/views/probe/detach`, { data : detachData }));
        }
        if(attachData.length) {
            requests.push(instance.put(`/api/views/probe/attach`, {data : attachData }));
        }

        Promise.all(requests).then((data) => {
            updatedViewer.probes = probesList;
            setViewer(updatedViewer);
            setAxisSplit(updatedViewer.options.isAxisSplit);
            setHideYAxis(updatedViewer.options.isHideYAxis);
            showNotification('success',TranslateText("GLOBAL.SUCCESS"), TranslateText("VIEWERS.SAVED"));
            setTitleRightSideBar(false);
        });
    };

    const initFetchSeries = (initViewer) => {
        
        if (initViewer.probes && initViewer.probes.length) {
            const { probes, from, to, options : { chartType } } = initViewer ;
           return fetchProbeSeriesData( probes, from, to, chartType);
        }
        setResponseHaveCome(true);

    };

    const addProbe = (probe) => {
        const optionsConfig = viewer.options.probeConfig ? viewer.options.probeConfig.find(probeOption => probeOption.id === probe.id) : null ;
        probe.options = optionsConfig 
            ? optionsConfig.options 
            : chartType === 'scatter'
            ? { yAxis : { name : probe.unit }, lineColor : probe.options.lineColor, pointColor : probe.options.lineColor }
            : { yAxis : { name : probe.unit }, lineColor : probe.options.lineColor }
        probe.changed = true;
        setProbesList([...probesList, probe]);
        setLoadedProbesId([...loadedProbesId,probe.id]);
        isChangeSelectedProbeList([...probesList, probe]);
        fetchNewSeries(probe);
        fetchNewReport(probe.id);
    };

    const isChangeSelectedProbeList = (probes) => {

        if ((viewer.probes && viewer.probes.length) !== probes.length) {
            if(titleRightSideBar === false && headerViewDetails === false && probeStyleRightSideBar === false) {
                localStorage.setItem("dataInAppIsChanged", "true");
            }
            setTreeLeftSideBar(true);
        }
        if ((viewer.probes && viewer.probes.length) === probes.length) {
            let sameProbes = [];
            viewer.probes.forEach(vProbe => {
                probes.forEach(probe => {
                    if (probe.id === vProbe.id) {
                        sameProbes.push(vProbe);
                    }
                });
                if (sameProbes.length === viewer.probes.length) {
                    if(titleRightSideBar === false && headerViewDetails === false && probeStyleRightSideBar === false) {
                        localStorage.setItem("dataInAppIsChanged", "false");
                    }
                    setTreeLeftSideBar(false);
                }
            });
            if ((viewer.probes && viewer.probes.length === 0) && probes.length === 0) {
                if(titleRightSideBar === false && headerViewDetails === false && probeStyleRightSideBar === false) {
                    localStorage.setItem("dataInAppIsChanged", "false");
                }
                setTreeLeftSideBar(false);
            }
        }

    };

    const removeProbe = (probeId) => {
        setLoadedProbesId(loadedProbesId.filter(id => id !== probeId));
        setProbesList(probesList.filter(probe => probe.id !== probeId));
        let probes=probesList.filter(probe => probe.id !== probeId);
        isChangeSelectedProbeList(probes);
        removeSeries(probeId);
        removeReport(probeId);
    };

    const fetchNewSeries = (probe) => {

        let filters = {
            probeId: probe.id,
            from : from ? from : moment().subtract(1, 'months').format('YYYY MMM DD').toString(),
            to : to ? to : moment().format('YYYY MMM DD'),
            chartType : viewer.options.chartType,
            limit : 1000
        };

        const request = instance.get('/api/series', {
                params: {
                    filters: filters
                }
        });

        setFetching({id:probe.id,fetching:true});
        Promise.resolve(request).then(response => {

            probe.options = isJson(probe.options) ? JSON.parse(probe.options) : probe.options;
            if(response.data === null) {
                response.data = [];
            }
            if(response.data.length < 1000) {
                response.data.unshift([Number(moment(filters.from).format("x")), null]);
                response.data.push([Number(moment(filters.to).format("x")), null]);
            }
            setChartData([...chartData, {...probe, series: response.data}]);
            setUpdatedChartData(true);
            setTimeout(() => setFetching({id:probe.id,fetching:false}),300 );
        })
    };

    const reFetchSeries =  (startTime, endTime) => {
        setChartData([]);
        setUpdatedChartData(true);

        initFetchReports(probesList,startTime, endTime);

        const filterPromises = reFetchFilters(startTime, endTime).filterPromises;
        const probePromises = fetchProbeSeriesData(probesList,startTime,endTime,viewer.options.chartType).probePromises;
        const seriesData = [] ;
        setChartData(seriesData);
        Promise.all([...probePromises,...filterPromises]).then(responses => {
                if (responses) {
                    responses.forEach(singleResponse => {
                        if (singleResponse) {
                            const {data} = singleResponse;
                            if (data.filterData) {
                                const {filterData} = data;
                                // filter data
                                if(data.seriesData === null) {
                                    data.seriesData = [];
                                }
                                if(data.seriesData.length < 1000) {
                                    data.seriesData.unshift([Number(moment(startTime).format("x")), null]);
                                    data.seriesData.push([Number(moment(endTime).format("x")), null]);
                                }
                                seriesData.push({id: filterData.probeId, name: filterData.name, series: data.seriesData});
                                setFetching({id: filterData.probeId, fetching: false});

                            } else {
                                //probes data
                                const keys = Object.keys(data);
                                keys.forEach(key => {
                                    const probeData = probesList.find(probe => probe.id === parseInt(key));
                                    if (probeData && probeData.options) {
                                        probeData.options = isJson(probeData.options) ? JSON.parse(probeData.options) : probeData.options;
                                        if(data[key] === null) {
                                            data[key] = [];
                                        }
                                        if(data[key].length < 1000) {
                                            data[key].unshift([Number(moment(startTime).format("x")), null]);
                                            data[key].push([Number(moment(endTime).format("x")), null]);
                                        }
                                        seriesData.push({...probeData, series: data[key]});
                                    }
                                });
                            }
                            // TO DO add part for analysis
                        }
                    });
                }
                setChartData(seriesData);
                setUpdatedChartData(true);
            }
        )
    };


    const reFetchFilters = (startTime, endTime) => {
        setLoading(true);

        const promisesArray = [] ;

        for (const filter of loadedFilters) {
            filter.endTime = endTime;
            filter.startTime = startTime;
            setFetching({id: filter.probeId, fetching: true});
            promisesArray.push(fetchFilterSeries(filter));
        }

        return {
            filterPromises: promisesArray
        };
    };

    const removeSeries = (probeId) => {
        setChartData(chartData.filter(data => data.id !== probeId));
        setUpdatedChartData(true);
    };

    const probesForAttach = () => {
        const forAttach = [];

        probesList.forEach(probe => {
            if (viewer && !viewer.probes) {
                forAttach.push(probe.id);
            } else if (viewer.probes && !viewer.probes.some(viewerProbe => {
                return viewerProbe.id === probe.id
            })) {
                forAttach.push(probe.id);
            }
        });
        return forAttach;
    };

    const probesForDetach = () => {
        const forDetach = [];
        viewer.probes && viewer.probes.forEach(viewerProbe => {
            if (!probesList.some(probe => probe.id === viewerProbe.id)) {
                forDetach.push(viewerProbe.id);
            }
        });
        return forDetach;
    };

    const updateProbeStyle = (probeId, probeOptions,newProbeLists) => {
        const updatedChartData = [...chartData];
        const observedProbe = chartData && chartData.find(data => data.id === probeId);

        if (observedProbe) {
            observedProbe.options = probeOptions;
            setProbesList([...newProbeLists]);
            setChartData(updatedChartData);
            setUpdatedChartData(true);
            setUpdatedProbe(observedProbe);
        }
    };

    const scatterTypeLineColor = () => {
        let newProbesList = [];
        probesList.forEach(probe => {
            let newProbe = probe;
            if (probe.options && probe.options.pointColor) {
                newProbe.options.lineColor = probe.options.pointColor;
            }
            newProbesList.push(newProbe); 
        })
    };

    const initFetchReports = (initProbesList,from,to) => {
        if (initProbesList && initProbesList.length) {
            const reports = [];

            const probeIds = initProbesList.map(probe => {
                return {
                    probeId : probe.id,
                    from : from,
                    to: to
                }
            });
            instance.post('/api/probes/reports',{ data : probeIds}).then(response => {
                response.data.data.forEach(report => reports.push(report));
                    setReportData(reports);
                    setAreDimensionsChanged({isVisibleLeftSidebar: true,isVisibleRightSidebar: isVisibleRightSidebar})
            });
        }
    };

    const fetchNewReport = (probeId) => {
        instance.get(`/api/probes/${probeId}/report`).then(response => {
            setReportData([...reportData, response.data]);
            setVisibleLeftSidebar(true);
            setAreDimensionsChanged({isVisibleLeftSidebar: true,isVisibleRightSidebar: isVisibleRightSidebar})
        });
    };

    const removeReport = (probeId) => {
        let isDeletedoneTime=false;
        let newFilterReports=reportData.filter(report =>{
            if(isDeletedoneTime ===false) {
                if (report.probe.id === 0) {
                    isDeletedoneTime=true;
                    return report.probe.id !== 0
                }
            }
            return report.probe.id !== probeId
        });
        setReportData(newFilterReports);
    };

    const handleLeftSidebar = () => {
        setVisibleLeftSidebar(!isVisibleLeftSidebar);
        setAreDimensionsChanged({isVisibleLeftSidebar: true,isVisibleRightSidebar: isVisibleRightSidebar});
        dispatch({
            type: TOGGLE_LEFT_SIDEBAR,
            payload: {isVisibleLeftSidebar}
        });
    };
    const handleRightSidebar = () => {
        setVisibleRightSidebar(!isVisibleRightSidebar);
        setAreDimensionsChanged({isVisibleLeftSidebar: true,isVisibleRightSidebar: isVisibleRightSidebar});
        dispatch({
            type: TOGGLE_RIGHT_SIDEBAR,
            payload: {isVisibleRightSidebar}
        });
    };
    const toggleAxisSplit = () => {
        if(isAxisSplit !== viewer.options.isAxisSplit && isHideYAxis === viewer.options.isHideYAxis ) {
            localStorage.setItem("viewerYAxisIsChanged", "false");
            setAxisSplit(!isAxisSplit);
        } else {
            localStorage.setItem("viewerYAxisIsChanged", "true");
            setAxisSplit(!isAxisSplit);
        }
    };
    const toggleHideYAxis = () => {
        if(isHideYAxis !== viewer.options.isHideYAxis && isAxisSplit === viewer.options.isAxisSplit) {
            localStorage.setItem("viewerYAxisIsChanged", "false");
            setHideYAxis(!isHideYAxis);
        } else {
            localStorage.setItem("viewerYAxisIsChanged", "true");
            setHideYAxis(!isHideYAxis);
        }
        
    };

    const fetchProbeSeriesData = (listOfProbes,startTime,endTime,chartType) => {
        const probeIds = [];

        listOfProbes.forEach(probe => probeIds.push(probe.id));

        const filters = {
            probeIds: probeIds,
            from : startTime,
            to : endTime,
            chartType : chartType,
            limit : 1000
        };
        const probePromises = instance.get('/api/series', {
            params: {
                filters: filters
            }
        });
        return {
            probePromises: [probePromises]
        };
    };

    return (
        <div className={isVisibleLeftSidebar ? isVisibleRightSidebar ?
            'viewer-details-wrapper viewer-show-right-sidebar viewer-show-left-sidebar' :
            'viewer-details-wrapper viewer-show-left-sidebar' :
            isVisibleRightSidebar ?
            'viewer-details-wrapper viewer-show-right-sidebar' :
                'viewer-details-wrapper'}>

            <div className={'viewer-details-header-wrapper flex'}>
                <ViewersDetailsHeader
                    name={viewer.name}
                    description={viewer.description}
                    from={from}
                    setFrom={setFrom}
                    propsViewerProbes={propsViewerProbes}
                    to={to}
                    setTo={setTo}
                    isVisibleLeftSidebar={state.toggleLeftSidebar}
                    viewerId={viewer.id}
                    viewer={viewer}
                    refetchSeries={reFetchSeries}
                    updateViewer={updateViewer}
                    toggleSidebar={handleLeftSidebar}
                    toggleSettings={handleRightSidebar}
                    setHeaderViewDetails={setHeaderViewDetails}
                    history={props.history}
                    titleRightSideBar={titleRightSideBar}
                    probeStyleRightSideBar={probeStyleRightSideBar}
                    treeLeftSideBar={treeLeftSideBar}
                    statusOfProbesList = {probeListStatus}
                    splitAxis={toggleAxisSplit}
                    hideYAxis={toggleHideYAxis}
                    isAxisSplit={isAxisSplit}
                    isHideYAxis={isHideYAxis}
                    setResponseHaveCome={setResponseHaveCome}
                />
            </div>
            <div className="viewer-details-body">
                <div className="viewer-details-wrap">
                    <div
                        className={'left-sidebar-wrapper flex flex-direction-column'}>
                        <ViewsDetailsLeftSideBar loadedProbesId={loadedProbesId} addProbe={addProbe}
                                                 removeProbe={removeProbe} updatedProbe={updatedProbe}
                                                 probeStyles={probeConfiguration} isFetching={isFetching}
                                                 addFilter={addFilterToList}
                                                 removeFilter={removeFilterFromList}
                                                 loadedFiltersId={loadedFilterIds}
                                                 disableProbes={disableProbes}
                        />
                    </div>
                    <div className={'viewer-details-content-wrapper flex'}>
                        <div className={'viewer-chart-container'}
                             style={{width: "100%"}} ref={ref}>
                            <div className='chart-wrap'>
                                <div className={'viewers-details-content bg-white'}>
                                    {/* {chartData.length === 0 &&  */}
                                    <Chart
                                        chartData={chartData}
                                        isUpdatedChartData={isUpdatedChartData}
                                        setUpdateChartData={setUpdatedChartData}
                                        chartType={chartType}
                                        isAxisSplit={isAxisSplit}
                                        isHideYAxis={isHideYAxis}
                                        onHideYAxis={isHideYAxis}
                                        setAreDimensionsChanged={setAreDimensionsChanged}
                                        areDimensionsChanged={areDimensionsChanged}
                                        parent={ref}
                                        isLoading={isLoading}
                                        responseHaveCome={responseHaveCome}
                                        setResponseHaveCome={setResponseHaveCome}
                                        setDisableProbes={setDisableProbes}
                                    />
                                    {/* } */}
                                    {
                                        reportData.length ? <ReportData reportData={reportData} /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'viewer-details-right-sidebar-wrapper flex flex-direction-column bg-white'}>
                        <ViewersDetailsRightSideBar
                            probesList={probesList}
                            viewerName={viewerTitle}
                            viewerDescriprion={viewerDescription}
                            changeViewerPropsHandler={handleChangeViewerProps}
                            updateProbeStyle={updateProbeStyle}
                            propsViewerProbes={propsViewerProbes}
                            setProbeStyleRightSideBar={setProbeStyleRightSideBar}
                            titleRightSideBar={titleRightSideBar}
                            headerViewDetails={headerViewDetails}
                            treeLeftSideBar={treeLeftSideBar}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};
export default ViewerDetails;
