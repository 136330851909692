import * as types from '../actions/actionTypes';

const initialState = {
    dataStructure: [],
    infoDataStructure: [],
    treeSetupTab: true,
    activeItemId: null,
    nameSource: null,
    statusFlag: false,
    tagSource: null,
    descSource: null,
    typeSource: null,
    updateId: null,
    sortData: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.HANDLE_DATA_STRUCTURE:
            return {
                ...state,
                treeSetupTab: !state.treeSetupTab,
                dataStructure: action.dataStructure,
                infoDataStructure: action.infoDataStructure
            };
            case types.HANDLE_ACTIVE_DATA_STRUCTURE:
            return {
                ...state,
                activeItemId: action.activeItemId
            };
            case types.UPDATE_DATA_STRUCTURE_NAME:
            return {
                ...state,
                nameSource: action.nameSource
            };
            case types.UPDATE_DATA_STRUCTURE_TAG:
            return {
                ...state,
                tagSource: action.tagSource
            };
            case types.UPDATE_DATA_STRUCTURE_DESC:
            return {
                ...state,
                descSource: action.descSource
            };
            case types.UPDATE_DATA_STRUCTURE_TYPE:
            return {
                ...state,
                typeSource: action.typeSource
            };
            case types.UPDATE_DATA_STRUCTURE_ID:
            return {
                ...state,
                updateId: action.updateId
            };
            case types.STATUS_DATA_STRUCTURE:
            return {
                ...state,
                statusFlag: !state.statusFlag
            };

            case types.HANDLE_SORT_DATA_BY_ID:
            return {
                ...state,
                sortData: action.sortData
            };
            case types.DELETE_NODE:
            return{
              ...state,
              sortData : action.sortData
            };
            case types.CHANGE_BRANCH_NAME :
            return {
              ...state,
              sortData  : action.sortData
            };
            case types.ADD_NEW_BRANCH:
                return {
                    ...state,
                    sortData : action.sortData
                };
        case types.CHANGE_SORT_DATA :
                return {
                  ...state,
                  sortData : action.sortData
                };
        default:
            return state;
    }
};
