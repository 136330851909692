import React, {useEffect, useState} from 'react';
import {Button, Icon, Input, Pagination} from "antd";

import RecipientsItem from "./RecipientsItem/RecipientsItem";
import Modal from "../../../../elements/Modal/Modal";
import {ModalClose, TranslateText} from "../../../../config/constants";
import {createRecipient, getRecipients, deleteRecipient, updateRecipient} from "../../../../api/alerting/alerting";
import AlertsSetupNoData from "../AlertsSetupNoData/AlertsSetupNoData";
import {LoaderBar} from "../AlertSetupLoader/AlertSetupLoader";
import useForm from "../../../../utils/Validation/Validation";
import Translate from '../../../Translate/Translate';

import './AlertSetupRecipients.scss';


const AlertSetupRecipients = () => {
    const [isModalOpen, toggleModal] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [editData, setEditData] = useState({
        email: '',
        phone: '',
        name: ''
    });
    const [editButton, setEditButton] = useState(false);
    const [loader, setLoader] = useState(true);
    const [type, setType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState([]);
    const [search, setSearch] = useState({text: ''});
    const [isSearch, setIsSearch] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);

    useEffect(() => {
        getAllRecipients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(isSearch){
            getAllRecipients();
            setIsSearch(false);
            searchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search]);

    const {
        errors,
        handleSubmit,
    } = useForm(type === 'edit'? updateSelectedRecipient : onSubmit, editData);

    const getAllRecipients = (page, filters) => {
        setLoader(true);
        getRecipients(page, filters || {name: search.text}).then(res => {
            if (res && res.data) {
                setPagination(res.meta);
                setRecipients(res.data);
                setTimeout(() => {
                    setLoader(false);
                    setLoadingSearch(false);
                }, 1000)
            }
        })
    };

    const {email, phone, name} = editData;

    function onSubmit() {

        setLoading(true);

        let payload = {
            name: name,
            email: email,
            phone: phone
        };
        createRecipient(payload)
            .then(() => {
                closeModal();
                setTimeout(() => {
                    getAllRecipients();
                    setLoading(false);
                }, 500);
            });
    }

    function updateSelectedRecipient() {

        setLoading(true);

        let payload = {
            name: name,
            email: email,
            phone: phone
        };
        updateRecipient(editData.id, payload)
            .then(() => {
                closeModal();
                setTimeout(() => {
                    getAllRecipients();
                    setLoading(false);
                }, 500);
            });
    };

    const editRecipients = (recipients) => {
        setEditData(recipients);
        setTimeout(() => {
            toggleModal(true)
        }, 100)
    };

    const delRecipients = (e) => {
        setLoading(true);
        if (e) {
            deleteRecipient(e)
                .then(() => {
                    setTimeout(() => {
                        getAllRecipients();
                        setLoading(false);
                        setIsSearch(false);
                    }, 500);
                })
        }
    };

    const onChange = (e) => {
        setEditData({...editData, [e.target.name]: e.target.value});
    };

    const isChange = (e) => {
        setSearch({...search, text: e.target.value});
        if (e.target.value.length === 0){
            setIsSearch(true);
        }
    };

    const searchData =()=>{
        if(search){
            setLoadingSearch(true);
            setTimeout(() => {
                getAllRecipients();
                setLoadingSearch(false);
            }, 500)
        }
    };

    const onChangePage = e => {
        getAllRecipients(e);
    };

    const closeModal = () => {
        toggleModal(false);
        setEditData({
            email: '',
            phone: '',
            name: ''
        });
        setType(null);
    };

    return (
        <div className='alert-setup-recipients flex flex-direction-column'>
            <div
                className="alert-setup-recipients-header flex flex-justify-content-space-between flex-align-items-center">
                <div className="alert-setup-recipients-search flex flex-align-items-center flex-justify-content-space-between">
                    <Input
                        name={"unit"}
                        type={"text"}
                        placeholder={TranslateText("GLOBAL.SEARCH")}
                        autoComplete='off'
                        allowClear
                        onChange={isChange}
                        className={'alerting-search-input'}
                        onPressEnter={!loadingSearch && searchData}
                    />
                    <div className="form-input flex flex-direction-column alerting-alerts-header-cta">
                        <Button type="primary" onClick={searchData} icon="search" disabled={loadingSearch || search.text.length === 0}><Translate string={"GLOBAL.SEARCH"}/></Button>
                    </div>
                </div>
                {loadingSearch && <div className="alerting-list-loader">
                    <Icon type="loading" style={{color: '#2980B9'}}/>
                </div>}
            </div>
            <div className="alert-setup-recipients-content">
                <div className="recipients-list">
                    {loader ?
                        <LoaderBar/> :
                        recipients && recipients.length > 0 ?
                            recipients.map((recipient, index) => (
                                <RecipientsItem
                                    key={index}
                                    name={recipient.name}
                                    email={recipient.email}
                                    phone={recipient.phone}
                                    itemIndex={index}
                                    id={recipient.id}
                                    onDelete={delRecipients}
                                    editRecipients={(recipients) => {
                                        editRecipients(recipients);
                                        setEditButton(!editButton);
                                        setType('edit')
                                    }
                                    }

                                />)) : search.text.length !== 0 ? <div></div> :
                            <AlertsSetupNoData title={'You have not created any recipients yet'}
                                               subtitle={'Add your first recipient'}
                                               button={'Add new recipient'}
                                               isButtonClick={() => toggleModal(!isModalOpen)}
                                               description={''}
                            />
                    }
                </div>
            </div>
            <footer className='flex'>
                <div className="footer-wrap flex-align-items-center flex-justify-content-center">
                    <div className="empty-field"/>
                    <div className="alert-setup-pagination flex flex-justify-content-center">
                        {recipients && recipients.length > 0 && <Pagination
                            defaultCurrent={1}
                            total={pagination.totalRecords}
                            onChange={onChangePage}
                            size={'small'}
                            current={pagination.currentPage}
                            defaultPageSize={pagination.perPage}
                        />}
                    </div>
                    <div className="alert-setup-action flex flex-justify-content-end">
                        <Button className="add-button btn btn-green"
                                type='button'
                                icon={'plus'}
                                onClick={() => {
                                    toggleModal(!isModalOpen);
                                    setType('new')
                                }
                                }
                        />
                    </div>
                    <Modal isOpen={isModalOpen} toggle={closeModal} size={'450px'}>
                        <form className="form-contact" onSubmit={handleSubmit}>
                            <div className="form-wrap">
                                <div className="form-header flex flex-justify-content-space-between">
                                    <h1 className="form-header-title">
                                        {type === 'edit' ? <Translate string={"ALERTING.EDIT_RECIPIENTS"}/> : <Translate string={"ALERTING.NEW_RECIPIENTS"}/>}
                                    </h1>
                                    <ModalClose className={'modal-close'} onClick={closeModal}/>
                                </div>
                                <div className="form-body flex">
                                    <div className="form-body-wrap flex flex-direction-column flex-align-items-center">
                                        <div className="form-input">
                                            <div className="flex flex-justify-content-space-between">
                                                <label><Translate string={"ALERTING.FULL_NAME"}/>*</label>
                                                {(errors && errors.name) && <span className="errMessage"> 
                                                    <Translate string={"GLOBAL_SETTINGS.ERROR_MESSAGE_NAME"}/>
                                                </span>}
                                            </div>
                                            <Input
                                                allowClear
                                                name={'name'}
                                                placeholder={TranslateText("ALERTING.FULL_NAME")}
                                                value={name || ''}
                                                onChange={onChange}
                                                autoComplete="off"
                                                // className={errors && errors.name ? 'error-input' : ''}
                                            />
                                        </div>
                                        { !((email.length === 0 && !errors.phone) || (!errors.email && !errors.phone) || (!errors.email && phone.length === 0)) &&
                                            <div className="form-input">
                                                <span className="errMessage"> 
                                                    <Translate string={"ALERTING.MISSING_DATA"}/>
                                                </span>
                                            </div>
                                        }
                                        <div className="form-input">
                                            <div className="flex flex-justify-content-space-between">
                                                <label><Translate string={"ALERTING.E_MAIL"}/></label>
                                                {/* {(errors && errors.email) && errors.email.errorComponent} */}
                                            </div>

                                            <Input allowClear
                                                   name={'email'}
                                                   placeholder={TranslateText("ALERTING.E_MAIL")}
                                                   value={ (email && email) || ''}
                                                   onChange={onChange}
                                                   autoComplete="off"
                                                //    className={errors && errors.email  ? 'error-input' : ''}
                                            />
                                        </div>
                                        <div className="form-input">
                                            <div className="flex flex-justify-content-space-between">
                                                <label><Translate string={"ALERTING.PHONE"}/></label>
                                                {/* {(errors && errors.phone) && errors.phone.errorComponent} */}
                                            </div>
                                            <Input allowClear
                                                   name={'phone'}
                                                   placeholder={TranslateText("ALERTING.PHONE")}
                                                   value={phone || ''}
                                                   onChange={onChange}
                                                   autoComplete="off"
                                                //    className={ errors && errors.phone ? 'error-input' : ''}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="form-action">
                                    <button type={'submit'}>{loading ? <Icon type="loading" style={{color: '#fff'}}/> : type === 'edit' ? <Translate string={"ALERTING.UPDATE_RECIPIENTS"}/> : <Translate string={"ALERTING.NEW_RECIPIENTS"}/>}</button>
                                    {/*{type === 'edit' ?*/}
                                    {/*    <button type='button'*/}
                                    {/*            onClick={updateSelectedRecipient}>+ Update contact</button> :*/}
                                    {/*    <button type='button' onClick={handleSubmit}>+ New contact</button>*/}
                                    {/*}*/}
                                </div>
                            </div>
                        </form>
                    </Modal>
                </div>
            </footer>
        </div>
    );
};

export default AlertSetupRecipients;