import React, {useState,useEffect} from "react";
import {Tree, Icon,Spin}                from "antd";
import ProbeTitle                  from "./ProbeTitle/ProbeTitle";
import ProbeContextMenu            from "./ContextMenu/ProbeContextMenu/ProbeContextMenu";

import {isJson, TranslateText}                    from "../../config/constants";

import "./Tree.scss";

const {TreeNode, DirectoryTree} = Tree;

const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;

const SidebarTree = (props) => {

    const {
        treeData, loadedProbesId, selectable, addCheckedProbe = () => {}, readOnly=false, disableProbes, 
        setCheckedProbe = () => {}, fromAlerting, isDatalogger, checkedProbe
    } = props ;

    const [loadedProbes,setLoadedProbes] = useState([]);
    const [checkedKeysList, setCheckedKeysList] = useState([]);

    useEffect(() => {
        if(checkedProbe.length === 0) {
            setCheckedKeysList([])
        }
        let defaultChecked = [];
        defaultChecked = checkedProbe.map(probe => isDatalogger ? `folder-${probe.triggerable_id}` : `probe-${probe.triggerable_id}`);
        if(checkedKeysList.length === 0 && defaultChecked.length !== checkedKeysList.length) {
            setCheckedKeysList(defaultChecked);
        }
        // eslint-disable-next-line
    }, [checkedProbe])

    /**
     *
     * @param id
     * @param currentFolder
     * @returns {*}
     */


    useEffect(() => {
        setLoadedProbes(loadedProbesId);
    }, [loadedProbesId]);

    const isProbeInList = (probeId) => {
        return loadedProbes.includes(probeId);
    };
    
    const handleOnCheck = (checkedKeys, {checkedNodes}) => {
        const updatedCheckedList = checkedKeys.filter(key => key.toString().includes('probe') );
        const updatedCheckedListDL = checkedKeys.filter(key => key.toString().includes('folder') );
        if(isDatalogger) {
            const filteredCheckedList = checkedNodes.map(node => node.props)
            setCheckedKeysList(updatedCheckedListDL);
            setCheckedProbe(filteredCheckedList);
        } else if(fromAlerting) {
            const filteredCheckedList = checkedNodes.map(node => node.props)
            setCheckedKeysList(updatedCheckedList);
            setCheckedProbe(filteredCheckedList);
        } else if (selectable) {
            const nextCheckedNode = checkedNodes.find(checkedNode => checkedNode.key === updatedCheckedList[updatedCheckedList.length-1]);
            const nextCheckedProbe = nextCheckedNode ? nextCheckedNode.props.title.props.probe : null;
            checkedKeys.length ? setCheckedKeysList([updatedCheckedList[updatedCheckedList.length-1]]) : setCheckedKeysList([]);
            addCheckedProbe(nextCheckedProbe);
        }
    };

    const createProbe = (probe) => {
        probe.options = isJson(probe.options) ? JSON.parse(probe.options) : probe.options;
        const key = `probe-${probe.id}` ;
        return (
            <TreeNode
                icon={<Icon type="file"/>}
                nodeId={probe.id}
                nodePath={probe.path}
                nodeTitle={probe.name}
                title={
                    readOnly ?
                    <ProbeTitle probe={probe} readOnly={readOnly}/>
                    :
                    <ProbeTitle
                       probe={probe}
                       isLoaded={isProbeInList}
                       disableProbes={disableProbes}
                    />
                }
                key={key}
                blockNode={true}
                checkable={true}
            >
            </TreeNode>
        );
    };

    const checkSelectedProbes = (probe) => {
        if(probe.probes) {
            return true;
        } else if(probe.children) {
            let isChecked = false;
            probe.children.forEach(child => {
                isChecked = isChecked || checkSelectedProbes(child);
            })
            return !!isChecked;
        } else {
            return false;
        }
    }

    const createFolders = (folder, dsIndex) => {
        const key = `folder-${folder.id}`;
        let listOfSelectedProbes = checkSelectedProbes(folder);
        return (
            <TreeNode
                icon={isDatalogger ? <Icon type="deployment-unit"/> : <Icon type="folder" />}
                title={<span>
                      {folder.name}
                  </span>
                }
                nodeId={folder.id}
                nodePath={folder.path}
                nodeType={'folder'}
                nodeTitle={folder.name}
                key={key}
                checkable={isDatalogger ? true : listOfSelectedProbes}
                selectable={isDatalogger ? true : listOfSelectedProbes}
            >
                {
                    folder.children && folder.children.map((folder, dsIndexChild) => {
                        const dsIndex1 = `0-${dsIndex}-${dsIndexChild}`;
                        return createFolders(folder, dsIndex1);
                    })
                }

                {
                    folder.probes && folder.probes.map((probe) => createProbe(probe))
                }
            </TreeNode>
        );
    };

    const createTree = (treeData) => {
        return treeData.map((folder, folderIndex) => {
            return createFolders(folder, folderIndex);
        });
    };

        return (
        <React.Fragment>
            {
                treeData.length === 0
                ? <div className={"tree-loader"}>
                    <Spin indicator={antIcon} />
                    <p>
                        {TranslateText("GLOBAL.LOADING")}...
                    </p>
                </div>
                : <DirectoryTree
                    showIcon
                    expandAction={false}
                    checkable={selectable}
                    switcherIcon={<Icon type="down"/>}
                    onCheck={handleOnCheck}
                    checkedKeys={checkedKeysList}
                    selectedKeys={checkedKeysList}
                    multiple={fromAlerting}
                >
                    {treeData ? createTree(treeData) : null}
                </DirectoryTree>
            }
            <ProbeContextMenu/>
        </React.Fragment>
    );
};
export default SidebarTree;
