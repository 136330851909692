import React, {useEffect, useState} from "react";

import "../DashboardView.scss";
import {SortableContainer} from "react-sortable-hoc";
import WidgetRepresentation from "./WidgetRepresentation/WidgetRepresentation";
import arrayMove from 'array-move';
import {Modal, Button} from "antd";
import instance from "../../../../config/axiosConf";
import {closeModalMessage, resetParamsForConfirmMessage, showNotification} from "../../../../config/constants";
import Translate from "../../../Translate/Translate";

const confirm= Modal.confirm;
const SortableList = SortableContainer(({fields, layoutClass}) => {
    return (
        <div className={"dashboard-view-container-grid widget-layout " + layoutClass}>
            {fields}
        </div>
    )
});

const WidgetLayoutOrderingModal = (props) => {

    const {layout, viewers, dashboard, isVisibleOrderingModal, setVisibleOrderingModal} = props;

    let maxField = 6;

    if (layout && layout.meta.maxField) {
        maxField = layout.meta.maxField;
    }

    const [fields, setFields] = useState([]);
    const [defaultFields, setDefaultFields] = useState([]);
    const [widgetsOrder, setWidgetsOrder] = useState([]);
    const [layoutClass, setLayoutClass] = useState(layout && layout.meta ? layout.meta.class : "layout-1");

    useEffect(() => {
        setFields(() => viewers
            .slice(0, maxField)
            .map((viewer, index) => (
                <WidgetRepresentation
                    key={index}
                    place={index + 1}
                    index={index}
                    name={viewer.name}
                    viewerId={viewer.id}
                    options={viewer.options}
                />
            ))
        );
        setDefaultFields(() => viewers
            .slice(0, maxField)
            .map((viewer, index) => (
                <WidgetRepresentation
                    key={index}
                    place={index + 1}
                    index={index}
                    name={viewer.name}
                    viewerId={viewer.id}
                    options={viewer.options}
                />
            ))
        );

        setLayoutClass(layout && layout.meta ? layout.meta.class : "layout-1");
    }, [maxField, layout, viewers, setFields, setLayoutClass]);

    const sortViewers = ({newIndex, oldIndex}) => {

        if (newIndex !== oldIndex) {
            let orderedViewers = arrayMove(fields, oldIndex, newIndex)
                .slice(0, maxField)
                .map((viewer, index) => {
                    return <WidgetRepresentation
                        key={index}
                        place={index + 1}
                        index={index}
                        name={viewer.props.name ? viewer.props.name : null}
                        viewerId={viewer.props.viewerId}
                        options={viewer.props.options}
                    />
                });

            let changedOrder = defaultFields.filter((field,index) => {
                return defaultFields[index].props.viewerId !== orderedViewers[index].props.viewerId
            });

            changedOrder.length !== 0 ? localStorage.setItem("dataInAppIsChanged","true") : localStorage.setItem("dataInAppIsChanged","false")
            setFields(orderedViewers);
            setWidgetsOrder(
                () => orderedViewers.map(viewer => (
                    {
                        viewId: viewer.props.viewerId,
                        dashId: dashboard.id,
                        viewIndex: viewer.props.index,
                        viewType: 'chart'
                    }
                )).filter(viewer => viewer.viewId)
            );
        };
    }

    const saveNewWidgetOrder = async () => {

        await instance.put(`/api/dashboards/views/sort`, {data: widgetsOrder});
        resetParamsForConfirmMessage();
        setVisibleOrderingModal(false);

        showNotification('success', 'Success', 'New ordering of widgets is saved.');

        setTimeout(() => window.location.reload(), 1000)

    };
    return (

        <Modal
            title="Rearrange Widgets"
            visible={isVisibleOrderingModal}
            onOk={saveNewWidgetOrder}
            onCancel={() =>{
                if(localStorage.getItem("dataInAppIsChanged") === "true"){
                    return confirm({
                        title: closeModalMessage.title,
                        content: closeModalMessage.content,
                        okText: closeModalMessage.okText,
                        okType: 'danger',
                        cancelText: closeModalMessage.cancelText,
                        onOk: () => {
                            resetParamsForConfirmMessage();
                            setVisibleOrderingModal(false)
                        }
                    });
                }
                setVisibleOrderingModal(false)}
            }
            footer={[

                <Button
                    key="CancelBtn"
                    onClick={() =>{
                        if(localStorage.getItem("dataInAppIsChanged") === "true"){
                            return confirm({
                                title: closeModalMessage.title,
                                content: closeModalMessage.content,
                                okText: closeModalMessage.okText,
                                okType: 'danger',
                                cancelText: closeModalMessage.cancelText,
                                onOk: () => {
                                    resetParamsForConfirmMessage();
                                    setVisibleOrderingModal(false);
                                    setFields(defaultFields);
                                }
                            });
                        }
                        setVisibleOrderingModal(false)}
                    }
                    className={'btn arrange-button'}
                    type="button"
                >
                    <Translate string={"GLOBAL.MODALS_CANCEL"}/>
                </Button>,
                <Button
                    key="SaveBtn"
                    onClick={saveNewWidgetOrder}
                    className={'btn btn-border-green'}
                    type="button"
                >
                    <Translate string={"GLOBAL.SAVE"}/>
                </Button>
            ]}
        >

            <div className={'fullscreen'}>
                <div className={'main-viewer'}>
                    <div className={"main-viewer-wrap-close flex"}>
                        <div className="dashboard-view-container modal-widgets" id={"chartHolder"}>
                            <SortableList

                                axis={layout && layout.id === 9 ? 'y' : 'xy'}
                                useDragHandle={true}
                                onSortEnd={sortViewers}
                                layoutClass={layoutClass}
                                fields={fields}
                                helperClass={'active-drag-card'}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </Modal>
    )
};

export default SortableContainer(WidgetLayoutOrderingModal);

