import React    from 'react'

import {Icon, Popconfirm} from "antd";
import Translate from "../../Translate/Translate";
import './TreeCard.scss';

export const TreeCard = (props) => {
    const { showMeLoader, status, onSelect, onDelete } = props;
    return(
        <div className={status ? "item-gs tree-structure selected" : "item-gs tree-structure"} >
            <div className={!showMeLoader && !status ? "item-action js-loader" : "item-action"} >
                <div className={'probe-delete-item'}>
                    <Popconfirm placement="left"
                                title={<div><b><Translate string={"GLOBAL_SETTINGS.CONFIRM_DELETE_TREE_HEADER"}/>
                                </b>
                                    <p><Translate string={"GLOBAL_SETTINGS.CONFIRM_DELETE_TREE_SECTION"}/></p></div>}
                                okText={<Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"}/>}
                                cancelText={<Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>} onConfirm={onDelete}>
                        <Icon type={showMeLoader ? "loading" : "delete"} theme="outlined"/>
                    </Popconfirm>
                </div>
            </div>
            <div className="item-wrap flex flex-justify-content-center flex-align-items-center" onClick={onSelect}>
                <div className="item-title" style={{WebkitBoxOrient: 'vertical', MozBoxOrient: 'vertical'}}>
                    {props.name}
                </div>
            </div>
        </div>
    );
};

export const NewTreeCard = (props) => {
    const { onDelete, onCreate } = props;
    return(
        <div className="item-gs item-new-tree">
            <div className="item-wrap flex flex-justify-content-center flex-align-items-center" onClick={onCreate}>
                <div className="item-title flex flex-direction-column flex-justify-content-center flex-align-items-center">
                    <Icon type="plus" theme="outlined" onClick={onDelete}/>
                    <Translate string={"GLOBAL.NEW_TREE_STRUCTURE"}/>
                </div>
            </div>
        </div>
    );
};

export const StartCard = () => {
    return(
        <div className="item-gs item-start-create-tree">
            <div className="item-wrap flex flex-justify-content-center flex-align-items-center">
                <div className="item-title flex flex-direction-column flex-justify-content-center flex-align-items-center">
                    <Icon type="loading" theme="outlined" />
                </div>
            </div>
        </div>
    );
};
