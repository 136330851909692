import React,{Component}                                from 'react';
import {connect}                                        from "react-redux";

import {Input,Select}                                   from 'antd';

import {TIME_RESOLUTION_INTERPOLATOR}                                from '../../../config/constants';
import {energyAnalysisActions}                          from "../../../actions";

import './Interpolator.scss';
import Translate from "../../Translate/Translate";
const Option = Select.Option;

class Interpolator extends Component {

    render() {

        const {
            energyAnalysisList,
            selectedAnalysisIndex,
            updateAnalysis
        } = this.props;

        const selectedEnergyAnalysis = energyAnalysisList[selectedAnalysisIndex];

        return (
            <div className="interpolator">
                <div className="input-wrapper">
                    <p className="input-label"><Translate string={"GLOBAL.INPUT_DEFINE"}/>:</p>
                    <div className="content">
                        <Input
                            type="text"
                            style={{ width: 220 }}
                            value={selectedEnergyAnalysis.inputDefineX}
                            onChange={event => {
                                const inputDefineX = event.target.value;
                                updateAnalysis(
                                    energyAnalysisList,
                                    {
                                        ...selectedEnergyAnalysis,
                                        inputDefineX
                                    },
                                    selectedAnalysisIndex
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="input-wrapper">
                    <p className="input-label"><Translate string={"ENERGY_ANALYSIS.TIME_RESOLUTION"}/>:</p>
                    <div className="content">
                        <Select
                            style={{ width: 220 }}
                            value={selectedEnergyAnalysis.selectedTimeResolution}
                            onChange={value => {
                                const selectedTimeResolution = TIME_RESOLUTION_INTERPOLATOR[value];
                                updateAnalysis(
                                    energyAnalysisList,
                                    {
                                        ...selectedEnergyAnalysis,
                                        selectedTimeResolution
                                    },
                                    selectedAnalysisIndex
                                );
                            }}
                        >
                            {TIME_RESOLUTION_INTERPOLATOR.map((time, index) => (
                                <Option key={index} value={index}>
                                    {time}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedAnalysisIndex : state.energyAnalysis.selectedAnalysisIndex,
    energyAnalysisList    : state.energyAnalysis.energyAnalysisList
});

const mapDispatchToProps = dispatch => ({
    updateAnalysis        : (analysisList,analysis,index) => dispatch(energyAnalysisActions.updateAnalysis(analysisList,analysis,index))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Interpolator);