import React, {useEffect, useState, useCallback} from 'react';
import {Alert} from 'antd';
import Translate from '../../Translate/Translate'

import DashboardViewHeader from "./DashboardViewHeader/DashboardViewHeader";

import DashboardViewChartPlot from "./DashboardViewChartPlot/DashboardViewChartPlot";
import {connect} from "react-redux";

import html2canvas from 'html2canvas/dist/html2canvas.min';

import "./DashboardView.scss";
import {dashboard} from "../../../actions";
import DashboardViewLeftSidebar from "./DashboardViewLeftSidebar/DashboardViewLeftSidebar";

import Fullscreen from "react-full-screen";
import instance from "../../../config/axiosConf";
import {isJson, LAYOUT} from "../../../config/constants";
import DashboardEmptyPlot from "./DashboardEmptyPlot/DashboardEmptyPlot";
import WidgetLayoutOrderingModal from "./WidgetLayoutOrderingModal/WidgetLayoutOrderingModal";

function findMissingNumbers(arr) {

    arr = arr.slice(0).sort(function (a, b) {
        return a - b;
    });
    let next = 0; // The next number in the sequence
    let missing = [];

    for (let i = 0; i < arr.length; i++) {
        // While the expected element is less than
        // the current element
        while (next < arr[i]) {
            // Add it to the missing list and
            // increment to the next expected number
            missing.push(next);
            next++;
        }
        next++;
    }
    return missing;
}

const DashboardView = (props) => {
    const {
        history,
        ModalForInfoOfDashboard,
        DashboardBackdrop,
        activeDashboardViewLayout,
        handleSidebarOnDashboardView,
        ModalForSelectLayout,
    } = props;

    const [dashboard, setDashboard] = useState({});
    const [dashboardFetched, setDashboardFetched] = useState(false);

    const [dashboardLayoutIsChanged, setDashboardLayoutIsChanged] = useState(false);
    const [dashboardSliderIsChanged, setDashboardSliderIsChanged] = useState(false);
    const [dashboardWidgetIsChanged, setWidgetDashboardIsChanged] = useState(false);
    const [dashboardWidgetIsClosed, setDashboardWidgetIsClosed] = useState(false);

    const [from, setFrom] = useState({});
    const [to, setTo] = useState({});

    const [dashboardViewers, setDashboardViewers] = useState([]);

    const [fullScreen, setFullScreen] = useState(false);
    const [viewersForDetach, setViewersForDetach] = useState([]);

    const [defaultLayout, setDefaultLayout] = useState(false);
    const [maxField, setMaxField] = useState(6);

    const [refreshSeries, setRefreshSeries] = useState(false);

    const [isVisibleLeftSidebar, setVisibleLeftSidebar] = useState(false);
    const [probesOnDashboard, setProbesOnDashboard] = useState([]);

    const [firedSelect, setFiredSelect] = useState(false);

    const [isVisibleOrderingModal, setVisibleOrderingModal] = useState(false);
    const [layoutChanged, setLayoutChanged] = useState(false);
    const [loadedFiltersId, setLoadedFilterIds] = useState([]);
    const [staticLayout,setStaticLayout] = useState({layoutId:0});
    const [isClickOnBlack,setIsClickOnBlack] = useState(false);


    const setOnFalseAllBooleanVariables = () => {
        setDashboardLayoutIsChanged(false);
        setDashboardSliderIsChanged(false);
        setWidgetDashboardIsChanged(false);
        setDashboardWidgetIsClosed(false);
    };

    const LocalDetachViewer = (viewerId, dashId) => {
        const updatedDashboardsViewList = [];
        const filteredViewers = dashboardViewers.map((viewer,index) => {
            if(viewer.id === viewerId){
                return {index: index, type: "empty"}
            }
            return viewer
        });
        setDashboardViewers(filteredViewers);
        setDashboardWidgetIsClosed(true);
        filteredViewers.map((item, index) => updatedDashboardsViewList.push({viewId: item.id, dashId:dashId , viewIndex: index, viewType: 'chart'}));
        setViewersForDetach([...viewersForDetach,{viewId : viewerId, viewType : "chart", dashId : dashId}]);
    };

    const sortWidgets = useCallback((dashboardViewers, internalMaxField = false) => {
        /**
         * Bigger to smaller layout, place widget on last free slot
         * Smaller to bigger layout place widget on first free slot
         */

        internalMaxField = internalMaxField ? internalMaxField : maxField;

        let reorderDashboardViewers = dashboardViewers.filter(viewer => !viewer.type);

        let layoutFields = [];

        reorderDashboardViewers.forEach(item => layoutFields.push((item.index)));

        if (reorderDashboardViewers.length > 0) {
            layoutFields = findMissingNumbers(layoutFields);
        }

        let emptyFields = [];

        layoutFields.forEach(item => { emptyFields.push({index: item, type: "empty"}) });

        if( (reorderDashboardViewers.length + emptyFields.length) < internalMaxField) {
            if (reorderDashboardViewers.length < internalMaxField) {
                for (let i = (reorderDashboardViewers.length + emptyFields.length) ; i < internalMaxField; i++) {
                    emptyFields.push({index: i, type: "empty"})
                }
            } else if (reorderDashboardViewers.length > internalMaxField) {
                emptyFields = [];
                for (let i = (reorderDashboardViewers.length + emptyFields.length); i < internalMaxField; i++) {
                    emptyFields.push({index: i, type: "empty"})
                }
            }
        }else if ( (reorderDashboardViewers.length + emptyFields.length) > internalMaxField) {
            while( (reorderDashboardViewers.length + emptyFields.length) > internalMaxField ) {

                if(emptyFields.length > 0) {
                    emptyFields.sort((a, b) => a.index - b.index);
                    emptyFields.pop()
                }else {
                    break;
                }
            }
        }

        let sortedArray = [...reorderDashboardViewers, ...emptyFields];

        sortedArray.sort( (a,b) => a.index - b.index);

        setDashboardViewers(sortedArray);

    }, [maxField]);

    useEffect(() => {
        const dashId = history.location.search.split("=");

        if (!dashId[1]) {
            history.push("/")
        }

        setDashboard({
            id: dashId[1],
            fetchDashboard: true
        });

        window.html2canvas = html2canvas;

    }, [history, setDashboard]);

    useEffect(() => {

        const setActiveLayout = (response,options = false) => {
            if (options) {
                let layout = LAYOUT.filter(lay => lay.id === options.layoutId)[0];
                layout = layout ? layout : LAYOUT[3];
                setDefaultLayout(layout);
                setMaxField(layout.meta.maxField);
                fetchDashboardViewers(response,layout.meta.maxField);
            }
        };

        const fetchDashboardViewers = (dashboard,layoutId) =>
        {
            let arrayPromise = [];

            instance.get(`/api/dashboards/${dashboard.id}/views`)
                .then( response => {
                    if (response.data && response.data.data) {
                        response.data.data.forEach( (view) => {
                            arrayPromise.push(instance.get(`/api/views/chart/${view.id}?include=probes`));
                        });

                        Promise.all(arrayPromise).then(results => {

                            let allProbesOnDashboard = [];

                            results.forEach(result => {
                                if(result.data.data.probes) {
                                    allProbesOnDashboard=[...allProbesOnDashboard,...result.data.data.probes];
                                }
                            });

                            // delete duplicate from array
                            allProbesOnDashboard=allProbesOnDashboard.reduce((acc, curr) => {
                                return acc.some(a => a["id"] === curr["id"]) ? acc : [...acc, curr]
                            }, []);

                            sortWidgets(response.data.data, layoutId);
                            setProbesOnDashboard(allProbesOnDashboard);
                            let ids = allProbesOnDashboard.map(probe => probe.id)
                            setLoadedFilterIds(ids);
                        });
                    }
                });
        };

        if (dashboard.id && dashboard.fetchDashboard) {

            instance.get(`/api/dashboards/${dashboard.id}`)
                .then(response => {
                    if (response.data && response.data.data) {
                        let options = response.data.data.options;
                        if (options && isJson(options)) {
                            options = JSON.parse(options);
                        }else{
                            options = {layoutId: 6}
                        }
                        if(staticLayout.layoutId === 0) {
                            setStaticLayout(options);
                        };
                        setDashboard({...response.data.data, options:options});
                        setDashboardFetched(true);
                        setActiveLayout(response.data.data, options);
                    }
                })
                .catch(() => {
                    history.push('/');
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxField, dashboardFetched, dashboard, activeDashboardViewLayout, history, setDashboard, sortWidgets]);

    //Reset active layout if the user leaves dashboard.
    useEffect(() => {
        return  () => {
            setDefaultLayout(false);
            props.clearActiveLayout();
            props.DestroyBackdropModalAndAllModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {

        if(activeDashboardViewLayout && activeDashboardViewLayout[0]){

            if(!defaultLayout || (defaultLayout.meta.class !== activeDashboardViewLayout[0].meta.class))
            {
                setDefaultLayout(activeDashboardViewLayout[0]);
                setMaxField(activeDashboardViewLayout[0].meta.maxField);
                setLayoutChanged(true);
                sortWidgets(dashboardViewers, activeDashboardViewLayout[0].meta.maxField);
            }
        }

    }, [dashboardViewers, defaultLayout, activeDashboardViewLayout, sortWidgets]);

    useEffect(() => {
        if(dashboardFetched){
            dashboard.from && setFrom(dashboard.from);
            dashboard.to && setTo(dashboard.to);
        }
    }, [dashboardFetched, dashboard.from, dashboard.to, setFrom, setTo]);

    const goFull = () => {
        setFullScreen(!fullScreen);
    };

    const handleGlobalDateRangeChange = ({from, to}) => {

        setDashboardSliderIsChanged(dashboard.from !== from || dashboard.to !== to);

        setFrom(from);
        setTo(to);
        setDashboard({
            ...dashboard,
            fetchDashboard: false,
        });

    };

    const handleLeftSidebar = () => {
        setVisibleLeftSidebar(!isVisibleLeftSidebar);
        setLayoutChanged(true)
    };

    const selectWidget = useCallback((viewId) => {

       let isThisSelected = false;

       dashboardViewers.forEach(dashboardView => {
            if (dashboardView.id === viewId && dashboardView.isSelected) {
                isThisSelected = true;
            } 
        });

        if(isThisSelected) {
            deselectWidget();
        } else {
            dashboardViewers.forEach(dashboardView => {
                dashboardView.isSelected = dashboardView.id === viewId;
            });
    
            instance.get(`/api/views/chart/${viewId}?include=probes`).then(view => {
                const config = isJson(view.data.data.options) ? JSON.parse(view.data.data.options) : view.data.data.options;
                const mapped = mapConfigFromViewerToProbes(view.data.data.probes,config);
                setProbesOnDashboard(mapped);
                let ids = view.data.data.probes.map(probe => probe.id)
                setLoadedFilterIds(ids);
                setFiredSelect(!firedSelect);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardViewers, firedSelect]);

    const deselectWidget = () => {
        dashboardViewers.forEach(dashboardView => {
            dashboardView.isSelected = false;
        });

        setProbesOnDashboard([]);
        setLoadedFilterIds([]);
        setFiredSelect(!firedSelect);
    }

    const addNewWidget = () => {
        const {history}=props;
        history.push({
            pathname: "/viewers"
        });
    };

    const handleWhenClickOnBlackScreen = () => {
        setIsClickOnBlack(true);
    };

    (dashboardLayoutIsChanged || dashboardSliderIsChanged || dashboardWidgetIsChanged || dashboardWidgetIsClosed) && localStorage.setItem("dashboardViewerIsChanged","true");

    const mapConfigFromViewerToProbes = (probes,config) => {
        const localProbes = [] ;
        const probesConfig = config.probeConfig ;
        probesConfig.forEach( probeConfig => {
            const tmpProbe = probes ? probes.find(item => probeConfig.id === item.id) : false ;
            const observedProbe = tmpProbe ? {...tmpProbe} : null ;
            if(observedProbe){
                observedProbe.options = {...isJson(probeConfig.options) ? JSON.parse(probeConfig.options) : probeConfig.options} ;
                localProbes.push(observedProbe);
            }
        });
        return localProbes ;
    }

    return (
        <Fullscreen
            enabled={fullScreen}
            onChange={isFull => setFullScreen(isFull)}
        >
            <div className={ModalForInfoOfDashboard ? 'main-viewer dashboard-view flex flex-direction-column js-dashboard-info-modal' : 'main-viewer dashboard-view flex flex-direction-column'}>
                    <span
                        onClick={handleWhenClickOnBlackScreen}
                        className={DashboardBackdrop ? 'dashboard-info-modal' : "dashboard-info-modal js-dashboard-info-modal-hide"}
                    />
                <div className="header-viewer">
                    <DashboardViewHeader
                        activeLayout={defaultLayout}
                        sidebarStatus={handleSidebarOnDashboardView}
                        dashboard={dashboard}
                        viewersForDetach={viewersForDetach}
                        defaultFrom={dashboard.from}
                        defaultTo={dashboard.to}
                        from={from}
                        to={to}
                        setFrom={setFrom}
                        setTo={setTo}
                        setRefreshSeries={setRefreshSeries}
                        handleGlobalDateRangeChange={handleGlobalDateRangeChange}
                        IsVisibleModalForSelectLayout={ModalForSelectLayout}
                        setFullScreen={goFull}
                        fullStatus={fullScreen}
                        setDashboardLayoutIsChanged={setDashboardLayoutIsChanged}
                        dashboardLayoutIsChanged={dashboardLayoutIsChanged}
                        setDashboard={setDashboard}
                        history={history}
                        setOnFalseAllBooleanVariables={setOnFalseAllBooleanVariables}
                        dashboardViewers={dashboardViewers}
                        handleLeftSidebar={handleLeftSidebar}
                        isVisibleLeftSidebar={isVisibleLeftSidebar}
                        setVisibleOrderingModal={setVisibleOrderingModal}
                        setStaticLayout={setStaticLayout}
                        staticLayout={staticLayout}
                        isClickOnBlack={isClickOnBlack}
                        setIsClickOnBlack={setIsClickOnBlack}
                        dashboardSliderIsChanged={dashboardSliderIsChanged}
                        dashboardWidgetIsClosed={dashboardWidgetIsClosed}
                    />
                </div>
                <div
                    className={isVisibleLeftSidebar ? "main-viewer-wrap" : "main-viewer-wrap-close flex"}>
                    <div className={"dashboard-view-left-sidebar"}>
                        <DashboardViewLeftSidebar probesOnDashboard={probesOnDashboard} loadedFiltersId={loadedFiltersId}/>
                    </div>
                    <div className="dashboard-view-container" id={"chartHolder"}>
                        <div className={"dashboard-view-container-grid " + (defaultLayout && defaultLayout.meta.class ? defaultLayout.meta.class : "layout-1") }>

                        <div className={"notification-bar flex flex-direction-column"}>
                            {
                                (dashboardLayoutIsChanged || dashboardSliderIsChanged || dashboardWidgetIsChanged || dashboardWidgetIsClosed) &&
                                <Alert
                                    type="info"
                                    showIcon
                                    message={<Translate string={"GLOBAL.UNSAVED_CHANGE"}/>}
                                    closable
                                    afterClose={() => setOnFalseAllBooleanVariables()}
                                />
                            }
                        </div>
                        {
                            dashboardFetched &&
                                dashboardViewers.slice(0, maxField).map((viewer, index) => {
                                    if(!viewer.type) {
                                        return <DashboardViewChartPlot
                                            key={index+"-"+viewer.name}
                                            place={index + 1}
                                            from={from}
                                            to={to}
                                            index={index}
                                            dashboard={dashboard}
                                            history={history}
                                            maxField={maxField}
                                            setRefreshSeries={setRefreshSeries}
                                            refreshSeries={refreshSeries}
                                            LocalDetachViewer={LocalDetachViewer}
                                            selectWidget={selectWidget}
                                            viewer={viewer}
                                            layoutChanged={layoutChanged}
                                            setLayoutChanged={setLayoutChanged}
                                        />
                                    } else {
                                        return <DashboardEmptyPlot
                                            key={index+"-"+viewer.type}
                                            place={index + 1}
                                            index={index}
                                            addNewWidget={addNewWidget}
                                        />
                                    }

                                }
                            )
                        }
                        </div>
                    </div>
                </div>


                <WidgetLayoutOrderingModal
                    layout = {defaultLayout}
                    viewers = {dashboardViewers}
                    isVisibleOrderingModal={isVisibleOrderingModal}
                    setVisibleOrderingModal={setVisibleOrderingModal}
                    dashboard={dashboard}
                />


            </div>

        </Fullscreen>
    );
};

const mapStateToProps = state => ({
    ModalForInfoOfDashboard: state.dashboard.ModalForInfoOfDashboard,
    ModalForSelectLayout: state.dashboard.HandleModalForSelectLayout,
    DashboardBackdrop: state.dashboard.DashboardBackdrop,
    activeDashboardViewLayout: state.dashboard.activeDashboardViewLayout,
    handleSidebarOnDashboardView: state.dashboard.handleSidebarOnDashboardView
});

const mapDispatchToProps = dispatch => ({
    DestroyBackdropModalAndAllModal: () => dispatch(dashboard.DestroyBackdropModalAndAllModal()),
    SetDashboardViewLayout: () => dispatch(dashboard.SetDashboardViewLayout()),
    UpdateDashboardViewIndex: (payload) => dispatch(dashboard.UpdateDashboardViewIndex(payload)),
    clearActiveLayout     : () => dispatch(dashboard.ClearActiveLayout())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardView);

