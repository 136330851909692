import React from "react"
import Translate from "../../../../../../Translate/Translate"
import {DatePicker, Input, Checkbox, Icon, Tooltip } from "antd";
import "./ModalDataLoggerTab.scss"
import moment from "moment";
import {fixDatatime} from "../../../../../../../config/constants";

const ModalDataLoggerTab = (props) => {
    let whenDate= fixDatatime(props.item.when);
    return(
        <React.Fragment>
            <div className="modal-form-datalogger">
                <div className="row-form flex-justify-content-center">
                    <div className="modal-form-column flex flex-direction-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.NAME"}/> <span>*</span> <span className={props.validatorObj.name || props.validatorObj.duplicateName ? "errMessage" : "disableErrorMessage"}>{props.validatorObj.duplicateName ? <Translate string={"GLOBAL_SETTINGS.ERROR_MESSAGE_DUPLICATE_NAME"}/> : <Translate string={"GLOBAL_SETTINGS.ERROR_MESSAGE_NAME"}/> }</span></label>
                        <Tooltip placement="top" title={<Translate string={"GLOBAL.CANNOT_EDIT_DATA_LOGGER_NAME"}/>}>
                            <Input autoComplete="off" className="modal-form-input" type="text"
                                name="name" value={props.item.name}
                                onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                                disabled={true}/>
                        </Tooltip>
                    </div>
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.CONNECTION_TYPES"}/></label>
                        <div className="modal-form-label flex">
                            <div className="checkbox-container">
                                <Checkbox  name="ftp"
                                           checked={props.ftpChecked}
                                           onChange={props.handleConnectionType}
                                ><Translate string={"GLOBAL.FTP"}/></Checkbox>
                            </div>
                            <div className="checkbox-container">
                                <Checkbox name="mqtt"
                                          checked={props.mqttChecked}
                                          onChange={props.handleConnectionType}
                                    ><Translate string={"GLOBAL.MQTT"}/></Checkbox>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="row-form flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.DESCRIPTION"}/></label>
                        <Input
                            autoComplete="off"
                            className="modal-form-input"
                            type="text"
                            name="description"
                            value={props.item.description}
                            onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                        />
                    </div>
                    <div className="modal-form-column flex-justify-content-center">
                        <label className="modal-form-label"><Translate string={"GLOBAL.RESPONSIBILITY"}/></label>
                        <Input
                            autoComplete="off"
                            className="modal-form-input"
                            type="text"
                            name="responsibility"
                            value={props.item.responsibility}
                            onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                        />
                    </div>
                </div>
                <div className="row-form flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.WHEN"}/></label>
                            <DatePicker
                                        format={"MM.DD.YYYY"}
                                        allowClear={false}
                                        value={ whenDate ? moment(whenDate,"MM.DD.YYYY") : null}
                                        onChange={(data, dataString) => {
                                            dataString.length === 0  ?  props.onUpdate("when", moment(props.item.when)) : props.onUpdate("when", dataString)
                                        }}
                                        disabledDate={current => {
                                            return current && current > moment();
                                        }}
                            />
                    </div>
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.WHERE"}/></label>
                        <Input
                            autoComplete="off"
                            className="modal-form-input"
                            type="text"
                            name="where"
                            value={props.item.where}
                            onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}/>
                    </div>
                </div>
                <div className="row-form flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.SERIAL_NUMBER"}/></label>
                        <Input
                            autoComplete="off"
                            className="modal-form-input"
                            type="text" name="serialNumber"
                            value={props.item.serialNumber}
                            onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                        />
                    </div>
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.BRAND"}/></label>
                        <Input
                            autoComplete="off"
                            className="modal-form-input"
                            type="text"
                            name="brand"
                            value={props.item.brand}
                            onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}/>
                    </div>
                </div>
            </div>
            <div className="modal-button-details">
                <button type="button" onClick={props.onSave} ><Icon type={'save'} /><Translate string={"GLOBAL.SAVE"}/></button>
            </div>
        </React.Fragment>
    );
};

export default ModalDataLoggerTab
