export const ROUTE = [
    {
        path: "/",
        component: "Dashboard",
        sidebar: false,
        chart: false
    },
    {
        path: "/dashboard-view",
        component: "DashboardView",
        sidebar: false,
        chart: false
    },
    {
        path: "/dashboard-view?dashId=1",
        component: "DashboardView",
        sidebar: false,
        chart: false
    },
    {
        path: "/viewers",
        component: "Viewers",
        sidebar: false,
        chart: false
    },
    {
        path: "/viewers/details",
        component: "Details",
        sidebar: false,
        chart: false
    },
    {
        path: "/energy-analysis",
        component: "EnergyAnalysis",
        sidebar: true,
        chart: true
    },
    {
        path: "/filtering",
        component: "Filter",
        sidebar: false,
        chart: true
    },
    {
        path: "/global-settings/tree-setup",
        component: "GlobalSettings",
        sidebar: false,
        chart: false
    },
    {
        path: "/global-settings/probe-setup",
        component: "GlobalSettings",
        sidebar: false,
        chart: false
    },
    {
        path: "/global-settings/acquisition-setup",
        component: "GlobalSettings",
        sidebar: false,
        chart: false
    },
    {
        path: "/alerting/alerts",
        component: "AlertingAlerts",
        sidebar: false,
        chart: false

    },
    {
        path: "/alerting/setup",
        component: "AlertingSetup",
        sidebar: false,
        chart: false
    }
];