import React, {Component} from "react";
import {Route, Redirect, Switch} from "react-router-dom";
import {NotFoundPage} from '../NotFoundPage/NotFoundPage';

import LeftSidebar from "../LeftSidebar/LeftSidebar";
import Filter from "../Filter/Filter";
import EnergyAnalysis from "../EnergyAnalysis/EnergyAnalysis";
import DashboardView from "../Dashboard/DashboardView/DashboardView";

import GlobalSettings from "../GlobalSettings/GlobalSettings";
import DashboardHome from "../Dashboard/"
import ViewersHome from "../Viewers";

import {ROUTE as routes} from "../../config/navigation";

import "./MainContent.scss";
import ViewerDetails from "../Viewers/ViewerDetails/ViewerDetails";
import { AlertingSetup } from "../Alerting"
import { AlertingAlerts } from "../Alerting"

class MainContent extends Component {

    state = {
        sidebar: true
    };

    render() {

        const {
            history
        } = this.props;

        const filterByValue = (array, string) => {
            return array.filter(o => {
                return Object.keys(o).some(k => {
                   if (typeof o[k] === 'string') return (o[k].toLowerCase() === string);
                return null});
            });
        };

        let activeRoute = filterByValue(routes, history.location.pathname)[0];

            return (
                <React.Fragment>
                    <div className="main-content flex">
                        {activeRoute && activeRoute.sidebar && <div className="main-content-left-sidebar">
                            <LeftSidebar history={history}/>
                        </div>}
                        <div className="main-content-viewer">
                            <Switch>
                            <Route exact path="/" component={DashboardHome}/>
                            <Route exact path="/dashboard-view" component={DashboardView}/>
                            <Route exact path="/viewers" component={ViewersHome}/>
                            <Route exact path="/viewers/details" component={ViewerDetails}/>
                            <Route exact path="/alerting/alerts" component={AlertingAlerts} />
                            <Route exact path="/alerting/setup" component={AlertingSetup} />
                            <Route exact path="/energy-analysis" component={EnergyAnalysis} />
                            <Route exact path="/filtering"       component={Filter} />

                            <Route exact path="/global-settings/tree-setup"  component={GlobalSettings} />
                            <Route exact path="/global-settings/probe-setup"       component={GlobalSettings} />
                            <Route exact path="/global-settings/acquisition-setup" component={GlobalSettings} />
                            <Redirect push to={"/404"} />
                            </Switch>
                            <Route exact path={"/404"} component={NotFoundPage}/>
                        </div>
                    </div>

                    {/*{!conditionDashboard && <div className="main-content flex">*/}
                    {/*<div className="main-content-left-sidebar">*/}
                    {/*<LeftSidebar history={history}/>*/}
                    {/*</div>*/}
                    {/*<div className="main-content-viewer" >*/}
                    {/*<div hidden={ mainContentCondition} className={ conditionForChart ? "main-content-viewer-container flex" : "main-content-viewer-container-hide flex"}>*/}
                    {/*<Route path="/energy-analysis" component={EnergyAnalysis} />*/}
                    {/*<Route path="/filtering"       component={Filter} />*/}
                    {/*<Viewer history={this.props.history}/>*/}
                    {/*</div>*/}
                    {/*<Route path="/data-source" component={UploadViewer} />*/}
                    {/*</div>*/}
                    {/*</div>}*/}
                    {/*{!conditionDashboard && <div className="main-content flex">*/}
                    {/*<div className="main-content-viewer">*/}
                    {/*<Route exact path="/global-settings/"  component={GlobalSettings} />*/}
                    {/*<Route path="/global-settings/probe-setup"       component={GlobalSettings} />*/}
                    {/*<Route path="/global-settings/acquisition-setup" component={GlobalSettings} />*/}
                    {/*</div>*/}
                    {/*</div> }*/}

                </React.Fragment>
            );
        }


    }

    export default MainContent;
