import React from "react";
import {Icon, Tooltip, Popconfirm} from 'antd';
import { TranslateText } from "../../../config/constants";
import Translate from "../../Translate/Translate";

const FilterTitle = (props) => {
    const handleOnEyeClick = () => {
        if (props.isLoaded(props.filter.probeId)) {
            props.removeFilterFromParent(props.filter.probeId);
        } else {
            props.addFilterToParent(props.filter);
        }
    };

    const handleOnDeleteClick = () => {
        props.removeFilterFromParent(props.filter.probeId);
        props.removeFilterFromTree(props.filter);
    };
    return (
        <span className="tree-node-child">
            <span className="tree-node-child-title">{props.filter.name}</span>
            {props.readOnly ?
                <span className="tree-node-eye">
                     <Icon
                         type={props.isLoaded(props.filter.probeId) && "eye"}
                         className={props.isLoaded(props.filter.probeId) && "eye"}
                         theme={props.isLoaded(props.filter.probeId) && "filled"}
                         style={{fontSize: "14px"}}

                     />
                </span>

            :
            <React.Fragment>
                <span style={{"padding": 2}}>
                    {
                        props.isFetching && props.isFetching.fetching && props.isFetching.id === props.filter.probeId ?
                            <Icon
                                type="loading"
                                style={{fontSize: "14px"}}
                            />
                            :
                            <Icon
                                type={props.isLoaded(props.filter.probeId) ? "eye" : "eye-invisible"}
                                onClick={() => {
                                    handleOnEyeClick();
                                }}
                                className={props.isLoaded(props.filter.probeId) ? "eye" : "eye-invisible"}
                                theme={props.isLoaded(props.filter.probeId) ? "filled" : "outlined"}
                                style={{fontSize: "14px"}}
                            />

                    }
                </span>
                {props.showDeleteIcon &&
                <span style={{"padding": 2}}>
                    {
                        props.filterDeleting && props.filterDeleting.deleting && props.filterDeleting.filterId === props.filter.id ?
                            <Icon
                                type="loading"
                                style={{fontSize: "14px"}}
                            />
                            :
                            <Popconfirm placement="left" title={<div><b><Translate string={"GLOBAL_SETTINGS.CONFIRM_DELETE_FILTER_HEADER"}/></b>
                                                            <br/>
                                                            <p><Translate string={"GLOBAL_SETTINGS.CONFIRM_DELETE_FILTER_SECTION"}/></p></div>}
                                                            okText={<Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"}/>}
                                                            cancelText={<Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>}
                                                            onConfirm={()=>handleOnDeleteClick()}>
                                <Icon type={'delete'} style={{fontSize: "14px"}} />
                            </Popconfirm>
                    }
                </span>
                }
                {
                    !props.filter.persistent &&
                <span style={{"padding": 2}}>
                    <Tooltip className={"channel-preview-with-icon"} placement="right" title={TranslateText("GLOBAL.FILTER_PERSISTING_WARNING")}>
                    <Icon type="info-circle" style={{fontSize: "12px"}} />
                    </Tooltip>
                </span>
                }
            </React.Fragment>
            }
        </span>
    );
}
export default FilterTitle;
