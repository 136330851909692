import { notification }        from "antd";

import * as types              from "./actionTypes";
import instance                from '../config/axiosConf';
import {listenOnWebSocket} from "./user";
import { TranslateText } from '../config/constants'

export const uploadProbe = (probes,flag) => async (dispatch, getState) => {
    const {
        uploadProbe: {files},
    } = getState();

    const formData = new FormData();

    const listForUpload = files.length ? files : probes ;

    listForUpload.forEach(file => {
        formData.append("file", file);
    });

    dispatch(listenOnWebSocket(listForUpload, flag, async () => {

        notification.info({
            key: "upload-probe",
            message: TranslateText("GLOBAL_SETTINGS.UPLOAD_PROBES_STARTED"),
            description: TranslateText("GLOBAL.UPLOAD_FILE_DESCRIPTION"),
            duration: 2,
            style: {
                width: 400
            }
        });

        const response = await instance.post(`/api/upload/probes`, formData);

        if (response && response.data) {
            dispatch({
                type: types.HANDLE_UPLOADING_FILELIST,
                uploading: false
            });
            
        }
    }));

};
export const handleUploadedFile = (files) => async dispatch => {
    await dispatch({
                    type  : types.HANDLE_PROBE_FILE_LIST,
                    files : files
    });
};
export const clearFiles = () => async dispatch => {
    await dispatch({
                    type  : types.CLEAR_PROBE_FILE_LIST,
                    files : []
    });
}
