import React, {Component} from 'react';
import SidebarTree from '../../Tree/Tree';

import './LeftSidebarBody.scss';

class LeftSidebarBody extends Component {
    render() {
        return (
            <div className="sidebar-tree-view-body flex flex-align-items-baseline">
                <div className="sidebar-syn-search-tree flex syn-row">
                    <div className="syna-tree-body">
                        <SidebarTree history={this.props.history} selectable={true} loadedProbesId={[]}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeftSidebarBody;
