import {
    TOGGLE_LEFT_SIDEBAR,
    TOGGLE_RIGHT_SIDEBAR
} from './ViewerDetailsType';

export default (state, action) => {
    switch (action.type) {
        case TOGGLE_LEFT_SIDEBAR:
            return {...state, toggleLeftSidebar: !state.toggleLeftSidebar};
        case TOGGLE_RIGHT_SIDEBAR:

            return {...state, toggleRightSidebar: !state.toggleRightSidebar};
        default:
            return state;
    }
}