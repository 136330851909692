import * as types from "./actionTypes";

import {notification} from 'antd';

import instance from '../config/axiosConf';

import {listenOnWebSocket} from "./user";
import {TranslateText} from "../config/constants";

export const handleFileList = (fileList ) => dispatch => {
     dispatch({
        type: types.HANDLE_FILE_LIST,
        fileList
    });
};

export const removeFileList = (fileListId) => async (dispatch, getState) => {
    const {
        uploadCSV: {fileList}
    } = getState();


    const filteredFileList = fileList.filter(item => {
        return item.uid !== fileListId
    });

    await dispatch({
        type: types.REMOVE_FILE_LIST,
        fileList: filteredFileList
    });
};

export const handleMultiUpload = csvList => async (dispatch) => {

    dispatch({
        type: types.HANDLE_MULTI_UPLOAD,
        csvList: csvList
    })
}


export const uploadCSVFile = (listForUpload) => async dispatch => {


    const formData = new FormData();
    listForUpload.forEach(file => {
        formData.append("file", file);
    });
    dispatch(listenOnWebSocket(listForUpload,"csv",async () => {

        notification.info({
            key: "upload-csv",
            message: TranslateText("GLOBAL_SETTINGS.UPLOAD_FOLDER_START_MESSAGE"),
            description: TranslateText("GLOBAL.UPLOAD_FILE_DESCRIPTION"),
            duration: 2,
            style: {
                width: 400
            }
        });
        const response = await instance.post(`/api/folders/upload`, formData);

        if (response && response.data){
            localStorage.removeItem("selectedTree");
            dispatch({
                type: types.HANDLE_UPLOADING_FILELIST,
                uploading: false
            });
            
        }

    }))

};
