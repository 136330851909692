import React              from 'react';
import {Icon}             from 'antd';
import propTypes          from 'prop-types';

import './FilterContainer.scss';

const FilterContainer = (props) => {
    return(
        <div className="active-filter-wrapper">
            {props.filtersList && props.filtersList.map((filter, index) => (
                <div
                    key={index}
                    className={`flex active-filter ${index === props.selectedFilterIndex ? 'selected-filter' : '' }` }
                >
                    <div
                        onClick={ () => props.selectFilterType(index) }
                    >
                        <p className="name">{filter.name}</p>
                    </div>
                    <div
                        onClick={() => props.removeFilterType(index) }
                    >
                        <Icon type="delete" />
                    </div>
                </div>
            ))}
        </div>
    );
}

FilterContainer.propTypes = {
  filtersList: propTypes.array.isRequired
};

export default FilterContainer;
