import React, {useState} from 'react';
import './Filter.scss';
import SidebarTree from "../Tree/TreeV2";
import Chart from "../Chart/Chart";
import FilterHeader from "./FilterHeader/FilterHeader";
import FilterRightSideBar from "./FilterRightSideBar/FilterRightSideBar";
import {fetchFilterSeries} from '../../actions/filterAnalyses';
import moment from "moment";
import instance from "../../config/axiosConf";

const Filter = () => {

    const [loadedProbeIds, setLoadedProbeIds] = useState([]);
    const [loadedFilterIds, setLoadedFilterIds] = useState([]);

    const [chartData, setChartData] = useState([]);
    const [checkedProbe, setCheckedProbe] = useState(null);

    const [from, setFrom] = useState(moment().subtract(1, 'month').format("YYYY-MM-DDTHH:mm:ssZ"));
    const [to, setTo] = useState(moment().format("YYYY-MM-DDTHH:mm:ssZ"));

    const [filterForAdd, setFilterForAdd] = useState([]);

    const [loadedFilters, setLoadedFilters] = useState([]);

    const [loadedProbes, setLoadedProbes] = useState([]);
    const [isUpdatedChartData, setUpdateChartData] = useState(false);
    const [isFetching, setFetching] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [defaultFrom] = useState(moment().subtract(1, 'month').format("YYYY-MM-DDTHH:mm:ssZ"));
    const [defaultTo] = useState(moment().format("YYYY-MM-DDTHH:mm:ssZ"));

    const addProbeToList = (probe) => {
        if (probe) {
            setLoadedProbeIds([...loadedProbeIds, probe.id]);
            setLoadedProbes([...loadedProbes, probe]);
            fetchProbeSeries(probe);
        }
    };
    const addCheckedProbe = (probe) => {
        setCheckedProbe(probe);
    };
    const removeProbeFromList = (probeId) => {
        setLoadedProbeIds(loadedProbeIds.filter(id => id !== probeId));
        setChartData(chartData.filter(probeLine => probeLine.id !== probeId));
        setUpdateChartData(true);
    };
    const fetchFilterSeriesData = (filter) => {
        filter.endTime = to;
        filter.startTime = from;
        setFetching({id: filter.probeId, fetching: true});
        setLoading(true);
        fetchFilterSeries(filter).then(response => {
            setChartData([...chartData, {id: filter.probeId, name: filter.name, series: response.data.seriesData}]);
            setTimeout(() => setFetching({id: filter.probeId, fetching: false}), 300);
            setUpdateChartData(true);
        });
    };
    const addFilterToList = (filter) => {
        setLoadedFilterIds([...loadedFilterIds, filter.probeId]);
        loadedFilters.push(filter);
        fetchFilterSeriesData(filter);
    };
    const removeFilterFromList = (filterId) => {
        setLoadedFilterIds(loadedFilterIds.filter(id => id !== filterId));
        setLoadedFilters(loadedFilters.filter(filter => filter.probeId !== filterId));
        removeFilterSeries(filterId);
    };
    const addTestFilterLineToChart = (filterData) => {
        setChartData([...chartData, ...filterData]);
        setUpdateChartData(true);
    };
    const removeFilterSeries = (filterId) => {
        setChartData(chartData.filter(line => line.id !== filterId));
        setUpdateChartData(true);
    };
    const reFetchSeries = (newFrom, newTo) => {

        const filterLines = [];
        const probeLines = [];
        const newFilterLines = [];
        const newProbeLines = [];

        loadedProbeIds.forEach(probeId => probeLines.push(instance.get('/api/series', {
            params: {
               filters:
                   {
                       probeId: probeId,
                       from: newFrom,
                       to: newTo,
                       chartType: 'line',
                       limit : 1000
                   }

            }
        })));

        loadedFilters.forEach(filter => {
            filterLines.push(fetchFilterSeries({...filter,nam:filter.name,endTime:newTo,startTime:newFrom}));
        });

        Promise.all(filterLines).then(response => {
            let index = 0 ;
            response.forEach(responseData => {
                newFilterLines.push(createChartDataObject({...responseData.data,probeId:loadedFilters[index++].probeId}));
            });
        }).then(() => {
            if(!probeLines.length){
                setChartData(newFilterLines);
                setUpdateChartData(true);
                return
            }
            Promise.all(probeLines).then(response => {
                response.forEach(responseData => {
                    const newProbeLineData = loadedProbes.find(probe => probe.id === responseData.config.params.filters.probeId);
                    newProbeLines.push({...newProbeLineData, series: responseData.data});
                });
            }).then(() => {
                setChartData([...newFilterLines,...newProbeLines]);
                setUpdateChartData(true);
            });
        });
    };
    const createChartDataObject = (data) => {
        return {
            series: data.series ? data.series : data.seriesData,
            name: data.filterData ? data.filterData.name : data.name,
            id: data.probeId
        }
    };
    const addFilterToTree = (filter) => {
        if (filter[0] && filter[0].test) {
            setChartData([...chartData, createChartDataObject(filter[0])]);
            delete filter[0].series;
        }
        setFilterForAdd(filter);
        setUpdateChartData(true);
    };
    const fetchProbeSeries = (probe) => {
        setFetching({id: probe.id, fetching: true});
        instance.get('/api/series', {
            params: {
                filters:
                    {
                        probeId: probe.id,
                        from: from,
                        to: to,
                        chartType: 'line',
                        limit: 1000
                    }
            }
        }).then(response => {
            setChartData([...chartData, {...probe, series: response.data}]);
            setTimeout(() => setFetching({id: probe.id, fetching: false}), 300);
            setUpdateChartData(true);
        });
    };

    return (
        <div className={'filter-view-details-wrapper'}>
            <div className={'filter-view-details-header-wrapper flex'}>
                <FilterHeader
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    refetchSeries={reFetchSeries}
                    defaultFrom={defaultFrom}
                    defaultTo={defaultTo}
                />
            </div>
            <div className="filter-view-details-body">
                <div className="filter-view-details-wrap">
                    <div
                        className={'filter-left-sidebar-wrapper flex flex-direction-column'}>
                        <div className="filter-left-sidebar-container bg-white">
                            <SidebarTree
                                loadedProbesId={loadedProbeIds}
                                addProbe={addProbeToList}
                                removeProbe={removeProbeFromList}
                                addCheckedProbe={addCheckedProbe}
                                selectable={true}
                                addFilter={addFilterToList}
                                removeFilter={removeFilterFromList}
                                loadedFiltersId={loadedFilterIds}
                                filterForAdd={filterForAdd}
                                clearFilterAddList={addFilterToTree}
                                isFetching={isFetching}
                            />
                        </div>
                    </div>
                    <div className={'filter-view-details-content-wrapper flex'}>
                        <div className={'filter-view-chart-container'}>
                            <div className='chart-wrap'>
                                <div className={'filters-view-details-content bg-white'}>
                                    <Chart chartData={chartData}
                                           checkForProbesFiltering={"filtering"}
                                           isUpdatedChartData={isUpdatedChartData}
                                           setUpdateChartData={setUpdateChartData}
                                           isLoading={isLoading}
                                           setLoading={setLoading}
                                           areDimensionsChanged={{isVisibleLeftSidebar: false ,isVisibleRightSidebar : false}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'filter-view-details-right-sidebar-wrapper flex flex-direction-column bg-white'}>
                        <FilterRightSideBar
                            checkedProbe={checkedProbe}
                            from={from}
                            to={to}
                            addToChart={addTestFilterLineToChart}
                            addFilterToTree={addFilterToTree}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Filter;
