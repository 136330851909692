import React, {useState, useEffect} from 'react';
import moment from 'moment';
import AlertHeader from "../AlertingHeader/AlertingHeader";

import {Input, Select,Button, Tooltip, Checkbox, Pagination, Modal} from "antd";
import {
    CloseIcon, DeleteIcon, DownloadIcon,
    RefreshIcon, TranslateText
} from "../../../config/constants";
import instance from '../../../config/axiosConf';
import { config as getConfig } from "../../../config/config";
import Translate from '../../Translate/Translate';
import {getAlertLogs, getAllStatusForAlerts, deleteAlertLog, closeAlertLog, getAllTriggersForAlert} from '../../../api/alerting/alerting';
import './AlertingAlerts.scss';
import AlertsAccordion from "./AlertsAccordion/AlertsAccordion";
import Chart from '../../Chart/Chart';
import { LoaderBar } from '../AlertingSetup/AlertSetupLoader/AlertSetupLoader';

import { RangePickerInput } from "../../FormInput/FormInput";

const {Option} = Select;

const AlertingAlerts = () => {

    const [data, setData] = useState([]);
    const [triggers, setTriggers] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [selectedAlarms, setSelectedAlarms] = useState([]);

    const [isModalOpen, toggleModal] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [pagination, setPagination] = useState([]);

    const [search, setSearch] = useState({text: '', status: '', triggers: '', closed: 'all'});
    const [dateRange, setDateRange] = useState([]);
    const [loading, setLoading] = useState(false);
    const [responseHaveCome, setResponseHaveCome] = useState(false);
    const [areDimensionsChanged, setAreDimensionsChanged] = useState({isVisibleLeftSidebar: false,isVisibleRightSidebar: false});
    const [isUpdatedChartData, setUpdatedChartData] = useState(false);

    const [checkedAlerts,setCheckedAlerts] = useState([])

    const checkedAllAlarms = data && data.every((el)=>checkedAlerts.includes(el.id))
      
    useEffect(() => {
        getAllAlertLogs();
        getAllTriggers();
        getAllStatuses();
    }, []);

   const getAllTriggers = () => {

    getAllTriggersForAlert().then(res => {
            if (res && res.data) {
                setTriggers(res.data);
            }
        })
    };
    
    const getAllStatuses = () => {

        getAllStatusForAlerts().then(res => {
            if (res && res.data) {
                setStatuses(res.data);
            }
        })
    };

    const deleteAlert = () => {
        if (selectedAlarms.length > 0) {
            const statusClosed = search.closed;
            setLoading(true);
            deleteAlertLog(selectedAlarms)
                .then(() => {
                    const payload = {
                        name: search.text,
                        status: search.status,
                        trigger: search.triggers,
                        from: dateRange[0] ? dateRange[0] : '',
                        to: dateRange[1] ? dateRange[1] : ''
                    };
                    if(statusClosed !== 'all') {
                        payload['closed'] = statusClosed;
                    }
                    setTimeout(() => {
                        getAllAlertLogs(1, payload);
                        setSelectedAlarms([]);
                    }, 500);
                })
        }
    }

    const showDeleteConfirm = () => {

        Modal.confirm({
            title: <Translate string={"ALERTING.DELETE_ALERT_CONFIRM_TITLE"} />,
            content: <Translate string={"ALERTING.DELETE_ALERT_CONFIRM_CONTENT"} />,
            okText: <Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"} />,
            okType: 'danger',
            cancelText: <Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>,
            onOk() {
                deleteAlert()
            },
            onCancel() {
            },
        });
    };

    const handleRefresh = () => {
        setLoading(true);
        setData([]);
        setSelectedAlarms([]);
        setCheckedAlerts([])
        searchData()
    }

    const handleCloseLog = () => {
        if (selectedAlarms.length > 0) {
            const statusClosed = search.closed;
            setLoading(true);
            closeAlertLog(selectedAlarms)
                .then(() => {
                    const payload = {
                        name: search.text,
                        status: search.status,
                        trigger: search.triggers,
                        from: dateRange[0] ? dateRange[0] : '',
                        to: dateRange[1] ? dateRange[1] : ''
                    };
                    if(statusClosed !== 'all') {
                        payload['closed'] = statusClosed;
                    }
                    setTimeout(() => {
                        getAllAlertLogs(1, payload);
                        setSelectedAlarms([]);
                        setCheckedAlerts([])
                    }, 500);
                })
        }
    }

    const isChange = (e, action) => {
        action ? setSearch({...search, [action]: e}) : setSearch({...search, text: e.target.value});
    };

    const handleOnSelectChange = (name, value) => {
        setDateRange([value[0], value[1]]);
    }

    const searchData = (page) => {
        const statusClosed = search.closed;
        setLoading(true);
        const payload = {
            name: search.text,
            status: search.status,
            trigger: search.triggers,
            from: dateRange[0] ? dateRange[0] : '',
            to: dateRange[1] ? dateRange[1] : ''
        };
        if(statusClosed !== 'all') {
            payload['closed'] = statusClosed;
        }
        setTimeout(() => {
            getAllAlertLogs(page, payload);
        }, 500)
    };


    const getAllAlertLogs = (page, filters) => {

        getAlertLogs(page, filters).then(res => {
            
            if (res && res.data) {
                setData(res.data);
                setPagination(res.meta);
                setLoading(false);
            }
        }).catch(() => setLoading(false));
    };

    const initialOptions = {
        componentName: <Translate string={"ALERTING.ALERTS"}/>,
        breadcrumb: null,
        section: 'alerts',
        datePicker: true
    };

    const defaultCheck =  (id) => {
        setSelectedAlarms(checkedAlerts);
        return checkedAlerts.includes(id)
    }
    
    const onCheckboxChange = (e, id) => {
        const isNotInList = checkedAlerts.findIndex(el => el === id) === -1;
        setCheckedAlerts((isNotInList) ? [...checkedAlerts,id] : checkedAlerts.filter(item => item !== id) )
        setSelectedAlarms(checkedAlerts);

    }

    const handleCheckAll = (e) => {
        let newArray = data.map((dat => dat.id))
        let mySetAllert = new Set(checkedAlerts.concat(newArray))
        setCheckedAlerts( (!checkedAllAlarms) ? [...mySetAllert] : checkedAlerts.filter(checkedAlert => !newArray.includes(checkedAlert)))
       
    }
    
    const openChartProbe = (probeId, cteatedTime) => {
        toggleModal(!isModalOpen);
        fetchNewSeries(probeId, cteatedTime)
    }

    const closeModal = () => {
        toggleModal(false);
        setChartData([]);
    };

    const fetchNewSeries = (probeId, createdTime) => {

        const timeDifference = getConfig().TIME_FOR_CHART_LOGS;
        const time = moment(createdTime);
         
        const pastTime = time.add(-timeDifference, 'hours').format();
        const futureTime = time.add(+timeDifference * 2,'hours').format()

        const filters = {
            probeIds: [probeId],
            from : pastTime,
            to : futureTime,
            chartType : 'line',
            limit : 500
        };
        instance.get('/api/series', {
            params: {
                filters: filters
            }
        }).then(res => {
            setUpdatedChartData(true);
            setChartData([...chartData, {id: probeId, series: res.data[probeId] }]);
        })
    };

    const onChangePage = (e) => {
        searchData(e)
    }


    // DatePicker customization
    const disabledRangeTime = () => {
        return {
            disabledSeconds: () => range(1, 60)
        };
    }

    const range = (start, end) => {
        const result = [];
        for(let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }



    return (
        <div className="app-container flex flex-direction-column">
            <AlertHeader options={initialOptions} />

            <div className="flex flex-direction-column alerting-alerts-content">
                <div className="alerting-alerts-header">
                    <div className="flex flex-align-items-center alerting-alerts-header-wrap">
                        <div className="flex flex-align-items-center flex-justify-content-space-between alerting-alerts-header-search">
                            <div className="form-input flex flex-direction-column">
                                <label><Translate string={"GLOBAL.SEARCH"}/></label>
                                <Input
                                    name={"unit"} type={"text"}
                                    placeholder={TranslateText("GLOBAL.SEARCH")}
                                    autoComplete='off'
                                    onChange={isChange}
                                />
                            </div>
                            <div className="form-input flex flex-direction-column">
                                <label><Translate string={"GLOBAL.STATUS"}/></label>
                                <Select
                                    placeholder={TranslateText("GLOBAL.STATUS")}
                                    allowClear={true}
                                    onChange={(e) => isChange(e, 'status')}
                                >
                                {statuses && statuses.map((status, index) => <Option key={index} value={status.name}>{status.name}</Option>
                                                )}
                                </Select>
                            </div>
                            <div className="form-input flex flex-direction-column">
                                <label><Translate string={"ALERTING.TRIGGER_NAME"}/></label>
                                <Select
                                    placeholder={TranslateText("ALERTING.TRIGGER_NAME")}
                                    allowClear={true}
                                    onChange={(e) => isChange(e, 'triggers')}
                                >
                                {triggers && triggers.map((trigger, index) => <Option key={index} value={trigger.name}>{trigger.name}</Option>
                                                )}
                                </Select>
                            </div>
                            <div className="form-input flex flex-direction-column">
                                <label><Translate string={"ALERTING.CLOSED_UNCLOSED"}/></label>
                                <Select
                                    placeholder={TranslateText("ALERTING.ALL")}
                                    allowClear={true}
                                    onChange={(e) => isChange(e, 'closed')}
                                >
                                    <Option key={'closed'} value={'true'}>{TranslateText("ALERTING.CLOSED")}</Option>
                                    <Option key={'unclosed'} value={'false'}>{TranslateText("ALERTING.UNCLOSED")}</Option>
                                    <Option key={'all'} value={'all'}>{TranslateText("ALERTING.ALL")}</Option>
                                </Select>
                            </div>
                            <div className="form-input flex flex-direction-column">
                                <label><Translate string={"ALERTING.DATE"}/></label>
                                <div className={"date-wrap"}>
                                    <RangePickerInput 
                                        className={"no-margin"} 
                                        onChangeData={(name,value) => handleOnSelectChange(name,value)}
                                        name={'dateRange'}
                                        showTime={{
                                            hideDisabledOptions: true,
                                            defaultValue: [moment("00:00", "HH:mm"), moment("23:59", "HH:mm")]
                                        }}
                                        disabledTime={disabledRangeTime}
                                        format="YYYY-MM-DD HH:mm"
                                    />
                                </div>
                            </div>
                            <div className="form-input flex flex-direction-column alerting-alerts-header-cta">
                                <Button type="primary" icon="search" 
                                onClick={(e)=>{e.persist();searchData();setCheckedAlerts([])}}><Translate string={"GLOBAL.SEARCH"}/></Button>
                            </div>
                        </div>
                        <div className="alerting-alerts-header-action">
                            <Tooltip placement="top" title={TranslateText("GLOBAL.REFRESH")}>
                                <Button onClick={handleRefresh}>
                                    <RefreshIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={selectedAlarms.length === 0 ? TranslateText("ALERTING.NOTHING_WAS_SELECTED") : TranslateText("ALERTING.CLOSE")}>
                                <Button onClick={handleCloseLog} disabled={selectedAlarms.length === 0}>
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={selectedAlarms.length === 0 ? TranslateText("ALERTING.NOTHING_WAS_SELECTED") : TranslateText("GLOBAL.DELETE")}>
                                <Button onClick={showDeleteConfirm} className={'alerting-delete'} disabled={selectedAlarms.length === 0}>
                                    <DeleteIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={TranslateText("GLOBAL.DOWNLOAD")}>
                                <Button onClick={() => {}}>
                                    <DownloadIcon />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="alerting-alerts-list-container">
                    <div className={"alerting-main-checkbox"}>
                        <Checkbox  checked={checkedAllAlarms}
                         onChange={(e)=>handleCheckAll(e.target.checked)}/>
                        <span className="selected-alarms"><Translate string={"GLOBAL.SELECT_ALL"}/></span>
    {checkedAlerts.length > 0 && <span className="selected-alarms">Selected {checkedAlerts.length} - {pagination.totalRecords}</span>}
                    </div>
                    <div className="alerting-alerts-list">
                        {loading ? <LoaderBar/>
                        : data.map(alarm => (<AlertsAccordion
                            key={alarm.id}
                            date={alarm.createdAt}
                            statusName={alarm.statusName}
                            statusColor={alarm.statusColor}
                            alertName={alarm.alertName}
                            triggerName={alarm.triggerName}
                            notificationStatus={alarm.notificationStatus}
                            notificationMessage={alarm.message}
                            eventTime={alarm.eventTime}
                            closed={alarm.closed}
                            id={alarm.id}
                            onCheckboxChange={onCheckboxChange}
                            checked={(id)=>defaultCheck(id)}
                            selectedAlarms={selectedAlarms}
                            triggerableID={alarm.triggerableType === 'probe' ? alarm.triggerableID : false} 
                            openChartProbe={openChartProbe}
                        />))}
                    </div>
                    <footer className='flex footer flex-justify-content-center flex-align-items-center'>
                        <div className="alert-setup-pagination flex flex-justify-content-center">
                            {data && data.length > 0 && <Pagination
                                defaultCurrent={1}
                                total={pagination.totalRecords}
                                onChange={onChangePage}
                                size={'small'}
                                current={pagination.currentPage}
                            />}
                        </div>
                    </footer>
                </div>
                
            </div>
            <Modal 
                isOpen={isModalOpen} 
                visible={isModalOpen} 
                onCancel={closeModal} 
                onOk={closeModal}
                toggle={toggleModal} 
                size={'500px'}
                title={TranslateText("VIEWERS.PROBE")}
                className="hyperlink-modal"
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className="hyperlink">
                    <div>
                        <Chart 
                            chartData={chartData} 
                            isUpdatedChartData={isUpdatedChartData}
                            setUpdateChartData={setUpdatedChartData}
                            isAxisSplit={true}
                            isHideYAxis={true}
                            onHideYAxis={true}
                            chartType="line"
                            responseHaveCome={responseHaveCome}
                            setResponseHaveCome={setResponseHaveCome}
                            setAreDimensionsChanged={setAreDimensionsChanged}
                            areDimensionsChanged={areDimensionsChanged}
                            fromAlerting={true}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AlertingAlerts;