import React from "react";

export const Refresh = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
        <path d="M10.7207 0.493994C10.525 0.412974 10.3579 0.446285 10.2194 0.594302L9.28839 1.51823C8.77755 1.036 8.19391 0.662429 7.53744 0.397447C6.88097 0.132491 6.2018 0 5.49997 0C4.75521 0 4.04386 0.145685 3.36591 0.436879C2.68792 0.728097 2.10306 1.11952 1.61133 1.61129C1.11958 2.1031 0.728059 2.68789 0.436846 3.36585C0.145657 4.04385 0 4.75512 0 5.5C0 6.24465 0.145657 6.95609 0.436871 7.63408C0.72816 8.31211 1.11958 8.89695 1.61136 9.38871C2.10308 9.88032 2.68794 10.2718 3.36594 10.5631C4.04388 10.8543 4.75524 11 5.5 11C6.32112 11 7.10193 10.8269 7.84182 10.4808C8.58177 10.1347 9.21198 9.6465 9.73251 9.01625C9.77063 8.96852 9.78854 8.91479 9.78608 8.85511C9.78385 8.79546 9.76103 8.74653 9.71808 8.70832L8.73714 7.72006C8.68444 7.67714 8.62494 7.6557 8.55807 7.6557C8.48169 7.66523 8.42684 7.69397 8.39337 7.74171C8.04485 8.19537 7.61756 8.54619 7.11146 8.79464C6.60546 9.04281 6.06836 9.16685 5.5002 9.16685C5.00376 9.16685 4.52989 9.07018 4.07872 8.87679C3.6275 8.68349 3.23716 8.4221 2.9078 8.09265C2.57846 7.76318 2.31707 7.37298 2.12368 6.92171C1.93034 6.47053 1.83364 5.99678 1.83364 5.5002C1.83364 5.00367 1.93041 4.5297 2.12368 4.07862C2.31699 3.62749 2.57836 3.23715 2.9078 2.90777C3.23726 2.57833 3.6275 2.31693 4.07872 2.12354C4.52979 1.93022 5.00376 1.8335 5.5002 1.8335C6.45985 1.8335 7.29301 2.16056 7.99962 2.81466L7.01113 3.8028C6.86306 3.94605 6.82975 4.1108 6.91077 4.29697C6.99206 4.48796 7.1329 4.58342 7.33352 4.58342H10.5418C10.6659 4.58342 10.7733 4.5381 10.864 4.44737C10.9547 4.35667 11 4.24926 11 4.12512V0.916725C10.9999 0.716258 10.9071 0.575415 10.7207 0.493994Z" fill="#7F8C8D"/>
    </svg>
);
export const closeRedIcon= () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6388 12.3887L10.2708 9.02065L13.6388 5.65258C13.7923 5.49915 13.9166 4.95812 13.5 4.54145C13.0833 4.12479 12.5422 4.24915 12.3888 4.40256L9.02073 7.77065L5.65266 4.40258C5.49927 4.24915 4.95822 4.12479 4.54157 4.54144C4.12492 4.95808 4.24927 5.49915 4.40268 5.65256L7.77077 9.02065L4.40268 12.3887C4.24928 12.5421 4.12489 13.0832 4.54157 13.4999C4.95826 13.9166 5.4993 13.7922 5.6527 13.6388L9.02079 10.2707L12.3889 13.6388C12.5423 13.7922 13.0833 13.9166 13.5 13.4999C13.9167 13.0832 13.7922 12.5421 13.6388 12.3887Z" fill="#E74C3C"/>
    </svg>
)