import * as types from "../actions/actionTypes";

const initialState = {
    files:[]
};
export default (state = initialState, action) => {
    switch (action.type) {
        case types.HANDLE_PROBE_FILE_LIST:{
            return {...state, files: [action.files]};}
        case types.CLEAR_PROBE_FILE_LIST:
            return {...state, files:[]}
        default:
            return state;
    }
};
