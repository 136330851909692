import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Icon, Tabs} from "antd";

import './EditDataLoggerModal.scss'
import ModalDataLoggerTab from "./ModalDataLoggerTab/ModalDataLoggerTab";
import ModalFtpTab from "./ModalFtpTab/ModalFtpTab";
import ModalMqttTab from "./ModalMqttTab/ModalMqttTab";
import Translate from "../../../../../Translate/Translate";

import {
    clipboradField,
    resetParamsForConfirmMessage,
    chooseTabForCheck,
    confirmMessage
} from "../../../../../../config/constants";
import {connect} from "react-redux";
import * as globalSettingActions from "../../../../../../actions/globalSettings";

const TabPane = Tabs.TabPane;

class EditDataLoggerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mqttItem: props.dataModal.connections.mqtt ? {...props.dataModal.connections.mqtt} : {},
            ftpItem:  props.dataModal.connections.ftp ? {...props.dataModal.connections.ftp} :{},
            validatorObj: {
                datalogger:{name: null}
            },
            ftpChecked: false,
            mqttChecked: false,
            dataloggerItem: {...props.dataModal},
            activeTabKey:"1",
            runTest: false,
            runConsumer: false
        };
    }

    componentDidMount() {
        const item  = this.props.dataModal;
        this.setState({
            ftpChecked : !!item.connections.ftp,
            mqttChecked : !!item.connections.mqtt
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.editMqtt !== this.props.editMqtt){
            let item= this.props.editMqtt;
            if(prevProps.editMqtt && prevProps.editMqtt.password){
                if(prevProps.editMqtt.password !== item.password){
                    if(this.state.mqttItem && this.state.mqttItem.id){
                        this.setState({mqttItem: {...this.state.mqttItem,password:item.password}});
                        return
                    }else{
                        this.setState({mqttItem: {...this.props.editMqtt,password:item.password}});
                        return
                    }

                }
            }
            this.setState({mqttItem: item});
        }
        if(prevProps.editFtp !== this.props.editFtp){
            let item= this.props.editFtp;
            if(prevProps.editFtp && prevProps.editFtp.password){
                if(prevProps.editFtp.password !== item.password){
                    if(this.state.ftpItem && this.state.ftpItem.id){
                        this.setState({ftpItem: {...this.state.ftpItem, password:item.password}});
                        return
                    }else{
                        this.setState({ftpItem: {...this.props.editFtp, password:item.password}});
                        return
                    }
                }
            }
            this.setState({...this.state, ftpItem: item})
        }
    }

    render() {
        const {
            props:{dataModal:{connections:{ftp,mqtt}}, chanelMessage, ExportDataLogger, GenerateNewMqttPassword, GenerateNewFtpPassword},
            state: {runTest, dataloggerItem, mqttItem, ftpItem, validatorObj, mqttChecked, ftpChecked, mqttTabKey},
        } = this;
        return (
            <div className="modal">
                <div className={"modal-content"}>
                    <div className="modal-header">
                        <h1><Translate string={"GLOBAL_SETTINGS.EDIT_DATA_LOGGER"}/></h1>
                        <span className="modal-header-icon"><Icon type={'close'} onClick={() => this.handleConfirmMessage()}/></span>
                    </div>
                    <div className="card-container">
                        <Tabs defaultActiveKey={mqttTabKey || "1"}  type="card" onTabClick={(key) => this.setState({activeTabKey:key})}>
                            <TabPane tab={<Translate string={"GLOBAL.DETAILS"}/>} key="1">
                                <ModalDataLoggerTab
                                onSave={(e)=>this.onSave(e,"dataloggerItem")}
                                onUpdate = {(name,value)=>this.onUpdate(name,value,"dataloggerItem")}
                                item={dataloggerItem}
                                ftpChecked={ftpChecked}
                                validatorObj={validatorObj.datalogger}
                                mqttChecked={mqttChecked}
                                handleConnectionType={(event,type) => this.handleConnectionType(event,"dataloggerItem")}
                            /></TabPane>

                            <TabPane tab={<span className={!ftp ? "tabPaneSpanDisable": "tabPaneSpanEnable"}><Translate string={"GLOBAL.FTP"}/></span>} disabled={!ftp}
                                     key="2" ><ModalFtpTab
                                            item={ftpItem}
                                            onUpdate = {(name,value)=>this.onUpdate(name,value,"ftpItem")}
                                            setGeneratedPassword={() => {GenerateNewFtpPassword(this.state.ftpItem.id) } }
                                            onSave={(e) => this.onSave(e,"ftpItem")}
                                            dataLogger={dataloggerItem}
                                            fieldValidator={this.fieldValidator}
                                            validatorObj={validatorObj}
                                            exportDataLoggerInfo={() => ExportDataLogger(dataloggerItem.id)}
                                            clipboradField={clipboradField}
                            />
                            </TabPane>
                            <TabPane  key="3" tab={<span className={!mqtt ? "tabPaneSpanDisable": "tabPaneSpanEnable"}><Translate string={"GLOBAL.MQTT"}/></span>} disabled={!mqtt} >
                                <ModalMqttTab
                                    item={mqttItem}
                                    dataLogger={dataloggerItem}
                                    onUpdate = {(name,value)=>this.onUpdate(name,value,"mqttItem")}
                                    onSave={(e) => this.onSave(e,"mqttItem")}
                                    onChangeSelectOptionMqtt = {(e, options)=> this.selectOptionOnMqtt(e,options)}
                                    exportDataLoggerInfo={() => ExportDataLogger(this.state.dataloggerItem.id)}
                                    generateNewMqttPassword={() => GenerateNewMqttPassword(this.state.mqttItem.id)}
                                    clipboradField={clipboradField}
                                    loadingStatus={runTest}
                                    message={chanelMessage}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
    onSave = async (e,tagName) => {
        const {
            state: {mqttItem,ftpItem, ftpChecked, mqttChecked, dataloggerItem},
            props: {updateDataLogger,updateMqtt,updateFtp,createMqttConnection,createFtpConnection,deleteMqqtConnection, deleteFtpConnection }} = this;
        switch (tagName) {
            case "dataloggerItem":{
                let createFtpPromise,deleteFtpPromise, createMqttPromise, deleteMqttPromise;
                if (ftpChecked && !dataloggerItem.connections.ftp) {
                    createFtpPromise = createFtpConnection({dlId: dataloggerItem.id})
                } else if (!ftpChecked && dataloggerItem.connections.ftp) {

                    deleteFtpPromise = deleteFtpConnection(dataloggerItem.connections.ftp.id)
                }

                if (mqttChecked && !dataloggerItem.connections.mqtt) {
                    createMqttPromise = createMqttConnection({dlId: dataloggerItem.id})
                } else if (!mqttChecked && dataloggerItem.connections.mqtt) {
                    deleteMqttPromise = deleteMqqtConnection(dataloggerItem.connections.mqtt.id)
                }
                Promise.all([createFtpPromise, createMqttPromise, deleteFtpPromise, deleteMqttPromise]).then(data => {
                    resetParamsForConfirmMessage();

                    let dataloogerForUpdate = {...dataloggerItem};
                    let connections = {mqtt: mqttItem, ftp: ftpItem};
                    if (data[0] && data[0].data.data.connections.ftp) {
                        this.setState({ftpItem: data[0].data.data.connections.ftp, savedFtpChecked: true});
                        connections = {...connections, ftp: data[0].data.data.connections.ftp}
                    }
                    if (data[1] && data[1].data.data.connections.mqtt) {
                        this.setState({mqttItem: data[1].data.data.connections.mqtt, savedMqttChecked: true});
                        connections = {...connections, mqtt: data[1].data.data.connections.mqtt}
                    }

                    if (data[2]) {
                        this.setState({ftpItem: {}, savedFtpChecked: false});
                        connections = {...connections, ftp: null}
                    }
                    if (data[3]) {
                        connections = {...connections, mqtt: null}
                        this.setState({mqttItem: {}, savedMqttChecked: false});
                    }
                    dataloogerForUpdate.connections = connections;
                    updateDataLogger(dataloogerForUpdate);
                    this.props.onClose();

                });
                break;
            }
            case "mqttItem": {
                resetParamsForConfirmMessage();
                updateMqtt(mqttItem);
                setTimeout(() => {
                    this.props.onClose();
                }, 500);
                break;
            }
            case "ftpItem":{
                if(ftpItem && ftpItem.id) {
                    resetParamsForConfirmMessage();
                    updateFtp(ftpItem);
                    setTimeout(() => {
                        this.props.onClose();
                    }, 500);
                }
                break;
            }
            default:
            {
                break;
            }
        }

    };
    fieldValidator = (obj) => {
        if(obj.name === "ip"){
            let reg=/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
            if(reg.test(obj.value) === false) {
                let newValidator = {...this.state.validatorObj, ip: true}
                this.setState({
                    validatorObj: newValidator
                });
                return true;
            }
            let newValidator = {...this.state.validatorObj, ip: false}
            this.setState({
                validatorObj: newValidator
            })
        }

    }
    onUpdate = (name,value,tabName) => {
        let items = this.state;

        items[tabName] = {...items[tabName],[name]: value };
        const {props:{dataModal}}= this;

        chooseTabForCheck(tabName,name,value,items,{dataModal});

        this.setState({
            [tabName]: items[tabName]
        });
        if(name === "name") {
            value.length !== 0 ?
                this.setState({validatorObj: {datalogger: {...this.state.validatorObj.datalogger, name: false}}})
                :
                this.setState({validatorObj: {datalogger: {...this.state.validatorObj.datalogger, name: true}}})
        }
    };

    handleConnectionType = (event,tabName) => {
        const {props:{dataModal}}= this;
        const checked = event.target.checked;
        let inputName = event.target.name;
        inputName += "Checked";

        this.setState({
            [inputName] : checked
        }, () => {
            chooseTabForCheck(tabName,inputName,checked,this.state,{dataModal});
        })
    };
    selectOptionOnMqtt = (name,value) => {
        this.onUpdate(name,value,"mqttItem")
    }

    handleConfirmMessage= () => {
        const { props:{onClose}}=this;
        localStorage.getItem("dataInAppIsChanged") === "true" ? confirmMessage(onClose) : onClose();
    }
}

EditDataLoggerModal.propTypes = {
    onClose: PropTypes.func.isRequired
};
const mapStateToProps= (state) => ({
    allDataLoggers : state.globalSettings.allDataLoggers,
    editMqtt    : state.globalSettings.editMqtt,
    editFtp     : state.globalSettings.editFtp,
    chanelMessage     : state.globalSettings.chanelMessage
});

const mapDispatchToProps = dispatch => ({
    ExportDataLogger        : (dataLoggerId) => dispatch(globalSettingActions.ExportDataLogger(dataLoggerId)),
    GenerateNewMqttPassword : (mqttId) => dispatch(globalSettingActions.GenerateNewMqttPassword(mqttId)),
    GenerateNewFtpPassword : (ftpId) => dispatch(globalSettingActions.GenerateNewFtpPassword(ftpId))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDataLoggerModal);
