import React, {Component} from 'react';

import {connect} from 'react-redux';
import {userActions, appActions } from "../../actions";
import {NavLink, withRouter} from 'react-router-dom';
import Translate from "../Translate/Translate"

import {Popover, Button, Icon, Dropdown, Menu, Modal} from 'antd';
import Logo from '../../assets/img/logo-synapsees.png';
import {onClickDataInAppIsChanged, leavePageMessage} from "../../config/constants"
import './TopBar.scss';

const confirm = Modal.confirm;

class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewer: false,
            filtering: false,
            energyanalysis: false
        }
    }

    render() {
        const {props: {dashboardsList, status, history, preferredLanguage, DashboardBackdrop}, setLanguage} = this;
        let userInfo = localStorage.getItem('userInfo');
        userInfo = JSON.parse(userInfo);

        let localLanguage = preferredLanguage;

        return (
            <header className="header-syn bg-white border-grey">
                <span
                    className={DashboardBackdrop ? 'top-bar-black-screen-modal' : null}
                />
                <div className="header-container flex flex-align-items-center">
                    <div className="header-syn-logo flex flex-align-items-center syn-row-top-bottom">
                        <NavLink exact to="/" activeClassName="active-top-bar">
                            <img src={Logo} alt="" onClick={(e) => onClickDataInAppIsChanged(e, "/", history)}/>
                        </NavLink>
                    </div>
                    <div className="header-syn-menu">
                        {status ? <ul className="flex flex-align-items-center flex-justify-content-center">
                                {dashboardsList && dashboardsList.length > 0 ?
                                    <li className={'link-with-dropdown'} id={'area'}>
                                        <Dropdown overlayClassName={'open-submenu'}
                                                  overlay={() => menu(dashboardsList, this, history)}
                                                  getPopupContainer={() => document.getElementById('area')}>
                                            <NavLink exact to="/" activeClassName="active-top-bar"
                                                     onClick={(e) => onClickDataInAppIsChanged(e, "/", history)}>
                                                <p className="link-in-dropdown-text">
                                                    <Translate string={"GLOBAL.DASHBOARD"}/>
                                                </p>
                                            </NavLink>
                                        </Dropdown>
                                    </li> :
                                    <li>
                                        <NavLink exact to="/" activeClassName="active-top-bar"
                                                 onClick={(e) => onClickDataInAppIsChanged(e, "/", history)}>
                                            <Translate string={"GLOBAL.DASHBOARD"}/>
                                        </NavLink>
                                    </li>
                                }
                                <li>
                                    <NavLink to="/viewers" activeClassName="active-top-bar" onClick={(e) => {
                                        onClickDataInAppIsChanged(e, "/viewers", history)
                                    }}>
                                        <Translate string={"VIEWERS.VIEWERS"}/>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/filtering" activeClassName="active-top-bar"
                                             onClick={(e) => onClickDataInAppIsChanged(e, "/filtering", history)}>
                                        <Translate string={"FILTERING.FILTERING"}/>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/energy-analysis" activeClassName="active-top-bar"
                                             onClick={(e) => onClickDataInAppIsChanged(e, "/energy-analysis", history)}>
                                        <Translate string={"ENERGY_ANALYSIS.ENERGY_ANALYSIS"}/>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/alerting/alerts" activeClassName={"active-top-bar"}
                                             onClick={(e) => onClickDataInAppIsChanged(e, "/alerting/alerts", history)}>
                                        <Translate string={"ALERTING.ALERTING"}/>
                                    </NavLink>
                                </li>
                            </ul>
                            : <ul className="flex flex-align-items-center flex-justify-content-center">
                                <li>
                                    <Translate string={"GLOBAL.DASHBOARD"}/>
                                </li>
                                <li>
                                    <Translate string={"VIEWERS.VIEWERS"}/>
                                </li>
                                <li>
                                    <Translate string={"FILTERING.FILTERING"}/>
                                </li>
                                <li>
                                    <Translate string={"ENERGY_ANALYSIS.ENERGY_ANALYSIS"}/>
                                </li>
                                <li>
                                    <Translate string={"ALERTING.ALERTING"}/>
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="header-settings">
                        <div className="header-settings-wrap flex flex-align-items-center right">
                            <div
                                className="header-link-global-settings header-language-select flex flex-direction-column">
                                <Button type={'button'} className={localLanguage === "it" ? 'selected-lang' : ''}
                                        onClick={() => setLanguage('it')}>IT</Button>
                                <span className={'languages-separator'}/>
                                <Button type={'button'} className={localLanguage === "en" ? 'selected-lang' : ""}
                                        onClick={() => setLanguage('en')}>EN</Button>
                            </div>
                            <div className="header-link-global-settings">
                                <NavLink exact to="/global-settings/tree-setup"
                                         activeClassName="active-global-settings"
                                         onClick={(e) => onClickDataInAppIsChanged(e, "/global-settings/tree-setup", history)}
                                >
                                    <Icon type="setting" theme="filled"/> <Translate string={"GLOBAL.GLOBAL_SETTINGS"}/>
                                </NavLink>
                            </div>
                            <Popover
                                placement="bottomRight"
                                title={'Menu'}
                                className='header-user-information flex flex-align-items-center'
                                content={
                                    <Button onClick={this.logoutUser} className="flex flex-align-items-center">
                                        <span className="logout-text"><Translate string={"GLOBAL.LOGOUT"}/></span>
                                    </Button>
                                }
                                trigger="hover"
                            >
                                <Icon type="user" theme="outlined" style={{fontSize: '24px'}}/>
                                <span>
                                    {userInfo && userInfo.username ? userInfo.username : 'SYN user'}
                                </span>
                            </Popover>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

    logoutUser = () => {
        const {logoutUser} = this.props;
        logoutUser(this.props.history);
    };
    setLanguage = (lang) => {
        const {HandleLanguagePicker} = this.props;
        if (lang) {
            HandleLanguagePicker(lang)
        }
    }
}

const menu = (e, self, h) => {
    return (

        <Menu>
            {e && e.map((item, index) =>
                <Menu.Item key={index}>
                    <button type={'button'} onClick={(event) => {
                        if (localStorage.getItem("dataInAppIsChanged") === "true" || localStorage.getItem("dataInProbeSetupIsChanged") === "true") {
                            event.preventDefault();
                            return confirm({
                                title: leavePageMessage.title,
                                content: leavePageMessage.content,
                                okText: leavePageMessage.okText,
                                okType: 'danger',
                                cancelText: leavePageMessage.cancelText,
                                onOk: () => {
                                    localStorage.setItem("dataInAppIsChanged", "false");
                                    localStorage.setItem("dataInProbeSetupIsChanged", "false");
                                    h.replace({
                                            pathname: "/dashboard-view",
                                            search: `?dashId=${item.id}`,
                                            state: {item}
                                    });
                                }
                             });
                        }
                        h.replace({
                            pathname: "/dashboard-view",
                            search: `?dashId=${item.id}`,
                            state: {item}
                        });
                        window.location.reload();
                    }

                    }
                    >
                        {item.name}
                    </button>
                </Menu.Item>)}
        </Menu>
    )

};


const mapStateToProps = (state) => ({
    status: state.tree.status,
    dashboardsList: state.dashboard.dashboardsList,
    DashboardBackdrop: state.dashboard.DashboardBackdrop,
    newAddedProbeIsSave: state.globalSettings.newAddedProbeIsSave
});
const mapDispatchToProps = dispatch => ({
    logoutUser: history => dispatch(userActions.logoutUser(history)),
    HandleLanguagePicker: lang => dispatch(appActions.HandleLanguagePicker(lang))
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TopBar));
