import {Upload, Button, Icon} from 'antd';
import React, {Component} from 'react';
import {connect} from "react-redux";
import Translate from "../Translate/Translate"
import {uploadProbe, handleUploadedFile, clearFiles} from "../../actions/uploadProbe";
import './UploadProbe.css';
import uploadImage from "../../assets/img/upload.jpg";

class UploadProbe extends Component {

    render() {

        const {files} = this.props;
        const {onRemove} = this;
        const {handleUploadedFiles} = this.props;
        const props = {
            beforeUpload: (file) => {
                handleUploadedFiles(file);
                return false;
            },
            files,
            multiple: false
        };
        return (
            <div>
                <Upload {...props}>
                    <UploadProbeButtonContent/>
                </Upload>
                <div>
                    {
                        files ? files.map(item => {
                        return (
                            <div className="list-wrap flex" key={item.uid}>
                                <div className="list-item">
                                    <h5>{item.name.split('.')[0]}</h5>
                                    <div className="liste-item-description flex">
                                        <span><b><Translate string={"UPLOAD.TYPE"}/>:</b> {item.type}</span>
                                        <span><b><Translate string={"UPLOAD.UID"}/>:</b> {item.uid}</span>
                                    </div>
                                </div>
                                <Button type='button' onClick={() => onRemove()}><Icon type="close"/></Button>
                            </div>
                        )
                    }): null
                    }
                </div>
            </div>
        );
    }

    onRemove = () => {
        const{
            clearFileList
        }=this.props
        clearFileList();
    }
}

const UploadProbeButtonContent = ({isDisabledUpload}) => (
    <div className={!isDisabledUpload ? 'no-added-file' : 'file-added'}>
        <div className="ant-upload-drag-icon" align="center">
            <img alt="Upload CSV file" className="upload-image-cover" src={uploadImage}/>
        </div>
        {!isDisabledUpload ? (
            <div className="ant-upload-text">
                Drop your .CSV file to add new probe <br/> or <br/>
                <Button type="primary" onClick={() => {
                }}>Add .CSV file</Button>
            </div>
        ) : (
            <div className="ant-upload-text upload-success">
                Thank you, add new .CSV file or upload exist.
            </div>
        )}
    </div>
);
const mapStateToProps = state => ({
    files: state.uploadProbe.files
});
const mapDispatchToProps = dispatch => ({
    uploadProbe: callBack => dispatch(uploadProbe(callBack)),
    handleUploadedFiles: (fileList) => dispatch(handleUploadedFile(fileList)),
    clearFileList: () => dispatch(clearFiles())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadProbe);
