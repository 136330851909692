import React, {useState,useEffect} from "react";
import {Tree, Icon,Spin}                from "antd";
import ProbeTitle                  from "./ProbeTitle/ProbeTitle";
import FilterTitle                 from "./FilterTitle/FilterTitle";
import FilterTestTitle             from "./FilterTestTitle/FilterTestTitle";
import AnalysisTitle               from "./AnalysisTitle/AnalysisTitle";
import ProbeContextMenu            from "./ContextMenu/ProbeContextMenu/ProbeContextMenu";
import instance                    from '../../config/axiosConf' ;

import {isJson, TranslateText}                    from "../../config/constants";
import {getRandomColor}            from "../../config/utils";

import "./Tree.scss";

const {TreeNode, DirectoryTree} = Tree;

const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;

const SidebarTree = (props) => {

    const {
        probeStyles = [], isFetching = {}, updatedProbe, loadedProbesId, loadedFiltersId = [], selectable,
        clearFilterAddList = () => {},removeProbe = () => {}, addProbe = () => {}, addFilter = () => {},
        removeFilter = () => {}, addCheckedProbe = () => {}, readOnly=false, showDeleteIcon=true, probesOnDashboard, disableProbes, 
        setCheckedProbe = () => {}, fromAlerting
    } = props ;

    const [treeData, setTreeData] = useState([]);
    const [loadedProbes,setLoadedProbes] = useState([]);
    const [checkedKeysList, setCheckedKeysList] = useState([]);
    const [filterForRemove, setFilterForRemove] = useState({});
    const [filterDeleting, setFilterDeleting] = useState({});


    const lineColor = updatedProbe && updatedProbe.options ? updatedProbe.options.lineColor : null;

    let { filterForAdd = [] } = props;
    /**
     *
     * @param id
     * @param currentFolder
     * @returns {*}
     */
    const findNode = (id, currentFolder) => {

        if(!currentFolder) {
            return false
        }

        let i = 0;
        let currentChild = null;
        let result = null;
        const probe = currentFolder.probes ? currentFolder.probes.find(probe => probe.id === id) : null;
        if (probe) {
            return probe;
        } else {
            if (currentFolder.children) {
                for (i = 0; i < currentFolder.children.length; i++) {
                    currentChild = currentFolder.children[i];
                    result = findNode(id, currentChild);
                    if (result) {
                        return result;
                    }
                }
            }
            return false;
        }
    };

    function updateTreeProbes (updateForProbes, treeData) {
        let treeDataToUpdate = [...treeData];
        // eslint-disable-next-line
        treeDataToUpdate.map((folder,index) => {
            if(folder.probes && folder.probes.length > 0){
                // eslint-disable-next-line
                folder.probes.map((probe,index) => {
                    folder.probes[index] = {...probe, ...updateForProbes};
                })
            }

            if(folder.children && folder.children.length > 0){
                // eslint-disable-next-line
                folder.children.map((subTree) => {
                    updateTreeProbes(updateForProbes, [subTree]);
                })
            }
        });

        return treeDataToUpdate;
    }

    useEffect(() => {
        let urlParams = new URLSearchParams();
        urlParams.append("include", "probes");
        urlParams.append("include", "filters");
        urlParams.append("include", "analyses");

        instance.get("/api/folders", {
            params: urlParams
        }).then(response => {
            if (response && response.data) {
                setTreeData(response.data.data);
            }
        });
    },[]);
    useEffect(() => {

        if(treeData.length !==0 && (probesOnDashboard && (probesOnDashboard.length !== 0 || probesOnDashboard.length === 0))) {

            let uTreeData = updateTreeProbes({existsInDashboard: false}, treeData);

            probesOnDashboard.forEach(dashProbe => {

               const selectedFolder = uTreeData.find(folder => folder.id === dashProbe.folderRootId);

               if (selectedFolder) {

                   let probe = findNode(dashProbe.id, selectedFolder);

                   if (probe) {
                       probe.existsInDashboard = true;
                       probe.options = dashProbe.options ;
                   }
               }
            });

            setTreeData([...uTreeData]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[probesOnDashboard]);

    useEffect(() => {
        setLoadedProbes(loadedProbesId);
    }, [loadedProbesId]);

    useEffect(() => {
        filterForAdd.forEach(filter => {
            const selectedFolder = treeData.find(folder => folder.id === filter.folderId);
            const observedNode = findNode(filter.parentId, selectedFolder);
            if (observedNode.filters) {
                observedNode.filters.push(filter);
            } else {
                observedNode.filters = [filter];
            }
        });
        clearFilterAddList([]);
        setTreeData([...treeData]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterForAdd.length]);

    useEffect(() => {
        if(filterForRemove.length !== 0) {
            const selectedFolder = treeData.find(folder => folder.id === filterForRemove.folderId);
            const observedNode = findNode(filterForRemove.parentId, selectedFolder);
            if (observedNode.filters) {
                observedNode.filters = observedNode.filters.filter(filter => filter.probeId !== filterForRemove.probeId);
                setTreeData([...treeData]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterForRemove.id]);


    useEffect(() => {
        if(updatedProbe && lineColor) {
            const selectedFolder = treeData.find(folder => folder.id === updatedProbe.folderRootId);
            const observedNode = findNode(updatedProbe.id, selectedFolder);

            if (observedNode) {
                observedNode.options = {...updatedProbe.options};
            }

            setTreeData([...treeData]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineColor]);
    useEffect(() => {

        if(probeStyles && probeStyles.length && treeData.length){
            probeStyles.forEach(probeStyle => {
                const selectedFolder = treeData.find(folder => folder.id === probeStyle.folderRootId);
                const observedNode = findNode(probeStyle.id, selectedFolder);

                if (observedNode) {
                    observedNode.options = {...probeStyle.options};
                }
                setTreeData([...treeData]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[probeStyles.length,treeData.length]);
    
    const removeFilterFromTree = async (filter) => {
        setFilterForRemove({});
        setFilterDeleting({deleting:true, filterId:filter.id});
        await instance.delete('/api/filters',{data:JSON.stringify({filterId:filter.id})})
        setFilterForRemove(filter);
        setFilterDeleting({deleting:false, filterId:filter.id});
    };
    const addProbeToList = (probeID) => {
        const updatedList = [...loadedProbes, probeID];
        setLoadedProbes(updatedList);
    };
    const removeProbeFromList = (probeId) => {
        setLoadedProbes(loadedProbes.filter(id => id !== probeId));
    };
    const isProbeInList = (probeId) => {
        return loadedProbes.includes(probeId);
    };
    const addProbeToParentList = (probe) => {

        addProbeToList(probe.id);
        const newProbeOptions = probe.options ? isJson(probe.options) ? JSON.parse(probe.options) : probe.options : {};
        if(newProbeOptions && !newProbeOptions.lineColor){
            newProbeOptions.lineColor = getRandomColor();
            instance.put(`/api/probes/${probe.id}`, {
                options: JSON.stringify(newProbeOptions)
            });
        }
        probe.options = newProbeOptions ;
        addProbe(probe);
    };
    const removeProbeFromParentList = (probe) => {
        removeProbeFromList(probe.id);
        removeProbe(probe.id);
    };
    const isFilterInList = (filterId) => {
        return loadedFiltersId.includes(filterId);
    };
    const addFilterToParentList = (filter) => {
        addFilter(filter);
    };
    const removeFilterFromParentList = (filterId) => {
        removeFilter(filterId);
    };
    const handleOnCheck = (checkedKeys, {checkedNodes, checked, node, event, halfCheckedKeys}) => {
        const updatedCheckedList = checkedKeys.filter(key => key.toString().includes('probe') );
        if(fromAlerting) {
            const filteredCheckedList = checkedNodes.map(node => node.props)
            setCheckedKeysList(updatedCheckedList);
            setCheckedProbe(filteredCheckedList);
        } else if (selectable) {
            const nextCheckedNode = checkedNodes.find(checkedNode => checkedNode.key === updatedCheckedList[updatedCheckedList.length-1]);
            const nextCheckedProbe = nextCheckedNode ? nextCheckedNode.props.title.props.probe : null;
            checkedKeys.length ? setCheckedKeysList([updatedCheckedList[updatedCheckedList.length-1]]) : setCheckedKeysList([]);
            addCheckedProbe(nextCheckedProbe);
        }
    };

    const removeTestFilter = (filterObject) => {
        const selectedFolder = treeData.find(folder => folder.id === filterObject.folderId);
        const observedNode = findNode(filterObject.parentId, selectedFolder);
        if (observedNode.filters) {
            observedNode.filters = observedNode.filters.filter(filter => filter.probeId !== filterObject.probeId);
            setTreeData([...treeData]);
        }
    };
    const createProbe = (probe) => {
        probe.options = isJson(probe.options) ? JSON.parse(probe.options) : probe.options;
        const key = `probe-${probe.id}` ;
        return (
            <TreeNode
                isLeaf={probe.filters && probe.filters.length === 0}
                icon={<Icon type="file"/>}
                nodeId={probe.id}
                nodePath={probe.path}
                nodeTitle={probe.name}
                title={
                    readOnly ?
                    <ProbeTitle probe={probe} readOnly={readOnly}/>
                    :
                    <ProbeTitle
                       probe={probe}
                       isLoaded={isProbeInList}
                       addProbeToParent={addProbeToParentList}
                       removeProbeFromParent={removeProbeFromParentList}
                       isFetching={isFetching}
                       disableProbes={disableProbes}
                    />
                }
                key={key}
                blockNode={true}

            >
                {
                    probe.filters && probe.filters.length !== 0 && probe.filters.map((filter, filterIndex) =>
                                createFilter(filter, filterIndex,probe))
                }
            </TreeNode>
        );
    };

    const createFilter = (filter, filterIndex,probe) => {
        const filterKey = `filter-${filter.id}`;
        const filterTestKey = `filter-test-${filterIndex}`;
        filter.folderId =probe.folderRootId;
        return filter.id ? (
                <TreeNode
                    isLeaf
                    title={
                        <FilterTitle
                            filter={filter}
                            isLoaded={isFilterInList}
                            addFilterToParent={addFilterToParentList}
                            removeFilterFromParent={removeFilterFromParentList}
                            removeFilterFromTree={removeFilterFromTree}
                            isFetching={isFetching}
                            filterDeleting={filterDeleting}
                            readOnly={readOnly}
                            showDeleteIcon={showDeleteIcon}
                        />
                    }
                    icon={<Icon type="filter"/>}
                    key={filterKey}
                    checkable={false}
                />
            ) :
            (
                <TreeNode
                    isLeaf
                    title={
                        <FilterTestTitle
                            filter={filter}
                            removeFilterFromParentList={removeFilterFromParentList}
                            removeTestFilter={removeTestFilter}
                            readOnly={readOnly}
                        />
                    }
                    icon={<Icon type="filter"/>}
                    key={filterTestKey}
                    checkable={false}
                />
            );
    };

    const createAnalysis = (analysis) => {
        const key = `analysis-${analysis.id}` ;
        return (
            <TreeNode
                icon={<Icon type="file"/>}
                title={
                    <AnalysisTitle
                        name={analysis.name}
                        id={analysis.id}
                    />
                }
                key={key}
                blockNode={true}

            />
        );
    };
    const createFolders = (folder, dsIndex) => {
        const key = `folder-${dsIndex}`;
        const analysesKey = `analysis-${dsIndex}` ;
        return (
            <TreeNode
                icon={<Icon type="folder"/>}
                title={<span
                    onContextMenu={e => {
                        // e.preventDefault();
                        // this.handleDataSourceContextMenu(e, id, path, name, 'folder');
                    }
                    }
                >
                      {folder.name}
                  </span>
                }
                nodeId={folder.id}
                nodePath={folder.path}
                nodeType={'folder'}
                nodeTitle={folder.name}
                key={key}
                checkable={fromAlerting}
            >
                {
                    folder.children && folder.children.map((folder, dsIndexChild) => {
                        const dsIndex1 = `0-${dsIndex}-${dsIndexChild}`;
                        return createFolders(folder, dsIndex1);
                    })

                }

                {
                    folder.analysis ?
                        <TreeNode
                            key={analysesKey}
                            title={'Analyses'}
                            defaultExpandAll={true}
                            disableCheckbox
                            icon={<Icon type="calculator"/>}
                        >
                            {
                                folder.analysis.map((singleAnalysis) =>
                                    createAnalysis(singleAnalysis))
                            }
                        </TreeNode>
                        : null
                }

                {
                    folder.probes && folder.probes.map((probe) => createProbe(probe))
                }
            </TreeNode>
        );
    };

    const createTree = (treeData) => {
        return treeData.map((folder, folderIndex) => {
            return createFolders(folder, folderIndex);
        });
    };

    return (
        <React.Fragment>
            {
                treeData.length === 0 ?
                <div className={"tree-loader"}>
                <Spin indicator={antIcon} />
                <p>
                    {TranslateText("GLOBAL.LOADING")}...
                </p>
                </div>
                :
                <DirectoryTree
                    showIcon
                    expandAction={false}
                    checkable={selectable}
                    switcherIcon={<Icon type="down"/>}
                    onCheck={handleOnCheck}
                    checkedKeys={checkedKeysList}
                    selectedKeys={checkedKeysList}
                    multiple={fromAlerting}
                >
                    {treeData ? createTree(treeData) : null}
                </DirectoryTree>
            }
            <ProbeContextMenu/>
        </React.Fragment>
    );
};
export default SidebarTree;
