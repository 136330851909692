import React, { Component } from 'react';

import {Icon} from 'antd';
import Translate from "../../Translate/Translate"

import './LeftSidebarAdvancedSearch.scss';

class LeftSidebarAdvancedSearch extends Component {
  render() {
    return (
        <span className="sidebar-advanced-search">
          <Icon
            type="caret-right"
            theme="outlined"
            style={{ fontSize: '11px' }}
          />
          <Translate string={"GLOBAL.ADVANCED_SEARCH"}/>
        </span>
    );
  }
}

export default LeftSidebarAdvancedSearch;
