import React,{Component}       from 'react';
import {connect}               from "react-redux";

import {Icon}                  from 'antd';

import {energyAnalysisActions} from "../../../actions";

import './EnergyAnalysisTypeContainer.scss';

class EnergyAnalysisTypeContainer extends Component{

  render() {

    const {
        energyAnalysisList,
        selectedAnalysisIndex,
        selectAnalysis,
        removeAnalysis
    } = this.props;

    return(
            <div className="active-analysis-wrapper">
                  {energyAnalysisList.map((analysis, index) => (
                      <div
                          key={index}
                          className={`flex analysis ${index === selectedAnalysisIndex ? 'selected-analysis': ''}`}
                      >
                        <div
                            onClick={ () => selectAnalysis(index) }
                        >
                            <p className="name">{analysis.name}</p>
                        </div>
                        <div
                            onClick={ () => removeAnalysis(energyAnalysisList,index)}
                        >
                          <Icon type="delete" />
                        </div>
                      </div>
                  ))}
            </div>
    );

  }
}
const mapStateToProps = state => ({
  energyAnalysisList    : state.energyAnalysis.energyAnalysisList,
  selectedAnalysisIndex : state.energyAnalysis.selectedAnalysisIndex
});
const mapDispatchToProps = dispatch => ({
    selectAnalysis      : analysisIndex                => dispatch(energyAnalysisActions.selectAnalysis(analysisIndex)),
    removeAnalysis      : (analysisList,analysisIndex) => dispatch(energyAnalysisActions.removeAnalysis(analysisList,analysisIndex))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnergyAnalysisTypeContainer);