import React                    from 'react';
import {Component}              from 'react';
import {connect}                from "react-redux";

import {Input }                 from 'antd';

import {energyAnalysisActions}  from "../../../actions";

import './SelectedAnalysisContainer.scss'
import Translate from "../../Translate/Translate";
class SelectedAnalysisContainer extends Component{

  changeComment = (comment,selectedEnergyAnalysis) =>{
    const {
        updateAnalysis,
        selectedAnalysisIndex,
        energyAnalysisList
    } = this.props;

      updateAnalysis(
          energyAnalysisList,
          {
              ...selectedEnergyAnalysis,
              comment
          },
          selectedAnalysisIndex
      );
  }
  render(){

    const {
        yAxisName,
        unit,
        selectedAnalysisIndex,
        energyAnalysisList
          } = this.props;
    const {
        changeComment
    } = this ;
    const selectedEnergyAnalysis = energyAnalysisList[selectedAnalysisIndex];
    return selectedEnergyAnalysis && selectedEnergyAnalysis.value ?
        (
            <div className="selected-analysis-container">
                <div className="flex selected-analysis-header">
                  <p className="analysis-name">{selectedEnergyAnalysis.displayName}</p>
                </div>

                <p className="analysis-props"> <Translate string={"ENERGY_ANALYSIS.OUTPUT"}/>: {yAxisName}</p>
                <p className="analysis-props"> <Translate string={"ENERGY_ANALYSIS.OUTPUT_UNIT"}/>: {unit}</p>
                <p className="analysis-props"> <Translate string={"ENERGY_ANALYSIS.INPUT"}/>: {selectedEnergyAnalysis.inputDefineX}</p>
                <p className="analysis-props"> <Translate string={"ENERGY_ANALYSIS.TIME_RES"}/>: {selectedEnergyAnalysis.selectedTimeResolution}</p>


                <span className="comment">
                  <p><Translate string={"ENERGY_ANALYSIS.USER_COMMENT"}/>:</p>
                  <Input
                      type="text"
                      value={selectedEnergyAnalysis && selectedEnergyAnalysis.comment}
                      disabled={!selectedEnergyAnalysis}
                      className="name"
                      onChange={event => {
                              changeComment(event.target.value,selectedEnergyAnalysis);
                      }}
                  />
                </span>
            </div>
  ) : (
    <></>
  );
  }
}

const mapStateToProps = state => ({
    selectedAnalysisIndex : state.energyAnalysis.selectedAnalysisIndex,
    energyAnalysisList    : state.energyAnalysis.energyAnalysisList,
    yAxisName             : state.energyAnalysis.yAxisName,
    unit                  : state.energyAnalysis.unit,
});
const mapDispatchToProps = dispatch => ({
    updateAnalysis        : (analysisList,analysis,index) => dispatch(energyAnalysisActions.updateAnalysis(analysisList,analysis,index))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectedAnalysisContainer);
