import { combineReducers } from 'redux';
import app                 from './app';
import user                from './user';
import tree                from './tree';
import uploadCSV           from './uploadCSV';
import filterAnalyses      from './filterAnalyses';
import energyAnalysis      from './energyAnalysis';
import dataStorage         from './dataStorage';
import uploadProbe         from './uploadProbe';
import globalSettings      from './globalSettings';
import viewer              from './viewer';
import dashboard           from './dashboard';
import * as types          from "../actions/actionTypes"

const appReducer = combineReducers({
    app,
    user,
    tree,
    uploadCSV,
    filterAnalyses,
    energyAnalysis,
    dataStorage,
    uploadProbe,
    globalSettings,
    dashboard,
    viewer
});

export const rootReducer = (state, action) => {
    if (action.type === types.HANDLE_CLEAR_STORE) {
        state = undefined;
    }
    return appReducer(state, action)
}