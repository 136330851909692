import React                                                   from "react";
import { Component }                                           from "react";
import { connect }                                             from "react-redux";

import { Input, Select }                                       from "antd";

import { TIME_RESOLUTION_AGGREGATOR, AGGREGATOR_FUNCTION }                from "../../../config/constants";

import {energyAnalysisActions}                                 from "../../../actions";
import "./Aggregator.scss";
import Translate from "../../Translate/Translate";
const Option = Select.Option;

class Aggregator extends Component {

    render() {
        const {
            energyAnalysisList,
            selectedAnalysisIndex,
            updateAnalysis
        } = this.props;

        const selectedEnergyAnalysis = energyAnalysisList[selectedAnalysisIndex];

        return (
              <div className="flex aggregator">
                  <div className="input-wrapper">
                      <p className="input-label"><Translate string={"GLOBAL.INPUT_DEFINE"}/>:</p>
                      <div className="content">
                          <Input
                              type="text"
                              style={{ width: 220 }}
                              value={selectedEnergyAnalysis.inputDefineX}
                              onChange={event => {
                                  const inputDefineX = event.target.value;
                                  updateAnalysis(
                                      energyAnalysisList,
                                      {
                                          ...selectedEnergyAnalysis,
                                          inputDefineX
                                      },
                                      selectedAnalysisIndex
                                  );
                              }}
                          />
                      </div>
                  </div>
                  <div className="input-wrapper">
                      <p className="input-label"><Translate string={"ENERGY_ANALYSIS.GROUP_BY_TIME_STEP"}/>:</p>
                      <div className="content">
                          <Select
                              style={{ width: 220 }}
                              value={selectedEnergyAnalysis.selectedTimeResolution}
                              onChange={value => {
                                  const selectedTimeResolution = TIME_RESOLUTION_AGGREGATOR[value];
                                  updateAnalysis(
                                      energyAnalysisList,
                                      {
                                          ...selectedEnergyAnalysis,
                                          selectedTimeResolution
                                      },
                                      selectedAnalysisIndex
                                  );
                              }}
                          >
                              {TIME_RESOLUTION_AGGREGATOR.map((time, index) => (
                                  <Option key={index} value={index}>
                                      {time}
                                  </Option>
                              ))}
                          </Select>
                      </div>
                  </div>
                  <div className="input-wrapper ">
                      <p className="input-label"><Translate string={"ENERGY_ANALYSIS.AGGREGATE_WITH"}/>:</p>
                      <div className="content">
                          <Select
                              style={{ width: 220 }}
                              value={selectedEnergyAnalysis.aggregatorFunc}
                              onChange={value => {
                                  const aggregatorFunc = AGGREGATOR_FUNCTION[value];
                                  updateAnalysis(
                                      energyAnalysisList,
                                      {
                                          ...selectedEnergyAnalysis,
                                          aggregatorFunc
                                      },
                                      selectedAnalysisIndex
                                  );
                              }}
                          >
                              {AGGREGATOR_FUNCTION.map((time, index) => (
                                  <Option key={index} value={index}>
                                      {time}
                                  </Option>
                              ))}
                          </Select>
                      </div>
                  </div>
                  <div className="input-wrapper">
                      <p className="input-label"><Translate string={"ENERGY_ANALYSIS.PERCENTILE_THRESHOLD"}/>:</p>
                      <div className="content">
                          <Input
                              type="number"
                              style={{ width: 220 }}
                              value={selectedEnergyAnalysis.percentileThreshold}
                              onChange={event => {
                                  event.preventDefault();
                                  const percentileThreshold = event.target.value;
                                  updateAnalysis(
                                      energyAnalysisList,
                                      {
                                          ...selectedEnergyAnalysis,
                                          percentileThreshold
                                      },
                                      selectedAnalysisIndex
                                  );
                              }}
                              addonAfter={<span>%</span>}
                          />
                      </div>
                  </div>
              </div>
          );
    }
}

const mapStateToProps = state => ({
    selectedAnalysisIndex      : state.energyAnalysis.selectedAnalysisIndex,
    energyAnalysisList         : state.energyAnalysis.energyAnalysisList
});

const mapDispatchToProps = dispatch => ({
    updateAnalysis        : (analysisList,analysis,index) => dispatch(energyAnalysisActions.updateAnalysis(analysisList,analysis,index))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Aggregator);