import React, {useEffect, useState, useRef} from 'react';
import {Button, Input, Pagination, Select, Radio, Checkbox, Icon} from "antd";
import {LoaderBar} from "../AlertSetupLoader/AlertSetupLoader";

import AlertsSetupNoData from "../AlertsSetupNoData/AlertsSetupNoData";
import Modal from "../../../../elements/Modal/Modal";
import {ModalClose, TranslateText} from "../../../../config/constants";
import TriggersItem from "./TriggersItem/TriggersItem";
import {createTrigger, deleteTrigger, getTriggers, updateTrigger} from "../../../../api/alerting/alerting";
import ProbesDrawer from "./ProbesDrawer/ProbesDrawer";
import Translate from '../../../Translate/Translate';
import instance from '../../../../config/axiosConf';

import './AlertSetupTriggers.scss';

const {Option} = Select;

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

const AlertSetupTriggers = (props) => {
    const [isModalOpen, toggleModal] = useState(false);
    const [data, setData] = useState([]);
    const [trigger, setTriggers] = useState([]);
    const [editData, setEditData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [type, setType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState([]);
    const [checkedProbe, setCheckedProbe] = useState([]);
    const [isFormValid, setIsFormValid] = useState(true);
    const [search, setSearch] = useState({text: ''});
    const [isSearch, setIsSearch] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [drawer, setDrawer] = useState(false);
    const [timeCurrency, setTimeCurrency] = useState('sec');
    const [triggerType, setTriggerType] = useState([]);
    const [allProbesState, setAllProbesState] = useState([]);
    const [allDataLoggersState, setAllDataLoggersState] = useState([]);


    useEffect(() => {
        setLoader(true);
        const requests = [];
        let urlParams = new URLSearchParams();
        urlParams.append("include", "probes");

        requests.push(instance.get("/api/folders", {
            params: urlParams
        }))
        requests.push(instance.get("/api/dataloggers"))
        Promise.all(requests).then(res => {
            // eslint-disable-next-line
            setAllProbesState(res[0].data.data);
            setAllDataLoggersState(res[1].data.data);
        }).then(() => {
            getAllTriggers();
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(isSearch){
            getAllTriggers();
            setIsSearch(false);
            searchData();
            setCheckedProbe([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search, checkedProbe]);
    
    const prevType = usePrevious({triggerType});
    useEffect(() => {
        if(triggerType === 'datalogger') {
            type === 'new' ? setTriggers({...trigger, condition: '=', timed: true, value: ''}) : setEditData({...editData, condition: 'isOffline', timed: true, value: ''})
            setTimeCurrency('min');
        } else {
            type === 'new'
                ? setTriggers({...trigger, condition: [], timed: false}) 
                : prevType && prevType.triggerType.length !== 0 && setEditData({...editData, condition: [], timed: false})
        }
        prevType && prevType.triggerType.length !== 0 && setCheckedProbe([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerType])

    const getAllTriggers = (page, filters) => {
        setLoader(true);
        
        getTriggers(page, filters || {name: search.text}).then(res => {
            if (res && res.data) {
                res.data.forEach(data => {
                    let triggerableElements = [];
                    data.triggerable && data.triggerable.forEach(trigger => {
                        let findedTrigger = [];
                        if(trigger.triggerable_type === 'datalogger') {
                            
                            findedTrigger.push({
                                triggerable_name:trigger.triggerable_name,
                                triggerable_id: trigger.triggerable_id
                            })
                            triggerableElements.length === 0 ? triggerableElements = findedTrigger : triggerableElements.push(...findedTrigger);
                        }else {
                        if(trigger.triggerable_type !== 'folder' && trigger.triggerable_type === 'probe') {
                           
                            findedTrigger.push({
                                triggerable_name:trigger.triggerable_name,
                                triggerable_id: trigger.triggerable_id
                            })
                         
                            triggerableElements.length === 0 ? triggerableElements = findedTrigger : triggerableElements.push(...findedTrigger);
                        }
                    }
                    })
                    data['selectedNodes'] = triggerableElements
                })
                setData(res.data);
                setPagination(res.meta);
                setTimeout(() => {
                    setLoader(false);
                    setLoadingSearch(false);
                }, 1000)
            }
        })
    };
    const createNewTrigger = () => {
        if (trigger &&  trigger.name && trigger.name.length > 0 && trigger.condition && trigger.condition.length > 0 && ((trigger.timed && trigger.interval !== '' && trigger.interval !== undefined) || !trigger.timed)&& checkedProbe.length > 0 ) {
            setLoading(true)

            const triggerable = checkedProbe.map(probe => {
                return  {
                    'triggerable_id': probe.nodeId,
                    'triggerable_type': triggerType === 'datalogger' ? 'datalogger' : probe.nodeType ? probe.nodeType : 'probe' 
                }
            });
            const triggered = {
                name: trigger.name,
                triggerType: triggerType,
                condition: trigger.condition,
                value: parseFloat(trigger.value),
                interval: !trigger.timed ? null : timeCurrency === 'min' ? parseInt(trigger.interval)*60 : parseInt(trigger.interval),
                timed: trigger.timed
            }

            let payload = {triggerable,...triggered};
    
            createTrigger(payload)
                .then(res => {
                    if(res && res.data) {
                        toggleModal(false);
                        closeModal();
                        setTimeout(() => {
                            getAllTriggers();
                        }, 500);
                    }
                });
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } else {
            setIsFormValid(false);
        }
    };

    const updateSelectedTrigger = () => {
        if (editData && editData.name && editData.name.length > 0 && editData.condition && editData.condition.length > 0 && (editData.timed ? (editData.interval !== '' && editData.interval !== 0) : true) && checkedProbe.length > 0 ) {

            setLoading(true);

            const triggerable = checkedProbe.map(probe => {
                return  {
                    'triggerable_id': probe.nodeId || probe.id || probe.triggerable_id,
                    'triggerable_type': triggerType === 'datalogger' ? 'datalogger' : probe.nodeType ? probe.nodeType : 'probe',
                    'trigger_id': editData.id
                }
            }) || [];

            const editedData = {
                name: editData.name,
                triggerType: triggerType,
                condition: editData.condition === 'isOffline' ? '=' : editData.condition,
                value: parseFloat(editData.value),
                interval: !editData.timed ? null : timeCurrency === 'min' ? parseInt(editData.interval)*60 : parseInt(editData.interval),
                timed: editData.timed,
            }

            let payload = {triggerable,...editedData};

            updateTrigger(editData.id, payload)
                .then(() => {
                    toggleModal(false);
                    setTimeout(() => {
                        getAllTriggers();
                    }, 500);
                    closeModal()
                });
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } else {
            setIsFormValid(false);
        } 
    };

    const delTrigger = (e) => {
        setLoading(true);
        if (e) {
            deleteTrigger(e)
                .then(() => {
                    setTimeout(() => {
                        getAllTriggers();
                        setLoading(false);
                        setIsSearch(false);
                    }, 500);
                })
        }
    };

    const editTrigger = (payload) => {
        setTriggerType(payload.triggerType);
        setTimeCurrency("sec");
        
        let filteredPayload = {
            ...payload,
            timed: payload.timed,
            timeCurrency: payload.triggerType === 'datalogger' ? 'min' : 'sec',
            interval: payload.triggerType === 'datalogger' ? payload.interval / 60 : payload.interval,
            triggerable: payload.triggerable
        }
        setCheckedProbe(payload.selectedNodes)
        
        setEditData(filteredPayload);
        setTimeout(() => {
            toggleModal(true)
        }, 100);
    };
    
    const onChange = (e) => {
        setIsFormValid(true);
        type === 'new' ? setTriggers({...trigger, [e.target.name]: e.target.value}) : setEditData({...editData, [e.target.name]: e.target.value});
    };

    const onChangeTriggerType = (value) => {
        setTriggerType(value);
        setIsFormValid(true);
        
        if(type === 'edit') {
            let duration = editData.interval;
            duration = Math.round(duration / 60);
            setEditData({...editData, interval: duration});
        } 
    }

    const closeModal = () => {
        toggleModal(false);
        setEditData([]);
        setType(null);
        setTriggers([]);
        setIsFormValid(true);
        setCheckedProbe([]);
        setTriggerType([]);
    };
    const showDrawer = () => {
        setDrawer(true);
    };
    const drawerClose = () => {
        setDrawer(false);
    };

    const onCheckBoxChange = e => {
        if(triggerType === 'datalogger') {
            return true
        }
        type === 'edit' ? setEditData({...editData, [e.target.name]: e.target.checked}) :
            setTriggers({...trigger, [e.target.name]: e.target.checked});
    };

    const onChangeCurrency = e => {
        if(triggerType === 'datalogger') {
            return true
        }
        
        if (trigger.interval) {
            let duration = trigger.interval;
            duration = e.target.value === 'min' ? Math.round(duration / 60) : duration * 60;
            setTriggers({...trigger, interval: duration});
        }

        setTimeCurrency(e.target.value); 
    }

    const onChangePage = e => {
        getAllTriggers(e);
    };

    const isChange = (e) => {
        setSearch({...search, text: e.target.value});
        if (e.target.value.length === 0){
            setIsSearch(true);
        }
    };

    const searchData =()=>{
        if(search){
            setLoadingSearch(true);
            setTimeout(() => {
                getAllTriggers();
                setLoadingSearch(false);
            }, 500)
        }
    };

    return (
        <div className='alert-setup-triggers flex flex-direction-column'>
            <div
                className="alert-setup-triggers-header flex flex-justify-content-space-between flex-align-items-center">
                <div className="alert-setup-triggers-search flex flex-align-items-center flex-justify-content-space-between">
                    <Input
                        name={"unit"}
                        type={"text"}
                        placeholder={TranslateText("GLOBAL.SEARCH")}
                        autoComplete='off'
                        allowClear
                        onChange={isChange}
                        className={'alerting-search-input'}
                        onPressEnter={searchData}
                    />
                    <div className="form-input flex flex-direction-column alerting-alerts-header-cta">
                        <Button type="primary" onClick={searchData} icon="search" disabled={loadingSearch || search.text.length === 0} ><Translate string={"GLOBAL.SEARCH"}/></Button>
                    </div>
                </div>
                {loadingSearch && <div className="alerting-list-loader">
                    <Icon type="loading" style={{color: '#2980B9'}}/>
                </div>}
            </div>
            <div className="alert-setup-triggers-content">
                <div className="alerts-list">
                    {loader ?
                        <LoaderBar/> :
                        data && data.length > 0 ? data.map((item, index) => {
                                return <TriggersItem key={index}
                                              name={item.name}
                                              condition={item.condition}
                                              triggerConditionText={item.triggerable && item.triggerable.length > 0 && item.triggerable[0].triggerable_type === 'datalogger' ? 'Is offline' : ""}
                                              value={item.value}
                                              triggerType={item.triggerable && item.triggerable.length > 0 && item.triggerable[0].triggerable_type === 'datalogger' ? 'datalogger' : 'treshold'}
                                              triggerable={item.triggerable ? item.triggerable : []}
                                              selectedNodes={item.selectedNodes ? item.selectedNodes : []}
                                              interval={item.interval}
                                              itemIndex={index}
                                              total={data.length}
                                              id={item.id}
                                              onDelete={delTrigger}
                                              timed={item.timed ? true:false}
                                              editTrigger={(trigger) => {
                                                editTrigger(trigger);
                                                setType('edit')
                                              }}
                                            />}) : search.text.length !== 0 ? <div></div> :
                            <AlertsSetupNoData title={'You have not created any trigger yet'}
                                               subtitle={'Add your first trigger'}
                                               button={'Add new trigger'}
                                               isButtonClick={() => {
                                                 toggleModal(!isModalOpen);
                                                 setType('new');
                                                }}
                                               description={''}
                            />
                    }
                </div>
            </div>
            <footer className='flex'>
                <div className="footer-wrap flex-align-items-center flex-justify-content-center modal-status">
                    <div className="empty-field"/>
                    <div className="alert-setup-pagination flex flex-justify-content-center">
                        {data && data.length > 0 && <Pagination
                            defaultCurrent={1}
                            total={pagination.totalRecords}
                            onChange={onChangePage}
                            size={'small'}
                            current={pagination.currentPage}
                            defaultPageSize={pagination.perPage}
                        />}
                    </div>
                    <div className="alert-setup-action flex flex-justify-content-end">
                        <Button className="add-button btn btn-green"
                                type='button'
                                icon={'plus'}
                                onClick={() => {
                                    toggleModal(!isModalOpen);
                                    setType('new')
                                }}
                        />
                    </div>
                    <Modal isOpen={isModalOpen} toggle={toggleModal} size={'450px'}>
                        <form className="form-alerts">
                            <div className="form-wrap">
                                <div className="form-header flex flex-justify-content-space-between">
                                    <h1 className="form-header-title">
                                        {type === 'edit' ? <Translate string={"ALERTING.EDIT_TRIGGER"}/> : <Translate string={"ALERTING.NEW_TRIGGER"}/>}
                                    </h1>
                                    <ModalClose className={'modal-close'} onClick={closeModal}/>
                                </div>
                                <div className="form-body flex">
                                    <div
                                        className="form-body-wrap flex flex-align-items-center flex-justify-content-center flex-direction-column">
                                        {!isFormValid && <div className="form-input error-message">
                                            <span className="warning-text"><Translate string={"ALERTING.ALL_FIELDS_MUST_BE_FILLED"} /></span>
                                        </div> }
                                        <div className="form-input">
                                            <label><Translate string={"ALERTING.TRIGGER_NAME"}/>*</label>
                                            <Input allowClear
                                                   name={'name'}
                                                   placeholder={TranslateText("ALERTING.TRIGGER_NAME")}
                                                   value={
                                                       trigger && trigger.name ? trigger.name :
                                                           editData && editData.name ? editData.name : ''
                                                   }
                                                   onChange={onChange}
                                                   autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-input flex flex-direction-column dropdown-button">
                                            <div className="form-input-group flex flex-direction-column dropdown-button">
                                                <label><Translate string={"ALERTING.TRIGGER_TYPE"}/>*</label>
                                                <Select
                                                    name={"triggerType"}
                                                    showSearch
                                                    style={{width: 150}}
                                                    placeholder={TranslateText("ALERTING.TRIGGER_TYPE")}
                                                    optionFilterProp="children"
                                                    onChange={(value, e) => onChangeTriggerType(value, e)}
                                                    filterOption={(input, option) =>
                                                        option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    value={triggerType}
                                                >
                                                    <Option value="treshold"><Translate string={"ALERTING.TRESHOLD"}/></Option>
                                                     <Option value="datalogger"><Translate string={"GLOBAL.DATA_LOGGER"}/></Option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="form-input">
                                                <label>{triggerType === 'datalogger' ? <Translate string={"GLOBAL.DATA_LOGGERS"}/> : <Translate string={"ALERTING.NODES"}/>}*</label>
                                            <div className="flex flex-align-items-center">
                                                <div className="node-list flex flex-align-items-center">
                                                    {checkedProbe.map((node, index) => <kbd key={index}
                                                                            className={'node-item'}>{node.nodeTitle || node.triggerable_name}</kbd>)}
                                                </div>
                                                <button type={'button'} onClick={showDrawer} className={'add-new-node'}><Icon type={'plus'} /></button>
                                            </div>
                                            <ProbesDrawer 
                                                data={triggerType === 'datalogger' ? allDataLoggersState : allProbesState}
                                                isDatalogger={triggerType === 'datalogger'}
                                                visible={drawer} 
                                                onClose={drawerClose} 
                                                setCheckedProbe={setCheckedProbe}
                                                checkedProbe={checkedProbe}
                                            />
                                        </div>
                                        <div
                                            className="form-input flex flex-justify-content-space-between flex-align-items-center">
                                            <div
                                                className="form-input-group flex flex-direction-column dropdown-button">
                                                <label><Translate string={"ALERTING.CONDITION"}/>*</label>
                                                <Select
                                                    name={"condition"}
                                                    showSearch
                                                    style={{width: 150}}
                                                    placeholder={TranslateText("ALERTING.CONDITION")}
                                                    optionFilterProp="children"
                                                    onChange={(value) => onChange({target : {name : "condition", value}})}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    value={
                                                        trigger && trigger.condition ? trigger.condition :
                                                            editData && editData.condition ? editData.condition : []
                                                        }
                                                >
                                                    
                                                        {triggerType === 'datalogger' && <Option value="="><Translate string={"ALERTING.IS_OFFLINE"}/></Option>}
                                                        {triggerType === 'treshold' && <Option value=">"><Translate string={"ALERTING.IS_GREATER_THEN"}/></Option>}
                                                        {triggerType === 'treshold' && <Option value="<"><Translate string={"ALERTING.IS_LOWER_THEN"}/></Option>}
                                                        {triggerType === 'treshold' && <Option value="="><Translate string={"ALERTING.IS_EQUAL"}/></Option>}
                                                        {triggerType === 'treshold' && <Option value=">="><Translate string={"ALERTING.IS_GREATER_OR_EQUAL"}/></Option>}
                                                        {triggerType === 'treshold' && <Option value="<="><Translate string={"ALERTING.IS_LOWER_OR_EQUAL"}/></Option>}
                                                   
                                                </Select>
                                            </div>
                                            {triggerType === 'treshold' && <div className="form-input-group short-input">
                                                <label><Translate string={"ALERTING.TRESHOLD"}/></label>
                                                <Input allowClear
                                                    name={'value'}
                                                    placeholder={TranslateText("ALERTING.TRESHOLD")}
                                                    value={
                                                        trigger && trigger.value ? trigger.value :
                                                            editData ? editData.value : ''
                                                    }
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    type="number"
                                                />
                                            </div>}
                                        </div>
                                        <div className="form-input flex">
                                            <Checkbox
                                                onChange={onCheckBoxChange}
                                                name='timed'
                                                checked={
                                                    triggerType === "datalogger"
                                                        ? true 
                                                        : trigger && trigger.timed 
                                                            ? trigger.timed 
                                                            : editData && editData.timed ? editData.timed : false}
                                            ><Translate string={"ALERTING.TIMED"}/></Checkbox>
                                        </div>
                                        {((trigger && trigger.timed) || (editData && editData.timed)) && <div className="form-input flex flex-justify-content-space-between flex-align-items-center">
                                            <div className="form-input-group flex flex-direction-column duration-field">
                                                <label><Translate string={"ALERTING.DURATION"}/>*</label>
                                                <Input allowClear
                                                       name={'interval'}
                                                       placeholder={TranslateText("ALERTING.DURATION")}
                                                       value={
                                                           trigger && trigger.interval ? trigger.interval :
                                                               editData && editData.interval ? editData.interval : ''
                                                       }
                                                       onChange={onChange}
                                                       autoComplete="off"
                                                       type="number"
                                                />
                                            </div>
                                            <div className="form-input-group select-duration-time">
                                                <Radio.Group 
                                                    buttonStyle="solid" 
                                                    size='large' 
                                                    value={triggerType === 'datalogger' ? 'min' : timeCurrency}
                                                    onChange={onChangeCurrency}
                                                    name='timeCurrency'
                                                >
                                                    <Radio.Button value="sec">sec</Radio.Button>
                                                    <Radio.Button value="min">min</Radio.Button>
                                                </Radio.Group>
                                            </div>
                                        </div>}
                                        {triggerType === 'datalogger' && <div className="form-input flex">
                                            <span className="warning-message"> 
                                                <Translate string={"ALERTING.WARNING_DATALOGGER"}/>
                                            </span>
                                        </div>}
                                    </div>
                                </div>
                                <div className="form-action">
                                    {
                                        loading
                                            ? <button type='button'><Icon type="loading" style={{color: '#fff'}}/></button>
                                            : type === 'edit' ?
                                                <button type='button' onClick={updateSelectedTrigger}><Translate string={"ALERTING.UPDATE_TRIGER"}/></button> :
                                                <button type='button' onClick={createNewTrigger}>+ <Translate string={"ALERTING.NEW_TRIGGER"}/></button>
                                    }
                                </div>
                            </div>
                        </form>
                    </Modal>
                </div>
            </footer>
        </div>
    );
};

export default AlertSetupTriggers;
