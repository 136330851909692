import React from 'react';

import {Icon, Button} from "antd";
import Translate from "../../../Translate/Translate"

import './DashboardViewLayoutTemplate.scss';


const lt1 = () => (
    <svg width="133" height="65" viewBox="0 0 133 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="44" height="33" fill="#273C75" fillOpacity="0.5"/>
        <rect y="33" width="45" height="32" fill="#273C75" fillOpacity="0.5"/>
        <rect x="45" y="33" width="43" height="32" fill="#273C75" fillOpacity="0.5"/>
        <rect x="45" width="43" height="32" fill="#273C75" fillOpacity="0.5"/>
        <rect x="89" y="33" width="43" height="32" fill="#273C75" fillOpacity="0.5"/>
        <rect x="89" width="43" height="32" fill="#273C75" fillOpacity="0.5"/>
        <line y1="32.5" x2="133" y2="32.5" stroke="white"/>
        <line x1="44.5" y1="65" x2="44.5" y2="2.18557e-08" stroke="white"/>
        <line x1="88.5" y1="65" x2="88.5" y2="2.18557e-08" stroke="white"/>
    </svg>
);
const lt2 = () => (
    <svg width="133" height="65" viewBox="0 0 133 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="50" width="133" height="15" fill="#273C75" fillOpacity="0.5"/>
        <rect width="133" height="16" fill="#273C75" fillOpacity="0.5"/>
        <path d="M0 15H66V51H0V15Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M66 15H133V33H66V15Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M66 33H133V51H66V33Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M0 15.5H133" stroke="white"/>
        <path d="M0 50.5H133" stroke="white"/>
        <path d="M67 15L67 50" stroke="white"/>
        <path d="M133 33L67 33" stroke="white"/>
    </svg>

);
const lt3 = () => (
    <svg width="133" height="65" viewBox="0 0 133 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="50" width="133" height="15" fill="#273C75" fillOpacity="0.5"/>
        <rect width="133" height="16" fill="#273C75" fillOpacity="0.5"/>
        <path d="M0 15H66V51H0V15Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M66 15H133V51H66V15Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M0 15.5H133" stroke="white"/>
        <path d="M0 50.5H133" stroke="white"/>
        <path d="M67 15L67 50" stroke="white"/>
    </svg>

);
const lt4 = () => (
    <svg width="133" height="65" viewBox="0 0 133 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="50" width="133" height="15" fill="#273C75" fillOpacity="0.5"/>
        <rect width="133" height="16" fill="#273C75" fillOpacity="0.5"/>
        <path d="M0 15H44V51H0V15Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M44 15H89V51H44V15Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M89 15H133V51H89V15Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M0 15.5H133" stroke="white"/>
        <path d="M0 50.5H133" stroke="white"/>
        <path d="M44 15L44 50" stroke="white"/>
        <path d="M90 15L90 50" stroke="white"/>
    </svg>

);
const lt5 = () => (
    <svg width="133" height="65" viewBox="0 0 133 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="50" width="133" height="15" fill="#273C75" fillOpacity="0.5"/>
        <rect width="133" height="16" fill="#273C75" fillOpacity="0.5"/>
        <path d="M0 15H44.3333V51H0V15Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M45 15H133V50H45V15Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M0 15.5H133" stroke="white"/>
        <path d="M0 50.5H133" stroke="white"/>
        <path d="M44 15L44 50" stroke="white"/>
    </svg>

);
const lt6 = () => (
    <svg width="133" height="65" viewBox="0 0 133 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="50" width="133" height="15" fill="#273C75" fillOpacity="0.5"/>
        <rect width="133" height="16" fill="#273C75" fillOpacity="0.5"/>
        <path d="M0 15H133V51H0V15Z" fill="#273C75" fillOpacity="0.5"/>
        <line y1="15.5" x2="133" y2="15.5" stroke="white"/>
        <line y1="50.5" x2="133" y2="50.5" stroke="white"/>
    </svg>


);
const lt7 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="133" height="67" viewBox="0 0 133 67" fill="none">
    <rect width="133" height="67" fill="#273C75" fillOpacity="0.5"/>
    </svg>


);
const lt8 = () => (
    <svg width="133" height="67" viewBox="0 0 133 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="1" width="67" height="65" fill="#273C75" fillOpacity="0.5"/>
        <path d="M44 1H132V66H67V1Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M66 1L66.4779 66.0002" stroke="white"/>
    </svg>


);
const lt9 = () => (
    <svg width="133" height="67" viewBox="0 0 133 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="1" width="67" height="65" fill="#273C75" fillOpacity="0.5"/>
        <path d="M44 1H132V66H67V1Z" fill="#273C75" fillOpacity="0.5"/>
        <path d="M66 1L66.4779 66.0002" stroke="white"/>
        <path d="M0 33H132" stroke="white"/>
    </svg>
);


export const NewChart = (props) => {
    return (
        <div onClick={() => props.history.push("/viewers")} className={`layout-col-${props.place + 1}-6 flex flex-align-items-center flex-justify-content-center`}>
            <Button>
                <Icon type={'plus'}/> <br/>
                <Translate string={"DASHBOARD.NEW_WIDGET"}/>
            </Button>
        </div>
    )
};

export const DashboardViewTemplate = (props) => {
    return (
        <div className={props.status ? 'view-template selected' : 'view-template'} onClick={props.activeLayout}>
            <p>{props.data.name}</p>
            <Icon component={() => GetTemplateImage(props.data.meta.img)}/>
        </div>
    )
};

const GetTemplateImage = (image) => {
    switch (image) {
        case 'lt1':
            return lt1();
        case 'lt2':
            return lt2();
        case 'lt3':
            return lt3();
        case 'lt4':
            return lt4();
        case 'lt5':
            return lt5();
        case 'lt6':
            return lt6();
        case 'lt7':
            return lt7();
        case 'lt8':
            return lt8();
        case 'lt9':
            return lt9();
        default:
            return lt7();
    }
};
