import * as types from '../actions/actionTypes';

const initialState = {
  filtersList: [],
  filterName: '',
  persistentFilter: false,
  selectedFilter: 0,
  testFilterCreated : false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_FILTER_ANALYSES:
      return {
        ...state,
        filtersList: action.filtersList,
        selectedFilter: action.selectedFilter
      };
    case types.CHANGE_FILTER_NAME:
      return { ...state, filterName: action.filterName };
    case types.CHANGE_FILTER_PERSISTENT_VALUE:
      return {
        ...state,
        persistentFilter: !state.persistentFilter
      };
    case types.HANDLE_PERSISTENT_SELECTED_FITLER:
      return {
        ...state,
        selectedFilter: action.selectedFilter
      };
    case types.TEST_FILTER_CREATED:
      return {
        ...state,
        testFilterCreated: true
      }
    case types.TEST_FILTER_PLOTTED:
      return {
        ...state,
        testFilterCreated:false
      }
    default:
      return state;
  }
};
