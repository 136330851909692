import React, { Component }          from "react"
import { connect }                   from "react-redux";

import { Icon }                      from "antd";

import { InputSelect, InputText }    from "../../../../../../FormInput/FormInput";
import { globalSettingsActions }     from "../../../../../../../actions";
import Translate from "../../../../../../Translate/Translate"
import "./SidebarAcquisitionFtpFilter.scss";

class SidebarAcquisitionFtpFilter extends Component{
    state = {
        filterObject : {},
        ftpDataLoggers:[]
    };
    componentDidMount() {
        const {props: {allDataLoggers}} = this;
        let filteredDataloggers = [];
        allDataLoggers.forEach(
            (item, index) => {
                if (item.connections && item.connections.ftp) {
                    filteredDataloggers.push(item);
                }
            });
        this.setState({ftpDataLoggers: [...filteredDataloggers]});

    }
    render() {
        const {
            props: { distinctFilterParams, folderLevels },
            state: {filterObject, ftpDataLoggers},
            handleOnSubmit, onPressEnter, handleOnChange, handleOnSelectChange } = this ;
        return (
            <form className="sidebar-form-filter" onSubmit={handleOnSubmit}>
                <div className="sidebar-filters-header">
                    <div
                        className="sidebar-filters-header-wrap flex flex-justify-content-space-between flex-align-items-center">
                        <h1><Translate string={"GLOBAL.FILTERS"}/></h1>
                        <div className="sidebar-filters-header-action">
                            <button className="btn btn-filter btn-border-black" type={'submit'}>
                                <Icon type="filter" theme="filled"/><Translate string={"GLOBAL.APPLY"}/>
                            </button>
                        </div>
                    </div>

                </div>
                <div className="sidebar-filters-body">
                <InputText
                    label={<Translate string={"GLOBAL.PROBE_NAME"}/>}
                    name={'name'}
                    handleOnPressEnter={(name,value) => onPressEnter(name,value)}
                    handleOnChange={handleOnChange}
                    value={filterObject.name}
                />
                <InputSelect
                    label={<Translate string={"GLOBAL.PATH"}/>}
                    name={'folderId'}
                    onChangeSelect={(name,value) => handleOnSelectChange(name,value)}
                    optionValues={folderLevels}
                    value={filterObject.folderId ? parseInt(filterObject.folderId) : filterObject.folderId}
                />
                <InputSelect
                    label={<Translate string={"GLOBAL.UNIT"}/>}
                    name={'unit'}
                    onChangeSelect={(name,value) => handleOnSelectChange(name,value)}
                    optionValues={distinctFilterParams.unit}
                    value={filterObject.unit}
                />
                <InputSelect
                    label={<Translate string={"GLOBAL.DATA_TYPE"}/>}
                    name={'dataType'}
                    onChangeSelect={(name,value) => handleOnSelectChange(name,value)}
                    optionValues={distinctFilterParams.data_type}
                    value={filterObject.dataType}
                />
                {/*<InputText*/}
                    {/*label={<Translate string={"GLOBAL.DATA_LOGGER_NAME"}/>}*/}
                    {/*name={'dataLoggerName'}*/}
                    {/*handleOnPressEnter={(name,value) => onPressEnter(name,value)}*/}
                    {/*handleOnChange={handleOnChange}*/}
                    {/*value={filterObject.dataLoggerName}*/}
                {/*/>*/}
                <InputSelect
                    label={<Translate string={"GLOBAL.DATA_LOGGER"}/>}
                    name={'dataLoggerId'}
                    onChangeSelect={(name, value) => handleOnSelectChange(name, value)}
                    optionValues={ftpDataLoggers}
                    value={filterObject.dataLoggerId}
                />
                <InputText
                    style={{paddingRight:'40px'}}
                    label={<Translate string={"GLOBAL_SETTINGS.DATA_LOGGER_PROBE_NAME"}/>}
                    name={'dlProbeName'}
                    handleOnPressEnter={(name, value) => onPressEnter(name, value)}
                    handleOnChange={handleOnChange}
                    value={filterObject.dlProbeName}
                />
                </div>
            </form>
        )
    }

    onPressEnter = (name,value) => {

        const { props: { handleFilterFtpProbe }, state: { filterObject } } = this;
        const strValue = value ? value.toString() : value ;
        const newFilterObject = {...filterObject, [name]:strValue}

        this.setState({
            filterObject : newFilterObject
        });
        handleFilterFtpProbe(newFilterObject);
    };


    handleOnSubmit = (event) => {
        event.preventDefault();
        const { props: { handleFilterFtpProbe }, state: { filterObject } } = this;
        handleFilterFtpProbe(filterObject);
    };

    handleOnChange = (event) => {

        const { target: { name, value } } = event ;
        const { state: { filterObject } } = this ;
        this.setState({
            filterObject: {
                ...filterObject,
                [name]:value
            }
        })
    };

    handleOnSelectChange = (name,value) => {

        const { state: { filterObject } } = this ;
        this.setState({
            filterObject: {
                ...filterObject,
                [name]:value
            }
        })
    };
}

const mapStateToProps = state => ({
    distinctFilterParams : state.globalSettings.distinctFilterParams,
    folderLevels         : state.globalSettings.folderLevels,
    allDataLoggers         : state.globalSettings.allDataLoggers
});

const mapDispatchToProps = dispatch => ({
    handleFilterFtpProbe : (filterObject) => dispatch(globalSettingsActions.FilterFtp(filterObject))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarAcquisitionFtpFilter);
