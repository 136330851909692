import React, {useEffect, useState, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

import {dashboard, viewer} from "../../../../actions";

import {Icon, Button, Tooltip} from "antd";
import instance from "../../../../config/axiosConf";

import Chart from "../../../Chart/Chart"
import DateRangeSlider from "../../../DateRangeSlider/DateRangeSlider";

import './DashboardViewChartPlot.scss';
import Translate from "../../../Translate/Translate";
import moment from 'moment';

function toggleWidgetLock(widgetId, newOptions) {
    return instance.put(`/api/views/chart/${widgetId}`, newOptions);
}

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

const DashboardWidget = (props) => {

    const {viewer, dashboard, history, place, LocalDetachViewer, GetViewerSeries, UpdateChartViewer, from, to, refreshSeries, setRefreshSeries, selectWidget, layoutChanged, setLayoutChanged} = props;

    const [viewerOptions,] = useState(viewer.options ? JSON.parse(viewer.options) : {});
    
    const initializeLock = (viewerOptions.dashboardConfig && viewerOptions.dashboardConfig.length && viewerOptions.dashboardConfig.filter(dash => dash.id === dashboard.id).reduce((lock, dash) => {
        lock = dash.locked;
        return lock;
    }, false)) || false;

    const [isLocked, setLocked] = useState(initializeLock);
    const [dateRange, setDateRange] = useState({});
    const [timeout, setClickTimeout] = useState(null);
    const [localChartData, setLocalChartData] = useState([]);
    const [isUpdatedChartData, setUpdatedChartData] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const chartHolderRef = useRef(null);
    const [areDimensionsChanged,setAreDimensionsChanged] = useState({isVisibleLeftSidebar: false,isVisibleRightSidebar: false});

    const dateRangeSetter = useCallback(() => {
        if (!isLocked) {
            setDateRange({
                from: from,
                to: to
            })
        } else {
            setDateRange({
                from: viewer.from,
                to: viewer.to
            })
        }

    }, [isLocked, from, to, viewer.from, viewer.to, setDateRange]);

    useEffect(() => {
        setAreDimensionsChanged({isVisibleLeftSidebar: false, isVisibleRightSidebar: layoutChanged})
    }, [layoutChanged, setAreDimensionsChanged]);

    useEffect(() => {
        dateRangeSetter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLocked, dashboard, viewer]);



    //TODO add condition to re-fetch only on date change
    const fetchSeries = useCallback(() => {
        GetViewerSeries(viewer.id, dateRange.from, dateRange.to).then(response => {
            if (response.data && response.data.data) {
                let probesWithSeries = response.data.data;

                if (viewerOptions.probeConfig) {
                    probesWithSeries = probesWithSeries.map(probeWithSeries => {
                        const observedData = viewerOptions.probeConfig ? viewerOptions.probeConfig.find(singleConfig => singleConfig.id === probeWithSeries.id) : {};
                        probeWithSeries.options = observedData ? observedData.options : {};
                        return probeWithSeries;
                    });
                }
                const startTime = Number(moment(dateRange.from).format("x"));
                const endTime = Number(moment(dateRange.to).format("x"));
                probesWithSeries.length > 0 && probesWithSeries[0].series.unshift([startTime, null]) && probesWithSeries[0].series.push([endTime, null]);
                setLocalChartData(probesWithSeries);
                setUpdatedChartData(true);
                setTimeout(() => setLoading(false),1000);
            } else if(response.data && response.data.data === null) {
                setLocalChartData([]);
                setUpdatedChartData(true);
                setTimeout(() => setLoading(false),1000);
            }
        });

    }, [dateRange, viewer, viewerOptions, GetViewerSeries]);

    const prevDate = usePrevious(dateRange);
    
    useEffect(()=>{
        if( Object.keys(dateRange).length === 0 || Object.keys(prevDate).length === 0 || prevDate.from !== dateRange.from || prevDate.to !== dateRange.to) {
            setLoading(true);
            fetchSeries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, setLoading, fetchSeries]);

    useEffect(() => {
        if (refreshSeries) {
            setLoading(true);
            fetchSeries();
            setRefreshSeries(false);
        }
    }, [fetchSeries, refreshSeries, setRefreshSeries]);

    const handleViewerRange = (dates) => {
        const [from, to] = dates;
        setDateRange({
            from,
            to
        });
        if (isLocked && (viewer.from !== from || viewer.to !== to) ) {
            setIsSaved(true);
            props.HandleDashboardChanges(true);
        }
    };

    const detachWidget = () => {
        if (viewer.id && dashboard.id) {
            LocalDetachViewer(viewer.id, dashboard.id);
        }
    };

    const handleDoubleClick = (e) => {
        e.preventDefault();
        window.clearTimeout(timeout);
        setClickTimeout(null);

        history.push("/viewers/details?id=" + viewer.id);
    };
    const handleSingleClick = (e, id) => {
        e.preventDefault();

        if(timeout === null) {
            setClickTimeout(window.setTimeout(() => {
                setClickTimeout(null);
                selectWidget(id)
            }, 300));
        }
    };

    const handleWidgetLock = () => {

        const isDashConfigExist = viewerOptions.dashboardConfig;
        const dashConfig = isDashConfigExist ? viewerOptions.dashboardConfig : [];
        const addNewDashToConfig = [{id: dashboard.id, locked: isLocked}];

        const updateDashConfig = (config) => {
            const index = config.findIndex(dash => dash.id === dashboard.id);
            const [dash]  = config.filter(dash => dash.id === dashboard.id);
            if(dash) {
                config[index] = {id: dash.id, locked: isLocked }
            } else {
                config = [...config, ...addNewDashToConfig]
            }
            return config;
        };
        
        const newDashConfig = dashConfig.length ? updateDashConfig(dashConfig) : addNewDashToConfig;
        
        const newOptions = {options: JSON.stringify({...viewerOptions, dashboardConfig: newDashConfig})};
        setLoading(true);
        toggleWidgetLock(viewer.id, newOptions).then(() => {
            setTimeout(() => setLoading(false),1000);
        });
        setIsSaved(false);
        props.HandleDashboardChanges(false);
    };

    const handleViewerDateRangeUpdate = () => {
        isLocked 
        ? UpdateChartViewer({
            id: viewer.id,
            from: dateRange.from,
            to: dateRange.to
        }).then(updatedViewer => {
            viewer.from = updatedViewer.from;
            viewer.to = updatedViewer.to;
        }).then(() => {
            handleWidgetLock();
        })
        : handleWidgetLock()
    };

    const temporaryLock = () => {
        if(initializeLock) {
            if(isLocked) {
                setLocked(false);
                setIsSaved(true);
                props.HandleDashboardChanges(true);
            } else {
                setLocked(true);
                setIsSaved(false);
                props.HandleDashboardChanges(false);
            }
        } else {
            if(isLocked) {
                setLocked(false);
                setIsSaved(false);
                props.HandleDashboardChanges(false);
            } else {
                setLocked(true);
                setIsSaved(true);
                props.HandleDashboardChanges(true);
            }
        }
    }


    return (
        <div className={viewer.isSelected ? `dashboard-widget layout-col-${place}-6 chart-item flex flex-direction-column is-active` : `dashboard-widget layout-col-${place}-6 chart-item flex flex-direction-column`} ref={chartHolderRef}>
            <div className="dashboard-view-plot-chart-header flex flex-justify-content-space-between">
                <div onClick={() => selectWidget(viewer.id)} className={"dashboard-view-plot-chart-header-div-title"}>
                    <p className={'dashboard-view-plot-chart-header-title'}>
                        {viewer.name} - {isSaved ? <span style={{"color": "red"}}><Translate string={"GLOBAL.UNSAVED_CHANGE"}/></span> : ""}
                    </p>
                </div>
                <Button className='no-transition' type={'button'} onClick={detachWidget} >
                    <Icon type={'close'}/>
                </Button>
            </div>
            <div className="dashboard-view-plot-chart" onDoubleClick={handleDoubleClick} onClick={(event) => handleSingleClick(event, viewer.id)}>
                <Chart
                    chartData={localChartData}
                    isDashboard={true}
                    chartType={viewerOptions.chartType}
                    isLoading={isLoading}
                    setLoading={setLoading}
                    isUpdatedChartData={isUpdatedChartData}
                    setUpdateChartData={setUpdatedChartData}
                    setLayoutChanged={setLayoutChanged}
                    parent={chartHolderRef}
                    setAreDimensionsChanged={setAreDimensionsChanged}
                    areDimensionsChanged={areDimensionsChanged}
                    isAxisSplit={viewerOptions.isAxisSplit}
                    isHideYAxis={viewerOptions.isHideYAxis}
                    onHideYAxis={viewerOptions.isHideYAxis}
                />
            </div>
            <div className="dashboard-view-plot-date-range-picker flex flex-align-items-center">
                <Tooltip placement="top" title={!isLocked ? 'Unlock widget' : 'Lock Widget'}>
                    <Button type={'button'} onClick={temporaryLock} className="btn-unlock-lock-save" disabled={isLoading}>
                        <Icon className={isLocked ? "unlock-widget" : "lock-widget"}
                              type={isLoading ? 'loading' : isLocked ? 'unlock' : 'lock'}/>
                    </Button>
                </Tooltip>
                <DateRangeSlider
                    from={!isLocked ? dateRange.from : viewer.from}
                    to={!isLocked ? dateRange.to : viewer.to}
                    onChange={handleViewerRange}
                    dateRangeProps={{
                        format: 'YYYY/MM/DD HH:mm',
                        disabled: !isLocked
                    }}
                    sliderProps={{
                        disabled: !isLocked
                    }}
                    style={{
                        width: '100%',
                        marginBottom: '0px',
                        textAlign: 'center'
                    }}
                    fromDashboard={true}
                />
                <Tooltip placement="top" title={<Translate string={"GLOBAL.SAVE"}/>}>
                    <Button className="btn-unlock-lock-save" type={'button'} onClick={handleViewerDateRangeUpdate}>
                        <Icon type={'save'}/>
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    GetViewerSeries: (viewerId, from, to) => dispatch(dashboard.GetViewerSeries(viewerId, from, to)),
    UpdateChartViewer: (payload) => dispatch(viewer.updateChartViewer(payload)),
    HandleDashboardChanges: (newState) => dispatch(dashboard.HandleDashboardChanges(newState))
});

export default connect(null, mapDispatchToProps)(DashboardWidget);

DashboardWidget.propTypes = {
    viewer: PropTypes.object,
    dashboard: PropTypes.object,
    history: PropTypes.object,
    place: PropTypes.number
};
