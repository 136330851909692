import React, {useEffect, useState} from 'react';
import Translate from "../../../Translate/Translate";
import { Icon, message, Button, Checkbox, Tooltip} from "antd";
import instance from "../../../../config/axiosConf";
import {
    ArrowLeftIcon, ArrowRightIcon,
    DashboardListIcon,
    SettingsIcon,
    SaveIcon, showNotification, TranslateText, RefreshIcon
} from "../../../../config/constants";

import './ViewersDetailsHeader.scss';
import {DashboardModal} from "../../../Dashboard/DashboardModal/DashboardModal";
import DateRangeSlider from "../../../DateRangeSlider/DateRangeSlider";

function attachViewerToDashboard(viewerId, dashboardId) {
    const payload = {viewId: viewerId, dashId: dashboardId, viewType: 'chart'};
    instance.get(`/api/dashboards/${dashboardId}/views`).then(response => {
        let newSorted = [];
        if(response && response.data && response.data.data) {
            newSorted = response.data.data.map(viewer => ({viewId: viewer.id, dashId: dashboardId, viewIndex: viewer.index, viewType: "chart"}));
            newSorted.push({viewId: viewerId, dashId: dashboardId, viewIndex: response.data.data.length, viewType: "chart"})
            instance.put(`/api/dashboards/views/attach`, JSON.stringify(payload)).then(response => {
                instance.put(`/api/dashboards/views/sort`, {data: newSorted});
            })
        }
    })
};

function detachViewerFromDashboard(viewerId, dashboardId) {
    const payload = {viewId: viewerId, dashId: dashboardId, viewType: 'chart'};
    instance.put(`/api/dashboards/views/detach`, JSON.stringify(payload));
};

const ViewerDetailsHeader = (props) => {

    const {
        from, to, isVisibleLeftSidebar, toggleSidebar, toggleSettings, history, statusOfProbesList,
        setHeaderViewDetails, titleRightSideBar, probeStyleRightSideBar, treeLeftSideBar, setTo, setFrom,
        splitAxis = () => {},isAxisSplit, hideYAxis = () => {}, isHideYAxis, viewer
    } = props;
    const [isVisibleInfoModal, setVisibleInfoModal] = useState(false);
    const [isVisibleModalForDashboardList, setVisibleModalForDashboardList] = useState(false);
    const [dashboardsList, setDashboardsList] = useState([]);
    const [isOpenModal, setOpenModal] = useState(false);
    const [dashboardDropdownOptions, setDasthboardDropdownOptions] = useState(null);
    const [isCloning, setCloning] = useState(false);
    // componentDidMount
    useEffect(() => {
        instance.get('/api/dashboards?include=viewers&perPage=100').then(response => {
            if (response && response.data) {
                setDashboardsList(response.data.data);

                const dashboardOptions = generateDashboardListOptions();
                setDasthboardDropdownOptions(dashboardOptions)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dashboardsList.forEach((dashboard, index) => {
            if (props.viewerId !== undefined && dashboard.viewers && dashboard.viewers.length > 0) {
                if (dashboard.viewers.some(viewer => viewer.id === props.viewerId)) {
                    dashboardsList[index].isChecked = true;
                } else {
                    dashboardsList[index].isChecked = false;
                }
            } else {
                dashboardsList[index].isChecked = false;
            }
        });
        const dashboardOptions = generateDashboardListOptions();
        setDasthboardDropdownOptions(dashboardOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardsList.length, dashboardsList, props.viewerId]);

    const toggleModalInfo = () => {
        setVisibleInfoModal(!isVisibleInfoModal);
        setVisibleModalForDashboardList(false)

    };
    const toggleModalForDashboardsList = () => {

        if(!statusOfProbesList){
            message.error(TranslateText("VIEWERS.NO_ATTACHED_PROBES"));
            return false;
        }

        if(localStorage.getItem("dataInAppIsChanged") === "true" || localStorage.getItem("viewerYAxisIsChanged") === "true"){
            message.error(TranslateText("GLOBAL.VIEW_UNSAVE"));
            return false
        }
        if (!isVisibleModalForDashboardList) {
            const dashboardOptions = generateDashboardListOptions();
            setDasthboardDropdownOptions(dashboardOptions);
        } else {
            setDasthboardDropdownOptions(null)
        }

        setVisibleModalForDashboardList(!isVisibleModalForDashboardList);
        setVisibleInfoModal(false)
    };

    const generateDashboardListOptions = () => {

        return dashboardsList.map((dashboard, index) => {
            return (
                <li key={'dashboard_' + index} id={'dashboard_' + index}>
                    {
                        props.viewerId !== undefined && dashboard.viewers && dashboard.viewers.length > 0 ?
                            <Checkbox className={"dashboard-list-checkbox"}
                                      checked={dashboardsList[index].isChecked}
                                      onChange={(event) => handleDashboardListCheckBox(event, props.viewerId, dashboard.id, 'dashboard_' + index)}>
                                {dashboard.name}
                            </Checkbox> :
                            <Checkbox className={"dashboard-list-checkbox"}
                                      checked={dashboardsList[index].isChecked}
                                      onChange={(event) => handleDashboardListCheckBox(event, props.viewerId, dashboard.id, 'dashboard_' + index)}>
                                {dashboard.name}
                            </Checkbox>
                    }

                </li>
            );
        })
    };

    const toggleBackdrop = () => {
        if (isVisibleInfoModal)
            setVisibleInfoModal(false);
        else if (isVisibleModalForDashboardList)
            setVisibleModalForDashboardList(false);
    };

    const handleDateRangeChange = (arg) => {
        const [from, to] = arg;
        if(!statusOfProbesList){
            setFrom(from);
            setTo(to);
            return false;
        }

        if (props.propsViewerProbes.to !== to || props.propsViewerProbes.from !== from) {
            if (titleRightSideBar === false && treeLeftSideBar === false && probeStyleRightSideBar === false) {
                localStorage.setItem("dataInAppIsChanged", "true")
            }
            setHeaderViewDetails(true);
        } else {
            if (titleRightSideBar === false && treeLeftSideBar === false && probeStyleRightSideBar === false) {
                localStorage.setItem("dataInAppIsChanged", "false");
            }
            setHeaderViewDetails(false);
        }
        if(arg[0] !== props.from || arg[1] !== props.to){
            setFrom(from);
            setTo(to);
            props.refetchSeries(from, to);
        }
    };

    const saveViewerDateRange = (event) => {
        event.preventDefault();
        localStorage.setItem("dataInAppIsChanged", "false");
        props.updateViewer(from, to);
    };

    const handleDashboardListCheckBox = (event, viewerId, dashboardId, checkboxId) => {
        const checkbox = document.getElementById(checkboxId);
        let dashboardListIndex = parseInt(checkboxId.split("_")[1]);

        if (event.target.checked) {
            dashboardsList[dashboardListIndex].isChecked = true;
            attachViewerToDashboard(viewerId, dashboardId);
        } else {
            detachViewerFromDashboard(viewerId, dashboardId);
            dashboardsList[dashboardListIndex].isChecked = false;
            checkbox.style.backgroundColor = "";
        }
        const dashboardOptions = generateDashboardListOptions();
        setDasthboardDropdownOptions(dashboardOptions);
        setTimeout(() => {
            checkbox.style.backgroundColor = "";
        }, 800);
    };

    const handleClone = () => {

        if(!statusOfProbesList){
            message.error(TranslateText("VIEWERS.NO_ATTACHED_PROBES"));
            return false;
        }

        if(localStorage.getItem("dataInAppIsChanged") === "true"){
            message.error(TranslateText("GLOBAL.VIEW_UNSAVE"));
            return false
        }
        setCloning(true);
        instance.put(`api/views/clone/${props.viewerId}`).then(response => {
            if (response.data) {
                setTimeout(() => {
                    setCloning(false);
                    showNotification("success", <Translate string={"GLOBAL.VIEWER_CLONED"}/>, <Button
                        onClick={() => history.push(`/viewers/details?id=${response.data.id}`)}><Translate
                        string={"GLOBAL.GO_TO"}/> {response.data.name}</Button>)
                }, 400);
            }
        });
    };
    const handleRefresh = () => {
        if(!statusOfProbesList){
            message.error(TranslateText("VIEWERS.NO_ATTACHED_PROBES"));
            return false;
        }
        if(localStorage.getItem("dataInAppIsChanged") === "true"){
            message.error(TranslateText("GLOBAL.VIEW_UNSAVE"));
            return false
        }
        props.refetchSeries(from, to)
    }
    const toggleModal = () => {
        setOpenModal(!isOpenModal);
    };

    const handleSubmitDashboardForm = async (dashboard, CreateNewDashboard) => {
        attachViewerToDashboard(props.viewerId, dashboard.id);
        let result = await instance.get('/api/dashboards?include=viewers&perPage=100');
        let lastElement = result.data.data[result.data.data.length - 1];
        result.data.data.pop();
        result.data.data.unshift(lastElement);
        setDashboardsList(result.data.data);
        CreateNewDashboard(dashboard);
        toggleModal();
    };
    const handleXAxios = () => {
        if(!statusOfProbesList){
            message.error(TranslateText("VIEWERS.NO_ATTACHED_PROBES"));
            return false;
        }
        hideYAxis()
    }
    const handleSplitYAxes = () => {
        if(!statusOfProbesList){
            message.error(TranslateText("VIEWERS.NO_ATTACHED_PROBES"));
            return false;
        }
        splitAxis();
    }

    return (
        <>
            <span
                onClick={() => toggleBackdrop()}
                className={(isVisibleInfoModal || isVisibleModalForDashboardList) ? 'viewer-header-info-modal' : "viewer-header-info-modal js-viewer-header-info-modal-hide"}
            />
            <div className="viewer-header grid dashboard-modal-holder" id={'dashboard-modal'}>
                <DashboardModal
                    isVisible={isOpenModal}
                    closeModal={toggleModal}
                    HandleSubmitDashboardForm={handleSubmitDashboardForm}
                    lastIndex={dashboardsList.length}
                />
                <div className={'dashboard-view-header-sidebar-button'}>
                    <Button type={'button'} onClick={toggleSidebar}>
                        {isVisibleLeftSidebar ?
                            <> <ArrowLeftIcon/><ArrowLeftIcon/> <Translate string={"GLOBAL.HIDE_PROBES"}/></>
                            :
                            <> <ArrowRightIcon/><ArrowRightIcon/><Translate string={"GLOBAL.SHOW_PROBES"}/></>
                        }
                    </Button>
                </div>
                <div className='dashboard-view-info flex flex-align-items-center js-modal-dashboard-info'>
                    <Tooltip placement="top" title={<Translate string={"GLOBAL.DESCRIPTION"}/>}>
                        <Button id={"dashboard-info"} onClick={toggleModalInfo}>
                            <Icon type="info-circle"/>
                        </Button>
                    </Tooltip>
                    <p className={'viewer-name-dashboard'}>
                        {props.name}
                    </p>
                    <div
                        className={isVisibleInfoModal ? "viewer-info-description js-description-viewer-show" : "viewer-info-description"}>
                        <Button onClick={toggleModalInfo} className={'viewer-info-description-close'}><Icon type={'close'}/></Button>
                        <div className="viewer-info-description-content">
                            <h3>{props.description}</h3>
                        </div>
                    </div>
                </div>

                <DateRangeSlider from={viewer.from} to={viewer.to} showTimeShortcuts={true} onChange={handleDateRangeChange}/>

                <div className="flex flex-align-items-center dashboard-view-cta">

                    <Tooltip placement="top" title={<Translate string={isAxisSplit ? "GLOBAL.MERGE" : "GLOBAL.SPLIT"}/>}>
                        <div className="dashboard-view-cta-save">
                            <Button onClick={handleSplitYAxes}>
                                <Icon type="filter" style={{fontSize:'18px', color: '#2980B9'}} theme={isAxisSplit ? "filled" : 'outlined'}/>
                            </Button>
                        </div>
                    </Tooltip>

                    <Tooltip placement="top" title={<Translate string={isHideYAxis ? "GLOBAL.HIDE_Y_AXIS" : "GLOBAL.SHOW_Y_AXIS"}/>}>
                        <div className="dashboard-view-cta-save">
                            <Button onClick={handleXAxios}>
                                <Icon type={isHideYAxis ? "stock" : 'line-chart'} style={{fontSize:'18px', color: '#2980B9'}} />
                            </Button>
                        </div>
                    </Tooltip>

                    <Tooltip placement="top" title={<Translate string={"GLOBAL.SAVE"}/>}>
                        <div className="dashboard-view-cta-save">
                            <Button onClick={saveViewerDateRange}>
                                <SaveIcon/>
                            </Button>
                        </div>
                    </Tooltip>
                    <Tooltip placement="top" title={<Translate string={"GLOBAL.REFRESH"}/>}>
                        <Button onClick={handleRefresh}>
                            <RefreshIcon/>
                        </Button>
                    </Tooltip>

                    <Tooltip placement="top" title={<Translate string={"GLOBAL.CLONE"}/>}>
                        <Button onClick={handleClone} disabled={isCloning}>
                            {
                                !isCloning ?
                                    <Icon type={"copy"} style={{color: '#5b9dc8', fontSize: '1.2em'}}/>
                                    :
                                    <Icon type={"loading"} style={{color: '#5b9dc8', fontSize: '1.2em'}}/>
                            }
                        </Button>
                    </Tooltip>

                    <div className={isVisibleModalForDashboardList ? "viewer-cta-select-dashboard js-modal--select-dashboards" : "viewer-cta-select-dashboard"}>

                        <Tooltip placement="top" title={<Translate string={"GLOBAL.DASHBOARDS"}/>}>
                            <Button onClick={toggleModalForDashboardsList} id={'select-dashboard'}>
                                <DashboardListIcon/>
                            </Button>
                        </Tooltip>

                        <div className={"modal-for-select-dashboard"}>
                            <ul>
                                {dashboardDropdownOptions}
                                <li className={'add-new-dashboard'}>
                                    <Button className="active-global-settings" onClick={toggleModal}>
                                        <Icon type="plus" theme="outlined"/>
                                        <Translate string={"GLOBAL.NEW_DASHBOARD"}/>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <Tooltip placement="top" title={<Translate string={"GLOBAL.SETTINGS"}/>}>
                        <Button onClick={toggleSettings}>
                            <SettingsIcon/>
                        </Button>
                    </Tooltip>

                </div>
            </div>
        </>
    )
};

export default ViewerDetailsHeader;
