import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import {rootReducer} from './reducers/index';

import Login from './components/Login/Login';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import {PrivateRoute} from './components/PrivateRoute/PrivateRoute';

import {
    resetParamsForConfirmMessage,
    showConfirmMessageForModal,
} from "./config/constants";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
     applyMiddleware(thunk)
));

window.addEventListener("popstate", (e) => {
    showConfirmMessageForModal(e);
});
window.addEventListener('beforeunload', (event) => {
    window.location.pathname !== "/dashboard-view" && localStorage.setItem("dashboardViewerIsChanged","false");
    if(localStorage.getItem("dataInAppIsChanged") === "true" || localStorage.getItem("dashboardViewerIsChanged") === "true" || localStorage.getItem("dataInProbeSetupIsChanged") === "true") {
        let e = event || window.event;
        e.preventDefault();
        // For IE and Firefox prior to version 4
        if (e) {
            e.returnValue = 'Any string';
        }
        // For Safari
        return 'Any string';
    }
});

window.addEventListener("load", (e) => {
    localStorage.setItem("viewerYAxisIsChanged", "false");
    localStorage.setItem("dashboardViewerIsChanged","false");
    localStorage.setItem("dataInProbeSetupIsChanged","false");
    resetParamsForConfirmMessage()
});

const Synapsees = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" component={Login}/>
          <Route
            path="/forgot-password"
            render={props => <ForgotPassword {...props} />}
          />
            <PrivateRoute component={App} />
        </Switch>
      </Router>
    </Provider>
  );
};


ReactDOM.render(<Synapsees />, document.getElementById('root'));

serviceWorker.unregister();
