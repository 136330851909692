import React, { Component } from 'react';

import { connect } from 'react-redux';
import { userActions } from '../../actions';

import { NavLink } from 'react-router-dom';

import {Button, Input} from 'antd';

import Logo from '../../assets/img/logo-synapsees-white.png';

class ForgotPassword extends Component {
  state = {
    email: ''
  };

  render() {
    const {
      props: { history },
      state: { email },
      handleLoginInput,
      forgotPasswordUser
    } = this;

    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      history.push('./');
    }

    return (
      <div className="login-container">
        <img src={Logo} alt="" className="login-synapsees-logo" />

        <div className="login-divider" />

        <form className="flex login-form-wrapper">
          <div className="flex login-inputs-wrapper">
            <Input
              type="text"
              value={email}
              onChange={event => handleLoginInput(event.target.value, 'email')}
              className="login-input"
              placeholder={
               // <Translate string={"FORGOT_PASSWORD.EMAIL_ADDRESS"}/>
                "Email address"
              }
            />

            <span className="forgot-password-text ">
              {/*<Translate string={"FORGOT_PASSWORD.REMEMBER_PASSWORD"}/>*/}
              Remember password?
              <NavLink exact to="/login" className="margin-left-5">
                {/*<Translate string={"FORGOT_PASSWORD.CLICK_TO_LOGIN"}/>*/}
                Click to Login
              </NavLink>
            </span>
          </div>

          <Button
            type="primary"
            className="login-button"
            onClick={forgotPasswordUser}
          >
            <p>
              {/*<Translate string={"FORGOT_PASSWORD.SEND_EMAIL"}/>*/}
            Send Email
            </p>
          </Button>
        </form>
      </div>
    );
  }

  handleLoginInput = (value, type) => this.setState({ [type]: value });

  forgotPasswordUser = event => {
    const {
      state: { email },
      props: { forgotPasswordUser, history }
    } = this;

    const callBack = () => history.push('./login');

    forgotPasswordUser(email, callBack);
  };
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  forgotPasswordUser: (email, callBack) =>
    dispatch(userActions.forgotPasswordUser(email, callBack))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
