import React, {useEffect, useState} from 'react' ;
import {Icon} from "antd" ;
import Translate from "../../Translate/Translate";
import {TranslateText} from "../../../config/constants"

import {RadioBox} from "../RadioBox/RadioBox";

import './ModalContent.scss'

export const ModalContent = (props) => {

    const {onClose, show, view, forUpdate} = props;
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const options = forUpdate ? JSON.parse(view.options) : {};

    const formRef = React.createRef();
    const modalRef = React.createRef();
    const errorMessageRef = React.createRef();
    const headerText = forUpdate ? <Translate string={"GLOBAL.UPDATE_VIEWER"}/> :
        <Translate string={"VIEWERS.CREATE_NEW_VIEWER"}/>;

    const inputNameMaxLength = 40;
    const inputDescMaxLength = 1000;

    useEffect(() => {
        if (view && view.id) {
            setName(view.name);
            setDescription(view.description);

        }
    }, [view]);
    const clearState = () => {
        setName('');
        setDescription('');
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        event.persist();
        const {handleClickOnSubmit} = props;
        let obj = takeValueFromInputs(formRef, forUpdate, view);
        if (obj.name) {
            if (forUpdate)
                obj.id = view.id;
            clearState();
            handleClickOnSubmit(obj);
        } else {
            errorMessageRef.current.style.display = 'block';
        }

    };


    const handleClickOutsideModal = (event) => {
        const {onClose} = props;
        const modal = modalRef.current;
        if (!modal.contains(event.target)) {
            onClose();
        }
    };

    const takeValueFromInputs = (formRef, forUpdate, view) => {
        let options = view ? {...JSON.parse(view.options)} : {};
        let obj = {options};
        let elements = formRef.current.elements;
        for (let i = 0; i < elements.length; ++i) {
            let element = elements[i];
            let name = element.name;
            let value = element.value;

            if (name) {
                if (element.type === 'radio') {
                    if (element.checked)
                        obj.options[name] = value;


                } else
                    obj[name] = value;
            }
        }
        if (obj.name) {
            if (forUpdate)
                obj.id = view.id;
        }

        return obj
    };

    const handleOnBlurEvent = (event) => {
        event && event.preventDefault();
        const {target: {value}} = event;
        errorMessageRef.current.style.display = value ? 'none' : 'block';
    };

    const renderSubmitButton = (view) => {

        const type = view ? 'sync' : 'plus';
        const text = view ? <Translate string={"GLOBAL.UPDATE_VIEWER"}/> : <Translate string={"GLOBAL.NEW_VIEWER"}/>;
        return (
            <button type={'submit'}>
                <Icon type={type} theme="outlined"/>
                {text}
            </button>
        )
    };

    const handleOnChange = (event) => {
        let obj = takeValueFromInputs(formRef, forUpdate, view);
        if (forUpdate) {
            const {chartType, distribution} = JSON.parse(view.options);
            if(event.target.name === 'name'){
                setName(event.target.value);
            }
            if(event.target.name === 'description'){
                setDescription(event.target.value);
            }

            obj.name !== view.name || obj.description !== view.description || (chartType && chartType) !== obj.options.chartType || (distribution && distribution) !== obj.options.chartType
                ? localStorage.setItem("dataInAppIsChanged", "true")
                : localStorage.setItem("dataInAppIsChanged", "false");
            return;
        }
        if(event.target.name === 'name'){
            setName(event.target.value);
        }
        if(event.target.name === 'description'){
            setDescription(event.target.value);
        }
        obj.name !== "" || obj.description !== "" || Object.keys(obj.options).length !== 0 ?
            localStorage.setItem("dataInAppIsChanged", "true")
            : localStorage.setItem("dataInAppIsChanged", "false");
    };

    if (!show) {
        return null;
    } else {
        return (
            <div className={'backdrop flex flex-align-items-center flex-justify-content-center'}
                 onClick={handleClickOutsideModal}>
                <div className={'modal'} ref={modalRef}>
                    <div className="close-modal">
                        <Icon type="close" className='close-modal-icon' onClick={() => onClose()}/>
                    </div>
                    <h2>{headerText}</h2>
                    <form onSubmit={handleSubmit} ref={formRef}>
                        <h2><Translate string={"GLOBAL.DETAILS"}/></h2>
                        <div className="viewer-input">
                            <div className={'input-field'}>
                                <div
                                    className="input-label-with-counter flex flex-justify-content-space-between flex-align-items-center">
                                    <label>
                                        <Translate string={"GLOBAL.NAME"}/>*<span
                                        style={{display: 'none', color: 'red'}} ref={errorMessageRef}>
                                    <Translate string={"GLOBAL.ERROR_MESSAGE_NAME"}/></span>
                                    </label>
                                    <span
                                        className={name.length === inputNameMaxLength ? 'form-input-count form-input-count-error' : 'form-input-count'}>
                                    {
                                        `${name.length}/${inputNameMaxLength}`
                                    }
                                </span>
                                </div>
                                <input
                                    type={'text'}
                                    placeholder={TranslateText("GLOBAL.ENTER_THE_NAME")}
                                    name={'name'}
                                    defaultValue={view ? view.name : ''}
                                    autoComplete={'off'}
                                    maxLength={40}
                                    onBlur={handleOnBlurEvent}
                                    onChange={(event) => handleOnChange(event)}
                                />
                            </div>
                            <div className={'input-field'}>
                                <div
                                    className="input-label-with-counter flex flex-justify-content-space-between flex-align-items-center">
                                    <label><Translate string={"GLOBAL.DESCRIPTION"}/></label>
                                    <span
                                        className={description.length === inputDescMaxLength ? 'form-input-count form-input-count-error' : 'form-input-count'}>
                                    {
                                        `${description.length}/${inputDescMaxLength}`
                                    }
                                </span>
                                </div>
                                <textarea
                                    placeholder={TranslateText("GLOBAL.DESCRIPTION")}
                                    rows="3" name={'description'}
                                    defaultValue={view ? view.description : ''}
                                    autoComplete={'off'}
                                    maxLength={1000}
                                    onChange={(event) => handleOnChange(event)
                                    }
                                />
                            </div>
                        </div>
                        <h2><Translate string={"GLOBAL.SIMPLE"}/></h2>
                        <div className={'custom-checkbox'}>
                            <RadioBox type={'Scatter'} value={<Translate string={"GLOBAL.SCATTER"}/>} name={'chartType'}
                                      checked={options.chartType === "scatter"} handleChange={handleOnChange}/>
                            <RadioBox type={'Column'} value={<Translate string={"GLOBAL.COLUMN"}/>} name={'chartType'}
                                      checked={options.chartType === "column"} handleChange={handleOnChange}/>
                            <RadioBox type={'Line'} value={<Translate string={"GLOBAL.LINE"}/>} name={'chartType'}
                                      checked={options.chartType === "line" || typeof options.chartType === "undefined"} handleChange={handleOnChange}/>
                            <RadioBox type={'Area'} value={<Translate string={"GLOBAL.AREA"}/>} name={'chartType'}
                                      checked={options.chartType === "area"} handleChange={handleOnChange}/>
                            {/*<RadioBox type={'Pie'}     name={'chartType'} checked={options.chartType === "pie"}     handleChange={handleOnChange}/>*/}
                        </div>
                        <div className={'footer'}>
                            {
                                renderSubmitButton(view)
                            }
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
