import React        from 'react';
import { scatterChart, areaChart, barChart, lineChart } from '../../../config/constants';
import './RadioBox.scss'
import {Icon} from "antd";

const chartTypes = {
    scatter : scatterChart,
    area    : areaChart,
    column  : barChart,
    line    : lineChart
};
export const RadioBox = (props) => {
    const { type, name, checked, handleChange, value} = props;
    return (
        <div className={"container"}>
            <p>{value}</p>
                    <label>
                        <input
                            type={"radio"}
                            name={name.toString()}
                            value={type.toString().toLowerCase()}
                            className={"card-input-element"}
                            defaultChecked={Boolean(checked)}
                            onChange={(event) => {handleChange(event)}}
                        />
                        <div className={"card-input"}>
                            <Icon component={chartTypes[props.type.toString().toLowerCase()]}/>
                        </div>
                    </label>
        </div>
    )
}