import React from 'react';

import DateRangeSlider from "../../DateRangeSlider/DateRangeSlider";
import './FilterHeader.scss';

const FilterHeader = (props) => {
    const { refetchSeries, defaultFrom, defaultTo} = props;

    const handleDateRangeChange = (arg) => {

        let [from, to] = arg;

        props.setFrom(from);
        props.setTo(to);

        if(arg[0] !== props.from || arg[1] !== props.to){
            refetchSeries(from, to);
        }

        
    };

    return (
        <div className={'filter-view-header'}>
            <div className='filter-view-slider flex flex-align-items-center'>
                <DateRangeSlider
                    from={defaultFrom}
                    to={defaultTo}
                    showTimeShortcuts={true}
                    onChange={handleDateRangeChange}/>
            </div>
        </div>
    )
};
export default FilterHeader;
