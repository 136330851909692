import React, { Component } from "react";

import { connect } from "react-redux";
import { userActions } from "../../actions";

import { NavLink } from "react-router-dom";

import {Button, Input, Checkbox} from 'antd'

import Logo from "../../assets/img/logo-synapsees-white.png";
import "./Login.css";

export class Login extends Component {
  state = {
    email: "",
    password: "",
    saveCredential:false
  };
  render() {
    const {
      state: { email, password,saveCredential },
      handleLoginInput,
      loginUser,
    } = this;


    return (
      <div className="login-container">
        <img src={Logo} alt="" className="login-synapsees-logo" />

        <div className="login-divider" />

        <form className="flex login-form-wrapper">
          <div className="flex login-inputs-wrapper">
            <Input
              type="text"
              value={email}
              onChange={event => handleLoginInput(event.target.value, "email")}
              className="login-input"
              placeholder={
                 // <Translate string={"LOGIN.EMAIL_OR_USERNAME"}/>
                  "Email or username"
              }
            />

            <Input
              type="password"
              onPressEnter={loginUser}
              value={password}
              onChange={event =>
                handleLoginInput(event.target.value, "password")
              }
              className="login-input"
              placeholder={
                 // <Translate string={"GLOBAL.PASSWORD"}/>
                  "Password"
              }
            />
            <div>
                <Checkbox checked={saveCredential} onChange={(event) =>this.handleCheckbox(event)} className="remember-me" style={{"display":"none"}}>
                  {/*<Translate string={"LOGIN.REMEMBER_ME"} />*/}
                Remember me
                </Checkbox>
              <span className="forgot-password-text">
              <NavLink exact to="/forgot-password">
                {/*<Translate string={"LOGIN.FORGOT_PASSWORD"}/>?*/}
                Forgot password
              </NavLink>
              </span>
            </div>
          </div>

          <Button type="primary" className="login-button" onClick={loginUser}>
            <p>
              {/*<Translate string={"LOGIN.LOGIN"}/>*/}
            Login
            </p>
          </Button>
        </form>
      </div>
    );
  }

  handleLoginInput = (value, type) => this.setState({ [type]: value });
  handleCheckbox = (e) => {
     this.setState({saveCredential: e.target.checked})
  }

  loginUser = () => {
    const {
      state: { email, password },
      props: { loginUser, history,location }
    } = this;

    if (!(email && password)) {
      return;
    }
    loginUser(email, password)
        .then(() => {
          const { from } = location.state || { from: { pathname: "/" } };
          history.push(from);
        })
        .catch(e => {
        });
  };
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  loginUser: (email, password) =>
    dispatch(userActions.loginUser(email, password))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
