import React, {useState} from "react";
import Clipboard from "react-clipboard.js";

import Translate from "../../../Translate/Translate"
import "./DataSourceContextMenu.css";
import { Modal } from "antd";
import { Menu, Item, Separator } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import UploadProbe from "../../../UploadProbe/UploadProbe";
import EditDataSource from "./EditDataSource/EditDataSource";
import {Link} from "react-router-dom";

const confirm = Modal.confirm;


const DataSourceContextMenu = (props) => {

    const [visible,setVisible]       = useState(false);
    const [modalTitle,setModalTitle] = useState(null);
    const [isEditing,seTediting]     = useState(false);
    const [newValue,setNewValue]     = useState(null);

    const showModal = (title, name, props) => {
        let newName = name;
        let oldName = name;
        if (props.edit) {
            if (name.length > 40) {
                newName = name.substring(0, 40) + "...";
            }
        };
        setVisible(true) ;
        setModalTitle(`${title} ${newName}`);
        seTediting(props.edit);
        setNewValue(oldName);
        // this.setState({
        //     visible: true,
        //     modalTitle: `${title} ${newName}`,
        //     edit: props.edit,
        //     inputValue: oldName
        // });
    };

    const handleOk = (event) => {
        event.preventDefault()

        // this.setState({
        //     visible: false
        // });
        setVisible(false);

        // const {uploadProbe,clearFileList} = this.props;
        //
        // const callBack = () => {
        //     showNotification("success", "Successfully added probe", " ");
        // };

        // uploadProbe(callBack);
        // clearFileList();
    };

    const handleCancel = () => {
        // const {clearFileList}=this.props;
        // clearFileList();
        // this.setState({
        //     visible: false
        // });
        setVisible(false);
    };

    const showConfirm = (id, name) => {

        // const {
        //     props: {deleteSelectedNode}
        // } = this;

        confirm({
            title: 'Are you sure you want to delete this ?',
            okText: 'Yes',
            okType: 'danger',
            onOk() {
                deleteSelectedNode(id, name)
            },
            onCancel() {
            },
        });
    };

    const deleteSelectedNode = (id,name) => {

    };
    const component = isEditing ? <EditDataSource inputValue={newValue}  /> : <UploadProbe/>;
    return (
        <div>
            <Menu id="synDataSourceContextMenu">
                <Item><Clipboard className="clipboard" data-clipboard-text={props ? props.path : null}
                                 button-title="Copy Path"><Translate string={"GLOBAL.COPY_PATH"}/></Clipboard></Item>
                <Item onClick={() => {}}><Translate string={"GLOBAL.DUPLICATE"}/> </Item>
                <Item onClick={() => showModal("Import probes","",{edit:false})}><Translate string={"GLOBAL.IMPORT_PROBES"}/></Item>
                <Item onClick={() => showModal("Edit-", props.name, { edit: true })}><Translate string={"GLOBAL.EDIT"}/></Item>
                { !props.disabledAction ? <Item onClick={() => this.props.HandleDataStructure(props.id)}>
                    <Link to="/data-source"><Translate string={"GLOBAL.EDIT_DATA_STRUCTURE"}/></Link>
                </Item> : null
                }
                <Item onClick={() => props.exportData(props.id, props.name)}><Translate string={"GLOBAL.EXPORT_DATA"}/></Item>
                <Item onClick={() => showModal("Settings-", props.name, { edit: true })}><Translate string={"GLOBAL.SETTINGS"}/></Item>
                <Separator/>
                <Item onClick={() => showConfirm(props.id, props.name)}><Translate string={"GLOBAL.DELETE"}/></Item>
            </Menu>
            <Modal
                title={modalTitle}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                // okText={files > 0 ? "Upload" : "OK"}
                // okButtonProps={{hidden: !files.length > 0}}
            >
                {component}
            </Modal>
        </div>
    );
};

export default DataSourceContextMenu;

// class DataSourceContextMenu extends Component {
//   state = {
//     visible: false,
//     path: null,
//     modalTitle: null,
//     chartColor: "#fff",
//     edit: false,
//     inputValue: "",
//       visibleDeleteModal: false
//   };
//
//     render() {
//         const {
//             state: {visible, modalTitle, inputValue, edit},
//             props: {context, files, disabledChild},
//             showModal,
//             handleOk,
//             handleCancel
//         } = this;
//         let component ;
//         component = edit ? <EditDataSource inputValue={inputValue}  /> : <UploadProbe/>;
//
//         return (
//             <div>
//                 <Menu id="synDataSourceContextMenu">
//                     <Item><Clipboard className="clipboard" data-clipboard-text={context ? context.path : null}
//                                      button-title="Copy Path">Copy Path</Clipboard></Item>
//                     <Item onClick={() => {}}>Duplicate</Item>
//                     <Item onClick={() => showModal("Import probes","",{edit:false})}>Import probes</Item>
//                     <Item onClick={() => showModal("Edit-", context.name, { edit: true })}>Edit</Item>
//                     { !disabledChild ? <Item onClick={() => this.props.HandleDataStructure(context.id)}>
//                         <Link to="/data-source">Edit data structure</Link>
//                     </Item> : null
//                     }
//                     <Item onClick={() => this.props.exportData(context.id, context.name)}>Export data</Item>
//                     <Item onClick={() => showModal("Settings-", context.name, { edit: true })}>Settings</Item>
//                     <Separator/>
//                     <Item onClick={() => this.showConfirm(context.id, context.name)}>Delete</Item>
//                 </Menu>
//                 <Modal
//                     title={modalTitle}
//                     visible={visible}
//                     onOk={handleOk}
//                     onCancel={handleCancel}
//                     okText={files.length > 0 ? "Upload" : "OK"}
//                     okButtonProps={{hidden: !files.length > 0}}
//                 >
//                     {component}
//                 </Modal>
//             </div>
//         );
//     }
//
//   showModal = (title, name, props) => {
//     let newName = name;
//     let oldName = name;
//     if (props.edit) {
//       if (name.length > 40) {
//         newName = name.substring(0, 40) + "...";
//       }
//     }
//     this.setState({
//       visible: true,
//       modalTitle: `${title} ${newName}`,
//       edit: props.edit,
//       inputValue: oldName
//     });
//   };
//
//     handleOk = (event) => {
//         event.preventDefault()
//
//         this.setState({
//             visible: false
//         });
//         const {uploadProbe,clearFileList} = this.props;
//         const callBack = () => {
//             showNotification("success", "Successfully added probe", " ");
//         };
//         uploadProbe(callBack);
//         clearFileList();
//     };
//
//   handleCancel = () => {
//       const {clearFileList}=this.props;
//       clearFileList();
//     this.setState({
//       visible: false
//     });
//   };
//
//        showConfirm = (id, name) => {
//
//            const {
//                props: {deleteSelectedNode}
//            } = this;
//
//           confirm({
//               title: 'Are you sure you want to delete this ?',
//              okText: 'Yes',
//               okType: 'danger',
//               onOk() {
//                   deleteSelectedNode(id, name)
//               },
//               onCancel() {
//               },
//           });
//       }
// }
// const mapStateToProps = state => ({
//   context: state.tree.context,
//   files: state.uploadProbe.files,
//     loadingSpinner: state.tree.loadingSpinner
// });
//
// const mapDispatchToProps = dispatch => ({
//     deleteSelectedNode: (id, name) => dispatch(treeActions.deleteSelectedNode(id, name)),
//     HandleDataStructure: (id, name) => dispatch(dataStorage.HandleDataStructure(id, name)),
//     exportData:(id, name) => dispatch(treeActions.exportData(id, name)),
//     clearFileList: () => dispatch(clearFiles()),
//     uploadProbe: callBack => dispatch(uploadProbe(callBack))
// });
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(DataSourceContextMenu);
