import React from 'react';
import {Icon} from "antd";
import {NavLink} from "react-router-dom";

import './AlertingHeader.scss';
import DateRangeSlider from "../../DateRangeSlider/DateRangeSlider";
import moment from "moment";

import {
    BellIcon
} from "../../../config/constants";

import Translate from '../../Translate/Translate';

const AlertHeader = (props) => {

    const from = moment().subtract(1, 'month').toISOString();
    const to = moment().toISOString();


    const handleDateRangeChange = (arg) => {

        // const [from, to] = arg;

        // const payload = {
        //     from,
        //     to
        // };

    };


    return (
            <div className={"app-component-header alerting alerting-header"}>
                <div className="grid alerting-header-container">
                    <div className="flex">
                        <div className="alerting-header-title flex flex-direction-column">
                            <h4>{props.options.componentName}</h4>
                            <div>{props.options.breadcrumb}</div>
                        </div>
                    </div>
                    <div className="alerting-date-picker">
                        {props.datePicker &&  <DateRangeSlider
                            from={from}
                            to={to}
                            showTimeShortcuts={true}
                            onChange={handleDateRangeChange}
                        /> }
                    </div>
                    <div className="alerting-header-action flex flex-align-items-center flex-justify-content-end">
                        <NavLink to="/alerting/alerts"
                                 className='btn btn-global-settings active alerting-alerts'
                                 activeClassName="active-top-bar">
                            <BellIcon /> <Translate string={"ALERTING.ALERTS"}/>
                        </NavLink>

                        <NavLink to="/alerting/setup"
                                 className='btn btn-global-settings alerting-setup'
                                 activeClassName="active-top-bar">
                            <Icon type="setting" theme="filled"/> <Translate string={"ALERTING.SETUP"}/>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
};

export default AlertHeader;