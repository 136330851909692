import React, { Component }       from "react"
import { connect }                from "react-redux";
import { Icon }                   from "antd";

import { InputSelect, InputText } from "../../../../../../FormInput/FormInput";
import { globalSettingsActions }  from "../../../../../../../actions";
import Translate from "../../../../../../Translate/Translate";
import "./SidebarAcquisitionMqttFilter.scss"

class SidebarAcquisitionMqttFilter extends Component{

    state = {
        filterObject : {},
        mqttDataLoggers:[]
    };
    componentDidMount() {
        const {props: {allDataLoggers}} = this;
        let filteredDataloggers = [];
        allDataLoggers.forEach(
            (item, index) => {
                if (item.connections && item.connections.mqtt) {
                    filteredDataloggers.push(item);
                }
            });
        this.setState({mqttDataLoggers: [...filteredDataloggers]});

    }

    render() {
        const {
            props : { distinctFilterParams, folderLevels },
            state : { filterObject, mqttDataLoggers },
            handleOnSubmit, onPressEnter, handleOnSelectChange, handleOnChange } = this ;
        return (
            <form className="sidebar-form-filter" onSubmit={handleOnSubmit}>
                <div className="sidebar-filters-header">
                    <div
                        className="sidebar-filters-header-wrap flex flex-justify-content-space-between flex-align-items-center">
                        <h1><Translate string={"GLOBAL.FILTERS"}/></h1>
                        <div className="sidebar-filters-header-action">
                            <button className="btn btn-filter btn-border-black" type={'submit'}>
                                <Icon type="filter" theme="filled"/><Translate string={"GLOBAL.APPLY"}/>
                            </button>
                        </div>
                    </div>

                </div>
                <div className="sidebar-filters-body">
                    <InputText
                        label={<Translate string={"GLOBAL.PROBE_NAME"}/>}
                        name={'name'}
                        handleOnPressEnter={(name, value) => onPressEnter(name, value)}
                        handleOnChange={handleOnChange}
                        value={filterObject.name}
                    />
                    <InputSelect
                        label={<Translate string={"GLOBAL.PATH"}/>}
                        name={'folderId'}
                        onChangeSelect={(value, name) => handleOnSelectChange(value, name)}
                        optionValues={folderLevels}
                        value={filterObject.folderId ? parseInt(filterObject.folderId) : filterObject.folderId}
                    />
                    <InputSelect
                        label={<Translate string={"GLOBAL.UNIT"}/>} name={'unit'}
                        onChangeSelect={(value, name) => handleOnSelectChange(value, name)}
                        optionValues={distinctFilterParams.unit}
                        value={filterObject.unit}
                    />
                    {/*<InputText*/}
                        {/*label={<Translate string={"GLOBAL.DATA_LOGGER"}/>}*/}
                        {/*name={'dataLogger'}*/}
                        {/*handleOnPressEnter={(name, value) => onPressEnter(name, value)}*/}
                        {/*handleOnChange={handleOnChange}*/}
                        {/*value={filterObject.dataLogger}*/}
                    {/*/>*/}
                    <InputSelect
                        label={<Translate string={"GLOBAL.DATA_LOGGER"}/>}
                        name={'dataLoggerId'}
                        onChangeSelect={(name, value) => handleOnSelectChange(name, value)}
                        optionValues={mqttDataLoggers}
                        value={filterObject.dataLoggerId}
                    />
                    {/*<InputText*/}
                        {/*label={<Translate string={"GLOBAL_SETTINGS.TOKEN"}/>}*/}
                        {/*name={'token'}*/}
                        {/*handleOnPressEnter={(name, value) => onPressEnter(name, value)}*/}
                        {/*handleOnChange={handleOnChange}*/}
                        {/*value={filterObject.token}*/}
                    {/*/>*/}
                    {/*<InputText*/}
                        {/*label={<Translate string={"GLOBAL_SETTINGS.TYPE_OF_VARIABLE"}/>}*/}
                        {/*name={'typeOfVariable'}*/}
                        {/*handleOnPressEnter={(name, value) => onPressEnter(name, value)}*/}
                        {/*handleOnChange={handleOnChange}*/}
                        {/*value={filterObject.typeOfVariable}*/}
                    {/*/>*/}
                    {/*<InputText*/}
                        {/*label={<Translate string={"GLOBAL.CHANNEL"}/>}*/}
                        {/*name={'channel'}*/}
                        {/*handleOnPressEnter={(name, value) => onPressEnter(name, value)}*/}
                        {/*handleOnChange={handleOnChange}*/}
                        {/*value={filterObject.channel}*/}
                    {/*/>*/}
                    <InputText
                        style={{paddingRight:'35px'}}
                        label={<Translate string={"GLOBAL.DATA_LOGGER_PROBE_ID"}/>}
                        name={'dlProbeId'}
                        handleOnPressEnter={(name, value) => onPressEnter(name, value)}
                        handleOnChange={handleOnChange}
                        value={filterObject.dlProbeId}
                    />
                    <InputText
                        style={{paddingRight:'40px'}}
                        label={<Translate string={"GLOBAL_SETTINGS.DATA_LOGGER_PROBE_NAME"}/>}
                        name={'dlProbeName'}
                        handleOnPressEnter={(name, value) => onPressEnter(name, value)}
                        handleOnChange={handleOnChange}
                        value={filterObject.dlProbeName}
                    />
                    {/*<InputText*/}
                        {/*label={<Translate string={"GLOBAL_SETTINGS.CHANNEL_PREVIEW"}/>}*/}
                        {/*name={'channelPreview'}*/}
                        {/*handleOnPressEnter={(name, value) => onPressEnter(name, value)}*/}
                        {/*handleOnChange={handleOnChange}*/}
                        {/*value={filterObject.channelPreview}*/}
                    {/*/>*/}
                    {/*<InputText*/}
                        {/*label={<Translate string={"GLOBAL.NOTE"}/>}*/}
                        {/*name={'note'}*/}
                        {/*handleOnPressEnter={(name, value) => onPressEnter(name, value)}*/}
                        {/*handleOnChange={handleOnChange}*/}
                        {/*value={filterObject.note}*/}
                    {/*/>*/}
                </div>
            </form>
        )
    };

    onPressEnter = (name,value) => {
        const { props: { handleFilterMqttProbe }, state: { filterObject } } = this;
        const strValue = value ? value.toString() : value ;
        const newFilterObject = {...filterObject, [name]:strValue}

        this.setState({
            filterObject : newFilterObject
        });
        handleFilterMqttProbe(newFilterObject);
    };
    handleOnSubmit = (event) => {
        event.preventDefault();
        const { props: { handleFilterMqttProbe }, state: { filterObject } } = this;
        handleFilterMqttProbe(filterObject);
    };

    handleOnChange = (event) => {

        const { target: { name, value } } = event ;
        const { state: { filterObject } } = this ;
        this.setState({
            filterObject: {
                ...filterObject,
                [name]:value
            }
        })
    };

    handleOnSelectChange = (name,value) => {

        const { state: { filterObject } } = this ;
        this.setState({
            filterObject: {
                ...filterObject,
                [name]:value
            }
        })
    };
}

const mapStateToProps = state => ({
    distinctFilterParams : state.globalSettings.distinctFilterParams,
    folderLevels         : state.globalSettings.folderLevels,
    allDataLoggers          : state.globalSettings.allDataLoggers
});
const mapDispatchToProps = dispatch => ({
    handleFilterMqttProbe : (filterObject) => dispatch(globalSettingsActions.FilterMqtt(filterObject))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarAcquisitionMqttFilter);
