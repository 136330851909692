import React, { Component } from 'react';
import { connect } from 'react-redux';

import Calculator from '../EnergyCalculator/Calculator';
import Interpolator from '../EnergyInterpolator/Interpolator';
import Aggregator from '../EnergyAggregator/Aggregator';

import './EnergyAnalysisToolContainer.scss';

class EnergyAnalysisToolContainer extends Component {
  render() {
    const { renderEnergyToolComponent } = this;

    return (
      <div className="energy-tool-wrapper">{renderEnergyToolComponent()}</div>
    );
  }

  renderEnergyToolComponent = () => {
    const {
        selectedAnalysisIndex,
        energyAnalysisList
    } = this.props;

    const selectedEnergyAnalysis =
      energyAnalysisList[selectedAnalysisIndex];

    if (!selectedEnergyAnalysis) return <></>;

    switch (selectedEnergyAnalysis.value) {
      case 'CALCULATOR':
        return (
          <Calculator/>
        );
      case 'INTERPOLATOR':
        return (
          <Interpolator/>
        );
      case 'AGGREGATOR':
        return (
          <Aggregator/>
        );
      default:
        return <div />;
    }
  };
}

const mapStateToProps = state => ({
  energyAnalysisList: state.energyAnalysis.energyAnalysisList,
  selectedEnergyAnalysisNode: state.energyAnalysis.selectedEnergyAnalysisNode,
  selectedAnalysisIndex:
    state.energyAnalysis.selectedAnalysisIndex,
  selectedRange: state.energyAnalysis.energyAnalysisDateRange
});
const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyAnalysisToolContainer);
