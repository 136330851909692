import React from "react";
import "./MqttItem.scss"
import {Checkbox, Input, Select, Icon} from 'antd';
import {TemplateForMqttChennelPreviewFild} from "./TemplateForMqttChennelPrewiewField/TemplateForMqttChennelPreviewFild";
import Translate from "../../../../../Translate/Translate";
import {TranslateText} from "../../../../../../config/constants"
const Option = Select.Option;

const MqttItem =  ({ item, defaultChecked, ...props }) => {
    let dataLoggerProbeIdRefValue= null;
     function onHandleRef(selectedField) {
         if(selectedField === "input") {
             dataLoggerProbeIdRefValue.focus();
         }
     }
     return (
     <div className="mqtt-item-container">
         <div className="mqtt-item-column">
             <div className="mqtt-item-first-row">
                 <Checkbox onChange={props.HandleCheckedMqttProbe} checked={defaultChecked} />
             </div>
             <div className="mqtt-item-second-row">
                 <div style={{display: 'flex', alignItems: 'flex-start'}}>
                     <Icon type={props.expand ? 'up' : 'down'} onClick={props.onToggle}
                           style={{fontSize: '17px', paddingTop: '8px'}}/>
                 </div>
                 <div className={"mqtt-inputs"}>
                     <div className={"mqtt-inputs-first-row"}>
                         <div className="mqtt-item-third-column-wrapping-input">
                             <label className="mqtt-item-label"><Translate string={"GLOBAL.PROBE_NAME"}/></label>
                             <p className={item.name ? 'mqtt-item-paragraf' : 'no-data-label'}>{item.name ? item.name : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                         </div>
                         <div className="mqtt-item-third-column-wrapping-input">
                             <label className="mqtt-item-label"><Translate string={"GLOBAL.PATH"}/></label>
                             <p className={item.path ? 'mqtt-item-paragraf' : 'no-data-label'}>{item.path ? item.path : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                         </div>
                         <div className="mqtt-item-third-column-wrapping-input">
                             <label className="mqtt-item-label"><Translate string={"GLOBAL.UNIT"}/></label>
                             <p className={item.unit ? 'mqtt-item-paragraf' : 'no-data-label'}>{item.unit ? item.unit : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                         </div>
                         <div className="mqtt-item-third-column-wrapping-input">
                             <label className="mqtt-item-label"><Translate string={"GLOBAL.DATA_LOGGER"}/></label>
                             <Select placeholder={TranslateText("GLOBAL.DATA_LOGGER")} value={item.dlId}
                                     style={{ width: 120 }}
                                     showSearch
                                     optionFilterProp={"children"}
                                     filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                     onChange={(value) => {
                                         props.onChangeSelectOptionMqtt("dlId",value);}}>
                                 {// eslint-disable-next-line
                                     props.allDataLoggers.map((item, index) => {
                                             if(item.connections && item.connections.mqtt) {
                                                 return <Option key={`probe-dl-${index}`}
                                                                value={item.id}>{item.name}</Option>
                                             }
                                         })
                                 }
                             </Select>
                         </div>
                         <div className="mqtt-item-third-column-wrapping-input">
                             <label className="mqtt-item-label"><Translate string={"GLOBAL_SETTINGS.TOKEN"}/></label>
                             <p className={item.dataLogger && item.dataLogger.connections.mqtt ? 'mqtt-item-paragraf' : 'no-data-label'}>{item.dataLogger && item.dataLogger.connections.mqtt  ? item.dataLogger && item.dataLogger.connections.mqtt.token : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                         </div>
                         <div className="mqtt-item-third-column-wrapping-input">
                             <label className="mqtt-item-label"><Translate string={"GLOBAL_SETTINGS.TYPE_OF_VARIABLE"}/></label>
                                    <Select placeholder={TranslateText("GLOBAL.DATA_LOGGER")}  value={ (item.dataLogger && item.dataLogger.connections.mqtt && item.dataLogger && item.dataLogger.connections.mqtt.typeOfVariable) && item.dataLogger && item.dataLogger.connections.mqtt.typeOfVariable} style={{ width: 120 }}
                                             showSearch
                                             optionFilterProp={"children"}
                                             filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                             onChange={value =>  props.onChangeSelectOptionMqtt("typeOfVariable",value)}
                                             disabled={item.dataLogger && !item.dataLogger.connections.mqtt ? true : false}
                                     >
                                         <Option value="set-get">
                                             <Translate string={"GLOBAL_SETTINGS.SET_GET"}/>
                                         </Option>
                                         <Option value="set">
                                             <Translate string={"GLOBAL_SETTINGS.SET"}/>
                                         </Option>
                                         <Option value="get">
                                             <Translate string={"GLOBAL_SETTINGS.GET"}/>
                                         </Option>
                                         <Option value="">
                                             <Translate string={"GLOBAL_SETTINGS.NONE"}/>
                                         </Option>
                                     </Select>
                         </div>
                         <div className="mqtt-item-third-column-wrapping-input" style={{"display":"none"}}>
                             <label className="mqtt-item-label"><Translate string={"GLOBAL.CHANNEL"}/></label>
                             <Input type="text"
                                    name={"channel"}
                                    className="mqtt-item-input"
                                    placeholder="Channel"
                                    value={item.dataLogger && item.dataLogger.connections.mqtt && item.dataLogger.connections.mqtt.channel}
                                    onChange={props.onChangeMqtt}
                                    onBlur={props.onBlurMqtt}
                                    disabled={item.dataLogger && !item.dataLogger.connections.mqtt ? true: false}
                             />

                         </div>
                     </div>
                     {props.expand ?
                     <div className={"mqtt-inputs-secound-row"}>
                         <div className="mqtt-item-third-column-wrapping-input">
                             <label className="mqtt-item-label"><Translate string={"GLOBAL.DATA_LOGGER_PROBE_ID"}/></label>
                             <Input type="text"
                                    name={"dlProbeId"}
                                    className="mqtt-item-input"
                                    placeholder={TranslateText("GLOBAL_SETTINGS.ENTER_CUSTOM_PROBE_ID")}
                                    value={item.dlProbeId}
                                    onChange={props.onChangeMqtt}
                                    onBlur={props.onBlurMqtt}
                                    ref={(input) => { dataLoggerProbeIdRefValue=input}}
                             />
                         </div>
                         <div className="mqtt-item-third-column-wrapping-input">
                             <label className="mqtt-item-label"><Translate string={"GLOBAL_SETTINGS.DATA_LOGGER_PROBE_NAME"}/></label>
                             <Input type="text"
                                    name={"dlProbeName"}
                                    value={item.dlProbeName}
                                    className="mqtt-item-input"
                                    placeholder={TranslateText("GLOBAL_SETTINGS.ENTER_DL_PROBE_NAME")}
                                    onChange={props.onChangeMqtt}
                                    onBlur={props.onBlurMqtt}
                             />
                         </div>
                         <div className="mqtt-item-third-column-wrapping-input">
                             <label className="mqtt-item-label"><Translate string={"GLOBAL.CHANNEL"}/></label>
                             <p className={item.channelPreview && item.channelPreview ? 'mqtt-item-paragraf' : 'no-data-label'}>
                                 {item.channelPreview ?
                                     <TemplateForMqttChennelPreviewFild channelPreview={item.channelPreview}
                                        item={item.dataLogger}
                                        handleDefaultOpenTab={props.handleDefaultOpenTab}
                                        onHandleRef={onHandleRef}
                                        clipboradField={() => props.clipboradField(item.channelPreview)}
                                    />
                                     : <Translate string={"GLOBAL.NO_DATA"}/>}
                             </p>
                         </div>
                         <div className="mqtt-item-third-column-wrapping-input">
                             <label className="mqtt-item-label"><Translate string={"GLOBAL.NOTE"}/></label>
                             <Input type="text"
                                    name={"note"}
                                    value={item.dataLogger && item.dataLogger.note}
                                    className="mqtt-item-input"
                                    placeholder={TranslateText("GLOBAL_SETTINGS.ENTER_NOTE")}
                                    onChange={props.onChangeMqtt}
                                    onBlur={props.onBlurMqtt}
                                    disabled={!!(item.dataLogger && item.dataLogger.id === 0)}
                             />
                         </div>
                     </div>
                      : null }
                 </div>
             </div>
         </div>
     </div>
     )
};
export default MqttItem
