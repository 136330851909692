import React, { PureComponent } from 'react';

import Highcharts from "highcharts/highstock";

import 'highcharts/highcharts-more';
import { NoDataToDisplay } from "react-highcharts-no-data-to-display";

import applyExporting from "highcharts/modules/exporting";
import applyOffline from "highcharts/modules/offline-exporting";
import { withHighcharts }   from 'react-jsx-highstock';

import './Chart.css';
import {isJson} from "../../config/constants";
import moment from 'moment';

applyExporting(Highcharts);
applyOffline(Highcharts);
NoDataToDisplay ( Highcharts );

class Chart extends PureComponent {

    constructor(props) {
        super(props);
        
        this.setChartRef = this.setChartRef.bind(this);
        this.chartID = Math.floor(Math.random() * 100000);

        this.state = {
            chartOptions: {
                chart: {
                    ignoreHiddenSeries: false,
                    renderTo: 'chartContainer-'+this.chartID
                },
                series: [],
                title: {
                    text: ""
                },
                lang: {
                    noData: "Loading..."
                },
                xAxis:
                    [{
                        type: "datetime",
                        ordinal: false,
                        showEmpty: false,
                    }]
                ,
                legend: {
                    enabled: true
                },
                rangeSelector: false,
                credits: {
                    enabled: false
                },
                tooltip: {
                    shared: true,
                    split: false,
                    xDateFormat : '%Y/%m/%d %H:%M:%S'
                },
                exporting: {
                    enabled: !this.props.isDashboard,
                },
                time: {
                    useUTC: false
                }
            },
            onHideYAxis: this.props.isHideYAxis,
            chartRef : null
        };
    };
    componentDidMount(){
        this.setChartRef();
    }

    componentWillUnmount() {
       this.state.chartRef && this.state.chartRef.destroy()
    }

    setSeries = (series) => {

        const updatedOptions = {...this.state.chartOptions,series: series};

        if(this.props.isAxisSplit){
            this.setState({
              chartOptions : {
                  ...updatedOptions,
                  chart : {
                      ...updatedOptions.chart,
                      chartType : this.props.chartType
                  }
              }
          },() =>  {
              this.setState({chartRef: this.props.isDashboard ? new Highcharts.Chart(this.state.chartOptions) : new Highcharts.stockChart(this.state.chartOptions)});
                  if(series && series.length) {
                      this.state.chartRef.hideLoading();
                      this.props.setUpdateChartData(false);
                      this.props.setDisableProbes && this.props.setDisableProbes(false);
                  } else {
                      this.state.chartRef.hideLoading();
                      this.props.setUpdateChartData(false);
                      this.props.setDisableProbes && this.props.setDisableProbes(false);
                  }
              });
        }else {
            this.setState({
                chartOptions: {
                    ...updatedOptions,
                    chart : {
                        ...updatedOptions.chart,
                        chartType : this.props.chartType
                    }
                }
            }, () => {
                this.setState({chartRef: this.props.isDashboard ? new Highcharts.Chart(this.state.chartOptions) : new Highcharts.stockChart(this.state.chartOptions)});
                if (series && series.length) {
                    this.state.chartRef.hideLoading();
                    this.props.setUpdateChartData(false);
                    this.props.setDisableProbes && this.props.setDisableProbes(false);
                } else {
                    this.state.chartRef.hideLoading();
                    this.props.setUpdateChartData(false);
                    this.props.setDisableProbes && this.props.setDisableProbes(false);
                }
            });
        }

    };

    componentDidUpdate(prevProps, prevState , snapshot) {
        
        if(prevProps.isAxisSplit !== this.props.isAxisSplit){
            this.populateSeries(this.props.chartData);
        }
        else if(prevProps.onHideYAxis !== this.props.onHideYAxis){
            this.populateSeries(this.props.chartData);
        }
        else if (this.props.isUpdatedChartData) {
                this.populateSeries(this.props.chartData);
                this.props.setUpdateChartData(false);
        }
        if(this.props.responseHaveCome){
            const updatedOptions = {...this.state.chartOptions,lang: {noData: "No data"}};
            this.setState({
                chartOptions : {
                    ...updatedOptions
                }
            },() => {
                this.setChartRefCallback(()=> {
                    this.props.setResponseHaveCome(false);
                });
            })

        }
        // part for resizing chart
        if((this.props.areDimensionsChanged.isVisibleLeftSidebar !== prevProps.areDimensionsChanged.isVisibleLeftSidebar) ||
        (this.props.areDimensionsChanged.isVisibleRightSidebar !== prevProps.areDimensionsChanged.isVisibleRightSidebar)){
            this.state.chartRef.setSize(this.props.parent.current.clientWidth - 30);
            this.props.setLayoutChanged && this.props.setLayoutChanged(false);
            this.props.setAreDimensionsChanged({isVisibleLeftSidebar: false, isVisibleRightSidebar: false})
        }
        //set formatter on chart
        if(this.props.chartData && this.props.chartType && 'scatter' === this.props.chartType && !this.state.chartOptions.tooltip.formatter) {
            this.setState({
                chartOptions : {
                    ...this.state.chartOptions,
                    tooltip:{
                        ...this.state.chartOptions.tooltip,
                        formatter: this.setScatterTooltipFormatter()
                    }
                }
            });
        }
        if(this.props.fromAlerting && (prevProps.chartData && prevProps.chartData[0] === undefined) && (this.props.chartData && this.props.chartData[0] && this.props.chartData[0].series.length > 0)) {
            this.populateSeries(this.props.chartData);
        }
    }

    createAxis = (axisObject) => {


        return {
            id: axisObject.id,
            lineWidth: 1,
            title:
                {
                    text: axisObject.name
                },
            opposite: false,
            showEmpty: false,
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            visible: this.props.onHideYAxis
        }
    };


    prepareSeries = (dataArray ) => {

        return dataArray.map((item) => {
            
            item.options = isJson(item.options) ? JSON.parse(item.options) : item.options;

            const yAxis = item.options && item.options.yAxis && item.options.yAxis.name ? item.options.yAxis : {name: item.unit};

            if (this.props.isAxisSplit) {
                yAxis.id = item.id.toString();
            } else {
                yAxis.id = yAxis.name;
            }

            if (!this.state.chartRef.get(yAxis.id)) {
                this.state.chartRef.addAxis(this.createAxis(yAxis));
            } else {
                const updatedAxis = {
                    title:
                        {
                            text: yAxis.name
                        },
                    visible: this.props.onHideYAxis,
                    min: yAxis.min,
                    max: yAxis.max
                };
                this.state.chartRef.get(yAxis.id).update(updatedAxis, true);
            }

            const payload = {
                id: item.id,
                name: item.name,
                showInNavigator: true,
                type: this.props.chartType ?  this.props.chartType : "line",
                color: item.options && item.options.lineColor ? item.options.lineColor : null,
                data: item.series || [],
                dashStyle: item.options && item.options.lineStyle ? item.options.lineStyle : null,
                yAxis: yAxis.id,
                marker: {
                    fillColor: item.options && item.options.pointColor ? item.options.pointColor : null,
                    radius: this.props.chartType && this.props.chartType === 'line' ?  1.7  : 2,
                    enabled: true
                },
                unit: item.unit,
                tooltip: {
                    shared:true,
                    xDateFormat : '%Y/%m/%d %H:%M:%S',
                    valueDecimals: 2,
                    valueSuffix: ` ${item.unit}`,
                },
                dataGrouping: {
                    enabled : false,
                }
            };

            if (this.props.isDashboard) {
                payload.events = {
                    legendItemClick: function () {
                        return false;
                    }
                }
            }

            return payload;
        });
    };

    populateSeries = (dataArray) => {
        let noData="Loading....";
        let probes = dataArray.length !==0 && dataArray.filter((probe) => {
            let probesArrayCheck = probe.series && probe.series.filter(series => { 
                return  series.length > 0 && series[1]
            })
                return probesArrayCheck && probesArrayCheck.length !== 0           
        })
    
        //Check for probes only for Filter component
        if (this.props.checkForProbesFiltering && probes === false){
            noData = "No data"
        }
        if (probes.length === 0 || !probes){
           noData="No data"
        }
         
        const updatedOptions = {...this.state.chartOptions,lang: {noData: noData}};
            this.setState({
                chartOptions : {
                    ...updatedOptions
                }

           },() => {
                    this.setSeries(this.prepareSeries(dataArray));
               });
    };

    setChartRef() {
        const chartRef = new Highcharts.StockChart(this.state.chartOptions);
        this.setState({
            chartRef : chartRef,
        });
    }
    setChartRefCallback=(callback)=> {
        const chartRef = new Highcharts.StockChart(this.state.chartOptions);
        this.setState({
            chartRef : chartRef,
        },()=> {
            callback();
        });
    }

    setScatterTooltipFormatter() {
        return function () {
            let seriesData = [];
            if (this.point) {
                this.point.series.chart.userOptions.series.forEach(serie => {
                    if (serie.data[this.point.index] && serie.data[this.point.index][1] && Math.abs(serie.data[this.point.index][1] - this.point.y) < 6) {
                        seriesData.push({
                            color: serie.marker.fillColor ? serie.marker.fillColor : serie.color,
                            name: serie.name,
                            unit: serie.unit
                        });
                    }
                })
            } else {
                this.points.forEach(point => {
                    seriesData.push({
                        color: point.color,
                        name: '',
                        unit: ''
                    });
                })
            }
            let tooltipView = moment(this.x, 'x').format('YYYY-MM-DD HH:mm:ss') + '<br/>';
            seriesData.forEach(serieData => {
                tooltipView += `<span><span style='color:${serieData.color}'> ● </span><span>${serieData.name} : <span style='font-weight: 600'>${parseFloat(this.y).toFixed(2)} ${serieData.unit}</span></span></span><br/>`
            } )
            return tooltipView;
        }
    }
    
    render() {
        return (<div id={'chartContainer-'+this.chartID} style={{width: '100%', height: '100%', overflow: 'hidden'}}/>);
    }
}

export default withHighcharts(Chart, Highcharts);
