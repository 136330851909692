import React from 'react';
import propTypes from 'prop-types';

import {Icon, Tooltip} from 'antd';

import ValidRangeParameters from './ValidRangeParameters/ValidRangeParameters';
import ValidPercentileParameters from './ValidPercentileParameters/ValidPercentileParameters';

import './SelectedFilterContainer.scss';

import {TranslateText} from "../../../../config/constants"

const SelectedFilterContainer = (props) => {
  return (
        props.selectedFilterType ?
      <div className="flex flex-align-items-center selected-filter-container-wrapper">
        <div className="flex selected-filter-container-header">
          <p className="active-filter-name">{props.selectedFilterType.name}</p>
          <Tooltip
              title={`${props.selectedFilterType.displayName} ${TranslateText("GLOBAL.INFO")}`}
              placement="bottomRight"
          >
            <Icon type="info-circle" theme="filled"/>
          </Tooltip>
        </div>
        {props.selectedFilterType.value !== 'VALID_PERCENTILE' ? (
            <ValidRangeParameters
                filterType={props.selectedFilterType}
                parameterValueHandler={props.getParametersValue}
                selectedFilterIndex={props.selectedFilterIndex}
            />
        ) : (
            <ValidPercentileParameters
                filterType={props.selectedFilterType}
                parameterValueHandler={props.getParametersValue}
                selectedFilterIndex={props.selectedFilterIndex}
            />
        )}
      </div> : null
);
};

SelectedFilterContainer.propTypes = {
  selectedFilter: propTypes.number
};

export default SelectedFilterContainer;
