import React                           from 'react';
import {Component}                     from 'react';
import {Icon,Button,Dropdown}          from 'antd';
import {connect}                       from "react-redux";

import {DropdownMenu}                  from '../../../config/constants';
import {energyAnalysisActions}         from '../../../actions';
import Translate from "../../Translate/Translate"

import './EnergyAnalysisHeader.scss';

class EnergyAnalysisHeader extends Component {

    render() {
        const {
            addAnalysis
        } = this.props;
        return (
            <div className="header-wrapper border-bottom flex flex-align-items-center">
                <div className="tab flex flex-align-items-center">
                    <p className="header-title"><Translate string={"ENERGY_ANALYSIS.ENERGY_TOOLS"}/></p>
                    <Dropdown
                        overlay={DropdownMenu(addAnalysis)}
                        trigger={['click']}
                        placement="topRight"
                    >
                        <Button onClick={() => {}} className="no-border">
                            <Icon type="plus" theme="outlined" style={{ fontSize: '22px' }} />
                        </Button>
                    </Dropdown>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
    addAnalysis : (analysis,selectedIndex) => dispatch(energyAnalysisActions.addAnalysis(analysis,selectedIndex))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnergyAnalysisHeader);