import {jwtDecoder} from '../config/utils';
import { progressBar } from '../config/constants';

import * as types from './actionTypes'
import axios from "axios";
import {config as getConfig} from "../config/config";
import * as utils from "../config/utils";
import instance from '../config/axiosConf';
import {store} from "../index";
import Paho from "paho-mqtt";
import {notification} from "antd";
import {handleTreeData} from "./tree";
import {GetProbesForFolder, GetProbesForFTP, GetProbesForMqtt} from "./globalSettings";
import {TranslateText, linkToDownload} from "../config/constants"

const baseUrl = getConfig().API_HOST;
const instanceRefreshToken = axios.create({
    baseURL: baseUrl
});

instanceRefreshToken.defaults.headers.common['Accept'] = "application/json";
instanceRefreshToken.defaults.headers.post['Content-Type'] = "application/json";

export const loginUser = (username, password) => async (dispatch, getState) => {

    const local = localStorage.getItem('local');

    const response = await instance.post('/api/oauth/token', JSON.stringify({username, password}));
    console.log(response);

    const userInfo = jwtDecoder(response.data.access_token);
    localStorage.setItem('accessToken', response.data.access_token);
    localStorage.setItem('refreshToken', response.data.refresh_token);
    localStorage.setItem('userInfo', JSON.stringify({...userInfo, username}));
    localStorage.setItem("confirmMessageModalIsOpen", "false");
    username.indexOf("@") !== -1 ? dispatch({type:types.SET_EMAIL, email:username}) : dispatch({type:types.SET_USERNAME, username:username});

    local === null ?
        localStorage.setItem('local', 'it') :
        dispatch({
            type: types.HANDLE_LANGUAGE_PICKER,
            currentLanguage: localStorage.getItem('local')
        });


    return userInfo
};

export const forgotPasswordUser = (username, callBack) => async dispatch => {
    try {
        // const response = await apiGenerator({
        //   method: 'post',
        //   route: 'LOGIN_ROUTE',
        //   body: JSON.stringify({
        //     username,
        //     password
        //   })
        // });
        // if (response) {
        //   const userInfo = jwtDecoder(response.access_token);
        //   localStorage.setItem('accessToken', response.access_token);
        //   localStorage.setItem('refreshToken', response.refresh_token);
        //   localStorage.setItem('userInfo', JSON.stringify({...userInfo,username}));
        //   callBack && callBack();
        // }
    } catch (error) {

        //
    }
};
export const logoutUser = (history) => (dispatch, getState) => {

    const {app: {currentLanguage}} = getState();
     localStorage.clear();
    localStorage.setItem('local', currentLanguage);
     dispatch({type: types.HANDLE_USER_AUTHORIZATION, isAuthorize: false});
     dispatch({type: types.HANDLE_CLEAR_STORE});
     history.push("/login");
};
export const checkToken=(config)=>{

    if(!config.headers.Authorization){
        let refreshToken = localStorage.getItem("refreshToken");
        if(refreshToken){
             console.log("refresh token exists, fetching new access token");
            return refreshAccessToken(refreshToken,config)
        }
        store.dispatch({
            type: types.HANDLE_USER_AUTHORIZATION,
            isAuthorize:false
        });
        store.dispatch({type: types.HANDLE_CLEAR_STORE})

        return Promise.reject({description:"Refresh token don't exist. You must login again",status:401, message:"Unauthorized access" });
    }

    let decodedToken = utils.jwtDecoder(config.headers.Authorization);

    if (utils.isTokenExpired(decodedToken.exp)) {
        let refreshToken = localStorage.getItem("refreshToken");
        return refreshAccessToken(refreshToken,config)
    }

    return config;
}

const refreshAccessToken = (refreshToken,config) => {
    let refreshDecodedToken = utils.jwtDecoder(refreshToken);
    if (utils.isTokenExpired(refreshDecodedToken.exp)) {
        store.dispatch({
            type: types.HANDLE_USER_AUTHORIZATION,
            isAuthorize: false
        });
        store.dispatch({type: types.HANDLE_CLEAR_STORE})
        return Promise.reject({description:"Sorry. You must authorize again",status:401, message:"Unauthorized access" });
    }
    return instanceRefreshToken.put("/api/oauth/token/refresh", {refresh_token: refreshToken})
        .then(newToken => {
            const newUserInfo = jwtDecoder(newToken.data.access_token);
            const oldUserInfo = JSON.parse(localStorage.getItem("userInfo"));
            localStorage.setItem("accessToken", newToken.data.access_token);
            localStorage.setItem('userInfo', JSON.stringify({...oldUserInfo,...newUserInfo}));
            if (!config.headers) {
                config.headers = {}
            }
            config.headers.Authorization = newToken.data.access_token && `Bearer ${newToken.data.access_token}`;
            return config;
        }).catch(err => {
            store.dispatch({type: types.HANDLE_CLEAR_STORE});
            return Promise.reject(err)
        });
};

export const listenOnWebSocketForSeries = (seriesTopic, handlePrepareSeries, uploadCallback = null) => dispatchEvent => {
    const userInfo = localStorage.getItem("userInfo");
    const userInfoData = JSON.parse(userInfo);
    const baseUrl = getConfig().SOCKET_HOST;
    const wsProtocol = getConfig().WS_PROTOCOL;
    const rabbitUrl = `${baseUrl}`;
    const port = getConfig().PORT;

    const client = new Paho.Client(
        rabbitUrl,
        port,
        "/ws",
        "myclientid_" + parseInt(Math.random() * 100, 10)
    );
    
    handlePrepareSeries(true);
    client.onConnectionLost = responseObject => {
        console.log(responseObject);
    };
    
    const onSuccessfulConnection = () => {
        
        handlePrepareSeries(false);

        client.subscribe(`/topic/${seriesTopic}`, {qos: 0});

        uploadCallback && uploadCallback()
    };

    let options = {
        timeout: 30,
        userName: userInfoData.code + ":" + userInfoData.username,
        password: userInfoData.rmqPassword,
        keepAliveInterval: 30,
        useSSL: wsProtocol === "wss",
        onSuccess: onSuccessfulConnection,
        onFailure: message => {
            handlePrepareSeries(false);
            console.log("username",userInfoData.code + ":" + userInfoData.username);
            console.log("userInfoData.rmqPassword",userInfoData.rmqPassword);
            console.log("CONNECTION FAILURE - " + message.errorMessage);
        }
    };


    client.onMessageArrived = message => {

        let parseMessage = JSON.parse(message.payloadString);
        
        if(parseMessage.status === 'SUCCESS' && parseMessage.link) {
            const apiHost = getConfig().API_HOST;
            const downloadLink = apiHost + (parseMessage.link.replace('\\u0026', '&').replace('\u0026', '&'));

            notification.success({
                key: seriesTopic,
                message: TranslateText("GLOBAL.FOR_DOWNLOAD"),
                description: linkToDownload(downloadLink, downloadLink),
                style: {
                    width: 400,
                    wordBreak: "break-word"

                },
                duration: 0
            });
        }
    }

    client.connect(options);
}

export const listenOnWebSocket = (list, flag, uploadCallback = null) => (dispatch, getState) => {
    let userInfo = localStorage.getItem("userInfo");
    let userInfoData = JSON.parse(userInfo);
    const baseUrl = getConfig().SOCKET_HOST;
    const rabbitUrl = `${baseUrl}`;
    const port = getConfig().PORT;
    const {globalSettings: {selectedId}} = getState();
    const wsProtocol = getConfig().WS_PROTOCOL;

    const client = new Paho.Client(
        rabbitUrl,
        port,
        "/ws",
        "myclientid_" + parseInt(Math.random() * 100, 10)
    );

    client.onConnectionLost = responseObject => {
        console.log(responseObject);
    };

    const onSuccessfulConnection = () => {

        list.forEach(file => {
            let topic = `${userInfoData.username}_` + file.name.split('.')[0].toLowerCase().replace(/[^a-zA-Z0-9_]*/g, '');
            client.subscribe(`/topic/${topic}`, {qos: 0});

            uploadCallback && uploadCallback()
        });
    };


    let counterMesages = 0;

    client.onMessageArrived = message => {

        let parseMessage = JSON.parse(message.payloadString);
        let progress =  parseMessage.progress;

        if((parseMessage.import_error === 'CSV corrupt.')  && counterMesages === 0) {
            notification.error({
                key: parseMessage.fileId,
                message: `${TranslateText("GLOBAL.ERROR_MESSAGE")}. ${parseMessage.import_error}`,
                duration: 5,
                description: '',
                style: {
                    width: 400
                }
            });
            counterMesages += 1;
        }

        if(!!parseMessage.import_error &&
            typeof parseMessage.import_error === "string" &&
            (parseMessage.import_error.includes("Non existing folder"))) {
            notification.error({
                key: parseMessage.fileId,
                message: `${TranslateText("GLOBAL.ERROR_MESSAGE")}. ${parseMessage.import_error}`,
                duration: 10,
                description: '',
                style: {
                    width: 400
                }
            });
        }

        if(progress > 0) {
            notification.info({
                key: parseMessage.fileId,
                message: `${TranslateText("GLOBAL.PROCESSING_STARTED")} ${parseMessage.fileId}`,
                duration: 0,
                description: progressBar(progress),
                style: {
                    width: 400
                }
            });

            if (progress === 100) {
                setTimeout(() => {
                    notification.success({
                        key: parseMessage.fileId,
                        message: TranslateText("GLOBAL.CONGRATULATIONS"),
                        description: `${TranslateText("GLOBAL.FILES")} ${parseMessage.fileId} ${TranslateText("GLOBAL.PROCESSED")}`,
                        style: {
                            width: 400
                        },
                        duration: 5
                    });

                }, 2000);

                switch (flag) {
                    case "probeMqtt": {
                        dispatch(GetProbesForMqtt(selectedId));
                        dispatch({
                            type: types.CLEAR_PROBE_FILE_LIST,
                            files: []
                        });
                        break;
                    }
                    case "probeFtp": {
                        dispatch(GetProbesForFTP(selectedId));
                        dispatch({
                            type: types.CLEAR_PROBE_FILE_LIST,
                            files: []
                        });
                        break;
                    }
                    case "probeSetup": {
                        dispatch(GetProbesForFolder(selectedId));
                        dispatch({
                            type: types.CLEAR_PROBE_FILE_LIST,
                            files: []
                        });
                        break;
                    }
                    case "csv": {

                        dispatch({type: types.HANDLE_UPLOADING_FILELIST});
                        dispatch(handleTreeData());
                        dispatch({
                            type: types.REMOVE_FILE_LIST,
                            fileList: []
                        });
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        }
    };
    refreshAccessToken(localStorage.getItem('refreshToken'), getConfig()).then(function() {
        userInfo = localStorage.getItem("userInfo");
        userInfoData = JSON.parse(userInfo);
        let options = {
            timeout: 30,
            userName: userInfoData.code + ":" + userInfoData.username,
            password: userInfoData.rmqPassword,
            keepAliveInterval: 30,
            useSSL: wsProtocol === "wss",
            onSuccess: onSuccessfulConnection,
            onFailure: message => {
                console.log("username",userInfoData.code + ":" + userInfoData.username);
                console.log("userInfoData.rmqPassword",userInfoData.rmqPassword);
                console.log("CONNECTION FAILURE - " + message.errorMessage);
            }
        };
        client.connect(options);
    });

};
// export const refreshToken = () => async (dispatch, getState) => {
//     const token = localStorage.getItem('refreshToken');
//     localStorage.removeItem('refreshToken');
//
//     try {
//         const response = await apiGenerator({
//             method: 'post',
//             route: 'REFRESH_TOKEN',
//             body: JSON.stringify({
//                 refresh_token: token
//             })
//         });
//
//         if (response && response.access_token) {
//             localStorage.setItem('accessToken', response.access_token);
//         }
//     } catch (error) {
//
//         //
//     }
// };

// export const handleUserAuthorization = () =>
//     dispatch => {
//         dispatch(
//             {type:HANDLE_USER_AUTHORIZATION}
//         )
//     }

// export const stompConnection = (userInfo, username) =>{
//
//   const client = new Client({
//     brokerURL: "ws://localhost:15674/ws/",
//     connectHeaders: {
//       host: userInfo.code,
//       login: username,
//       passcode: userInfo.rmqPassword
//     },
//     reconnectDelay: 5000,
//     heartbeatIncoming: 4000,
//     heartbeatOutgoing: 4000
//   });
//
//
//   client.onStompError = (frame) => {
//     console.log('Broker reported error: ' + frame.headers['message']);
//     console.log('Additional details: ' + frame.body);
//   };
//
//   client.activate();
//
// };
