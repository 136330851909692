import * as types from '../actions/actionTypes';

const initialState = {

          selectAnalysisMenu    : false,
          energyAnalysisList    : [],
          energyAnalysisName    : '',
          persistent            : true,
          selectedAnalysisIndex : 0,
          yAxisName             : '' ,
          dataType              : '' ,
          unit                  : '',
          energyVec             : ''

};

export default (state = initialState, action) => {

  switch (action.type) {

      case types.ADD_ANALYSIS:
        return {
          ...state,
          energyAnalysisList    : [...state.energyAnalysisList,action.newEnergyAnalysis],
          selectedAnalysisIndex : state.energyAnalysisList.length
        };

      case types.UPDATE_ANALYSIS:
        return {
          ...state,
          energyAnalysisList    : action.energyAnalysisList,
          selectedAnalysisIndex : action.selectedAnalysisIndex
        };

      case types.REMOVE_ANALYSIS:
        return{
          ...state,
          energyAnalysisList    : action.energyAnalysisList,
          selectedAnalysisIndex : action.selectedAnalysisIndex,
        };

      case types.CHANGE_ANALYSIS_NAME:
         return {
           ...state,
           energyAnalysisName   : action.energyAnalysisName
         };

      case types.CHANGE_PERSISTENT_VALUE:
         return {
           ...state,
           persistent           : !state.persistent
         };

      case types.SELECT_ANALYSIS:
        return {
          ...state,
          selectedAnalysisIndex : action.selectedAnalysisIndex
        };

      case types.HANDLE_SELECT_ANALYSIS_MENU:
        return {
          ...state,
          selectAnalysisMenu    : true
        };

      case types.HANDLE_DESELECT_ANALYSIS_MENU:
        return {
          ...state,
          selectAnalysisMenu    : false
        }
      case types.CHANGE_Y_AXIS_NAME:
        return {
          ...state,
          yAxisName             : action.yAxisName
        };

      case types.CHANGE_DATA_TYPE:
        return {
          ...state,
          dataType              : action.dataType
        };

      case types.CHANGE_UNIT:
        return {
          ...state,
          unit                  : action.unit
        };

      case types.CHANGE_ENERGY_VECTOR:
        return {
          ...state,
          energyVec             : action.energyVec
        };

      case types.POPULATE_ANALYSIS_PARAMETERS:
        return {
          ...state,
          energyAnalysisList    : action.energyAnalysisList,
          energyAnalysisName    : action.energyAnalysisName,
          persistent            : action.persistent,
          selectedAnalysisIndex : 0,
          yAxisName             : action.yAxisName ,
          dataType              : action.dataType ,
          unit                  : action.unit,
          energyVec             : action.energyVec
        }

      default:
      return state;
  }
};
