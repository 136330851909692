import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Icon, Tabs} from "antd";

import './CreateNewDataLoggerModal.scss'
import CreateNewDataLoggerModalDetailsTab from "./CreateNewDataLoggerModalDetailsTab/CreateNewDataLoggerModalDetailsTab";
import CreateNewDataLoggerModalFtpTab from "./CreateNewDataLoggerModalFtpTab/CreateNewDataLoggerModalFtpTab";
import CreateNewDataLoggerModalMqttTab from "./CreateNewDataLoggerModalMqttTab/CreateNewDataLoggerModalMqttTab";
import Translate from "../../Translate/Translate";

import {
    clipboradField,
    resetParamsForConfirmMessage,
    chooseTabForCheck,
    confirmMessage
} from "../../../config/constants";
import {connect} from "react-redux";
import * as globalSettingActions from "../../../actions/globalSettings";

const TabPane = Tabs.TabPane;

class CreateNewDataLoggerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dlId:null,
            mqttItem: {},
            ftpItem: {},
            dataloggerItem:{},
            validatorObj: {
                datalogger:{name: null}
            },
            ftpChecked: false,
            mqttChecked: false,
            savedFtpChecked:null,
            savedMqttChecked:null,
            connections:[],
            openTabKey:"1",
            runTest: false,
            runConsumer: false,
            isDLSaved: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.editMqtt !== this.props.editMqtt){
            let item= this.props.editMqtt;
            if(prevProps.editMqtt && prevProps.editMqtt.password){
                if(prevProps.editMqtt.password !== item.password){
                    if(this.state.mqttItem && this.state.mqttItem.id){
                        this.setState({mqttItem: {...this.state.mqttItem,password:item.password}});
                        return
                    }else{
                        this.setState({mqttItem: {...this.props.editMqtt,password:item.password}});
                        return
                    }
                }
            }
            this.setState({mqttItem: item});
        }
        if(prevProps.editFtp !== this.props.editFtp){
            let item= this.props.editFtp;
            if(prevProps.editFtp && prevProps.editFtp.password){
                if(prevProps.editFtp.password !== item.password){
                    if(this.state.ftpItem && this.state.ftpItem.id){
                        this.setState({ftpItem: {...this.state.ftpItem, password:item.password}});
                        return
                    }else{
                        this.setState({ftpItem: {...this.props.editFtp, password:item.password}});
                        return
                    }
                }
            }
            this.setState({...this.state, ftpItem: item})
        }

        if(prevProps.createdDatalogger !== this.props.createdDatalogger){
            const {mqtt,ftp} =this.props.createdDatalogger.connections;
            this.setState({...this.state, ftpItem: ftp, mqttItem: mqtt,dlId:this.props.createdDatalogger.id});
        }
    }

    render() {
        const {props:{GenerateNewFtpPassword, GenerateNewMqttPassword, ExportDataLogger, createdDatalogger, chanelMessage, mqttTabKey},
               state:{runTest, mqttItem, ftpItem, validatorObj, runConsumer, dataloggerItem, savedFtpChecked, mqttChecked, ftpChecked, dlId, isDLSaved}}=this
        return (
            <div className="create-new-datalogger-modal">
                <div className={"create-new-datalogger-modal-content"}>
                    <div className="create-new-datalogger-modal-header">
                        <h1><Translate string={"GLOBAL.ADD_NEW_DATA_LOGGER"}/></h1>
                        <span className="modal-header-icon"><Icon type={'close'} onClick={() => this.handleConfirmMessage()}/></span>
                    </div>
                    <div className="create-new-datalogger-modal-card-container">
                        <Tabs defaultActiveKey={mqttTabKey || "1"}  type="card" onTabClick={(key) => this.setState({openTabKey: key})}>
                            <TabPane tab={<Translate string={"GLOBAL.DETAILS"}/>} key="1">
                                <CreateNewDataLoggerModalDetailsTab
                                onSave={this.onSave}
                                dlId={dlId}
                                onUpdate = {(name,value)=>this.onUpdate(name,value,"dataloggerItem")}
                                ftpChecked={ftpChecked}
                                mqttChecked={mqttChecked}
                                validatorObj={validatorObj.datalogger}
                                handleConnectionType={(event) => this.handleConnectionType(event,"dataloggerItem")}
                                isDLSaved={isDLSaved}
                                nameOfDL={dataloggerItem.name}
                                />
                            </TabPane>
                            <TabPane tab={<span className={!savedFtpChecked ? "tabPaneSpanDisable": "tabPaneSpanEnable"}><Translate string={"GLOBAL.FTP"}/></span>} key="2"
                                     disabled={!savedFtpChecked}>
                                {
                                    ftpItem &&
                                    <CreateNewDataLoggerModalFtpTab
                                        item={ftpItem}
                                        onUpdate={(name, value) => this.onUpdate(name, value, "ftpItem")}
                                        setGeneratedPassword={() => { GenerateNewFtpPassword(ftpItem.id)}}
                                        onSave={(e) => this.onSave(e, "ftpItem")}
                                        fieldValidator={this.fieldValidator}
                                        validatorObj={validatorObj}
                                        exportDataLoggerInfo={() => ExportDataLogger(dataloggerItem.id)}
                                        clipboradField={clipboradField}
                                        dataLogger={createdDatalogger}
                                    />
                                }
                            </TabPane>

                                <TabPane tab={<span
                                    className={!this.state.savedMqttChecked ? "tabPaneSpanDisable" : "tabPaneSpanEnable"}><Translate string={"GLOBAL.MQTT"}/></span>}
                                         key="3" disabled={!this.state.savedMqttChecked}>
                                    {
                                        mqttItem &&
                                        <CreateNewDataLoggerModalMqttTab
                                            item={mqttItem}
                                            onUpdate={(name, value) => this.onUpdate(name, value, "mqttItem")}
                                            onSave={(e) => this.onSave(e, "mqttItem")}
                                            onChangeSelectOptionMqtt={(e, options) => this.selectOptionOnMqtt(e, options)}
                                            exportDataLoggerInfo={() => ExportDataLogger(dataloggerItem.id)}
                                            generateNewMqttPassword={() => GenerateNewMqttPassword(mqttItem.id)}
                                            clipboradField={clipboradField}
                                            dataLogger={createdDatalogger}
                                            loadingStatus={runTest}
                                            consumer={(id)=>this.runConsumerAction(id)}
                                            consumerAction = {runConsumer}
                                            message={chanelMessage}
                                        />
                                    }
                                </TabPane>

                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
    onSave = async (e,tagName) => {
        const {
            state: {mqttItem,ftpItem, ftpChecked, mqttChecked, dataloggerItem, validatorObj,dlId},
            props: {createDataLogger,UpdateMqtt,UpdateFtp, CreateMqttConnection, CreateFtpConnection, DeleteMqttConnection, DeleteFtpConnection , UpdateDataLogger,allDataLoggers}} = this;
        switch (tagName) {
            case "dataloggerItemSaveAndClose":{
                let dataloogerForUpdate = {...this.state.dataloggerItem};
                let connections=[];
                if(ftpChecked && mqttChecked){
                    connections.push({type:"ftp"});
                    connections.push({type:"mqtt"});
                }
                else if(ftpChecked && mqttChecked === false){
                    connections.push({type:"ftp"});
                }
                else if(mqttChecked && ftpChecked === false){
                    connections.push({type:"mqtt"});
                }
                let duplicateDatalogger = [];
                if (allDataLoggers.length !== 0 && this.state.dataloggerItem.name) {
                    allDataLoggers.filter((item) => {
                    return item.name.toLowerCase() === this.state.dataloggerItem.name.toLowerCase()
                })};
                if(validatorObj.datalogger.name === false && (duplicateDatalogger.length === 0 || duplicateDatalogger === false)){
                    resetParamsForConfirmMessage();
                    dataloogerForUpdate.connections=connections;
                    createDataLogger(dataloogerForUpdate);
                    setTimeout(() => {
                        this.props.onClose();
                    }, 500);
                }

                if(validatorObj.datalogger && validatorObj.datalogger.name === null){
                    this.setState({validatorObj:{ datalogger: {...this.state.validatorObj.datalogger, name: true}}})
                }
                if(typeof duplicateDatalogger !== "boolean" && duplicateDatalogger && duplicateDatalogger.length !== 0){
                    this.setState({validatorObj:{ datalogger: {...this.state.validatorObj.datalogger, duplicateName: true}}})
                }

                break;
            }
            case "dataloggerItemSave" : {
                let duplicateDatalogger = [];
                if (allDataLoggers.length !== 0 && this.state.dataloggerItem.name) {
                    duplicateDatalogger = allDataLoggers.filter(item => {
                    return item.name.toLowerCase() === this.state.dataloggerItem.name.toLowerCase()
                })};
                if(validatorObj.datalogger.name === false && (duplicateDatalogger.length === 0 || duplicateDatalogger === false)){
                    let dataloogerForUpdate = {...this.state.dataloggerItem};
                    let connections=[];
                    if(ftpChecked && mqttChecked){
                        connections.push({type:"ftp"});
                        connections.push({type:"mqtt"});
                        this.setState({savedFtpChecked: ftpChecked, savedMqttChecked: mqttChecked});
                    }
                    else if(ftpChecked && mqttChecked === false) {
                        connections.push({type: "ftp"});
                        this.setState({savedFtpChecked: ftpChecked, savedMqttChecked: null})
                    }
                    else if(mqttChecked && ftpChecked === false){
                        connections.push({type:"mqtt"});
                        this.setState({savedMqttChecked: mqttChecked, savedFtpChecked:null})
                    }
                    else {
                        this.setState({savedFtpChecked: null, savedMqttChecked: null})
                    }

                    resetParamsForConfirmMessage();
                    dataloogerForUpdate.connections=connections;
                    createDataLogger(dataloogerForUpdate).then(response => {
                        this.setState({
                            ...this.state,
                            dataloggerItem : {...this.state.dataloggerItem,id : response},
                            validatorObj:{ datalogger: {...this.state.validatorObj.datalogger, duplicateName: false}},
                            isDLSaved: true
                        })
                    });
                }
                if(validatorObj.datalogger && validatorObj.datalogger.name === null){
                    this.setState({validatorObj:{ datalogger: {...this.state.validatorObj.datalogger, name: true}}})
                }
                if(typeof duplicateDatalogger !== "boolean" && duplicateDatalogger && duplicateDatalogger.length !== 0){
                    this.setState({validatorObj:{ datalogger: {...this.state.validatorObj.datalogger, duplicateName: true}}})
                }
                break;
            }
            case "dataloggerItemUpdate": {

                let duplicateDatalogger = allDataLoggers.length !== 0 && allDataLoggers.filter((item) => {
                    if(allDataLoggers[0].name.toLowerCase() !== this.state.dataloggerItem.name.toLowerCase()) {
                        return item.name.toLowerCase() === this.state.dataloggerItem.name.toLowerCase()
                    }
                    return false;
                });
                let createFtpPromise,deleteFtpPromise, createMqttPromise, deleteMqttPromise;
                if(validatorObj.datalogger.name === false && (duplicateDatalogger.length === 0 || duplicateDatalogger === false)) {
                    if (ftpChecked && !ftpItem) {
                        createFtpPromise = CreateFtpConnection({dlId: dlId})
                    } else if (!ftpChecked && ftpItem) {
                        deleteFtpPromise = DeleteFtpConnection(ftpItem.id)
                    }
                    if (mqttChecked && !mqttItem) {
                        createMqttPromise = CreateMqttConnection({dlId: dlId})
                    } else if (!mqttChecked && mqttItem) {
                        deleteMqttPromise = DeleteMqttConnection(mqttItem.id)
                    }
                    Promise.all([createFtpPromise, createMqttPromise, deleteFtpPromise, deleteMqttPromise]).then((data) => {
                        let dataloogerForUpdate = {...dataloggerItem, id: dlId};
                        let connections = {mqtt: mqttItem, ftp: ftpItem};
                        if (data[0] && data[0].data.data.connections.ftp) {
                            this.setState({ftpItem: data[0].data.data.connections.ftp, savedFtpChecked: true});
                            connections = {...connections, ftp: data[0].data.data.connections.ftp}
                        }
                        if (data[1] && data[1].data.data.connections.mqtt) {
                            this.setState({mqttItem: data[1].data.data.connections.mqtt, savedMqttChecked: true});
                            connections = {...connections, mqtt: data[1].data.data.connections.mqtt}
                        }

                        if (data[2]) {
                            this.setState({ftpItem: {}, savedFtpChecked: false});
                            connections = {...connections, ftp: null}
                        }
                        if (data[3]) {
                            connections = {...connections, mqtt: null}
                            this.setState({mqttItem: {}, savedMqttChecked: false});
                        }
                        resetParamsForConfirmMessage();
                        dataloogerForUpdate.connections = connections;
                        UpdateDataLogger(dataloogerForUpdate);
                        this.setState({validatorObj:{ datalogger: {...this.state.validatorObj.datalogger, duplicateName: false}}})
                    });
                }
                if(validatorObj.datalogger && (validatorObj.datalogger.name === null || validatorObj.datalogger.name)){
                    this.setState({validatorObj:{ datalogger: {...this.state.validatorObj.datalogger, name: true, duplicateName:false}}});
                }

                if(typeof duplicateDatalogger !== "boolean" && duplicateDatalogger.length !== 0){
                    this.setState({validatorObj:{ datalogger: {...this.state.validatorObj.datalogger, duplicateName: true}}})
                }
                break;
            }
            case "mqttItem": {
                resetParamsForConfirmMessage();
                UpdateMqtt(mqttItem);
                setTimeout(() => {
                    this.props.onClose();
                }, 500);
                break;
            }
            case "ftpItem":{
                resetParamsForConfirmMessage();
                if(ftpItem && ftpItem.id) {
                    UpdateFtp(ftpItem);
                    setTimeout(() => {
                        this.props.onClose();
                    }, 500);
                }
                break;
            }
            default:
            {
                break;
            }
        }

    };

    fieldValidator = (obj) => {
        if(obj.name === "ip"){
            let reg=/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
            if(reg.test(obj.value) === false) {
                let newValidator = {...this.state.validatorObj, ip: true}
                this.setState({
                    validatorObj: newValidator
                });
                return true;
            }
            let newValidator = {...this.state.validatorObj, ip: false}
            this.setState({
                validatorObj: newValidator
            })
        }
    };
    onUpdate = (name,value,tabName) => {
        let items = this.state;
        items[tabName] = {...items[tabName],[name]: value };
        this.setState({
            [tabName]: items[tabName]
        }, () =>{this.handleDoesSomeValueInFieldChange(name,value,tabName);});
        if(name === "name") {
            value.length !== 0 ?
                this.setState({validatorObj: {datalogger: {...this.state.validatorObj.datalogger, name: false}}})
                :
                this.setState({validatorObj: {datalogger: {...this.state.validatorObj.datalogger, name: true}}})

        }

    };

    handleDoesSomeValueInFieldChange = (name,value,tabName) => {

        if(this.props.createdDatalogger !== null){
            const {createdDatalogger:dataModal,createdDatalogger:{connections:{mqtt: editMqtt}},createdDatalogger:{connections:{ftp: editFtp}}} = this.props;
            chooseTabForCheck(tabName,name,value,this.state,{dataModal, editMqtt, editFtp});
            return;
        }
        if(tabName && this.props.createdDatalogger === null) {
            this.state[tabName].name
            || this.state[tabName].description
            || this.state[tabName].responsibility
            || this.state[tabName].when
            || this.state[tabName].where
            || this.state[tabName].serialNumber
            || this.state[tabName].brand
            || this.state["ftpChecked"]
            || this.state["mqttChecked"]
                ? localStorage.setItem("dataInAppIsChanged","true")
                : localStorage.setItem("dataInAppIsChanged","false");
        }
    };

    handleConnectionType = (event,tabName) => {
        const checked = event.target.checked;
        let inputName = event.target.name;
        inputName += "Checked";

        this.setState({
            [inputName] : checked
        }, () => {
            this.handleDoesSomeValueInFieldChange(inputName,checked,tabName)
        });

    }
    selectOptionOnMqtt = (name,value) => {
        this.onUpdate(name,value,"mqttItem")
    };
    handleConfirmMessage = () => {
        const {props:{onClose}}=this;
        localStorage.getItem("dataInAppIsChanged") === "true" ? confirmMessage(onClose) : onClose();
    }
}

CreateNewDataLoggerModal.propTypes = {
    onClose: PropTypes.func.isRequired
};
const mapStateToProps= (state) => ({
    allDataLoggers    : state.globalSettings.allDataLoggers,
    editMqtt    : state.globalSettings.editMqtt,
    editFtp     : state.globalSettings.editFtp,
    createdDatalogger  : state.globalSettings.createdDatalogger,
    chanelMessage     : state.globalSettings.chanelMessage
});

const mapDispatchToProps = dispatch => ({
    ExportDataLogger        : (dataLoggerId) => dispatch(globalSettingActions.ExportDataLogger(dataLoggerId)),
    GenerateNewMqttPassword : (mqttId) => dispatch(globalSettingActions.GenerateNewMqttPassword(mqttId)),
    GenerateNewFtpPassword : (ftpId) => dispatch(globalSettingActions.GenerateNewFtpPassword(ftpId)),
    UpdateMqtt   : (mqtt) => dispatch(globalSettingActions.UpdateMqtt(mqtt)),
    UpdateFtp   : (ftp) => dispatch(globalSettingActions.UpdateFtp(ftp)),
    UpdateDataLogger: (itemObj) => dispatch(globalSettingActions.UpdateDataLogger(itemObj)),
    CreateMqttConnection: (payload) => dispatch(globalSettingActions.CreateMqttConnection(payload)),
    CreateFtpConnection: (payload) => dispatch(globalSettingActions.CreateFtpConnection(payload)),
    DeleteMqttConnection: (payload) => dispatch(globalSettingActions.DeleteMqttConnection(payload)),
    DeleteFtpConnection: (payload) => dispatch(globalSettingActions.DeleteFtpConnection(payload)),
    actionConsumer: (status, id) => dispatch(globalSettingActions.actionConsumer(status, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewDataLoggerModal);
