import React, { useState } from "react";

import { TabBarContext } from "./TabBarContext";

const TabBarProvider = TabBarContext.Provider;

export const TabBar = ({ initialTab, children }) => {
    const [tab, setTab] = useState(initialTab);
    return (
        <TabBarProvider value={{ activeTab: tab, setTab }}>
            {children}
        </TabBarProvider>
    );
};
