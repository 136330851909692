import React from "react"

import {DatePicker, Input, Checkbox, Icon, Button, Tooltip} from "antd";
import "./CreateNewDataLoggerModalDetailsTab.scss"
import  Translate  from '../../../Translate/Translate';
import moment from "moment"

const CreateNewDataLoggerModalDetailsTab = (props) => {
    return(
        <React.Fragment>
            <div className="modal-form-datalogger">
                <div className="row-form flex-justify-content-center">
                    <div className="modal-form-column flex flex-direction-column">
                        <label className="modal-form-label">
                            <Translate string={'GLOBAL.NAME'}/> <span>*</span> 
                            <span className={props.validatorObj.name || props.validatorObj.duplicateName ? "errMessage" : "disableErrorMessage"}> 
                                {props.validatorObj.duplicateName ? <Translate string={"GLOBAL_SETTINGS.ERROR_MESSAGE_DUPLICATE_NAME"}/> : <Translate string={"GLOBAL_SETTINGS.ERROR_MESSAGE_NAME"}/> }
                            </span>
                        </label>
                        { !props.isDLSaved
                            ? <Input autoComplete="off" className="modal-form-input" type="text"
                               name="name"
                               onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                        />
                            : <Tooltip placement="top" title={<Translate string={"GLOBAL.CANNOT_EDIT_DATA_LOGGER_NAME"}/>}>
                                <Input autoComplete="off" className="modal-form-input" type="text"
                                    name="name" value={props.nameOfDL}
                                    onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                                    disabled={true}/>
                            </Tooltip>
                        }
                    </div>
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.CONNECTION_TYPES"}/></label>
                        <div className="modal-form-label flex">
                            <div className="checkbox-container">
                                <Checkbox  name="ftp"
                                           checked={props.ftpChecked}
                                           onChange={props.handleConnectionType}
                                >
                                    <Translate string={"GLOBAL.FTP"}/>
                                </Checkbox>
                            </div>
                            <div className="checkbox-container">
                                <Checkbox name="mqtt"
                                          checked={props.mqttChecked}
                                          onChange={props.handleConnectionType}
                                    ><Translate string={"GLOBAL.MQTT"}/>
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-form flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={'GLOBAL.DESCRIPTION'}/></label>
                        <Input
                            autoComplete="off"
                            className="modal-form-input"
                            type="text"
                            name="description"
                            onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                        />
                    </div>
                    <div className="modal-form-column flex-justify-content-center">
                        <label className="modal-form-label"><Translate string={"GLOBAL.RESPONSIBILITY"}/></label>
                        <Input
                            autoComplete="off"
                            className="modal-form-input"
                            type="text"
                            name="responsibility"
                            onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                        />
                    </div>
                </div>
                <div className="row-form flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.WHEN"}/></label>
                        <DatePicker format={"MM.DD.YYYY"}
                                    allowClear={false}
                                    onChange={(data,dataString)=> {props.onUpdate("when",dataString)}}
                                    disabledDate={current => {
                                        return current && current > moment();
                                    }}
                        />
                    </div>
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.WHERE"}/></label>
                        <Input
                            autoComplete="off"
                            className="modal-form-input"
                            type="text"
                            name="where"
                            onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                        />
                    </div>
                </div>
                <div className="row-form flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.SERIAL_NUMBER"} /></label>
                        <Input
                            autoComplete="off"
                            className="modal-form-input"
                            type="text" name="serialNumber"
                            onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                        />
                    </div>
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.BRAND"}/></label>
                        <Input
                            autoComplete="off"
                            className="modal-form-input"
                            type="text"
                            name="brand"
                            onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}
                        />
                    </div>
                </div>
            </div>
            <div className="create-modal-button-details">
                <Button className={props.dlId ? 'btn btn-border-green' : "hideButton"} type="button" onClick={(e) =>  props.onSave(e,"dataloggerItemUpdate")}><Icon type={'save'} /><Translate string={"GLOBAL.UPDATE"}/></Button>
                <Button className={!props.dlId ? 'btn btn-border-green': "hideButton"} type="button" onClick={(e) =>  props.onSave(e,"dataloggerItemSave")}><Icon type={'save'} /><Translate string={"GLOBAL.SAVE"}/></Button>
                <Button className={!props.dlId ? 'btn btn-border-green create-modal-details-save-and-close-button': "hideButton"} type="button" onClick={(e) =>  props.onSave(e,"dataloggerItemSaveAndClose")}> <Icon type={'save'} /><Translate string={"GLOBAL_SETTINGS.SAVE_AND_CLOSE"}/></Button>
            </div>
        </React.Fragment>
    );
};

export default CreateNewDataLoggerModalDetailsTab
