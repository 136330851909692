import React     from 'react';
import { Input } from 'antd';

import './ValidRangeParameters.scss';

import './ValidRangeParameters.css';
import Translate from "../../../../Translate/Translate";

const ValidRangeParameters = (props) => {
    return (
        <div className=" flex filter-range-parameters-wrapper">
            <p><Translate string={"FILTERING.PARAMETERS"}/>:</p>
            <div className="flex filter-bounds-wrapper">
                <p className="filter-bound-label"><Translate string={"FILTERING.MAX_VALUE"}/> :</p>
                <Input
                    name={'maxValue'}
                    type="number"
                    value={props.filterType.maxValue}
                    className="filter-input-bound"
                    onChange={props.parameterValueHandler}
                />
            </div>
            <div className="flex filter-bounds-wrapper">
                <p className="filter-bound-label"><Translate string={"FILTERING.MIN_VALUE"}/> :</p>
                <Input
                    name={'minValue'}
                    type="number"
                    value={props.filterType.minValue}
                    className="filter-input-bound"
                    onChange={props.parameterValueHandler}
                />
            </div>
        </div>
    );
};
export default ValidRangeParameters;
