import React, {useState} from 'react';
import {Drawer} from "antd";
import SidebarTree from "../../../../Tree/TreeAlertingProbe";
import {TranslateText} from "../../../../../config/constants";


const ProbesDrawer = (props) => {
    const [loadedProbeIds, setLoadedProbeIds] = useState([]);

    const [loadedProbes, setLoadedProbes] = useState([]);

    const addProbeToList = (probe) => {
        if (probe) {
            setLoadedProbeIds([...loadedProbeIds, probe.id]);
            setLoadedProbes([...loadedProbes, probe]);
        }
    };
    
    const removeProbeFromList = (probeId) => {
        setLoadedProbeIds(loadedProbeIds.filter(id => id !== probeId));
    };
  
    return(
        <Drawer
            title={props.isDatalogger ? TranslateText("GLOBAL.DATA_LOGGERS") : TranslateText("GLOBAL.PROBES_LIST")}
            placement='right'
            closable={'false'}
            onClose={props.onClose}
            visible={props.visible}
        >
            <SidebarTree
                treeData={props.data}
                isDatalogger={props.isDatalogger}
                loadedProbesId={loadedProbeIds}
                addProbe={addProbeToList}
                removeProbe={removeProbeFromList}
                selectable={true}
                isAlerting={true}
                isFolderCheckable={true}
                setCheckedProbe={props.setCheckedProbe}
                checkedProbe={props.checkedProbe}
                fromAlerting={true}
            />
        </Drawer>
    )
}
export default ProbesDrawer;