import React, {Component} from "react";
import {connect} from "react-redux";
import {contextMenu} from "react-contexify";
import {Tree, Icon} from "antd";


import {treeActions} from "../../actions";

import ProbeTitle from "./ProbeTitle/ProbeTitle";
import FilterTitle from "./FilterTitle/FilterTitle";
import FilterTestTitle from "./FilterTestTitle/FilterTestTitle";
import AnalysisTitle from "./AnalysisTitle/AnalysisTitle";
import DataSourceContextMenu from "./ContextMenu/DataSourceContextMenu/DataSourceContextMenu";
import ProbeContextMenu from "./ContextMenu/ProbeContextMenu/ProbeContextMenu";

import "./Tree.scss";

const {TreeNode, DirectoryTree} = Tree;

class SidebarTree extends Component {

    state = {
        disabledChild: false
    };

    createFolders = ({name, dsIndex, id, path, probes, children, analysis}) => {
        return (
            <TreeNode
                icon={<Icon type="folder"/>}
                title={<span
                    onContextMenu={e => {
                        e.preventDefault();
                        this.handleDataSourceContextMenu(e, id, path, name, 'folder');
                    }
                    }
                >
                      {name}
                  </span>
                }
                nodeId={id}
                nodePath={path}
                nodeType={'folder'}
                nodeTitle={name}
                key={`0-${dsIndex}`}
            >
                {
                    children && children.map((folder, dsIndexChild) => {
                        return this.createFolders({...folder, dsIndex: `0-${dsIndex}-${dsIndexChild}`});
                    })

                }

                {
                    analysis ?
                        <TreeNode
                            key={`analysis-${dsIndex}`}
                            title={'Analyses'}
                            defaultExpandAll={true}
                            disableCheckbox
                            icon={<Icon type="calculator"/>}
                        >
                            {
                               analysis.length > 0 && analysis.map((singleAnalysis, analysisIndex) =>
                                    this.createAnalysis(singleAnalysis, analysisIndex, dsIndex))
                            }
                        </TreeNode>
                        : null
                }

                {
                    probes ? probes.map((probe, probeIndex) => this.createProbe({...probe, probeIndex, dsIndex})) : null
                }
            </TreeNode>
        );
    };

    createProbe = ({id, name, path, dsIndex, probeIndex, filters, unit, options}) => {
        return (
            <TreeNode
                isLeaf={!filters}
                icon={<Icon type="file"/>}
                nodeId={id}
                nodePath={path}
                nodeTitle={name}
                title={
                    <ProbeTitle
                        probe={{id, name, path, dsIndex, probeIndex, filters, unit, options}}
                        readOnly={false}

                    />
                }
                key={`probe-${id}`}
            >
                {
                    filters && filters.map((filter, filterIndex) => this.createFilter({
                            ...filter,
                            probeIndex,
                            dsIndex,
                            filterIndex
                        }))
                }
            </TreeNode>
        );
    };

    createFilter = ({id, name, probeIndex, dsIndex, filterIndex, probeId, filters, parentId}) => {
        return id ? (
                <TreeNode
                    isLeaf
                    disableCheckbox
                    title={
                        <FilterTitle
                            name={name}
                            filters={filters}
                            parentId={parentId}
                            id={id}
                            probeId={probeId}
                            history={this.props.history}
                        />
                    }
                    icon={<Icon type="filter"/>}
                    key={`0-${dsIndex}-${probeIndex}-${filterIndex}`}
                />
            ) :
            (
                <TreeNode
                    isLeaf
                    disableCheckbox
                    title={
                        <FilterTestTitle
                            name={name}
                            filters={filters}
                            parentId={parentId}
                            id={id}
                            probeId={probeId}
                            history={this.props.history}
                        />
                    }
                    icon={<Icon type="filter"/>}
                    key={`0-${dsIndex}-${probeIndex}-${filterIndex}`}
                />
            )
            ;
    };

    createAnalysis = (analysis, analysisIndex, dsIndex) => {
        return (
            <TreeNode
                icon={<Icon type="file"/>}
                title={
                    <AnalysisTitle
                        name={analysis.name}
                        id={analysis.id}
                        history={this.props.history}
                    />
                }
                key={`${dsIndex}-analysis-${analysisIndex}`}
            />
        );
    }

    createTree = () => {
        return this.props.treeStructure.map((datasource, dsIndex) => {
            return this.createFolders({...datasource, dsIndex});
        });
    };

    handleOnCheck = (checkedKeys, {checkedNodes}) => {
        let newArray;
        newArray = checkedNodes.map(node => {
                if (node.props.title.props) {
                    return {
                        probeId: node.props.title.props.probe.id,
                        probeName: node.props.title.props.probe.name,
                        unit: node.props.title.props.probe.unit,
                        options : node.props.title.props.probe.options
                    }
                }
                return node;
            }
        ).filter((node) => node && node.probeId);
        this.props.handleCheckedProbes(newArray);
    };

    handleDataSourceContextMenu = (e, id, path, name, nodeType) => {

        this.props.handleContextMenu(id, path, name, nodeType);
        if(nodeType ==='folder'){
        contextMenu.show({
            id: "synDataSourceContextMenu",
            event: e
        });}
        else {
            contextMenu.show(
                {
                    id: "synProbeContextMenu",
                    event: e,
                    props:
                        {
                            msg: "hello",
                            probeName: name
                        }
                });
        }
    };

    handleOnSelect = (selectedKeys, {selectedNodes}) => {
        if (selectedNodes.length && selectedNodes[0].props.title.props && selectedNodes[0].props.title.props.id) {
            const selectedNode = selectedNodes[0].props.title.props;
            this.props.handleSelectedNode(selectedNode);
        } else {
            this.props.handleSelectedNode(null);
        }
    };

    render() {
        const disabledChild = this.state.disabledChild;
        const { props : { selectable } } = this;
        return (
            <React.Fragment>
                <DirectoryTree
                    showIcon
                    checkable={selectable}
                    selectable={selectable}
                    multiple={selectable}
                    switcherIcon={<Icon type="down"/>}
                    onCheck={this.handleOnCheck}
                    onSelect={this.handleOnSelect}
                    onRightClick={(e) => {
                        e.event.preventDefault();
                        this.handleDataSourceContextMenu(e.event, e.node.props.nodeId, e.node.props.nodePath, e.node.props.nodeTitle, e.node.props.nodeType);
                    }}
                    checkedKeys={this.props.checkedNodes || []}
                >
                    {this.props.treeStructure ? this.createTree() : null}
                </DirectoryTree>
                <DataSourceContextMenu showModal={this.showModal} disabledChild={disabledChild}/>
                <ProbeContextMenu showModal={this.showModal}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    treeStructure: state.tree.structure,
    arrayOfCheckedNodes: state.tree.arrayOfCheckedNodes
});

const mapDispatchToProps = dispatch => ({
    handleContextMenu: (id, path, name) => dispatch(treeActions.handleContextMenu(id, path, name)),
    handleCheckedProbes: arrayOfCheckedNodes => dispatch(treeActions.handleCheckedNode(arrayOfCheckedNodes)),
    handleSelectedNode: (item, type) => dispatch(treeActions.handleSelectedNode(item, type))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidebarTree);
