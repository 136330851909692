import * as types from "./actionTypes";
import instance from "../config/axiosConf";
import {handleTreeData} from "./tree";
import { showNotification, TranslateText} from "../config/constants";
import {addNodeUnderParent, removeNodeAtPath} from "react-sortable-tree";
import {listenOnWebSocketForSeries} from "./user";

export const HideGlobalSettingsContent = () => dispatch => {
    dispatch({
        type: types.HIDE_MODAL_NEW_FOLDER,
        handleModalNewTree: true
    });
};

export const CreateNewFolder = (folder) => async dispatch => {

    dispatch({type: types.HANDLE_START_CREATE_NEW_CARD});

    const response = await instance.post(`/api/folders`, folder);

    if (response.status && response.status >= 200 && response.status < 300) {
        localStorage.removeItem("selectedTree");
        setTimeout(() => {
            let data = {
                icon: "check",
                status: "Success"
            };

            dispatch({
                type: types.MODAL_SUCCESS_MESSAGE,
                statusOfFolderCreator: [data]
            })
        }, 1000);

        setTimeout(() =>
            dispatch({
                type: types.HIDE_MODAL_NEW_FOLDER
            }), 2000);

        setTimeout(() => {
            dispatch({type: types.HANDLE_START_CREATE_NEW_CARD});
            dispatch(handleTreeData());

            dispatch({
                type: types.MODAL_SUCCESS_MESSAGE,
                statusOfFolderCreator: []
            });
        }, 2000);

    }
};

export const HandleDataStructure = id => async dispatch => {

    if (id) {
        const response = await instance.get(`api/folders/${id}`);

        dispatch({
            type: types.HANDLE_SORT_DATA_BY_ID,
            sortData: [{...response.data, expanded: true}]
        });
        dispatch({
            type: types.KEEP_SELECTED_ID,
            selectedId: id
        });
    }
};


export const DeleteFolder = (id, name) => async dispatch => {

    dispatch({
            type: types.HANDLE_LOADER_ON_DELETE,
            selectedTreeForDelete: id
        });

    const response = await instance.delete(`/api/folders/${id}`);

    if (response.status && response.status >= 200 && response.status < 300) {

        localStorage.removeItem('selectedTree');

        showNotification("success", TranslateText("GLOBAL.SUCCESS"), `${name} ${TranslateText("GLOBAL_SETTINGS.DELETE_FOLDER")}.`);

        dispatch({type: types.HANDLE_LOADER_ON_DELETE});

        dispatch(handleTreeData());

    }
};

export const OnMoveNode = async event => {

    const currentNodeId = event.node.customId || event.node.id;
    await instance.put(`/api/folders/${currentNodeId}`, {
        parentId: event.nextParentNode.id || event.nextParentNode.customId,
        name: event.node.name,
        description: event.node.description,
        index: event.node.index
    });
};

export const ChangeFolderName =  (event, rowInfo) => async(dispatch)  => {


    const branchID = rowInfo.node.customId || rowInfo.node.id;

    await instance.put(`/api/folders/${branchID}`, {
        name: event.target.value,
        index: rowInfo.node.index,
        description: rowInfo.node.description,
        parentId: rowInfo.node.parentId
    });
};

export const ChangeBranchName = (newSortData) => async (dispatch, getState) =>{

    let { tree:{structure}} = getState();
    
    if(newSortData[0].index === 0){
        let foundIndex = structure.findIndex(item => item.id === newSortData[0].id);

        structure[foundIndex].name = newSortData[0].name;

        dispatch({
            type: types.HANDLE_TREE_DATA,
            structure: structure
        });
    }

    dispatch({
        type: types.CHANGE_BRANCH_NAME,
        sortData: newSortData
    });

};

export const DeleteNode = (rowInfo, getNodeKey) => async (dispatch, getState) => {

    let { dataStorage: { sortData }} = getState();

    const path = rowInfo.path;
    const branchID = rowInfo.node.customId || rowInfo.node.id;
    const response = await instance.delete(`/api/folders/${branchID}`);

    if (response.status && response.status >= 200 && response.status < 300) {
        sortData = removeNodeAtPath({
            treeData: sortData,
            path,
            getNodeKey,
        });
        dispatch({
            type: types.DELETE_NODE,
            sortData: sortData
        });
        showNotification("success", TranslateText("GLOBAL.SUCCESS"), `${rowInfo.node.name} ${TranslateText("GLOBAL_SETTINGS.DELETE_BRANCH")}`);
    } else {
        showNotification("error", TranslateText("GLOBAL.ERROR"), TranslateText("GLOBAL_SETTINGS.DELETE_BRANCH_EXCEPTION"));
    }
};

export const AddNewBranch = () => async (dispatch, getState) => {
    const { dataStorage: { sortData }} = getState();

    const name = sortData.length > 0 ? sortData[0].name : '';
    const getNodeKey = ({treeIndex}) => treeIndex;

    const tempNode = addNodeUnderParent({
        treeData: sortData,
        parentKey: 0,
        expandParent: true,
        getNodeKey,
        newNode: {
            name: sortData[0].children ? (name + " " + (sortData[0].children.length + 1)) : name + 1,
        }
    });

    const response = await instance.post(`/api/folders/${sortData[0].id}`,
        {
            name: sortData[0].children ? name + " " + (sortData[0].children.length + 1) : name + 1,
            index: tempNode.treeIndex,
            description: '',
            parentId: 0

        }
    );

    if (response.status && response.status >= 200 && response.status < 300) {

        const realNode = addNodeUnderParent({
            treeData: sortData,
            parentKey: 0,
            expandParent: true,
            getNodeKey,
            newNode: {
                name: sortData[0].children ? (name + " " + (sortData[0].children.length + 1)) : name + 1,
                customId: response.data.folderId
            }
        });

        dispatch({
            type: types.ADD_NEW_BRANCH,
            sortData: realNode.treeData
        });
        showNotification("success", TranslateText("GLOBAL.SUCCESS"), TranslateText("GLOBAL_SETTINGS.NEW_BRANCH_IS_CREATED"));
    } else {
        showNotification("error", TranslateText("GLOBAL.ERROR"), TranslateText("GLOBAL_SETTINGS.NEW_BRANCH_IS_EXCEPTION"));
    }
};

export const CloneFolder = (folder) => async (dispatch, getState) => {

    const {dataStorage:{sortData}} = getState();

    const response = await instance.put(`api/folders/${sortData[0].id}/clone`, {
        name: folder.name,
        description: folder.description
    });

    if (response.status && response.status >= 200 && response.status < 300) {
        dispatch(handleTreeData());

        setTimeout(() => {
            let data = {
                icon: "check",
                status: "Success"
            };

            dispatch({
                type: types.MODAL_SUCCESS_MESSAGE,
                statusOfFolderCreator: [data]
            })
        }, 1000);

        setTimeout(() =>
            dispatch({
                type: types.HIDE_MODAL_NEW_FOLDER
            }), 2000);

        setTimeout(() => {
            dispatch({type: types.HANDLE_START_CREATE_NEW_CARD});
            dispatch(handleTreeData());

            dispatch({
                type: types.MODAL_SUCCESS_MESSAGE,
                statusOfFolderCreator: []
            });

            dispatch({
                type: types.REMOVE_CLONE_FOLDER
            });

        }, 2000);

    } else {
        showNotification("error", TranslateText("GLOBAL.ERROR"), TranslateText("GLOBAL_SETTINGS.CLONE_BRNACH_EXCEPTION"));
    }

};

export const CreateDataLogger = (payload) => async (dispatch) => {

    let params = '';

    if (payload.connections) {
        payload.connections.forEach(conn => {
            params += `${conn.type}=1&`
        });
        params = params.slice(0, params.length-1);
        delete payload.connections;
        delete payload.connectionType;
    }

    const response = await instance.post(`/api/dataloggers?${params}`,JSON.stringify(payload));

    if (response && response.data.data) {
        dispatch({type: types.LAST_CREATED_DATALOGGER, datalogger: response.data.data});
        dispatch(GetAllDataLoggers());
        dispatch({
            type: types.CLOSE_MODAL_FOR_NEW_DATA_LOGGER,
            closeModalForNewDataLogger: false
        });
        return response.data.data.id;
    }
};

export const CreateProbes = (payload) => async (dispatch, getState) => {

    const {globalSettings: {probes}} = getState();

    const response = await instance.post('/api/probes',
        JSON.stringify(payload));

    if (response && response.data.data) {

        const newProbesList =  [...probes, ...response.data.data];

        dispatch(Paginate(null,payload[0].folderRootId));

        dispatch({
            type: types.CREATE_NEW_PROBE,
            probesList: newProbesList
        })
    }
};

export const GetAllDataLoggers = () => async dispatch => {

    const response = await instance.get('/api/dataloggers', {
        params: {
            include: "connections"
        }
    });

    if (response && response.data) {
        const allDataloggers = await instance.get('/api/dataloggers', {
            params: {
                include: "connections",
                perPage:response.data.meta.totalRecords
            }
        });
        if(allDataloggers && allDataloggers.data){
            dispatch({
                type: types.GET_ALL_DATALOGGERS,
                dataLoggers: response.data.data || [],
                allDataLoggers: allDataloggers.data.data || [],
                paginationDataLogger: response.data.meta
            })
        }
    }
};

export const DeleteDataLoggerById = (payloadId) => async dispatch => {

    const response = await instance.delete(`/api/dataloggers/${payloadId}`, JSON.stringify(payloadId));

    if (response && response.data) {
        dispatch({
            type: types.HANDLE_DELETE_DATALOGGER,
            id: payloadId
        })
    }
};

export const Paginate = (page=1, folderId) => async dispatch => {

    let urlParams = new URLSearchParams();
    urlParams.append("folder_id",folderId);
    urlParams.append("include","tags");
    urlParams.append("include","sourceType");
    urlParams.append("perPage","10");
    urlParams.append("page",page);

    const response = await instance.get(`/api/probes`, {
        params: urlParams
    });

    if(response && response.data) {
        dispatch({
            type: types.HANDLE_PROBE_PAGINATION,
            probes: response.data.data,
            metaDataForProbePagination: response.data.meta
        });
    }
};

const PaginateWhenDeleteProbeOnProbeSetup = (page=1, folderId, probesList) => async dispatch => {

    let urlParams = new URLSearchParams();
    urlParams.append("folder_id",folderId);
    urlParams.append("include","tags");
    urlParams.append("include","sourceType");
    urlParams.append("perPage","10");
    urlParams.append("page",page);

    const response = await instance.get(`/api/probes`, {
        params: urlParams
    });

    if(response && response.data) {
        let unSavedProbes = probesList.filter(probe => probe && !probe.id);
        const probes=[...unSavedProbes, ...response.data.data];
        dispatch({
            type: types.HANDLE_PROBE_PAGINATION,
            probes: probes,
            metaDataForProbePagination: response.data.meta
        });
    }
};

export const PaginationForFtp = (page=1, folderId) => async dispatch => {

    const response = await instance.get(`api/probes`, {
        params: {
            include: "datalogger.connections",
            filters: {
                connectionType: 'ftp'
            },
            folder_id: folderId,
            page: page,
            perPage: 10
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.HANDLE_FTP_PAGINATION,
            ftpProbes: response.data.data,
            ftpProbesMetaData: response.data.meta
        })
    }
};

export const GetProbesForFolder = folderId => async dispatch => {
    let response;

    let urlParams = new URLSearchParams();
    urlParams.append("folder_id",folderId);
    urlParams.append("include","tags");
    urlParams.append("include","sourceType");
    urlParams.append("perPage","10");

    response = await instance.get(`/api/probes`, {
            params: urlParams
        });

    if(response && response.data) {
        dispatch({
            type: types.HANDLE_PROBE_PAGINATION,
            metaDataForProbePagination: response.data.meta,
            probes: response.data.data
        });
        dispatch({
            type: types.CLEAR_ALL_CHECKED_PROBES
        });
    }
};

export const HandleCheckedProbe = (probeId, checked) => (dispatch, getState) => {
    
    let { globalSettings: {checkedProbes}} = getState();
    
    checkedProbes = checked ? [...checkedProbes, probeId] : checkedProbes.filter(item => item !== probeId);
   
    dispatch({
        type: types.HANDLE_CHECKED_PROBES_IN_PROBE_SETUP,
        checkedProbes: checkedProbes
    });
};

export const HandleCheckAllProbes = (checkAll) => (dispatch, getState) => {

    let { globalSettings: {checkedProbes, probes}} = getState();
    const newArrayOfprobes = probes.map(probe => probe.id)
    let mySetProbes = new Set(checkedProbes.concat(newArrayOfprobes))
    checkedProbes = (checkAll) ? [...mySetProbes]  : checkedProbes.filter(checkProbe => !newArrayOfprobes.includes(checkProbe))
    
    dispatch({
        type: types.HANDLE_CHECK_ALL_PROBES_IN_PROBE_SETUP,
        checkedProbes: checkedProbes
    });
};
export const UnCheckAllCheckedProbes = () => (dispatch) => {
    dispatch({
        type: types.HANDLE_CHECK_ALL_PROBES_IN_PROBE_SETUP,
        checkedProbes:[]
    })
}

export const HandleCheckedCsvProbe = (csvProbeId, checked) => (dispatch,getState) => {

    let { globalSettings: {checkedCsvProbes}} = getState();

    checkedCsvProbes = checked ? [...checkedCsvProbes,csvProbeId] : checkedCsvProbes.filter(item => item !== csvProbeId);

    dispatch({
        type : types.HANDLE_CHECKED_CSV_PROBES,
        checkedCsvProbes : checkedCsvProbes
    });
};

export const HandleCheckAllCsvProbes = (checkAll) => (dispatch,getState) => {

    let { globalSettings: {checkedCsvProbes, csvProbes}} = getState();

    const newArrayOfProbesCsv = csvProbes.map(probeCsv => probeCsv.id)
    let mySetProbesCsv = new Set(checkedCsvProbes.concat(newArrayOfProbesCsv))
    checkedCsvProbes = (checkAll) ? [...mySetProbesCsv] : checkedCsvProbes.filter(checkedCsvProbe => !newArrayOfProbesCsv.includes(checkedCsvProbe))

    dispatch({
        type : types.HANDLE_CHECK_ALL_CSV_PROBES,
        checkedCsvProbes : checkedCsvProbes
    });
};
export const UnCheckAllCheckedCsvProbes = () => (dispatch) => {
    dispatch({
        type: types.HANDLE_CHECK_ALL_CSV_PROBES,
        checkedCsvProbes:[]
    })
}

export const HandleCheckedMqttProbe = (mqttProbeId, checked) => (dispatch,getState) => {

    let { globalSettings: {checkedMqttProbes }} = getState();

    checkedMqttProbes = checked ? [...checkedMqttProbes,mqttProbeId] : checkedMqttProbes.filter(item => item !== mqttProbeId);

    dispatch({
        type : types.HANDLE_CHECKED_MQTT_PROBES,
        checkedMqttProbes : checkedMqttProbes
    });
};

export const HandleCheckAllMqttProbes = (checkAll,mqttProbesWithMqttCon) => (dispatch,getState) => {

    let { globalSettings: {checkedMqttProbes, mqttProbes }} = getState();

    let newArrayOfProbeMqtt = mqttProbes.map(mqqtProbe => mqqtProbe.id)
    let mySetProbeMqtt = new Set(checkedMqttProbes.concat(newArrayOfProbeMqtt))
    checkedMqttProbes = (checkAll) ? [...mySetProbeMqtt] : checkedMqttProbes.filter(checkedMqttProbe => !newArrayOfProbeMqtt.includes(checkedMqttProbe))

    dispatch({
        type : types.HANDLE_CHECK_ALL_MQTT_PROBES,
        checkedMqttProbes : checkedMqttProbes
    });
};
export const UnCheckAllCheckedMqtt = () => (dispatch) => {
    dispatch({
        type: types.HANDLE_CHECK_ALL_MQTT_PROBES,
        checkedMqttProbes:[]
    })
}

export const HandleCheckedFtpProbe = (ftpProbeId, checked) => (dispatch,getState) => {

    let { globalSettings: {checkedFtpProbes }} = getState();

    checkedFtpProbes = checked ? [...checkedFtpProbes,ftpProbeId] : checkedFtpProbes.filter(item => item !== ftpProbeId);

    dispatch({
        type : types.HANDLE_CHECKED_FTP_PROBES,
        checkedFtpProbes : checkedFtpProbes
    });
};

export const HandleCheckAllFtpProbes = (checkAll,ftpProbesWithFtpConnection) => (dispatch,getState) => {

    let { globalSettings: {checkedFtpProbes, ftpProbes }} = getState();

    let newArrayOfProbeFtp = ftpProbes.map(ftpProbe => ftpProbe.id)
    let mySetProbeFtp = new Set(checkedFtpProbes.concat(newArrayOfProbeFtp))
    checkedFtpProbes = (checkAll) ? [...mySetProbeFtp] : checkedFtpProbes.filter(checkedFtpProbe => !newArrayOfProbeFtp.includes(checkedFtpProbe))

    dispatch({
        type : types.HANDLE_CHECK_ALL_FTP_PROBES,
        checkedFtpProbes : checkedFtpProbes
    });
};
export const UnCheckAllCheckedFtpProbes = () => (dispatch) => {
    dispatch({
        type: types.HANDLE_CHECK_ALL_FTP_PROBES,
        checkedFtpProbes:[]
    })
}

export const DeleteProbe = (probeId, probeList) => async (dispatch,getState) => {

    const { globalSettings: { selectedId,metaDataForProbePagination } } = getState();

    await instance.delete(`/api/probes/${probeId}`);
    dispatch(PaginateWhenDeleteProbeOnProbeSetup(metaDataForProbePagination.currentPage, selectedId,probeList));

};

export const DownloadTemplate = (pathFile) => async (dispatch) => {
    const response = await fetch(pathFile);
    const textBody = await response.text();

    if (typeof (textBody) === 'string' && textBody.length !== 0 && !textBody.startsWith("<!DOCTYPE html>")) {
        let pathname = pathFile.split("/")[3].split(".")[0]
        Download(textBody,pathname);
    }
};

const downloadProbes = async (probs,selectedId,fileName,type) => {
    if (probs && probs.length !== 0) {

        let response = await instance.get(`/api/folders/${selectedId}/probes/export`,
            {
                params: {
                    include: type,
                    probe_ids: probs.join(',')
                }
            });
        const textBody = response.data;
        Download(textBody,fileName);
    }
};
export const ExportSelectedProbes = (exprotName) => async (dispatch, getState) => {

    const { globalSettings: {checkedProbes,checkedCsvProbes,checkedMqttProbes,checkedFtpProbes, selectedId }} = getState();
    switch (exprotName) {
        case "csvExport":{
            downloadProbes(checkedCsvProbes,selectedId,"csvProbs", 'csv');
            break;
        }
        case "probeExport":{
            downloadProbes(checkedProbes,selectedId,"probes", 'csv');
            break;
        }
        case "mqttExport": {
            downloadProbes(checkedMqttProbes,selectedId,"mqttProbs", 'mqtt');
            break;
        }
        case "ftpExport": {
            downloadProbes(checkedFtpProbes,selectedId,"ftpProbs", 'ftp');
            break;
        }
        default: {
        }
    }
};

export const DownloadProbeSeries = (checkedProbes, selectedId, handlePrepareSeries) => async dispatch => {

    const listOfProbes = checkedProbes.join();
    const seriesTopic = `${selectedId}_${listOfProbes}_${new Date().getTime()}`;

    dispatch(listenOnWebSocketForSeries(seriesTopic, handlePrepareSeries, async () => {

        await instance.get(`/api/folders/${selectedId}/probes/series`,
            {
                params: {
                    probe_ids: listOfProbes,
                    topic: seriesTopic
                }
            }
        );
    }))
    
}

export const ExportAllProbes = (data) => async (dispatch) => {

    let response = await instance.get(`/api/folders/${data.folderId}/probes/export`);
    let textBody = response.data;
    Download(textBody,data.name);
};
const Download = (data,fileName) => {
    const blob= new Blob([data],{type:"text/csv"});
    const url= window.URL.createObjectURL(blob);
    const a= document.createElement("a");
    a.href=url;
    a.download=`${fileName}.csv`;
    a.click();
    a.remove();
    document.body.addEventListener("blur",window.URL.revokeObjectURL(blob))
};
export const ExportAcquisitionProbes = (data, type) => async (dispatch) => {

    let response = await instance.get(`/api/folders/${data.folderId}/probes/export`,
        {
            params: {
                include: type
            }
        });
    let textBody = response.data;
    Download(textBody,data.name)
};

export const GetAllFilterParams = () => async (dispatch, getState) => {

    const { globalSettings: {selectedId}} = getState();
    
    const response = await instance.get(`/api/probes/filters`, {
        params: {
            folder_id: selectedId
        }
    });
    if (response && response.data)
        dispatch({
            type: types.GET_ALL_DISTINCT_FILTER_PARAMS,
            distinctFilterParams: response.data.data,
        })
};

export const FilterProbes = (filterParams,page) => async (dispatch, getState) => {
    const { globalSettings: {selectedId}} = getState();
    if(filterParams.folderId) {
        filterParams.folderId = filterParams.folderId.toString();
    }
    const newfilterParams =  {...filterParams}

    let urlParams = new URLSearchParams();
    urlParams.append("folder_id",selectedId);
    urlParams.append("include","tags");
    urlParams.append("include","sourceType");
    urlParams.append("filters",JSON.stringify(newfilterParams));
    urlParams.append("perPage","10");
    urlParams.append("page",page);
    const response = await instance.get(`/api/probes`, {
        params: urlParams

    });
    if (response && response.data.data) {
        dispatch({
            type: types.CREATE_PROBE_FILTER,
            probes: response.data.data,
            metaDataForProbePagination: response.data.meta,
            filterParams:filterParams
        })
    }
};

export const GetProbesForCSV = (folderId) => async dispatch => {

    const response = await instance.get(`api/probes`,{
        params: {
            folder_id: folderId,
                filters: {
                connectionType: 'manual'
            }
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.GET_CSV_PROBES,
            csvProbes: response.data.data,
            csvProbesMetaData: response.data.meta
        })
    }
};

export const GetProbesForMqtt = (folderId) => async dispatch => {

    const response = await instance.get(`api/probes`, {
        params:{
            folder_id: folderId,
            include: "datalogger.connections",
            filters : {
                connectionType: 'mqtt'
            },
            fromPlace: 'acq-setup'
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.GET_MQTT_PROBES,
            mqttProbes: response.data.data,
            mqttProbesMetaData: response.data.meta
        })
    }

};

export const UpdateProbe = (probePayload, probeId) => async (dispatch, getState) => {

    if(Object.keys(probePayload).includes("dlId") && probePayload.dlId === undefined){
        probePayload.dlId=null;
    }

    const response = await instance.put(`/api/probes/${probeId}`,probePayload);
    if (response && response.data) {
        dispatch(GetAllFilterParams());
    }
};
export const UpdateAcqusitionSetupMqttProbe = (probePayload, probeId) => async (dispatch, getState) => {

    const response = await instance.put(`/api/probes/${probeId}`, JSON.stringify(probePayload));

    if (response && response.data) {
        let {globalSettings: {mqttProbes}} = getState();
        const updatedMqttProbe = response.data.data;
        const probeIndexForUpdate = mqttProbes.findIndex(probe => probe.id === updatedMqttProbe.id);
        let updatedMqttProbesList = [...mqttProbes];
        updatedMqttProbesList[probeIndexForUpdate] = updatedMqttProbe;
        if(updatedMqttProbesList[probeIndexForUpdate].path === "") {
            updatedMqttProbesList[probeIndexForUpdate].path = mqttProbes[probeIndexForUpdate].path
        }
        dispatch({
            type: types.UPDATE_ACQUSTION_SETUP_MQTT_PROBE,
            mqttProbes: updatedMqttProbesList
        })
    }
};


export const UpdateDataLogger = (logerPayload, dlId) => async (dispatch,getState) => {

    const response = await instance.put(`/api/dataloggers/${logerPayload.id || dlId}`,
        JSON.stringify(logerPayload)
    );
    if(response && response.data) {
            const selectedId = getState().globalSettings.selectedId;

            dispatch({type: types.LAST_CREATED_DATALOGGER, datalogger: logerPayload});
            dispatch(GetAllDataLoggers());
            dispatch(GetProbesForFTP(selectedId));
            dispatch(GetProbesForMqtt(selectedId));
            // We need upgrade editFtp and editMqtt redux variable
            response.data.data.connections.ftp && response.data.data.connections.ftp.id && dispatch(GetFtpById(response.data.data.connections.ftp.id));
            response.data.data.connections.mqtt && response.data.data.connections.mqtt.id && dispatch(GetMqttById(response.data.data.connections.mqtt.id));
    }
};

export const UpdateDataLoggerAcqusitionSetupMqtt = (logerPayload, dlId) => async (dispatch,getState) => {
    const response = await instance.put(`/api/dataloggers/${logerPayload.id || dlId}`,
        JSON.stringify(logerPayload)
    );

    if (response && response.data) {
        const selectedId=getState().globalSettings.selectedId;
        dispatch(GetProbesForMqtt(selectedId));
    }
};

export const GetProbesForFTP = (folderId) => async dispatch => {

    const response = await instance.get(`api/probes`, {
        params:{
            folder_id: folderId,
            include: "datalogger.connections",
            filters : {
                connectionType: 'ftp'
            },
            fromPlace: 'acq-setup'
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.GET_FTP_PROBES,
            ftpProbes: response.data.data,
            ftpProbesMetaData: response.data.meta
        })
    }
};

export const UpdateAcqusitionSetupFtpProbe = (probePayload, probeId) => async (dispatch, getState) => {

    const response = await instance.put(`/api/probes/${probeId}`, JSON.stringify(probePayload));

    if (response && response.data) {
        let {globalSettings: {ftpProbes}} = getState();
        const updatedFtpProbe = response.data.data;
        const probeIndexForUpdate = ftpProbes.findIndex(probe => probe.id === updatedFtpProbe.id);
        let updatedFtpProbesList = [...ftpProbes];
        updatedFtpProbesList[probeIndexForUpdate] = updatedFtpProbe;
        dispatch({
            type: types.UPDATE_ACQUISTION_SETUP_FTP_PROBE,
            ftpProbes: updatedFtpProbesList
        })
    }
};

export const GetFolderPaths = (folderId) => async dispatch => {

    const response = await instance.get(`api/folders/${folderId}`, {
        params: {
            format: 'flat'
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.LOAD_FOLDER_PATHS,
            folderLevels: response.data
        });
    }
};

export const FilterDataLogger = (filterObject,page) => async (dispatch) => {
    
    const response = await instance.get(`/api/dataloggers`, {
        params: {
            include: "connections",
            filters: filterObject,
            perPage: 10,
            page:page
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.CREATE_DATA_LOGGER_FILTER,
            dataLoggers: response.data.data,
            paginationDataLogger: response.data.meta,
            filterParams:filterObject
        })
    }
};

export const FilterFtp = (ftpFilterObject,page,selectedId) => async (dispatch, getState) => {

    const { globalSettings: { selectedId}} = getState();
    if(ftpFilterObject.folderId) {
        ftpFilterObject.folderId = ftpFilterObject.folderId.toString();
    }
    const newFtpFilterObject =  {...ftpFilterObject,connectionType: 'ftp'}
   
    const response = await instance.get(`/api/probes`, {
        params: {
            include: "datalogger.connections",
            folder_id : selectedId,
            filters: newFtpFilterObject,
            perPage: 10,
            page:page,
            fromPlace: 'acq-setup'
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.CREATE_FTP_FILTER,
            ftpProbes: response.data.data,
            ftpProbesMetaData: response.data.meta,
            filterParams:ftpFilterObject
        })
    }
};

export const FilterCsv = (csvFilterObject,page) => async (dispatch, getState) => {
    const { globalSettings: { selectedId}} = getState();
    if(csvFilterObject.folderId) {
        csvFilterObject.folderId = csvFilterObject.folderId.toString();
    }
    const newCsvFilterObject = {...csvFilterObject,connectionType: 'manual'}
    const response = await instance.get(`/api/probes`, {
        params: {
            folder_id: selectedId,
            filters: newCsvFilterObject,
            perPage: 10,
            page:page
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.CREATE_CSV_FILTER,
            csvProbes: response.data.data,
            csvProbesMetaData: response.data.meta,
            filterParams:csvFilterObject
        })
    }
};
export const FilterMqtt = (mqttFilterObject,page) => async (dispatch, getState) => {
    const { globalSettings: { selectedId }} = getState();
    if(mqttFilterObject.folderId) {
        mqttFilterObject.folderId = mqttFilterObject.folderId.toString();
    }
    const newmqttFilterObject = {...mqttFilterObject,connectionType: 'mqtt'}
    const response = await instance.get(`/api/probes`, {
        params: {
            include: "datalogger.connections",
            folder_id : selectedId,
            filters: newmqttFilterObject,
            perPage: 10,
            page:page,
            fromPlace: 'acq-setup'
        }
    });
    if (response && response.data) {
        dispatch({
            type: types.CREATE_MQTT_FILTER,
            mqttProbes: response.data.data,
            mqttProbesMetaData: response.data.meta,
            filterParams:mqttFilterObject
        })
    }
};

export const CreateMqttConnection = (payload) => () => {
   return  instance.post(`api/mqtt`, JSON.stringify(payload));
};

export const CreateFtpConnection = (payload) => () => {
    payload.host="deep.synapsees.com";
    return  instance.post(`api/ftp`, JSON.stringify(payload));
};

export const DeleteMqttConnection = (id) =>  () => {
    return instance.delete(`api/mqtt/${id}`);
};

export const DeleteFtpConnection = (id) => () => {
   return instance.delete(`api/ftp/${id}`);
};

export const PaginateDataLogger = (page) => async dispatch => {

    page = page || 1;

    const response = await instance.get(`/api/dataloggers`, {
        params: {
            include: "connections",
            page: page,
            perPage: 10
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.HANDLE_DATA_LOGGERS_PAGINATION,
            dataLoggers: response.data.data || [],
            paginationDataLogger: response.data.meta
        })
    }
};

export const GetMqttById = (id) => async (dispatch) => {
    const response = await instance.get(`/api/mqtt/${id}`);

    if(response && response.data){
        dispatch({
            type: types.GET_DATALOGGER_MQTT_TAB_BY_ID,
            editMqtt:response.data.data || []
        })
    }
}

export const UpdateMqtt = (mqttEditedObj) => async (dispatch, getState) => {
    if(mqttEditedObj.keepAlive) {
        mqttEditedObj.keepAlive = parseInt(mqttEditedObj.keepAlive);
    }
    if(mqttEditedObj.AnalogDeadBandDelay){
        mqttEditedObj.AnalogDeadBandDelay= parseInt(mqttEditedObj.AnalogDeadBandDelay);
    }
    if(mqttEditedObj.qos) {
        mqttEditedObj.qos = parseInt(mqttEditedObj.qos);
    }
    const response = await instance.put(`/api/mqtt/${mqttEditedObj.id}`, JSON.stringify(mqttEditedObj));
    if (response && response.data) {
        const selectedId=getState().globalSettings.selectedId
        dispatch(GetProbesForMqtt(selectedId));
    }

}
export const GetFtpById = (id) => async(dispatch) => {
    const response = await instance.get(`/api/ftp/${id}`);
    if (response && response.data) {
        dispatch({
            type: types.GET_DATALOGGER_FTP_TAB_BY_ID,
            editFtp:response.data.data || []
        })
    }
}
export const UpdateFtp = (editObj) => async (dispatch,getState) => {
    editObj.port=parseInt(editObj.port);
    const response = await instance.put(`/api/ftp/${editObj.id}`, JSON.stringify(editObj));
    if(response && response.data){
        const selectedId=getState().globalSettings.selectedId;
        dispatch(GetProbesForFTP(selectedId));
    }
}
export const DispatchTabKey = tabKey => dispatch => {

    dispatch({
        type: types.GET_ACQUISITION_SIDEBAR_TAB_KEY,
        tabKey: tabKey
    })
};
export const ExportFolder = () => async (dispatch,getState) => {

    const {globalSettings:{selectedId},tree:{structure}} = getState();

    const response = await instance.get(`/api/folders/${selectedId}/export`,);
    const textBody = response.data;

    const indexOfFolder = structure.findIndex(folder => folder.id === selectedId);
    const folderName = structure[indexOfFolder].name;

    Download(textBody,folderName);
}

export const PaginationForCsv = (page=1, folderId) => async dispatch => {
    const response = await instance.get(`api/probes`, {
        params: {
            filters: {
                connectionType: 'manual'
            },
            folder_id: folderId,
            page: page,
            perPage: 10
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.HANDLE_CSV_PAGINATION,
            csvProbes: response.data.data,
            csvProbesMetaData: response.data.meta
        })
    }
};
export const PaginationForMqtt = (page=1, folderId) => async dispatch => {

    const response = await instance.get(`api/probes`, {
        params: {
            include: "datalogger.connections",
            filters : {
                connectionType: 'mqtt'
            },
            folder_id: folderId,
            page: page,
            perPage: 10
        }
    });

    if (response && response.data) {
        dispatch({
            type: types.HANDLE_MQTT_PAGINATION,
            mqttProbes: response.data.data,
            mqttProbesMetaData: response.data.meta
        })
    }
};
export const ExportDataLogger = (dataLoggerId) => async (dispatch,getState) => {

    const {globalSettings:{dataLoggers}} = getState();
    const selectedDataLogger = dataLoggers.find(dataLogger => dataLogger.id === dataLoggerId);
    const dataLoggerName = `${selectedDataLogger.name}_configuration`;
    const response = await instance.get(`/api/dataloggers/${dataLoggerId}/export`);
    const textBody = response.data;
    Download(textBody,dataLoggerName);
}

export const GenerateNewMqttPassword = (mqttId) => async (dispatch) => {

    const response = await instance.put(`/api/mqtt/${mqttId}/generate-password`);

    if(response && response.data){
        dispatch({
            type : types.UPDATE_MQTT_PASSWORD,
            mqttItem : response.data.data
        });

    }
};

export const GenerateNewFtpPassword = (ftpId) => async (dispatch) => {

    const response = await instance.put(`/api/ftp/${ftpId}/generate-password`);

    if(response && response.data){
        dispatch({
            type : types.UPDATE_FTP_PASSWORD,
            ftpItem : response.data.data
        });

    }
};

export const NewProbeIsAddInProbeSetupToogle = (isAdd) => async (dispatch)=> {
    dispatch({type:types.HANDLE_IS_ADD_NEW_PROBE_IN_PROBE_SETUP,
              added:isAdd
    })
}

export const ResetVariableCreatedDatalogger = () => async (dispatch) => {
    dispatch({type: types.LAST_CREATED_DATALOGGER, datalogger: null});
};

export const actionConsumer = (status, id) => async (dispatch)=> {
  if(!status){
      await instance.get(`/api/dataloggers/${id}/consumer/start?conn=mqtt`);
  }else{
      await instance.get(`/api/dataloggers/${id}/consumer/stop?conn=mqtt`);
  }
};

export const updateApply = (isDisabled) => (dispatch) => {
    dispatch({type: types.UPDATE_APPLY, isDisabled});
}

export const handleDownloadSeries = (isGettingProbeSeries) => (dispatch) => {
    dispatch({type: types.HANDLE_GETING_SERIES_STATE, isGettingProbeSeries})
}