import React from 'react';
import { Icon } from 'antd';
import {Link} from 'react-router-dom';
import Translate from "../../Translate/Translate";
import './Indicator.scss';


const TreeIcon = () =>
    (
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5068 4.81295C14.3241 3.54914 13.7171 2.38697 12.773 1.50771C11.729 0.535457 10.3681 0 8.94073 0C7.5134 0 6.15242 0.535457 5.10841 1.50771C4.16432 2.38697 3.55732 3.54914 3.37457 4.81295C1.34108 5.70088 0 7.72647 0 9.96878C0 13.07 2.52307 15.5931 5.62429 15.5931C6.5801 15.5931 7.51158 15.3501 8.33634 14.8958V20.627H9.54496V14.8958C10.3697 15.3501 11.3012 15.5931 12.257 15.5931C15.3583 15.5931 17.8813 13.07 17.8813 9.96878C17.8814 7.72647 16.5403 5.70084 14.5068 4.81295ZM12.2571 14.3845C11.2711 14.3845 10.3181 14.0553 9.54504 13.4529V9.75658L12.2555 8.33143L11.693 7.26168L9.54504 8.39109V6.01205H8.33642V11.0699L6.18843 9.94049L5.62594 11.0102L8.33642 12.4354V13.4529C7.56339 14.0553 6.61036 14.3845 5.62437 14.3845C3.18954 14.3845 1.2087 12.4036 1.2087 9.96878C1.2087 8.10707 2.38613 6.43559 4.13858 5.80949L4.50826 5.67743L4.53783 5.286C4.62154 4.17935 5.11671 3.15162 5.93217 2.39217C6.75165 1.62893 7.82014 1.20861 8.94073 1.20861C10.0613 1.20861 11.1298 1.62893 11.9493 2.39213C12.7648 3.15158 13.26 4.17927 13.3437 5.28596L13.3732 5.67739L13.7429 5.80945C15.4954 6.43555 16.6728 8.10703 16.6728 9.96874C16.6728 12.4036 14.6919 14.3845 12.2571 14.3845Z"
                fill="#4C4C4C"/>
        </svg>
    );

const ProbeIcon = () =>(
    <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.75638 7.33926C1.69251 7.53086 1.77767 7.66924 1.92669 7.77036C2.19281 7.95132 2.25135 8.20679 2.24603 8.51548C2.23539 9.4256 2.25135 10.3357 2.23539 11.2405C2.23006 11.5066 2.31522 11.5758 2.56537 11.5598C2.91664 11.5385 3.26791 11.5492 3.61918 11.5598C3.70966 11.5598 3.86933 11.5013 3.86401 11.6663C3.84804 12.2464 3.99706 12.8425 3.70966 13.3907C3.23065 14.3008 3.33178 15.1204 3.99174 15.9028C4.46543 16.467 4.44946 17.1748 3.9811 17.7497C3.92788 17.8135 3.83207 17.8561 3.84272 17.9625C4.63574 18.069 4.77944 17.9785 4.92314 17.2121C5.04556 16.5468 4.87524 15.956 4.42817 15.4451C4.05561 15.0246 3.86933 14.5031 4.1248 14.0081C4.49204 13.3002 4.51865 12.5711 4.49204 11.8206C4.48139 11.6184 4.53462 11.5439 4.74751 11.5545C5.09878 11.5705 5.45005 11.5439 5.80132 11.5652C6.05147 11.5811 6.13663 11.5066 6.1313 11.2458C6.11534 10.3251 6.1313 9.39898 6.12066 8.47823C6.11534 8.19615 6.18453 7.97261 6.41871 7.8023C6.56773 7.69585 6.70611 7.56812 6.62628 7.3499C6.54644 7.13701 6.38145 7.0625 6.16856 7.0625C4.84331 7.0625 3.51806 7.0625 2.19281 7.0625C1.97459 7.0625 1.82025 7.13701 1.75638 7.33926ZM3.56064 7.7065C3.76821 7.70117 3.97578 7.7065 4.18335 7.7065C4.57187 7.7065 4.9604 7.7065 5.34893 7.7065C5.4607 7.7065 5.59375 7.67456 5.55117 7.88213C5.36489 8.75499 5.48198 9.63849 5.46602 10.5167C5.4607 10.8307 5.39151 10.9478 5.0562 10.9371C4.44946 10.9105 3.8374 10.9158 3.22533 10.9371C2.94325 10.9478 2.87406 10.8413 2.87938 10.5752C2.89535 9.85138 2.87406 9.12223 2.88471 8.39839C2.89003 8.16954 2.67181 7.87681 2.8368 7.7544C3.0018 7.63198 3.31581 7.71182 3.56064 7.7065Z" fill="#4C4C4C"/>
        <path d="M0.298058 1.22386C0.101134 1.36224 -0.117081 1.51126 0.0745221 1.77205C0.250158 2.01688 0.457727 1.87318 0.644008 1.74012C1.70314 1.00564 2.87405 0.622437 4.14608 0.633082C5.45536 0.622437 6.61562 0.994998 7.66411 1.72415C7.85572 1.85721 8.07925 2.03817 8.26553 1.77205C8.4731 1.48465 8.20699 1.33562 8.00474 1.19725C5.72148 -0.410088 2.576 -0.399443 0.298058 1.22386Z" fill="#4C4C4C"/>
        <path d="M0.989935 2.30949C0.830267 2.4319 0.670598 2.57028 0.830267 2.79381C0.973969 2.99606 1.16025 2.9588 1.33588 2.82575C1.92666 2.384 2.60791 2.14449 3.31578 1.96354C3.70431 1.66549 4.14606 1.85177 4.56119 1.84112C4.67829 1.8358 4.84328 1.84645 4.94972 1.96354C5.67888 2.11788 6.36545 2.36271 6.97219 2.80978C7.15315 2.94284 7.35008 3.01735 7.50975 2.78849C7.66409 2.57028 7.50975 2.4319 7.34475 2.30416C5.59904 0.957622 2.74097 0.957623 0.989935 2.30949Z" fill="#4C4C4C"/>
        <path d="M1.75638 3.34195C1.58607 3.46436 1.45833 3.61871 1.61268 3.82628C1.75106 4.00723 1.92137 3.9753 2.09169 3.85821C2.12362 3.83692 2.16088 3.82095 2.19281 3.79966C3.50742 2.97471 4.82202 2.98003 6.14195 3.79434C6.33888 3.91676 6.55709 4.08707 6.73805 3.79966C6.91368 3.52823 6.66886 3.39517 6.49322 3.27808C5.78536 2.8044 5.00298 2.57021 4.13013 2.55957C3.27856 2.58618 2.47489 2.83101 1.75638 3.34195Z" fill="#4C4C4C"/>
        <path d="M2.52279 4.37988C2.35248 4.5023 2.2141 4.65132 2.37909 4.86421C2.51215 5.03985 2.67182 5.01856 2.8581 4.90147C3.72563 4.34263 4.60913 4.34263 5.47667 4.89615C5.67891 5.02388 5.84923 5.03453 5.98228 4.8376C6.11534 4.64068 5.98228 4.49698 5.82794 4.38521C5.32764 4.02861 4.77412 3.85298 4.11948 3.84766C3.56064 3.86362 3.01244 4.02861 2.52279 4.37988Z" fill="#4C4C4C"/>
        <path d="M3.1508 5.87555C3.26789 6.04586 3.42224 6.0565 3.58723 5.95538C3.97575 5.71055 4.35896 5.71055 4.75281 5.95538C4.9178 6.0565 5.07747 6.05118 5.18923 5.88087C5.32229 5.68394 5.22117 5.53492 5.0615 5.41783C4.79006 5.2209 4.47605 5.15703 4.16736 5.13574C3.82673 5.15703 3.51804 5.23154 3.25192 5.43911C3.10822 5.55621 3.02839 5.69991 3.1508 5.87555Z" fill="#4C4C4C"/>
        <path d="M3.3158 1.96338C3.86399 1.92612 4.40687 1.90484 4.95507 1.95806C4.40687 1.83032 3.85867 1.79307 3.3158 1.96338Z" fill="#4C4C4C"/>
        <path d="M4.56122 1.84126C4.14608 1.8519 3.70432 1.66562 3.3158 1.96367C3.85867 1.79336 4.40687 1.83061 4.95507 1.95835C4.8433 1.84658 4.67831 1.83594 4.56122 1.84126Z" fill="#4C4C4C"/>
    </svg>
);
const AcquisitionIcon = () =>(
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M15.9834 12.2113C16.2031 11.9916 16.2031 11.6393 15.9834 11.4196L13.0197 8.45584C12.9161 8.35222 12.771 8.29004 12.6259 8.29004C12.4808 8.29004 12.3358 8.34807 12.2321 8.45584L9.2684 11.4196C9.04872 11.6393 9.04872 11.9916 9.2684 12.2113C9.37618 12.3191 9.52126 12.3771 9.66219 12.3771C9.80312 12.3771 9.9482 12.3232 10.056 12.2113L12.0663 10.2009V19.4403C12.0663 19.7512 12.315 19.9999 12.6259 19.9999C12.9368 19.9999 13.1855 19.7512 13.1855 19.4403V10.2009L15.1959 12.2113C15.4114 12.431 15.7638 12.431 15.9834 12.2113Z" fill="#4C4C4C"/>
            <path d="M4.16995 16.0786C3.95027 16.2983 3.95027 16.6507 4.16995 16.8703L7.13369 19.8341C7.23731 19.9377 7.38239 19.9999 7.52747 19.9999C7.67255 19.9999 7.81762 19.9419 7.92125 19.8341L10.885 16.8703C11.1047 16.6507 11.1047 16.2983 10.885 16.0786C10.7772 15.9709 10.6321 15.9128 10.4912 15.9128C10.3503 15.9128 10.2052 15.9667 10.0974 16.0786L8.08705 18.089V8.84962C8.08705 8.53874 7.83835 8.29004 7.52747 8.29004C7.21659 8.29004 6.96788 8.53874 6.96788 8.84962V18.089L4.95752 16.0786C4.74612 15.859 4.39379 15.859 4.16995 16.0786Z" fill="#4C4C4C"/>
            <path d="M16.3938 4.86632C16.1782 3.58549 15.544 2.41658 14.5741 1.52953C13.4964 0.543005 12.0953 0 10.6363 0C9.50881 0 8.41036 0.323316 7.46943 0.932642C6.68601 1.43834 6.03523 2.12228 5.57513 2.92642C5.37617 2.88912 5.16891 2.86839 4.96166 2.86839C3.2 2.86839 1.7658 4.30259 1.7658 6.06425C1.7658 6.29223 1.79067 6.51192 1.83212 6.72746C0.692228 7.55648 0 8.89119 0 10.313C0 11.4611 0.426943 12.5762 1.20622 13.4591C2.00622 14.3627 3.06321 14.8974 4.19067 14.9596C4.20311 14.9596 4.2114 14.9596 4.22383 14.9596H5.3886C5.69948 14.9596 5.94819 14.7109 5.94819 14.4C5.94819 14.0891 5.69948 13.8404 5.3886 13.8404H4.24041C2.54508 13.7368 1.11917 12.1244 1.11917 10.3088C1.11917 9.13575 1.74922 8.04145 2.76477 7.4487C3.00104 7.31192 3.10052 7.02591 3.00933 6.76891C2.92642 6.54508 2.88497 6.30881 2.88497 6.05596C2.88497 4.91192 3.81762 3.97927 4.96166 3.97927C5.20622 3.97927 5.44663 4.02073 5.67047 4.10363C5.94404 4.20311 6.24663 4.07876 6.37098 3.81762C7.14611 2.17202 8.82073 1.11088 10.6404 1.11088C13.086 1.11088 15.1047 2.94301 15.3368 5.37202C15.3617 5.62487 15.5523 5.82798 15.801 5.86943C17.6456 6.18446 19.0383 7.88808 19.0383 9.83212C19.0383 11.8922 17.4176 13.6829 15.4197 13.8363H14.1596C14.1389 13.8363 14.1223 13.8321 14.1016 13.8321C13.7907 13.8321 13.542 14.0808 13.542 14.3917C13.542 14.686 13.7699 14.9264 14.0601 14.9472V14.9513H15.4404C15.4528 14.9513 15.4653 14.9513 15.4819 14.9513C16.7461 14.8601 17.9275 14.2798 18.8062 13.3098C19.6808 12.3482 20.1575 11.113 20.1575 9.82798C20.1534 7.50674 18.5658 5.43834 16.3938 4.86632Z" fill="#4C4C4C"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="20.1575" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

const TreeAntIcon = props => (
    <Icon component={TreeIcon} {...props} />
);

const ProbeAntIcon = props => (
    <Icon component={ProbeIcon} {...props} />
);
const AcquisitionAntIcon = props => (
    <Icon component={AcquisitionIcon} {...props} />
);

export const Indicator = props => {

    const route                         = props.history.location.pathname;
    const activeTreeSetup               = route.toString().includes("tree-setup")   ? 'active' : '' ;
    const activeProbeSetup              = route.toString().includes("probe-setup") ? 'active' : '' ;
    const activeAcquisitionSetup        = route.toString().includes("acquisition-setup") ? 'active' : '' ;
    const { hasProbes } = props;

    const handleOnLinkClick = ( event ) => {
        if(hasProbes.length === 0) {
            event.preventDefault();
            return
        }
        props.checkIsAddedProbeSaved(event,"/global-settings/acquisition-setup", props.history)
    };
    return(
        <div className="indicator-container flex flex-direction-column">
            <div className={`flex flex-align-items-center flex-justify-content-space-between ${activeTreeSetup} tab-indicator-wrap`}>
                <Link to={"/global-settings/tree-setup"}
                      onClick={(e) => {
                          props.checkIsAddedProbeSaved(e,"/global-settings/tree-setup", props.history);
                      }}
                >
                <span className="flex flex-align-items-center tab-indicator">
                    <TreeAntIcon /> <Translate string={"GLOBAL.TREE_SETUP"}/>
                </span>
            </Link>

            <span className='bg-green indicator-status'/>
            </div>

            <div className={`flex flex-align-items-center flex-justify-content-space-between tab-indicator-wrap ${activeProbeSetup}`}>
                <Link to={"/global-settings/probe-setup"} >
                <span className={`flex flex-align-items-center tab-indicator`}>
                    <ProbeAntIcon /> <Translate string={"GLOBAL.PROBE_SETUP"}/>
                </span>
                </Link>
                <span className={hasProbes.length !== 0 ? 'indicator-status bg-green' : 'indicator-status bg-orange' }/>

            </div>
            <div className={`flex flex-align-items-center flex-justify-content-space-between tab-indicator-wrap ${activeAcquisitionSetup}`}>
                <Link to={"/global-settings/acquisition-setup"} onClick={ handleOnLinkClick }>
                <span className="flex flex-align-items-center tab-indicator">
                    <AcquisitionAntIcon /> <Translate string={"GLOBAL.ACQUISITION_SETUP"}/>
                </span>
                </Link>
                <span className={ hasProbes.length !== 0 ? 'indicator-status bg-green' :'indicator-status bg-gray'}/>
            </div>
        </div>
        )
};
