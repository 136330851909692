import React, {useState, useRef} from "react";
import Arrow from "./Arrow";
import {Checkbox} from "antd";
import Translate from '../../../Translate/Translate';

import './AlertsAccordion.scss';


function AlertsAccordion(props) {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon");

    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
        );
    }

    const defaultCheck = props.checked(props.id)

    // const time = moment(props.date)
    // const timeDateFormat = time.format()
    // const lastWord = props.notificationStatus.split(" ").splice((-1))
    // const timeFormatNotificationStatus = props.notificationStatus.replace(lastWord , timeDateFormat)

    return (

        <div className="alerts-item">
            <div className="alerts-item-wrap flex">
                <div className="alerts-item-checkbox">
                    <Checkbox
                        className={'probe-checkbox'}
                        onChange={e => props.onCheckboxChange(e.target.checked, props.id)}
                        checked={defaultCheck}
                    />
                </div>
                <div className="alerts-item-content flex flex">
                    <div className={`alerts-item-icon expand-probe-item`} style={{display: 'flex', alignItems: 'flex-start'}}>
                        <span onClick={toggleAccordion}>
                            <Arrow className={`${setRotate}`}/>
                        </span>
                    </div>
                    <div className="alerts-item-data">
                        <div className="alerts-data-visible-on-load">
                            <div className="alerts-group flex flex-direction-column">
                                <label><Translate string={"ALERTING.DATE"}/></label>
                                <p>{props.date}</p>
                            </div>
                            <div className="alerts-group flex flex-direction-column">
                                <label><Translate string={"ALERTING.ALERT_NAME"}/></label>
                                <p>{props.alertName}</p>
                            </div>
                            <div className="alerts-group flex flex-direction-column">
                                <label><Translate string={"GLOBAL.STATUS"}/></label>
                                <p className='flex flex-align-items-center'><span className={"status-dot"} style={{background: `${props.statusColor}`}}/> {props.statusName}</p>
                            </div>
                            <div className="alerts-group">
                                <label><Translate string={"ALERTING.TRIGGER_NAME"}/></label>
                                <p>{props.triggerName}</p>
                            </div>
                            <div className="alerts-group">
                                <label><Translate string={"ALERTING.CLOSED"}/></label>
                                <p>{<Translate string={props.closed ? "ALERTING.YES" : "ALERTING.NO"}/>}</p>
                            </div>
                        </div>
                        <div className={`alerts-data-hidden-on-load ${setActive}`} ref={content}
                             style={{maxHeight: `${setHeight}`}}>
                            <div className="alerts-group">
                                <label><Translate string={"ALERTING.NOTIFICATION_STATUS"}/></label>
                                <p className='status-text'>{props.notificationStatus} {!!props.notificationStatus && props.notificationStatus.includes("not sent") ? "" : (" at " + props.date)} </p>
                            </div>
                            <div className="alerts-group">
                                <label><Translate string={"ALERTING.NOTIFICATION_MESSAGE"}/></label>
                                <p>{props.notificationMessage}</p>
                            </div>
                            {props.triggerableID && <div className="alerts-group flex flex-direction-column">
                                <label><Translate string={"ALERTING.HYPERLINK"}/></label>
                                <span onClick={() => props.openChartProbe(props.triggerableID, props.date)} className="hyperlink-link"><Translate string={"ALERTING.HYPERLINK"}/></span>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlertsAccordion;
