import React from 'react';

import {Input, Checkbox, Icon, Select, Divider, DatePicker, Tag, Popconfirm} from 'antd';
import {SOURCE_TYPE, TranslateText, fixDatatime} from "../../../../../config/constants";
import Translate from "../../../../Translate/Translate";
import moment from "moment";
import './Probe.scss';

const Option = Select.Option;

export const Probe = props => {

    const {probe, tagInputValues, filterParams} = props ;
    let instaledAtDate= fixDatatime(probe.installedAt);
    let filteredTags = [...new Set(probe.tags)];
    const tagOptions = [];
    filterParams !== null && filterParams.tags !== null && filterParams.tags.forEach(tag => {
        let condition = tag !=='ftp' && tag !== 'FTP' && tag !== 'csv' && tag !== 'CSV' && tag !== 'mqtt' && tag !== 'MQTT';
        condition && tagOptions.push(<Option key={tag} value={tag}>{tag}</Option>)
    })
    return (
        <div className={'probe'}>
            <div className={'top-side'}>
                <div className={'top-side-checkbox'}>
                    <Checkbox
                        className={'probe-checkbox'}
                        onChange={props.handleCheckedProbe}
                        checked={props.defaultChecked}
                        disabled={props.isCheckboxDisabled}
                    />
                </div>
                <div className={'flex probe-setup-input'}>
                    <div className={'expand-probe-item'} style={{display: 'flex', alignItems: 'flex-start'}}>
                        <Icon type={props.expand ? 'up' : 'down'} onClick={props.onToggle}
                              style={{fontSize: '17px', paddingTop: '8px'}}/>
                    </div>

                    <div className={'probe-inputs'}>
                        <div className="first-probe-row">
                            <div className={'top-side-name-of-the-probe flex-grow-one'}>
                                <label><Translate string={"GLOBAL.PROBE_NAME"}/></label>
                                <Input
                                    name={"name"} type={"text"}
                                    placeholder={TranslateText("GLOBAL_SETTINGS.ENTER_NAME_OF_THE_PROPS")}
                                    autoComplete='off'
                                    value={probe.name}
                                    onChange={props.onChangeProbe}
                                    onBlur={props.onBlurProbe}

                                />
                            </div>
                            <div className={'middle-side-unit flex-grow-one'}>
                                <label><Translate string={"GLOBAL.UNIT"}/></label>
                                <Input
                                    name={"unit"} type={"text"}
                                    placeholder={TranslateText("GLOBAL_SETTINGS.ENTER_UNIT")}
                                    autoComplete='off'
                                    value={probe.unit}
                                    onChange={props.onChangeProbe}
                                    onBlur={props.onBlurProbe}

                                />
                            </div>

                            <div className={'top-side-data-type flex-grow-one'}>
                                <label><Translate string={"GLOBAL.DATA_TYPE"}/></label>
                                <Input
                                    name={"dataType"} type={"text"}
                                    placeholder={TranslateText("GLOBAL.DATA_TYPE")}
                                    autoComplete='off'
                                    value={probe.dataType}
                                    onChange={props.onChangeProbe}
                                    onBlur={props.onBlurProbe}

                                />
                            </div>
                            <div className={'top-side-level-of-the-probe flex-grow-tree'}>
                                <label><Translate string={"GLOBAL.PATH"}/></label>
                                <Select
                                    value={probe.folderId}
                                    onChange={value => props.onChangeSelectOptionProbe('folderId',value)}
                                    showSearch
                                    optionFilterProp={"children"}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {props.folderLevels.map(level => {
                                        return <Option value={level.id} key={'level-'+level.id}>{level.path}</Option>
                                }
                                    )}
                                </Select>
                            </div>
                        </div>

                        <div
                            className={props.expand ? 'expand probe-details middle-side flex flex-direction-column' : 'expand-hide probe-details middle-side flex flex-direction-column'}>

                            <div className="flex second-probe-row probe-details-row">
                                <div className={'top-side-level-of-the-probe flex-grow-one'}>
                                    <label><Translate string={"GLOBAL.SOURCE_TYPE"}/></label>
                                    <Select
                                        showSearch
                                        value={probe.sourceType ? probe.sourceType.type : ''}
                                        placeholder={TranslateText("GLOBAL.SOURCE_TYPE")}
                                        optionFilterProp={"children"}
                                        onChange={value => props.onChangeSelectOptionProbe('sourceType',value)}
                                    >
                                        {SOURCE_TYPE.map((cType, index) => <Option key={index}
                                                                                  value={cType}>{cType}</Option>)}
                                    </Select>
                                </div>
                                <div className={'top-side-level-of-the-probe flex-grow-tree data-logger-select'}>
                                    <label><Translate string={"GLOBAL.DATA_LOGGER"}/></label>
                                    <Select
                                        showSearch
                                        placeholder={<Translate string={"GLOBAL.DATA_LOGGER"}/>}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        value={probe.dlId}
                                        allowClear={true}
                                        dropdownStyle={{ zIndex: 998 }}
                                        onChange={value => props.onChangeSelectOptionProbe('dlId',value)}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{margin: '4px 0'}}/>
                                                <button className={'add-new-data-logger btn-no-border'}
                                                        onMouseDown={e => e.preventDefault()}
                                                        onClick={props.openModal}
                                                >
                                                    <Icon type="plus"/>
                                                    <Translate string={"GLOBAL.ADD_NEW_DATA_LOGGER"}/>
                                                </button>
                                            </div>
                                        )}
                                    >
                                        {
                                            props.allDataLoggers ? props.allDataLoggers.map(
                                                (dataLogger, index) => {
                                                    return <Option key={`probe-dl-${index}`}
                                                                   value={dataLogger.id}>{dataLogger.name}</Option>
                                                }) : ''
                                        }
                                    </Select>
                                </div>
                                <div className={'top-side-level-of-the-probe flex-grow-one'}>
                                    <label><Translate string={"GLOBAL.SENSOR"}/></label>
                                    <Input placeholder={TranslateText("GLOBAL.SENSOR")}
                                           name={'sensor'}
                                           autoComplete='off'
                                           value={probe.sensor}
                                           onBlur={props.onBlurProbe}
                                           onChange={props.onChangeProbe}
                                    />
                                </div>
                                <div className={'top-side-level-of-the-probe flex-grow-one'}>
                                    <label><Translate string={"GLOBAL.LABEL"}/></label>
                                    <Input placeholder={TranslateText("GLOBAL.LABEL")}
                                           name={'label'}
                                           autoComplete='off'
                                           value={probe.label}
                                           onBlur={props.onBlurProbe}
                                           onChange={props.onChangeProbe}
                                    />
                                </div>
                            </div>

                            <div className={'third-probe-row'}>
                                <div className={'bottom-side-tags flex-grow-one'}>
                                    <label><Translate string={"GLOBAL_SETTINGS.TAGS"}/></label>
                                    <Select
                                        mode="tags"
                                        //dropdownClassName={'hide-dropdown-tag'}
                                        placeholder={TranslateText("GLOBAL.ADD_TAGS_FOR_THIS_DATA_SOURCE")}
                                        name={'tags'}
                                        onChange={value => {
                                            let newArray = value.map(el => el.trim()).filter(el => el !== '');
                                            let newValues = [...new Set(filteredTags.concat(newArray))]
                                            props.updateNewTags(newArray);
                                            props.onChangeSelectOptionProbe('tags',newValues)
                                        }}
                                        onDeselect={value => {
                                            let updatedTagIndex = tagInputValues.findIndex(tag => tag === value );
                                            let updatedTag = tagInputValues.splice(updatedTagIndex, 1);
                                            props.updateNewTags(updatedTag);
                                            let valueIndex = filteredTags.findIndex(tag => tag === value );
                                            let newValues = filteredTags.splice(valueIndex, 1);
                                            props.onChangeSelectOptionProbe('tags',newValues)
                                        }}
                                        value={tagInputValues}
                                        getPopupContainer={() => document.getElementById('main-content-position')}
                                    >
                                        {tagOptions}
                                        <Option value="ftp"><Translate string={"GLOBAL.FTP"}/></Option>
                                        <Option value="csv"><Translate string={"GLOBAL.CSV"}/></Option>
                                        <Option value="mqtt"><Translate string={"GLOBAL.MQTT"}/></Option>
                                    </Select>
                                </div>
                                <div className={'bottom-side-location flex-grow-one'}>
                                    <label><Translate string={"GLOBAL.LOCATION"}/></label>
                                    <Input
                                        placeholder={TranslateText("GLOBAL.LOCATION")}
                                        name={'location'}
                                        autoComplete='off'
                                        value={probe.location}
                                        onBlur={props.onBlurProbe}
                                        onChange={props.onChangeProbe}
                                    />
                                </div>
                                <div className={'bottom-side-energy-vector flex-grow-one'}>
                                    <label><Translate string={"GLOBAL.ENERGY_VECTOR"}/></label>
                                    <Input
                                        placeholder={TranslateText("GLOBAL.ENERGY_VECTOR")}
                                        name={'energyVector'}
                                        value={probe.energyVector}
                                        autoComplete='off'
                                        onBlur={props.onBlurProbe}
                                        onChange={props.onChangeProbe}
                                    />
                                </div>
                                <div className={'bottom-side-installation-date flex-grow-one'}>
                                    <label><Translate string={"GLOBAL.INSTALLATION_DATE"}/></label>
                                    <DatePicker
                                        placeholder={TranslateText("GLOBAL.INSTALLATION_DATE_PLACEHOLDER")}
                                        format={"MM.DD.YYYY"}
                                        value={instaledAtDate ? moment(instaledAtDate, "MM.DD.YYYY") : null}
                                        allowClear={true}
                                        onChange={(value,stringValue) => props.onChangeSelectOptionProbe('installedAt',stringValue)}
                                        disabledDate={current => {
                                            return current && current > moment();
                                        }}
                                    />
                                </div>
                                <div className={'bottom-side-installation-responsable flex-grow-one'}>
                                    <label><Translate string={"GLOBAL_SETTINGS.INSTALLED_BY"}/></label>
                                    <Input
                                        placeholder={TranslateText("GLOBAL_SETTINGS.INSTALLED_BY")}
                                        name={'installer'}
                                        value={probe.installer}
                                        autoComplete='off'
                                        onBlur={props.onBlurProbe}
                                        onChange={props.onChangeProbe}
                                    />
                                </div>
                            </div>
                            <div className="tag-probe-row">
                                {// eslint-disable-next-line
                                probe.tags && filteredTags.map((tag, index) => {
                                    if(tag !== "") {
                                        return (
                                            <Tag
                                                key={index}
                                                closable
                                                onClose={(e) => {
                                                    e.preventDefault();
                                                    props.handleClose(tag);
                                                }}
                                            >
                                                {tag}
                                            </Tag>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'probe-delete-item'}>

                    <Popconfirm placement="left" title={<div><b><Translate string={"GLOBAL_SETTINGS.CONFIRM_DELETE_PROBE_HEADER"}/></b>
                                                            <br/>
                                                            <p><Translate string={"GLOBAL_SETTINGS.CONFIRM_DELETE_PROBE_SECTION"}/></p></div>}
                                                            okText={<Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"}/>}
                                                            cancelText={<Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>}
                                                            onConfirm={()=>props.onDelete()}>
                        <Icon type={'close'} />
                    </Popconfirm>
                </div>
            </div>
        </div>
    )
};
