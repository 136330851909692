import React          from 'react';
import { Component }  from 'react';
import {connect}      from 'react-redux';

import { Icon }       from 'antd';

import * as types     from "../../../actions/actionTypes";

import './AnalysisMenu.scss';
import Translate from "../../Translate/Translate";

class AnalysisMenu extends Component{

  render() {

    const {
      updateAnalysisMenu
    } = this.props ;

    return(

          <div className="energy-analysis-menu-wrapper">
              <p className="title">
                  <Translate string={"ENERGY_ANALYSIS.SELECT_THE_ENERGY_ANALYSIS"}/>
              </p>
              <div className="flex process">
                  <div className="flex flex-justify-content-center flex-align-items-center process-wrapper">
                      <p className="process-text"><Translate string={"ENERGY_ANALYSIS.PROCESS1"}/></p>
                      <Icon type="line-chart" style={{ fontSize: '100px' }} />
                  </div>
                  <div className="flex flex-justify-content-center flex-align-items-center process-wrapper">
                      <p className="process-text"><Translate string={"ENERGY_ANALYSIS.PROCESS2"}/></p>
                      <Icon type="line-chart" style={{ fontSize: '100px' }} />
                  </div>
                  <div className="flex flex-justify-content-center flex-align-items-center process-wrapper">
                      <p className="process-text"><Translate string={"ENERGY_ANALYSIS.PROCESS3"}/></p>
                      <Icon type="line-chart" style={{ fontSize: '100px' }} />
                  </div>
              </div>
              <div>
                  <div className="flex flex-justify-content-center  flex-align-items-center process-wrapper"
                       onClick={updateAnalysisMenu}
                  >
                      <p className="process-text"><Translate string={"ENERGY_ANALYSIS.CUSTOM"}/></p>
                      <Icon type="calculator" style={{ fontSize: '100px' }} />
                  </div>
              </div>
          </div>
    )
  }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
  updateAnalysisMenu   : () => dispatch({type: types.HANDLE_SELECT_ANALYSIS_MENU})
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalysisMenu);
