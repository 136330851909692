import React, {Component} from 'react';


import Datalogger from "./Datalogger/Datalogger"
import Ftp from "./Ftp/Ftp";
import Csv from "./Csv/Csv";
import Mqtt from "./Mqtt/Mqtt";
import * as globalSettingActions from "../../../../actions/globalSettings";
import {connect} from "react-redux";
import {Tabs} from 'antd';
import Translate from "../../../Translate/Translate";

import './GlobalSettingsAcquisitionSetup.scss';

const TabPane = Tabs.TabPane;

class GlobalSettingsAcquisitionSetup extends Component {

    constructor(props){
        super(props);
        this.state = {
            root: "/global-settings/acquisition-setup",
            checked: false,
            defaultTab:"",
            mqttIdInEditDl:false,
            openEditDL:null


        }
    }

    componentDidMount() {

        const {selectedId,dataLoggers,GetAllFilterParams,GetLevels,GetAllDataLoggers} = this.props;
        GetLevels(selectedId);
        GetAllFilterParams();
        if(!dataLoggers){
            GetAllDataLoggers();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.defaultTab === "datalogger"){
            this.setState({defaultTab: ""})
        }
    }

    render() {
        const {props:{dataLoggers,ftpProbes, mqttProbes, csvProbes, allFilterParams, history},state:{defaultTab,openEditDL,mqttIdInEditDl,selectedDataLogger}} = this;
        return (
            dataLoggers || ftpProbes || mqttProbes || csvProbes || allFilterParams ?
                <React.Fragment>
                    <div className="acquisition-setup-wrap flex flex-direction-column">
                        <div className="acquisition-body">
                                <Tabs className={"tabs-style"} key={defaultTab} type="card" onChange={e => this.handleOnChangeTab(e)}>
                                    <TabPane tab={<Translate string={"GLOBAL.DATA_LOGGER"}/>} key={"datalogger"}>
                                        <Datalogger title='Probe Setup'
                                            mqttIdInEditDl = {mqttIdInEditDl}
                                            openEditDL = {openEditDL}
                                            selectedDataLogger = {selectedDataLogger}
                                        />
                                    </TabPane>
                                    <TabPane tab={<span className={dataLoggers.length === 0 ? "tabPaneSpanDisable": "tabPaneSpanEnable"}><Translate string={"GLOBAL.FTP"}/></span>} key={"ftp"} disabled={dataLoggers.length === 0}>
                                        <Ftp title='Acquisition Setup' ftpProbes={ftpProbes || []} history={history}/>
                                    </TabPane>
                                    <TabPane tab={<Translate string={"GLOBAL.CSV"}/>} key={"csv"}>
                                        <Csv title='Acquisition Setup' csvProbes={csvProbes || []}/>
                                    </TabPane>
                                    <TabPane tab={<span className={dataLoggers.length === 0 ? "tabPaneSpanDisable": "tabPaneSpanEnable"}><Translate string={"GLOBAL.MQTT"}/> </span>} key={"mqtt"} disabled={dataLoggers.length === 0}>
                                        <Mqtt title='Acquisition Setup'
                                              handleDefaultOpenTab={this.handleDefaultOpenTab}
                                        />
                                    </TabPane>
                                </Tabs>
                        </div>
                    </div>
                </React.Fragment>
                 : <></>
        )
    }


    getTabKey = e => {
        this.props.DispatchTabKey(e)
    }
    handleOnChangeTab = (e) => {
        this.getTabKey(e);
    };
    handleDefaultOpenTab = (tabName,openEditDL,mqttIdInEditDl, selectedDataLogger) => {
        this.setState({defaultTab:tabName, openEditDL,mqttIdInEditDl, selectedDataLogger});
    };
}


const mapStateToProps = state =>({
    ftpProbes  : state.globalSettings.ftpProbes,
    mqttProbes : state.globalSettings.mqttProbes,
    csvProbes : state.globalSettings.csvProbes,
    allFilterParams  : state.globalSettings.distinctFilterParams,
    selectedId : state.globalSettings.selectedId,
    dataLoggers : state.globalSettings.dataLoggers
});

const mapDispatchToProps = dispatch => ({
    GetAllDataLoggers : () => dispatch(globalSettingActions.GetAllDataLoggers()),
    GetAllFilterParams   : ()   => dispatch(globalSettingActions.GetAllFilterParams()),
    GetLevels            : (folderId) => dispatch(globalSettingActions.GetFolderPaths(folderId)),
    DispatchTabKey: (tabKey) => dispatch(globalSettingActions.DispatchTabKey(tabKey))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalSettingsAcquisitionSetup);

