import React, { Component }         from "react"
import { connect }                  from "react-redux";

import { Icon }                     from "antd";

import { InputSelect, InputText }   from "../../../../../../FormInput/FormInput";
import { globalSettingsActions }    from "../../../../../../../actions";
import Translate from "../../../../../../Translate/Translate"
import "./SidebarAcquisitionCsvFilter.scss"

class SidebarAcquisitionCsvFilter extends Component{

    state ={
        filterObject: {}
    };

    render() {
        const {
            props: { folderLevels, distinctFilterParams },
            state : { filterObject },
            handleOnSubmit, onPressEnter,handleOnSelectChange, handleOnChange } = this ;
        return (
            <form className="sidebar-form-filter" onSubmit={handleOnSubmit}>
                <div className="sidebar-filters-header">
                    <div
                        className="sidebar-filters-header-wrap flex flex-justify-content-space-between flex-align-items-center">
                        <h1><Translate string={"GLOBAL.FILTERS"}/></h1>
                        <div className="sidebar-filters-header-action">
                            <button className="btn btn-filter btn-border-black" type={'submit'}>
                                <Icon type="filter" theme="filled"/><Translate string={"GLOBAL.APPLY"}/>
                            </button>
                        </div>
                    </div>

                </div>
                <div className="sidebar-filters-body">
                <InputText
                    label={<Translate string={"GLOBAL.PROBE_NAME"}/>}
                    name={'name'}
                    handleOnPressEnter={(name, value) => onPressEnter(name, value)}
                    handleOnChange={handleOnChange}
                    value={filterObject.name}
                />
                <InputSelect
                    label={<Translate string={"GLOBAL.PATH"}/>}
                    name={'folderId'}
                    onChangeSelect={(value, name) => handleOnSelectChange(value, name)}
                    optionValues={folderLevels}
                    value={filterObject.folderId ? parseInt(filterObject.folderId) : filterObject.folderId}
                />
                <InputSelect
                    label={<Translate string={"GLOBAL.UNIT"}/>}
                    name={'unit'}
                    onChangeSelect={(value, name) => handleOnSelectChange(value, name)}
                    optionValues={distinctFilterParams.unit}
                    value={filterObject.unit}
                />
                <InputSelect
                    label={<Translate string={"GLOBAL.DATA_TYPE"}/>}
                    name={'dataType'}
                    onChangeSelect={(value, name) => handleOnSelectChange(value, name)}
                    optionValues={distinctFilterParams.data_type}
                    value={filterObject.dataType}
                />
                </div>
            </form>
        )
    };

    onPressEnter = (name,value) => {
        const { props: { handleFilterCsvProbe }, state: { filterObject } } = this;
        const strValue = value ? value.toString() : value ;
        const newFilterObject = {...filterObject, [name]:strValue}

        this.setState({
            filterObject : newFilterObject
        });
        handleFilterCsvProbe(newFilterObject);
    };
    handleOnSubmit = (event) => {
        event.preventDefault();
        const { props: { handleFilterCsvProbe }, state: { filterObject } } = this;
        handleFilterCsvProbe(filterObject);
    };

    handleOnChange = (event) => {

        const { target: { name, value } } = event ;
        const { state: { filterObject } } = this ;
        this.setState({
            filterObject: {
                ...filterObject,
                [name]:value
            }
        })
    };

    handleOnSelectChange = (name,value) => {

        const { state: { filterObject } } = this ;
        this.setState({
            filterObject: {
                ...filterObject,
                [name]:value
            }
        })
    };

}

const mapStateToProps = state => ({
    distinctFilterParams : state.globalSettings.distinctFilterParams,
    folderLevels         : state.globalSettings.folderLevels
});

const mapDispatchToProps = dispatch => ({
    handleFilterCsvProbe : (filterObject) => dispatch(globalSettingsActions.FilterCsv(filterObject))

});


export default connect(mapStateToProps, mapDispatchToProps)(SidebarAcquisitionCsvFilter)