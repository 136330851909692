import React from 'react';
import {TabBar, TabPanel, Tab} from "../../../elements/Tab";
import AlertHeader from "../AlertingHeader/AlertingHeader";

import './AlertingSetup.scss';
import AlertSetupRecipients from "./AlertSetupRecipients";
import AlertSetupNotificationTemplates from "./AlertSetupNotificationTemplates/AlertSetupNotificationTemplates";
import AlertSetupTriggers from "./AlertSetupTriggers";
import AlertSetupAlerts from "./AlertSetupAlerts";
import AlertSetupStatus from "./AlertSetupStatus";

import Translate from '../../Translate/Translate';


const AlertingSetup = () => {
    const initialOptions = {
        componentName: <Translate string={"ALERTING.ALERTS_SETUP"}/>,
        breadcrumb: null,
        section: 'setup',
        datePicker: false
    };
    return (
        <div className="app-container flex flex-direction-column">
            <AlertHeader options={initialOptions}/>
            <div className="flex flex-direction-column alerting-setup-content">
                <div className="alerting-setup-wrap flex flex-direction-column">
                    <TabBar initialTab="tab-1">
                        <div className="tab-header flex flex-justify-content-end">
                            <nav className='tab-navigation'>
                                <Tab tab="tab-1" tag="span">
                                    <button><Translate string={"ALERTING.TRIGGERS"}/></button>
                                </Tab>
                                <Tab tab="tab-2" tag="span">
                                    <button><Translate string={"ALERTING.RECIPIENTS"}/></button>
                                </Tab>
                                <Tab tab="tab-3" tag="span">
                                    <button><Translate string={"GLOBAL.STATUS"}/></button>
                                </Tab>
                                <Tab tab="tab-4" tag="span">
                                    <button><Translate string={"ALERTING.NOTIFICATION_TEMPLATES"}/></button>
                                </Tab>
                                <Tab tab="tab-5" tag="span">
                                    <button><Translate string={"ALERTING.ALERTS"}/></button>
                                </Tab>
                            </nav>
                        </div>
                        <div className="tab-content">
                            <main>
                                <TabPanel whenActive="tab-1">
                                    <AlertSetupTriggers />
                                </TabPanel>
                                <TabPanel whenActive="tab-2">
                                    <AlertSetupRecipients/>
                                </TabPanel>
                                <TabPanel whenActive="tab-3">
                                    <AlertSetupStatus />
                                </TabPanel>
                                <TabPanel whenActive="tab-4">
                                    <AlertSetupNotificationTemplates />
                                </TabPanel>
                                <TabPanel whenActive="tab-5">
                                    <AlertSetupAlerts/>
                                </TabPanel>
                            </main>
                        </div>
                    </TabBar>
                </div>
            </div>
        </div>
    );
};

export default AlertingSetup;