
const validator = {
    name: {
        rules: [
            {
                test: /^ *$/,
                message: 'Can\'t be blank.',
            },
        ],
        errors: [],
        valid: false,
        invalid: false,
        state: '',
    },
    phone: {
        rules: [
            {
                test: (value)=>{
                    return /([+(\d]{1})(([\d+() -.]){4,16})([+(\d]{1})$/.test(value)
                },
                message: 'Invalid phone number',
            },
            // {
            //     test: /^ *$/,
            //     message: 'Can\'t be blank.',
            // },
        ],
        errors: [],
        valid: false,
        invalid: true,
        state: '',
    },
    email: {
        rules: [
            {
                test: (value)=>{
                    // eslint-disable-next-line
                    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
                },
                message: 'Invalid email address',
            },
            {
                test: /^ *$/,
                message: 'Can\'t be blank.',
            },
        ],
        errors: [],
        valid: false,
        invalid: true,
        state: '',
    },

};

export default validator;