import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './DateRangeSlider.scss';

import {Button, DatePicker, Slider} from "antd";
import Translate from "../Translate/Translate";

const DateRangeSlider = (props) => {
    const {dateRangeProps, sliderProps, from, to, onChange, style, showTimeShortcuts = 'none' } = props;
    const [startDefault, setStartDefault] = useState(moment(from));
    const [endDefault, setEndDefault] = useState(moment(to));
    const [sliderRange, setSliderRange] = useState([]);
    const [sliderMinMax, setSliderMinMax] = useState({});
    const [isEndOpen, setEndOpen] = useState(false);

    useEffect(() => {
        if (from && to) {
            handleDateRangeOk({from: moment(from),to: moment(to)});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to, setStartDefault, setEndDefault, setSliderRange]);

    const disabledStartDate = startValue => {
        if (!startValue || !endDefault) {
            return false;
        }
        return moment.duration(endDefault.diff(startValue)).asHours() < 1;
    };

    const disabledEndDate = endValue => {
        if (!endValue || !startDefault) {
            return false;
        }
        return moment.duration(endValue.diff(startDefault)).asHours() < 1;
    };

    const handleStartOpenChange = open => {
        if (!open) {
            setEndOpen(true);
        }
    };

    const handleEndOpenChange = open => {
        setEndOpen(open);
    };

    const handleDateRangeOk = (arg) => {

        let {from, to} = arg;

        if (arg instanceof Array) {

            [from, to] = arg;

            from = moment.unix(from);
            to   = moment.unix(to);
        }else{
            setSliderMinMax({
                from,
                to
            })            
        }
        
        setStartDefault(from);
        setEndDefault(to);
        setSliderRange([from.unix(), to.unix()]);

        typeof onChange === 'function' &&
        onChange([from.format('YYYY-MM-DDTHH:mm:ssZ').toString(), to.format('YYYY-MM-DDTHH:mm:ssZ').toString()]);
    };

    const handleSliderChange = (arg) => {
        setSliderRange(arg);
        setStartDefault(moment.unix(arg[0]));
        setEndDefault(moment.unix(arg[1]));
    };

    const setDateAndTime = (type) => {
        const definedStartRange = moment().subtract(1, type);
        const definedEndRange = moment();

        setSliderMinMax({
            from: definedStartRange,
            to: definedEndRange
        });

        setSliderRange([definedStartRange.unix(), definedEndRange.unix()]);
        setStartDefault(definedStartRange);
        setEndDefault(definedEndRange);

        typeof onChange === 'function' &&
        onChange([definedStartRange.format('YYYY-MM-DDTHH:mm:ssZ').toString(), definedEndRange.format('YYYY-MM-DDTHH:mm:ssZ').toString()]);
    };

    return (
        <div className="viewer-date-picker flex flex-direction-column" style={{...style}}>
            <div className='dashboard-view-slider flex flex-align-items-center'>
                <DatePicker
                    disabledDate={disabledStartDate}
                    format="YYYY MMM DD, HH:mm:ss"
                    value={startDefault}
                    placeholder="Start"
                    onChange={setStartDefault}
                    onOk={(value) => handleDateRangeOk({from:value, to:endDefault})}
                    onOpenChange={handleStartOpenChange}
                    className={props.fromDashboard ? 'datepicker-slider-start slider-min-width' : 'datepicker-slider-start'}
                    showToday={false}
                    showTime
                    {...dateRangeProps}
                />
                <Slider
                    min={sliderMinMax.from ? sliderMinMax.from.unix() : moment(from).unix()}
                    max={sliderMinMax.to ? sliderMinMax.to.unix() : moment(to).unix()}
                    range
                    value={sliderRange}
                    onChange={handleSliderChange}
                    onAfterChange={handleDateRangeOk}
                    tooltipVisible={false}
                    {...sliderProps}
                />
                <DatePicker
                    disabledDate={disabledEndDate}
                    format="YYYY MMM DD, HH:mm:ss"
                    value={endDefault}
                    placeholder="End"
                    onChange={setEndDefault}
                    onOk={(value) => handleDateRangeOk({from:startDefault, to:value})}
                    onOpenChange={handleEndOpenChange}
                    className={props.fromDashboard ? 'datepicker-slider-end slider-min-width' : 'datepicker-slider-end'}
                    showToday={false}
                    showTime
                    open={isEndOpen}
                    {...dateRangeProps}
                />
            </div>
            <div className="viewer-predefine-date" style={{display: showTimeShortcuts}}>
                <ul className="flex flex-align-items-center flex-justify-content-center dashboard-view-predefine-date-wrap">
                    <li><Button type={'button'} onClick={() => setDateAndTime('hour')}><Translate string={"GLOBAL.LAST_HOUR"}/></Button></li>
                    <li><Button type={'button'} onClick={() => setDateAndTime('day')}><Translate string={"GLOBAL.LAST_DAY"}/></Button></li>
                    <li><Button type={'button'} onClick={() => setDateAndTime('week')}><Translate string={"GLOBAL.LAST_WEEK"}/></Button></li>
                    <li><Button type={'button'} onClick={() => setDateAndTime('month')}><Translate string={"GLOBAL.LAST_MONTH"}/></Button></li>
                    <li><Button type={'button'} onClick={() => setDateAndTime('year')}><Translate string={"GLOBAL.LAST_YEAR"}/></Button></li>
                </ul>
            </div>
        </div>
    );
};

export default DateRangeSlider;

DateRangeSlider.propTypes = {
    from : PropTypes.string,
    to   : PropTypes.string,
    onChange : PropTypes.func,
    showTimeShortcuts : PropTypes.bool,
    style : PropTypes.object,
    dateRangeProps : PropTypes.object,
    sliderProps : PropTypes.object
};
