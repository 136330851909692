import React, {Component} from "react";
import {Button, Icon, Pagination, Checkbox, Tooltip, notification} from "antd";
import "./Mqtt.scss"
import MqttItem from "./MqttItem/MqttItem";

import {connect} from 'react-redux';
import {globalSettingsActions} from '../../../../../actions'
import {handleDownloadProps, clipboradField, TranslateText} from "../../../../../config/constants";
import UploadModal from "../../../../UploadModal/UploadModal";
import * as globalSettingActions from "../../../../../actions/globalSettings";
import {uploadProbe} from "../../../../../actions/uploadProbe";
import Translate from "../../../../Translate/Translate";
class Mqtt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mqttProbes: [],
            shown: false,
            visible: false
        }
    }

    componentDidMount() {
        const {GetProbesForMqtt, selectedId} = this.props;
        GetProbesForMqtt(selectedId);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mqttProbes !== prevProps.mqttProbes){
            this.setMqttProbesList(this.props.mqttProbes);
        }
        if (prevProps.selectedId !== this.props.selectedId) {
            const {GetProbesForMqtt, selectedId} = this.props;
            GetProbesForMqtt(selectedId);
        }
        if (this.props.mqttFilterObject !== prevProps.mqttFilterObject){
            this.props.UnCheckAllCheckedMqtt()
        }
    };

    render() {
        const {
            props: {
                HandleCheckedMqttProbe,
                HandleCheckAllMqttProbes,
                checkedMqttProbes,
                mqttProbesMetaData,
                DownloadTemplate,
                handleDefaultOpenTab,
                allDataLoggers,
                isGettingProbeSeries
            },
            state: {
                mqttProbes
            }
        } = this;

        const checkedAll = mqttProbes && mqttProbes.every((mqqtProbe)=>checkedMqttProbes.includes(mqqtProbe.id))
        return (
            mqttProbesMetaData ?
                <div className={'mqtt-container'}>

                    <div className={'header'}>
                        <Checkbox onChange={event => HandleCheckAllMqttProbes(event.target.checked,mqttProbes)}
                                  checked={checkedAll}/>
                        <span className="selected-probes" style={{marginLeft:"10px"}}><Translate string={"GLOBAL.SELECT_ALL"}/></span>
                        {checkedMqttProbes.length > 0 && <span className="selected-probes" style={{marginLeft:"10px"}}> Selected {checkedMqttProbes.length} - {mqttProbesMetaData.totalRecords}</span>}
                    
                        {/* <span
                            className="showing-per-page"><Translate string={"GLOBAL.SHOWING"}/> {mqttProbes.length < 10 ? mqttProbesMetaData.totalRecords
                            : (mqttProbesMetaData.perPage * mqttProbesMetaData.previousPage === mqttProbes.length ?
                                mqttProbesMetaData.perPage * mqttProbesMetaData.previousPage : mqttProbesMetaData.perPage * mqttProbesMetaData.previousPage + mqttProbes.length)}
                                 - {mqttProbesMetaData.totalRecords}</span> */}

                    </div>
                    <div className={'main-content'}>
                        {// eslint-disable-next-line
                        mqttProbes.map((item, index) => {
                            if(item.dataLogger && item.dataLogger.connections && item.dataLogger.connections.mqtt) {
                                const checked = this.defaultChecked(item.id);

                                return (<MqttItem
                                    HandleCheckedMqttProbe={e => HandleCheckedMqttProbe(item.id, e.target.checked)}
                                    item={item}
                                    key={index}
                                    onBlurMqtt={(e) => this.mqttValueHandler(e, index)}
                                    onChangeSelectOptionMqtt={(e, options) => this.selectOptionOnMqtt(e, options, index)}
                                    allDataLoggers={allDataLoggers}
                                    onChangeMqtt={(e) => this.onChange(e, index)}
                                    expand={this.state.shown[index]}
                                    onToggle={() => this.toggle(index)}
                                    defaultChecked={checked}
                                    handleDefaultOpenTab={handleDefaultOpenTab}
                                    clipboradField={clipboradField}

                                />)
                            }
                        })}
                    </div>
                    <div className={'footer'}>
                        <div className={'footer-download-upload-buttons'} />
                        <div className={'footer-pagination'}>
                            <Pagination
                                defaultCurrent={1}
                                total={mqttProbesMetaData.totalRecords}
                                onChange={this.onChangePage}
                                size={'small'}
                                current={mqttProbesMetaData.currentPage}
                            />
                        </div>
                        <div className={'footer-save-add-buttons'}>
                            <Tooltip placement="top" title={checkedMqttProbes.length === 0 ? <Translate string={"FILTERING.NO_SELECTED_PROBE"}/> : ''}>
                                <Button onClick={() => this.handleDownloadSeriesButton()} disabled={isGettingProbeSeries || checkedMqttProbes.length === 0}>
                                    <Icon type="arrow-down" style={{fontSize: '17px'}}/>
                                    <Translate string={"GLOBAL.EXPORT_SELECTED"}/>
                                </Button>
                            </Tooltip>
                        </div>
                        <UploadModal
                            title={<Translate string={"GLOBAL.UPLOAD_PROBE"}/>}
                            uploadFlag={"probeMqtt"}
                            visible={this.state.visible}
                            uploadFiles={this.props.uploadProbe}
                            callBack={this.toggleUploadModal}
                            DownloadTemplate={() => DownloadTemplate(require('../../../../../assets/template/ProbesUploadTemplate.csv'))}
                        />
                    </div>
                </div> : null
        )
    }

    toggleUploadModal = () => {
        this.setState({
            ...this.state,
            visible: !this.state.visible
        });
    };

    selectOptionOnMqtt = (name, value, index) => {
        const event = {
            target: {
                name: name,
                value: value
            }
        };
        this.onChange(event, index);
        this.mqttValueHandler(event, index);
    };
    handleChannelPreview = (mqttProbe, index) => {
        if (mqttProbe[index].dataLogger.connections.mqtt) {
            const token = mqttProbe[index].dataLogger.connections.mqtt.token
            const channel = mqttProbe[index].dataLogger.connections.mqtt.channel
            const id = mqttProbe[index].dlProbeId || "";
            const typeOfVariable = mqttProbe[index].dataLogger.connections.mqtt.typeOfVariable;
            if(typeOfVariable.length === 0){
                return `${token}/${channel}/${id}`;

            }
            return `${token}/${channel}/${id}/${typeOfVariable}`;
        }
    }
    onChange = (e, index) => {
        let spreadMqttProbes = [...this.state.mqttProbes.map(item => {
                item.dataLogger={...item.dataLogger}
                return {...item}
            }
        )];

        if (e.target.name === "dlId") {
            const currentDataLogger = this.props.allDataLoggers.find(dl => parseInt(dl.id) === parseInt(e.target.value));
            const note = currentDataLogger.note;

            spreadMqttProbes[index]["dataLogger"] = currentDataLogger ;
            spreadMqttProbes[index].dlId = currentDataLogger.id;
            spreadMqttProbes[index].channelPreview = this.handleChannelPreview(spreadMqttProbes, index)
            spreadMqttProbes[index]["note"] = note;

            notification.success({
                message: TranslateText("GLOBAL_SETTINGS.PLEASE_RESTART_CONSUMER"),
                description: `${TranslateText("GLOBAL.DATA_LOGGER")}: ${currentDataLogger.name}.  ${TranslateText("GLOBAL.SOURCE_TYPE")}: ${spreadMqttProbes[index].connectionType}`,
                style: {
                    width: 400,
                    wordBreak: "break-word"
                },
                duration: 0
            });

        }
        if (e.target.name === "typeOfVariable") {
            spreadMqttProbes[index]["dataLogger"]["connections"]["mqtt"][e.target.name] = e.target.value;
            spreadMqttProbes[index].channelPreview = this.handleChannelPreview(spreadMqttProbes, index)
            this.setState({mqttProbes: spreadMqttProbes});
            return
        }
        if (e.target.name === "note") {
            spreadMqttProbes[index].dataLogger[e.target.name] = e.target.value;
            this.setState({mqttProbes: spreadMqttProbes});
            return
        }

        if (e.target.name === "dlProbeId") {
            notification.success({
                message: TranslateText("GLOBAL_SETTINGS.PLEASE_RESTART_CONSUMER"),
                description: `${TranslateText("GLOBAL.DATA_LOGGER")}: ${spreadMqttProbes[index].dataLogger.name}.  ${TranslateText("GLOBAL.SOURCE_TYPE")}: ${spreadMqttProbes[index].connectionType}`,
                style: {
                    width: 400,
                    wordBreak: "break-word"
                },
                duration: 0
            });
            spreadMqttProbes[index].dlProbeId = e.target.value.trim().replace(/\s\s+/g, '-');
            this.setState({mqttProbes: spreadMqttProbes});
            return
        }

        spreadMqttProbes[index][e.target.name] = e.target.value;
        spreadMqttProbes[index].channelPreview = this.handleChannelPreview(spreadMqttProbes, index);

        this.setState({mqttProbes: spreadMqttProbes})
    };

    mqttValueHandler = (e, index) => {
        const { mqttProbes } = this.state;

        const payload = {
            [e.target.name]: e.target.value
        };

        const currentMqtt={...this.state.mqttProbes[index]};
        const previousMqtt={...this.props.mqttProbes.find((item)=>item.id===currentMqtt.id)};

        if (e.target.name === "note") {

            if (currentMqtt.dataLogger.note !== previousMqtt.dataLogger.note) {
                this.updateMqttDataLogger(payload,mqttProbes[index].dlId)
            }
            return
        }

        if (e.target.name === "typeOfVariable"){
            this.updateMqttConnection({...{id:mqttProbes[index].dataLogger.connections.mqtt.id}, ...payload});
            return
        }


        let currentInput = currentMqtt[e.target.name] ;
        let previousInput  = previousMqtt[e.target.name];

        if (currentInput !== previousInput || e.target.name === "dlId") {
            this.updateMqttProbe(payload, mqttProbes[index].id);
        }

    };

    toggle(panelNumber) {
        this.setState({
            shown: {
                ...this.state.shown,
                [panelNumber]: !this.state.shown[panelNumber]
            }
        });
    }

    updateMqttDataLogger = (payload, dlId) => {
        const {UpdateDataLoggerAcqusitionSetupMqtt} = this.props;
        UpdateDataLoggerAcqusitionSetupMqtt(payload, dlId);
    };


    defaultChecked = probeId => {
        const {checkedMqttProbes} = this.props;
        return checkedMqttProbes.includes(probeId);
    };

    updateMqttProbe = (payload, probeId) => {
        const {UpdateAcqusitionSetupMqttProbe} = this.props;
        UpdateAcqusitionSetupMqttProbe(payload, probeId);
    };

    updateMqttConnection = (payload) => {
        const {UpdateMqtt} = this.props;
        UpdateMqtt(payload);
    };

    setMqttProbesList = (mqttProbesList) => {

        let probesWithMqttConnection=[];
        // eslint-disable-next-line
        mqttProbesList.map((mqttProbe) => {
            if (mqttProbe.dataLogger && mqttProbe.dataLogger.connections  && mqttProbe.dataLogger.connections.mqtt) {
                const token = mqttProbe.dataLogger.connections.mqtt.token
                const channel = mqttProbe.dataLogger.connections.mqtt.channel;
                const id = mqttProbe.dlProbeId || "";
                const typeOfVariable = mqttProbe.dataLogger.connections.mqtt.typeOfVariable;

                if(typeOfVariable.length === 0){
                    mqttProbe.channelPreview = `${token}/${channel}/${id}`;
                }
                if(typeOfVariable.length !== 0) {
                    mqttProbe.channelPreview = `${token}/${channel}/${id}/${typeOfVariable}`;
                }
                probesWithMqttConnection.push(mqttProbe)
            }
        });
        this.setState({
            mqttProbes: [...probesWithMqttConnection]
        });
    };

    onChangePage = pageNumber => {
        const { FilterMqtt } = this.props
        FilterMqtt(this.props.mqttFilterObject,pageNumber)
    };
    handleDownloadProbesToCSV = () => {

        const {props: {ExportSelectedProbes, checkedMqttProbes, selectedId, ExportAcquisitionProbes}} = this;

        let data = {
            folderId: selectedId,
            name: 'mqttExport'
        };

        if (checkedMqttProbes && checkedMqttProbes.length > 0) {
            handleDownloadProps("mqttExport", ExportSelectedProbes, checkedMqttProbes)
        } else
            ExportAcquisitionProbes(data, 'mqtt')
    }

    handleDownloadSeriesButton = () => {

        const{props:{checkedMqttProbes, selectedId, DownloadProbeSeries, handleDownloadSeries}} = this;

        notification.success({
            message: TranslateText("GLOBAL.NOTIFICATION"),
            description: TranslateText("GLOBAL.PREPARING_CSV"),
            style: {
                width: 400,
                wordBreak: "break-word"
            },
            duration: 3
        });

        DownloadProbeSeries(checkedMqttProbes, selectedId, handleDownloadSeries);
    }
}

const mapStateToProps = state => ({
    allDataLoggers: state.globalSettings.allDataLoggers,
    mqttProbes: state.globalSettings.mqttProbes,
    checkedMqttProbes: state.globalSettings.checkedMqttProbes,
    mqttProbesMetaData: state.globalSettings.mqttProbesMetaData,
    selectedId: state.globalSettings.selectedId,
    isGettingProbeSeries: state.globalSettings.isGettingProbeSeries,
    mqttFilterObject:state.globalSettings.mqttFilterObject
});

const mapDispatchToProps = dispatch => ({
    DownloadTemplate: (pathFile) => dispatch(globalSettingsActions.DownloadTemplate(pathFile)),
    GetProbesForMqtt: (folderId) => dispatch(globalSettingsActions.GetProbesForMqtt(folderId)),
    UpdateAcqusitionSetupMqttProbe: (probe, probeId) => dispatch(globalSettingsActions.UpdateAcqusitionSetupMqttProbe(probe, probeId)),
    UpdateMqtt: (mqtt) => dispatch(globalSettingsActions.UpdateMqtt(mqtt)),
    GetMqttById: (mqttid) => dispatch(globalSettingsActions.GetMqttById(mqttid)),
    HandleCheckedMqttProbe: (mqttProbeId, checked) => dispatch(globalSettingsActions.HandleCheckedMqttProbe(mqttProbeId, checked)),
    HandleCheckAllMqttProbes: (checkAll,mqttProbes) => dispatch(globalSettingsActions.HandleCheckAllMqttProbes(checkAll,mqttProbes)),
    PaginationForMqtt: (page, folderId) => dispatch(globalSettingsActions.PaginationForMqtt(page, folderId)),
    UpdateDataLoggerAcqusitionSetupMqtt: (probe, dlId) => dispatch(globalSettingsActions.UpdateDataLoggerAcqusitionSetupMqtt(probe, dlId)),
    ExportSelectedProbes: (exportName) => dispatch(globalSettingsActions.ExportSelectedProbes(exportName)),
    ExportAcquisitionProbes: (data, type) => dispatch(globalSettingActions.ExportAcquisitionProbes(data, type)),
    uploadProbe          : (fileList,flag)        => dispatch(uploadProbe(fileList,flag)),
    DownloadProbeSeries : (checkedFtpProbes, selectedId, handleDownloadSeries) => dispatch(globalSettingsActions.DownloadProbeSeries(checkedFtpProbes, selectedId, handleDownloadSeries)),
    handleDownloadSeries : (disabledState) => dispatch(globalSettingsActions.handleDownloadSeries(disabledState)),
    FilterMqtt : (mqttFilterObject,page) => dispatch(globalSettingsActions.FilterMqtt(mqttFilterObject,page)),
    UnCheckAllCheckedMqtt:()=>dispatch(globalSettingsActions.UnCheckAllCheckedMqtt())
});

export default connect(mapStateToProps, mapDispatchToProps)(Mqtt);
