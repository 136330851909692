import React     from 'react';
import { Input } from 'antd';

import Translate from "../../../../Translate/Translate";
import './ValidPercentileParameters.scss';

const ValidPercentileParameters = (props) => {
  return (
      <div className=" flex filter-percentile-parameters-wrapper">
          <p><Translate string={"FILTERING.PARAMETERS"}/>:</p>
          <div className="flex filter-bounds-wrapper">
              <p className="filter-bound-label"><Translate string={"FILTERING.MAX_PERCENT"}/> :</p>
              <Input
                  name={'maxValue'}
                  type="number"
                  value={props.filterType.maxValue}
                  className="filter-input-bound"
                  onChange={props.parameterValueHandler}
                  addonAfter={<span>%</span>}
              />
          </div>
          <div className="flex filter-bounds-wrapper">
              <p className="filter-bound-label"><Translate string={"FILTERING.MIN_PERCENT"}/> :</p>
              <Input
                  name={'minValue'}
                  type="number"
                  value={props.filterType.minValue}
                  className="filter-input-bound"
                  onChange={props.parameterValueHandler}
                  addonAfter={<span>%</span>}
              />
          </div>
      </div>
  );
};

export default ValidPercentileParameters;
