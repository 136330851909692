import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Icon, Input, Pagination} from "antd";

import {LoaderBar} from "../AlertSetupLoader/AlertSetupLoader";
import AlertsSetupNoData from "../AlertsSetupNoData/AlertsSetupNoData";
import Modal from "../../../../elements/Modal/Modal";
import {ModalClose, TranslateText} from "../../../../config/constants";
import {
    getNotificationTemplates,
    createNotificationTemplate,
    deleteNotificationTemplate,
    updateNotificationTemplate
} from "../../../../api/alerting/alerting";
import NotificationTemplatesItem from "./NotificationTemplatesItem/NotificationTemplatesItem";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import Translate from '../../../Translate/Translate';

import "@webscopeio/react-textarea-autocomplete/style.css";
import './AlertSetupNotificationTemplates.scss'

const AlertSetupNotificationTemplates = () => {
    const [isModalOpen, toggleModal] = useState(false);
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState({
        name: "",
        message: "",
        sms: false,
        email: false
    });
    const [notTemplate, setNotTemplate] = useState({
        name: "",
        message: "",
        sms: false,
        email: false
    })
    const [loader, setLoader] = useState(true);
    const [type, setType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState([]);
    const [search, setSearch] = useState({text: ''});
    const [isSearch, setIsSearch] = useState(false);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isTypeValid, setIsTypeValid] = useState(true)
    const [loadingSearch, setLoadingSearch] = useState(false);

    let tags = [
        {name: "nodename", char: "{nodename}"},
        {name: "probename", char: "{probename️}"},
        {name: "statusname", char: "{statusname}"},
        {name: "alertname", char: "{alertname}"},
        {name: "timestamp", char: "{timestamp}"},
        {name: "dataloggername", char: "{dataloggername}"},
        {name: "recipientname", char: "{recipientname}"},
        {name: "triggervalue", char: "{triggervalue}"}
    ];

    useEffect(() => {
        getAllNotificationTemplates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(isSearch){
            getAllNotificationTemplates();
            setIsSearch(false);
            searchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search]);


    const getAllNotificationTemplates = (page, filters) => {
        setLoader(true);
        getNotificationTemplates(page, filters || {name: search.text}).then(res => {
            if (res && res.data) {
                setData(res.data);
                setPagination(res.meta);
                setTimeout(() => {
                    setLoader(false);
                    setLoadingSearch(false);
                }, 1000)
            }
        })

    };
    const createNewNotificationTemplate = () => {

        if(notTemplate.name.length > 0 && (notTemplate.sms || notTemplate.email)) {
            setLoading(true);
            let payload = {
                name: notTemplate.name,
                message: notTemplate.message,
                sms: notTemplate.sms,
                email: notTemplate.email
            };
            createNotificationTemplate(payload)
                .then(() => {
                    toggleModal(false);
                    setTimeout(() => {
                        getAllNotificationTemplates();
                        setLoading(false);
                    }, 500);
                    closeModal();
                });
        }
        if(notTemplate.name.length === 0){
            setIsNameValid(false);
        }
        if(!notTemplate.sms && !notTemplate.email) {
            setIsTypeValid(false);
        }

    };

    const updateSelectedNotificationTemplate = () => {

        if(editData.name.length > 0 && (editData.sms || editData.email)) {
            setLoading(true);
            let payload = {
                name: editData.name,
                message: editData.message,
                sms: editData.sms,
                email: editData.email
            };
            updateNotificationTemplate(editData.id, payload)
                .then(() => {
                    toggleModal(false);
                    setTimeout(() => {
                        getAllNotificationTemplates();
                        setLoading(false);
                    }, 500);
                    closeModal()
                });
        }
        if(editData.name.length === 0){
            setIsNameValid(false);
        }
        if(!editData.sms && !editData.email) {
            setIsTypeValid(false);
        }
    };

    const delNotificationTemplate = (e) => {
        setLoading(true);
        if (e) {
            deleteNotificationTemplate(e)
                .then(() => {
                    setTimeout(() => {
                        getAllNotificationTemplates();
                        setLoading(false);
                        setIsSearch(false)
                    }, 500);
                })
        }
    };

    const editNotificationTemplate = (payload) => {
        setEditData(payload);
        setTimeout(() => {
            toggleModal(true)
        }, 100)
    };

    const onChange = (e) => {
        type === 'edit' ? setEditData({...editData, [e.target.name]: e.target.value}) :
            setNotTemplate({...notTemplate, [e.target.name]: e.target.value});
        e.target.name === 'name' && e.target.value.length === 0 ? setIsNameValid(false) : setIsNameValid(true);
    };
    const onCheckBoxChange = e => {
        type === 'edit' ? setEditData({...editData, [e.target.name]: e.target.checked}) :
        setNotTemplate({...notTemplate, [e.target.name]: e.target.checked});
        (e.target.name === 'sms' || e.target.name === 'email') && setIsTypeValid(true);
    };

    const closeModal = () => {
        toggleModal(false);
        setEditData({
            name: "",
            message: "",
            sms: false,
            email: false
        });
        setType(null);
        setNotTemplate({
            name: "",
            message: "",
            sms: false,
            email: false
        });
        setIsNameValid(true);
        setIsTypeValid(true);
    };

    const searchData = () => {
        if (search) {
            setLoadingSearch(true);
            setTimeout(() => {
                getAllNotificationTemplates();
                setLoadingSearch(false);
            }, 500)
        }
    };
    const onChangePage = e => {
        getAllNotificationTemplates(e);
    };
    const isChange = (e) => {
        setSearch({...search, text: e.target.value});
        if (e.target.value.length === 0){
            setIsSearch(true)
        }
    };

    const addTagToMessage = (tag) => {
        type === 'edit' ? setEditData({...editData, message: `${editData.message}${tag}`}) :
            setNotTemplate({...notTemplate, message: `${notTemplate.message}${tag}`});
    }

    const Item = ({entity: {name}}) => <div>{`${name}`}</div>;

    const TagItem = ({item}) => {
        return (
            <kbd className={'tag-item'} onClick={() => addTagToMessage(item.char)}>
                {item.char}
            </kbd>
        )
    }

    return (
        <div className='alert-setup-template flex flex-direction-column'>
            <div className="alert-setup-template-header flex flex-justify-content-space-between flex-align-items-center">
                <div
                    className="alert-setup-template-search flex flex-align-items-center flex-justify-content-space-between">
                    <Input
                        name={"unit"}
                        type={"text"}
                        placeholder={TranslateText("GLOBAL.SEARCH")}
                        autoComplete='off'
                        allowClear
                        onChange={isChange}
                        className='alerting-search-input'
                        onPressEnter={!loadingSearch && searchData}
                    />
                    <div className="form-input flex flex-direction-column alerting-alerts-header-cta">
                        <Button type="primary" onClick={searchData} icon="search" disabled={loadingSearch || search.text.length === 0}><Translate string={"GLOBAL.SEARCH"}/></Button>
                    </div>
                </div>
                {loadingSearch && <div className="alerting-list-loader">
                    <Icon type="loading" style={{color: '#2980B9'}}/>
                </div>}
            </div>
            <div className="alert-setup-template-content">
                <div className="template-list" style={{height: '100%'}}>
                    {loader ?
                        <LoaderBar/> :
                        data && data.length > 0 ? data.map((item, index) => (
                                <NotificationTemplatesItem
                                    key={index}
                                    name={item.name}
                                    message={item.message}
                                    email={item.email}
                                    sms={item.sms}
                                    itemIndex={index}
                                    total={data.length}
                                    id={item.id}
                                    onDelete={delNotificationTemplate}
                                    editNotificationTemplate={(status) => {
                                        editNotificationTemplate(status);
                                        setType('edit')
                                    }}
                                />)) :  search.text.length !== 0 ? <div></div> :
                            <AlertsSetupNoData title={'You have not created any status notification template'}
                                               subtitle={'Add your first notification template'}
                                               button={'Add new notification template'}
                                               isButtonClick={() => {
                                                toggleModal(!isModalOpen);
                                                setType('new');
                                               } }
                                               description={''}
                            />
                    }
                </div>
            </div>
            <footer className='flex'>
                <div className="footer-wrap flex-align-items-center flex-justify-content-center modal-status">
                    <div className="empty-field"/>
                    <div className="alert-setup-pagination flex flex-justify-content-center">
                        {data && data.length > 0 && <Pagination
                            defaultCurrent={1}
                            total={pagination.totalRecords}
                            onChange={onChangePage}
                            size={'small'}
                            current={pagination.currentPage}
                        />}
                    </div>
                    <div className="alert-setup-action flex flex-justify-content-end">
                        <Button className="add-button btn btn-green"
                                type='button'
                                icon={'plus'}
                                onClick={() => {
                                    toggleModal(!isModalOpen);
                                    setType('new');
                                }}
                        />
                    </div>
                    <Modal isOpen={isModalOpen} toggle={toggleModal} size={'450px'}>
                        <form className="form-template">
                            <div className="form-wrap">
                                <div className="form-header flex flex-justify-content-space-between">
                                    <h1 className="form-header-title">
                                        {type === 'edit' ? <Translate string={"ALERTING.EDIT_NOTIFICATION_TEMPLATE"}/> : <Translate string={"ALERTING.NEW_NOTIFICATION_TEMPLATE"}/>}
                                    </h1>
                                    <ModalClose className={'modal-close'} onClick={closeModal}/>
                                </div>
                                <div className="form-body flex">
                                    <div
                                        className="form-body-wrap flex flex-align-items-center flex-justify-content-center flex-direction-column">
                                        <div className="form-input">
                                            <div className="flex flex-justify-content-space-between">
                                                <label><Translate string={"GLOBAL.NAME"}/>*</label>
                                                {!isNameValid && <span className="errMessage"> 
                                                    <Translate string={"GLOBAL_SETTINGS.ERROR_MESSAGE_NAME"}/>
                                                </span>}
                                            </div>
                                            <Input allowClear
                                                   name={'name'}
                                                   placeholder={TranslateText("GLOBAL.NAME")}
                                                   value={
                                                       type === null ? '' : type === 'new' ? notTemplate.name : editData.name
                                                   }
                                                   onChange={onChange}
                                                   autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-input">
                                            <div className="flex flex-justify-content-space-between">
                                                <label><Translate string={"ALERTING.MESSAGE"}/></label>
                                                {(notTemplate.sms || editData.sms) && <span className="warning-message">{type === 'new' ? notTemplate.message.length : editData.message.length}/160</span>}
                                            </div>
                                            <ReactTextareaAutocomplete
                                                className="my-textarea"
                                                onChange={onChange}
                                                loadingComponent={() => <span>Loading</span>}
                                                minChar={1}
                                                name='message'
                                                value={
                                                    type === null ? '' : type === 'new' ? notTemplate.message : editData.message
                                                }
                                                placeholder={TranslateText("ALERTING.MESSAGE")}
                                                trigger={{
                                                    "{": {
                                                        dataProvider: (token) => {
                                                            if (!token || !token.length) {
                                                                return tags
                                                            }
                                                            return tags.filter(prop => {
                                                                if (prop.name.search(new RegExp(token, "i")) !== -1) {
                                                                    return prop
                                                                }
                                                                return null
                                                            })
                                                        },
                                                        component: Item,
                                                        output: (item, trigger) => item.char
                                                    }
                                                }}
                                            />
                                            <span className='legend-name'><Translate string={"ALERTING.ALLOWED_TAGS"}/>:</span>
                                            
                                            <div className="tag-list flex">
                                                {tags.map((tag, index) => <TagItem item={tag} key={`${tag}-${index}`} />)}
                                            </div>
                                        </div>
                                        <div className="form-input">
                                            <div className="flex flex-justify-content-space-between">
                                                <label><Translate string={"ALERTING.TYPE"}/>*</label>
                                                {!isTypeValid && <span className="errMessage"> 
                                                    <Translate string={"ALERTING.MISSING_TYPE"}/>
                                                </span>}
                                            </div>
                                            <div className="form-checkbox-container">
                                                <Checkbox
                                                    onChange={onCheckBoxChange}
                                                    name='sms'
                                                    value={
                                                        type === null ? false : type === 'new' ? notTemplate.sms : editData.sms
                                                    }
                                                    checked={notTemplate && notTemplate.sms ? notTemplate.sms : editData && editData.sms ? editData.sms : false}
                                                ><Translate string={"ALERTING.SMS"}/></Checkbox>
                                                <Checkbox
                                                    onChange={onCheckBoxChange}
                                                    name='email'
                                                    value={
                                                        type === null ? false : type === 'new' ? notTemplate.email : editData.email
                                                    }
                                                    checked={notTemplate && notTemplate.email ? notTemplate.email : editData && editData.email ? editData.email : false}
                                                >
                                                    <Translate string={"ALERTING.E_MAIL"}/>
                                                </Checkbox>
                                            </div>
                                        </div>
                                        {(notTemplate.sms || editData.sms) && 
                                            <div className="form-input">
                                                <span className="warning-message"> 
                                                    <Translate string={"ALERTING.WARNING_SMS_CHARACTERS"}/>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-action">
                                    {loading
                                        ? <button type='button'><Icon type="loading" style={{color: '#fff'}}/></button>
                                        : type === 'edit' 
                                            ? <button type='button' onClick={updateSelectedNotificationTemplate}>
                                            <Translate string={"ALERTING.UPDATE_NOTIFICATION_TEMPLATE"}/>
                                            </button> 
                                            : <button type='button' onClick={createNewNotificationTemplate}>
                                                + <Translate string={"ALERTING.NEW_NOTIFICATION_TEMPLATE"}/>
                                            </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </Modal>
                </div>
            </footer>
        </div>
    );
};

export default AlertSetupNotificationTemplates;