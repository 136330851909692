import React from 'react';
import {Button, Dropdown, Menu, Modal} from "antd";
import Translate from "../../../../Translate/Translate";

import './AlertsItem.scss';

const AlertsItem = props => {
    const menu = (e) => {

        return (<Menu>
            <Menu.Item>
                <Button type='button' onClick={()=>props.onEdit(props)}><Translate string={"GLOBAL.EDIT"}/></Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='button' onClick={showDeleteConfirm}><Translate string={"GLOBAL.DELETE"}/></Button>
            </Menu.Item>
        </Menu>)
    };

    const showDeleteConfirm = () => {

        Modal.confirm({
            title: <Translate string={"ALERTING.DELETE_ALERT_CONFIRM_TITLE"} />,
            content: <Translate string={"ALERTING.DELETE_ALERT_CONFIRM_CONTENT"} />,
            okText: <Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"} />,
            okType: 'danger',
            cancelText: <Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>,
            onOk() {
                props.onDelete(props.id)
            },
            onCancel() {
            },
        });
    };

    return (
        <div className='alerts-item flex'>
            <div className="alerts-item-action" id={`area${props.itemIndex}`}>
                <Dropdown trigger={["click"]} overlay={() => menu(props)}
                          getPopupContainer={() => document.getElementById(`area${props.itemIndex}`)}
                          placement="bottomLeft">
                    <button className={'button-icon'}>
                        <span/>
                        <span/>
                        <span/>
                    </button>
                </Dropdown>
            </div>
            <div className="alerts-data-view">
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"GLOBAL.NAME"}/></label>
                    <div className='flex flex-align-items-center'>
                        <p className={'alerts-status'}> {props.name}</p>
                    </div>
                </div>
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.TRIGGERS"}/></label>
                    <p className={'alerts-status'}>{props.triggers && props.triggers.data && props.triggers.data.map(trigger => `${trigger.name} `)}</p>
                </div>
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.RECIPIENTS"}/></label>
                    <p className={'alerts-status'}>{props.recipients && props.recipients.data && props.recipients.data.map(recipient => `${recipient.name} `)}</p>
                </div>
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"GLOBAL.STATUS"}/></label>
                    <p className={'alerts-status'}>{props.status && props.status.data && props.status.data.map(status => `${status.name} `)}</p>
                </div>
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.NOTIFICATION_TEMPLATE"}/></label>
                    <p className={'alerts-status'}>{props.notification}</p>
                </div>
            </div>
        </div>
    );
};

export default AlertsItem;