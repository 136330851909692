import React, { Component } from "react";

import "./EditDataSourceSettings.css";
import { Input } from "antd";

const { TextArea } = Input;

class EditDataSourceSettings extends Component {
  render() {
    const {
      props: { inputValue }
    } = this;

    return (
      <div className="datasource-name">
        <TextArea autosize={true} value={inputValue} disabled />
        <Input className="input-space"
               // placeholder="Change Data source name"
        />
      </div>
    );
  }
}

export default EditDataSourceSettings;