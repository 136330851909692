import React, { Component }                        from "react";
import { connect }                                 from "react-redux";
import { Icon, Tooltip }                           from "antd";

import { InputSelect, InputText, RangePickerInput } from "../../../../../FormInput/FormInput";

import { globalSettingsActions }                   from "../../../../../../actions";
import { SOURCE_TYPE } from "../../../../../../config/constants";
import Translate from "../../../../../Translate/Translate";

import './SidebarProbeFilter.scss';

class SidebarProbeFilter extends Component {

    state = {
        filterObject : {}
    };

    render() {
        const {
            props:{ distinctFilterParams,folderLevels, allDataLoggers, isApplyDisabled },
            state:{ filterObject },
            handleOnSubmit, onPressEnter, handleTagsInput, handleOnChange, handleOnSelectChange
        } = this;
        return (
            distinctFilterParams && folderLevels &&
            <form className="sidebar-form-filter" onSubmit={handleOnSubmit}>
                <div className="sidebar-filters-header">
                    <div
                        className="sidebar-filters-header-wrap flex flex-justify-content-space-between flex-align-items-center">
                        <h1><Translate string={"GLOBAL.FILTERS"}/></h1>
                        <div className="sidebar-filters-header-action">
                        <Tooltip placement="top" title={isApplyDisabled ? <Translate string={"GLOBAL.VIEW_UNSAVE"}/> : ''}>
                            <button 
                                className={isApplyDisabled ? "btn btn-filter btn-border-black disable-apply-button" : "btn btn-filter btn-border-black" } 
                                type={'submit'} disabled={isApplyDisabled}>
                                <Icon type="filter" theme="filled"/><Translate string={"GLOBAL.APPLY"}/>
                            </button>
                        </Tooltip>
                        </div>
                    </div>

                </div>
                <div className="sidebar-filters-body">
                    <InputText
                        label={<Translate string={"GLOBAL.PROBE_NAME"}/>}
                        name={'name'}
                        handleOnPressEnter={(name,value) => onPressEnter(name,value)}
                        handleOnChange={handleOnChange}
                        value={filterObject.name}
                    />
                    <InputSelect
                        label={<Translate string={"GLOBAL.PATH"}/>}
                        name={'folderId'}
                        onChangeSelect={(name,value) => handleOnSelectChange(name,value)}
                        optionValues={folderLevels}
                        value={filterObject.folderId ? parseInt(filterObject.folderId) : filterObject.folderId}
                    />
                    <InputSelect
                        label={<Translate string={"GLOBAL.UNIT"}/>}
                        name={'unit'}
                        onChangeSelect={(name,value) => handleOnSelectChange(name,value)}
                        optionValues={distinctFilterParams.unit}
                        value={filterObject.unit}
                    />
                    <InputSelect
                        label={<Translate string={"GLOBAL.DATA_TYPE"}/>}
                        name={'dataType'}
                        onChangeSelect={(name, value) => handleOnSelectChange(name, value)}
                        optionValues={distinctFilterParams.data_type}
                        value={filterObject.dataType}
                    />
                    <InputSelect
                        label={<Translate string={"GLOBAL.SOURCE_TYPE"}/>}
                        name={'sourceType'}
                        onChangeSelect={(name, value) => handleOnSelectChange(name, value)}
                        optionValues={SOURCE_TYPE}
                        value={filterObject.sourceType}
                    />
                    <InputSelect
                        label={<Translate string={"GLOBAL.DATA_LOGGER"}/>}
                        name={'dataLoggerId'}
                        onChangeSelect={(name, value) => handleOnSelectChange(name, value)}
                        optionValues={allDataLoggers}
                        value={filterObject.dataLoggerId}
                    />
                    <InputSelect
                        label={<Translate string={"GLOBAL_SETTINGS.TAG"}/>}
                        mode={"tags"}
                        name={'tags'}
                        onChangeSelect={(name, value) => handleTagsInput(name, value)}
                        optionValues={distinctFilterParams.tags || []}
                        value={filterObject.tags}
                    />
                    <InputText
                        label={<Translate string={"GLOBAL.SENSOR"}/>}
                        name={'sensor'}
                        handleOnPressEnter={(name,value) => onPressEnter(name,value)}
                        handleOnChange={handleOnChange}
                        value={filterObject.sensor}
                    />
                    <RangePickerInput
                        label={<Translate string={"GLOBAL.INSTALLATION_DATE"}/>}
                        onChangeData={(name,value) => handleOnSelectChange(name,value)}
                        name={'installedAt'}
                    />
                    <InputText
                        style={{paddingRight:'35px'}}
                        label={<Translate string={"GLOBAL_SETTINGS.INSTALLATION_RESPONSIBLE"}/>}
                        name={'installer'}
                        handleOnPressEnter={(name,value) => handleOnSelectChange(name,value)}
                        handleOnChange={handleOnChange}
                        value={filterObject.installer}
                    />
                    <InputText
                        label={<Translate string={"GLOBAL.ENERGY_VECTOR"}/>}
                        name={'energyVector'}
                        handleOnPressEnter={(name,value) => onPressEnter(name,value)}
                        handleOnChange={handleOnChange}
                        value={filterObject.energyVector}
                    />
                    <InputText
                        label={<Translate string={"GLOBAL.LOCATION"}/>}
                        name={'location'}
                        handleOnPressEnter={(name,value) => onPressEnter(name,value)}
                        handleOnChange={handleOnChange}
                        value={filterObject.location}
                    />
                </div>
            </form>
        )
    }
    onPressEnter = (name,value) => {

        const { props: { handleFilterProbe }, state : { filterObject } } = this;
        const strValue = ( value && name !== "dataLoggerId" ) ? value.toString() : value ;
        const newFilterObject = {...filterObject, [name]:strValue};
        this.setState({
            filterObject : newFilterObject
        });
        handleFilterProbe(newFilterObject);
    };

    handleOnSubmit = (event) => {
        event.preventDefault();
        const { props: { handleFilterProbe }, state: { filterObject } } = this;
        localStorage.setItem("dataInProbeSetupIsChanged","false");
        handleFilterProbe(filterObject);
    };

    handleTagsInput = (name,value) => {
        const {state: { filterObject } } = this;
        const updatedFilterObject = {...filterObject, [name]:value };

        this.setState({
            filterObject : updatedFilterObject
        });
    };

    handleOnChange = (event) => {
        const { target: { name, value } } = event ;
        const { state: { filterObject } } = this ;

        this.setState({
            filterObject: {
                ...filterObject,
                [name]:value
            }
        })
    };

    handleOnSelectChange = (name, value) => {
        const { state: { filterObject } } = this;
        let strValue;
        let strValueSecundParameter;
        if(name === "installedAt"){
            strValue=value[0];
            strValueSecundParameter=value[1];

            this.setState({
                filterObject: {
                    ...filterObject,
                    startTime:strValue,
                    endTime: strValueSecundParameter
                }
            });
            return 
        }

        strValue = (value && name !== "dataLoggerId") ? value.toString() : value;
        this.setState({
            filterObject: {
                ...filterObject,
                [name]:strValue
            }
        })
    }
}
const mapStateToProps = state => ({
    distinctFilterParams : state.globalSettings.distinctFilterParams,
    folderLevels         : state.globalSettings.folderLevels,
    allDataLoggers          : state.globalSettings.allDataLoggers,
    isApplyDisabled      : state.globalSettings.isApplyDisabled
});

const mapDispatchToProps = dispatch => ({
    handleFilterProbe    : (filterObject) => dispatch(globalSettingsActions.FilterProbes(filterObject))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProbeFilter);
