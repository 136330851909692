import React from 'react';
import {Button, Dropdown, Menu, Modal} from "antd";
import Translate from "../../../../Translate/Translate";

import './RecipientsItem.scss'

const RecipientsItem = (props) => {

    const menu = (e) => {

        return (<Menu>
            <Menu.Item>
                <Button type='button' onClick={() => props.editRecipients(props)}><Translate string={"GLOBAL.EDIT"}/></Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='button' onClick={showDeleteConfirm}><Translate string={"GLOBAL.DELETE"}/></Button>
            </Menu.Item>
        </Menu>)
    };
    const showDeleteConfirm = () => {

        Modal.confirm({
            title: <Translate string={"ALERTING.DELETE_CONFIRM_TITLE"} />,
            content: <Translate string={"ALERTING.DELETE_CONFIRM_CONTENT"} />,
            okText: <Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"} />,
            okType: 'danger',
            cancelText: <Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>,
            onOk() {
                props.onDelete(props.id)
            },
            onCancel() {
            },
        });
    };

    return (
        <div className='recipients-item flex'>
            <div className="recipients-action" id={`area${props.itemIndex}`}>
                <Dropdown trigger={["click"]} overlay={() => menu(props)}
                          getPopupContainer={() => document.getElementById(`area${props.itemIndex}`)}
                          placement="bottomLeft">
                    <button className={'button-icon'}>
                        <span/>
                        <span/>
                        <span/>
                    </button>
                </Dropdown>
            </div>
            <div className="recipients-data">
                <div className="recipients-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.FULL_NAME"}/></label>
                    <p className='recipients-user'> {props.name}</p>
                </div>
                <div className="recipients-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.E_MAIL"}/></label>
                    <p> {props.email}</p>
                </div>
                <div className="recipients-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.PHONE"}/></label>
                    <p>{props.phone}</p>
                </div>
            </div>
        </div>
    );
};

export default RecipientsItem;