import React, { Component }                 from 'react';
import { connect }                          from "react-redux";

import { NewTreeCard, TreeCard }            from "../../../TreeCard/TreeCard";
import { Indicator }                        from "../../../Indicator/Indicator";
import { globalSettingsActions }            from "../../../../../actions";
import {onClickDataInAppIsChanged} from "../../../../../config/constants";

class TreeSetupSidebar extends Component {

    state = {
        isOpen : false
    };

    componentDidMount() {
        const { props : { selectedId, GetProbesForFolder} } = this;
        const storedFolderId = localStorage.getItem("selectedTree") ;
        const selectedTreeId = storedFolderId ? parseInt(storedFolderId) : selectedId;

        if( selectedTreeId ) {
            GetProbesForFolder(selectedTreeId);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { props: { selectedId, GetProbesForFolder }} = this;
        if(this.props.selectedId !== prevProps.selectedId){
            GetProbesForFolder(selectedId);
        }
    }

    render() {
        const {
            props: { history, treeStructure, handleLoader, sortData, selectedTreeForDelete, probes, newAddedProbeIsSave , NewProbeIsAddInProbeSetupToogle },
            onSelect, onDelete, toggleModal
        } = this;
        let storedFolderId;

        if(sortData.length > 0) {
            storedFolderId = sortData[0].id;
            if(localStorage.getItem("selectedTree")){
                storedFolderId=parseInt(localStorage.getItem("selectedTree"));
            }
        }
        return (
            <div className="global-settings-sidebar">
                <div className="global-settings-sidebar-wrap">
                <div className="global-settings-sidebar-container">
                <div className="global-settings-sidebar-grid">
                    {treeStructure && treeStructure.map((item, index) => (
                        <TreeCard key={index}
                                  showMeLoader={item.id === selectedTreeForDelete && handleLoader}
                                  onSelect={(e) => onSelect(e, item.id)}
                                  onDelete={(e) => onDelete(e, item.id, item.name)}
                                  name={item.name}
                                  status={item.id === storedFolderId}
                        />
                    ))}
                    <NewTreeCard onCreate={toggleModal}/>
                </div>
                </div>
                </div>
                <div className="global-settings-indicator">
                    <Indicator history={history}
                               hasProbes={probes}
                               newAddedProbeIsSave={newAddedProbeIsSave}
                               NewProbeIsAddInProbeSetupToogle={NewProbeIsAddInProbeSetupToogle}
                               checkIsAddedProbeSaved={onClickDataInAppIsChanged}/>
                </div>
            </div>
        )
    }

    toggleModal = () => {
        const {
            state: { isOpen },
            props: { HideGlobalSettingsContent }
        } = this;

        this.setState({
            isOpen: !isOpen
        });

        HideGlobalSettingsContent();
    };

    onDelete = (e, id, name) => {
        const { props: { DeleteFolder } } = this;
        if (id) {
            DeleteFolder(id, name)
        }
    };

    onSelect = (e, id) => {
        e.preventDefault();
        const { props: { HandleDataStructure } } = this;
        localStorage.setItem('selectedTree', id);
        HandleDataStructure(id);
    };
}

const mapStateToProps = state => ({
    handleSidebar         : state.globalSettings.handleSidebar,
    handleLoader          : state.globalSettings.handleLoader,
    treeStructure         : state.tree.structure,
    sortData              : state.dataStorage.sortData,
    selectedId            : state.globalSettings.selectedId,
    selectedTreeForDelete : state.globalSettings.selectedTreeForDelete,
    probes   : state.globalSettings.probes
});

const mapDispatchToProps = dispatch => ({
    HideGlobalSettingsContent   : ()         => dispatch(globalSettingsActions.HideGlobalSettingsContent()),
    DeleteFolder                : (id,name)  => dispatch(globalSettingsActions.DeleteFolder(id,name)),
    HandleDataStructure         : (id)       => dispatch(globalSettingsActions.HandleDataStructure(id)),
    GetProbesForFolder          : (folderId) => dispatch(globalSettingsActions.GetProbesForFolder(folderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TreeSetupSidebar) ;
