import React, { Component } from "react";

import { connect } from "react-redux";
import { appActions } from "../../actions";

import {Icon, Input} from 'antd';

import LeftSidebarAdvancedSearch from "./LeftSidebarAdvancedSearch/LeftSidebarAdvancedSearch";
import LeftSidebarBody from "./LeftSidebarBody/LeftSidebarBody";

import "./LeftSidebar.scss";

const Search = Input.Search;

class LeftSidebar extends Component {
  render() {
    const {
      props: { sidebarLeftVisible: visibility,history},
      toggleMenu
    } = this;
    return (
        <div className="sidebar-container border-grey">
          <div className="sidebar-container-box bg-white flex flex-direction-column">
            <div className="sidebar-search border-grey flex">
              <div className="sidebar-search-input">
                <Search placeholder="Search"/>
                <LeftSidebarAdvancedSearch/>
              </div>
              <div className="sidebar-button-js border-grey" onClick={toggleMenu}>
                <div className="sidebar-button-container flex flex-justify-content-center">
                  <Icon type={visibility ? "double-right" : "double-left"} theme="outlined" style={{fontSize: "24px"}}/>
                </div>
              </div>
            </div>
            <div className="sidebar-tree-view">
              <LeftSidebarBody history={history}/>
            </div>
          </div>
        </div>
    );
  }

  toggleMenu = () => {
    this.props.handleLeftSidebar();
  };
}

const mapStateToProps = state => ({
  sidebarLeftVisible: state.app.sidebarLeftVisible
});

const mapDispatchToProps = dispatch => ({
  handleLeftSidebar: callBack =>
    dispatch(appActions.handleLeftSidebar(callBack))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftSidebar);
