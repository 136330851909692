import * as types from '../actions/actionTypes';

const initialState = {
    structure: null,
    selectedNode: null,
    selectedProbe: null,
    treeStructureChanged: false,
    arrayOfVisibleNodes: [],
    arrayOfCheckedNodes:[],
    arrayOfCheckedNodesInViewsDetailsLeftSideBar:[],
    visibility: true,
    loader: false,
    context: null,
    loading: false,
    status: false,
    loadingSpinner: false,
    probeValues: null
};

export default (state = initialState, action) => {
      switch (action.type) {
        case types.GET_TREE_DATA:
            return {
                ...state,
                arrayOfVisibleNodes: action.arrayOfVisibleNodes
            };
        case types.HANDLE_SELECTED_NODE:
            return {...state, selectedNode: action.selectedNode};
        case types.HANDLE_CHART_BY_PROBES_ID:
            return {
                ...state,
                selectedProbe: action.selectedProbe,
                visibility: !action.visibility
            };
        case types.HANDLE_VISIBILITY:
            return {...state, selectedProbe: action.selectedProbe};
        case types.FETCHING_TREE_DATA:
            return {...state, loader: !state.loader};
        case types.HANDLE_TREE_DATA:
            return {...state, structure: action.structure || state.structure, loader: !state.loader};
        case types.HANDLE_CONTEXT_MENU:
            return {...state, context: action.context};
        case types.HANDLE_CHECKED_NODES: {
            return {...state, arrayOfCheckedNodes: action.arrayOfCheckedNodes}
        }
        case types.HANDLE_CHECKED_NODES_IN_VIEWS_DETAILS_LEFT_SIDEBAR:{
            return {...state, arrayOfCheckedNodesInViewsDetailsLeftSideBar: action.arrayOfCheckedNodesInViewsDetailsLeftSideBar}
        }
        case types.HANDLE_LOADING_SPINNER: {
            return {...state, loadingSpinner: !state.loadingSpinner}
        }
        case types.HANDLE_PROBE_VALUE: {
            return {...state, probeValues: action.probeValues}
        }
        case types.HANDLE_START_SCREEN: {
            return {...state, status: action.status }
        }
        case types.PUT_FILTER_IN_DATASTRUCTURE:
            return {
                ...state,
                structure : action.newStructure
            }
        default:
            return state;
    }
};
