import * as types from "./actionTypes";
import { showNotification, deleteNotification, destroyNotification } from "../config/constants";

import instance from "../config/axiosConf";

export const handleSelectedNode = (selectedNode) => async dispatch => {
  dispatch({
    type: types.HANDLE_SELECTED_NODE,
    selectedNode: selectedNode
  });
};

export const isStart = () => dispatch => {
  dispatch({
    type: types.APP_IS_STARTED_FIRST_TIME
  })
};


export const handleCheckedNode = arrayOfCheckedNodes => async dispatch => {

    if(window.location.pathname === "/filtering") {
        dispatch({
            type: types.HANDLE_CHECKED_NODES,
            arrayOfCheckedNodes: arrayOfCheckedNodes
        });
    }
    if(window.location.pathname === "/viewers/details"){
        dispatch({
            type: types.HANDLE_CHECKED_NODES_IN_VIEWS_DETAILS_LEFT_SIDEBAR,
            arrayOfCheckedNodesInViewsDetailsLeftSideBar: arrayOfCheckedNodes
        });
    }
};
export const handleContextMenu = (id, path, name) => async dispatch => {
  dispatch({
    type: types.HANDLE_CONTEXT_MENU,
    context: { id, path, name }
  });
};

export const handleProbeValue = (id, name) => async dispatch => {
  dispatch({
    type: types.HANDLE_PROBE_VALUE,
    probeValues: {id, name}
  })
}

export const deleteSelectedNode = (id, name) => async (dispatch, getState) => {
  dispatch({ type: types.HANDLE_LOADING_SPINNER})
  const {loadingSpinner} = getState().tree;

  if(loadingSpinner){
     deleteNotification("warning", "Deleting CSV file")
  }

  let dataSourceName = name
  if(name.length > 40){
    dataSourceName = name.substring(0, 40) + '...'
  }

  await instance.delete(`/api/folders/${id}`);
  destroyNotification();
  dispatch(handleTreeData());
  dispatch({ type: types.HANDLE_LOADING_SPINNER });
  showNotification("success", 'Successfully removed ', dataSourceName)
};

export const deleteSelectedProbe = (id, name) => async (dispatch, getState) => {
  dispatch({ type: types.HANDLE_LOADING_SPINNER})
  const {loadingSpinner} = getState().tree;

  if(loadingSpinner){
    deleteNotification("warning", "Deleting CSV file")
  }

  let probeSourceName = name
  if(name.length > 40){
    probeSourceName = name.substring(0, 40) + '...'
  }

  await instance.delete(`/api/probes/${id}`);
  destroyNotification();
  dispatch(handleTreeData());
  dispatch({ type: types.HANDLE_LOADING_SPINNER });
  showNotification("success", 'Successfully removed ', probeSourceName)
}

export const exportData = (id, name) => async dispatch => {

  let response = await instance.get(`/api/folders/${id}?format=csv`);

  let textBody = response.data;

  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(textBody)
  );
  element.setAttribute("download", name);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);

  dispatch(handleTreeData());
};

export const handleTreeData = (includes = true) => async (dispatch,getState) => {

  const {globalSettings: { startAppFirstTime, handleSidebar } } = getState();
  dispatch({ type: types.FETCHING_TREE_DATA });

  let newDataId = parseInt(localStorage.getItem('selectedTree'));

  await instance
    .get( includes ? "/api/folders?include=probes&include=analyses&include=filters" : "/api/folders")
    .then(response => {
        if (!response.data.data) {
            if (handleSidebar) {
                setTimeout(() => {
                    dispatch({
                        type: types.HANDLE_GLOBAL_SETTINGS_SIDEBAR,
                        handleSidebar: false
                    });
                    dispatch({
                        type: types.HANDLE_SORT_DATA_BY_ID,
                        sortData: []
                    });
                    dispatch({
                        type: types.APP_IS_STARTED_FIRST_TIME
                    });

                }, 500);
            }

            dispatch({
                type: types.HANDLE_START_SCREEN,
                status: false
            });

            dispatch({
                type: types.HANDLE_TREE_DATA,
                structure: []
            });
        }

        if (response && response.data && response.data.data) {
            let data = response.data.data;
            let treeForShow=[];
            treeForShow.push(data[0]);
            if(!isNaN(newDataId)) {
                treeForShow = data.filter((treeObj) => treeObj.id === newDataId);
            }
            if(newDataId && treeForShow[0] && newDataId !== treeForShow[0].id) {
                localStorage.setItem("selectedTree", treeForShow[0].id)
            }
            if (data.length === 0 || (data.length > 0 && startAppFirstTime)) {
                setTimeout(() =>
                    dispatch({
                        type: types.HANDLE_GLOBAL_SETTINGS_SIDEBAR,
                        handleSidebar: true
                    }), 500);

                dispatch({
                    type: types.APP_IS_STARTED_FIRST_TIME
                });
            }

            dispatch({
                type: types.HANDLE_SORT_DATA_BY_ID,
                sortData: [{...treeForShow[0], expanded:true}]
            });

            if(newDataId) {
                 dispatch({
                    type: types.KEEP_SELECTED_ID,
                    selectedId: newDataId
                });

            } else {
                dispatch({
                    type: types.KEEP_SELECTED_ID,
                    selectedId: data[0].id
                });

            }

            dispatch({
                type: types.HANDLE_START_SCREEN,
                status: true
            });

            dispatch({
                type: types.HANDLE_TREE_DATA,
                structure: response.data.data
            });

        }


    });

  };

export const getProbeById = (id) => {
  return({
    type: types.GET_PROBE_BY_ID,
    id:id
  });
};
export const removeProbeById = (id, loadedProbes) => {

  let newLoadedProbes = loadedProbes.filter(probeId => probeId !== id)
  return({
    type: types.REMOVE_PROBE_LINE_BY_ID,
    loadedProbes: newLoadedProbes,
    probe : {
      removedId : id
    }
  });
};
export const removeFilterById = (id,loadedFilters) => {

  let newLoadedFilters = loadedFilters.filter(filterId => filterId !== id);

  return({
    type: types.REMOVE_FILTER_LINE_BY_ID,
    loadedFilters: newLoadedFilters,
    filter : {
      removedId: id
    }
  })
};
export const removeAnalysisById = (id,loadedAnalysis) => {
  let newLoadedAnalysis = loadedAnalysis.filter(analysisId => analysisId !== id);
  return({
    type           : types.REMOVE_ANALYSIS_LINE_BY_ID,
    loadedAnalysis : newLoadedAnalysis,
    analysis       : {
              removedId : id
    }
  })
};
export const removeTestFilter = (filterId,probeId) => (dispatch,getState) =>{
  const {
    tree : {structure}
  } = getState();

  let probeIndex = null ;
  structure.forEach(folder => {
    probeIndex = {
          folderId: folder.id,
          probeIndex: folder.probes.findIndex(probe => probe.id === probeId)
        }
      }
  )

  const folderIndex = structure.findIndex(folder => folder.id === probeIndex.folderId);
  const filterIndex = structure[folderIndex].probes[probeIndex.probeIndex].filters.findIndex(filter => filter.probeId === filterId);

  let newStructure = structure.map(folder => folder);

  newStructure[folderIndex].probes[probeIndex.probeIndex].filters.splice(filterIndex,1);

  if(newStructure[folderIndex].probes[probeIndex.probeIndex].filters.length < 1)
    newStructure[folderIndex].probes[probeIndex.probeIndex].filters = null

  dispatch({
    type : types.PUT_FILTER_IN_DATASTRUCTURE,
    newStructure : newStructure
  })
};
