import React from "react";
import "./TemplateForMqttChennelPreviewFild.scss"
import {Icon, Tooltip} from 'antd';
import Translate from "../../../../../../Translate/Translate"

export const TemplateForMqttChennelPreviewFild = (props) => {

    const splitedChannelPreview = props.channelPreview.split("/");
    const textChannel = <Translate string={"GLOBAL_SETTINGS.SET_CHANNEL"}/>;
    const textDataLoggerProbeId = <Translate string={"GLOBAL_SETTINGS.SET_DATALOGGER_PROBE_ID"}/>;

    if(splitedChannelPreview[1].length === 0) {
        splitedChannelPreview[1] = <span className={"span-channel-preview"} onClick={() => {
            props.handleDefaultOpenTab("datalogger", true, "3", props.item)
        }}>
            <i>{"{"}</i>{textChannel}<i>{"}"}</i>
    </span>;
    }
    if(splitedChannelPreview[2].length === 0 ) {
        splitedChannelPreview[2] = <span className={"span-channel-preview"} onClick={() =>{props.onHandleRef("input")}}>
            <i>{"{"}</i>{textDataLoggerProbeId}<i>{"}"}</i>
    </span>;
    }

    let findedObjectInArray = splitedChannelPreview.filter(itemChannel => itemChannel.length === undefined);

    return(
        <React.Fragment>
            {findedObjectInArray.length === 0 ?
               <React.Fragment>
                    <Tooltip className={"channel-preview-with-icon"} placement="left" title={<Translate string={"GLOBAL.CLICK_TO_COPY"}/>}>
                        <Icon type="copy" className={"mqtt-channel-preview-icon"}
                              onClick={props.clipboradField}
                        />
                    </Tooltip>
                    {splitedChannelPreview[3] === undefined ?
                        <span>{splitedChannelPreview[0]}/{splitedChannelPreview[1]}/{splitedChannelPreview[2]}</span>
                    :
                        <span>{splitedChannelPreview[0]}/{splitedChannelPreview[1]}/{splitedChannelPreview[2]}/{splitedChannelPreview[3]}</span>
                    }
               </React.Fragment>
            :
                splitedChannelPreview[3] === undefined ?
                        <span>{splitedChannelPreview[0]}/{splitedChannelPreview[1]}/{splitedChannelPreview[2]}</span>
                        :
                        <span>{splitedChannelPreview[0]}/{splitedChannelPreview[1]}/{splitedChannelPreview[2]}/{splitedChannelPreview[3]}</span>

            }
       </React.Fragment>
    )
}