import React, { Component } from "react";

import "./EditDataSource.css";
import { ChromePicker } from "react-color";
import { Tabs, Radio } from "antd";
import EditDataSourceSettings from "./EditDataSourceSettings/EditDataSourceSettings";
import Translate from "../../../../Translate/Translate";

const TabPane = Tabs.TabPane;

class EditDataSource extends Component {
  state = {
    chartColor: ""
  };

  render() {
    const {
      state: { chartColor },
      props: { inputValue },
      handleColor
    } = this;

    return (
      <div>
        <Tabs
          defaultActiveKey="1"
          tabPosition="left"
          style={{ height: 400 }}
          className="tabs"
        >
          <TabPane tab={<Translate string={"GLOBAL.SETTINGS"}/>} key="1" className="tabs-left-content">
            <EditDataSourceSettings inputValue={inputValue} />
          </TabPane>
          <TabPane tab={<Translate string={"GLOBAL.CHART_SETTINGS"}/>} key="2" className="tabs-left-content">
            <ChromePicker
              className="sketch-picker"
              color={chartColor}
              onChange={handleColor}
              disableAlpha={true}
            />
            <div>
              <Radio.Group defaultValue="line" buttonStyle="solid">
                <Radio.Button value="line"><Translate string={"GLOBAL.LINE"}/></Radio.Button>
                <Radio.Button value="bar"><Translate string={"GLOBAL.BAR"}/></Radio.Button>
                <Radio.Button value="pie"><Translate string={"GLOBAL.PIE"}/></Radio.Button>
              </Radio.Group>
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }

  handleColor = color => {
    this.setState({
      chartColor: color
    });
  };
}

export default EditDataSource;
