import instance from '../../config/axiosConf'

export const getRecipients = (page = 1, filters) => {

    let fetchRecipients = instance.get(`/api/recipients`, {
        params: {
            page: page,
            perPage: 10,
            filters: filters
        }
    });

    return new Promise((resolve, reject) => {
        fetchRecipients.then(res => {
            if (res && res.data) {
                resolve(res.data)
            }
        })
    })
};
export const getAllRecipientsForAlerts = (page = 1, filters) => {

    let fetchRecipients = instance.get(`/api/recipients`, {
        params: {
            page: page,
            perPage: 100000,
            filters: filters
        }
    });

    return new Promise((resolve, reject) => {
        fetchRecipients.then(res => {
            if (res && res.data) {
                resolve(res.data)
            }
        })
    })
};

export const createRecipient = payload => {

    let createNewRecipient = instance.post(`/api/recipients`, payload);

    return new Promise((resolve, reject) => {
        createNewRecipient.then(res => {
            resolve(res)
        })
    })
};
export const updateRecipient = (id, payload) => {

    let updRecipient = instance.put(`/api/recipients/${id}`, payload);

    return new Promise((resolve, reject) => {
        updRecipient.then(res => {
            resolve(res)
        })
    })
};

export const deleteRecipient = id => {

    let delRecipient = instance.delete(`/api/recipients/${id}`);

    return new Promise((resolve, reject) => {
        delRecipient.then(res => {
            resolve(res)
        })
    })
};

export const getStatus = (page = 1, filters) => {

    let fetchRecipients = instance.get(`/api/statuses`, {
        params: {
            page: page,
            perPage: 10,
            filters: filters
        }
    });

    return new Promise((resolve, reject) => {
        fetchRecipients.then(res => {
            if (res && res.data) {
                resolve(res.data)
            }
        })
    })
};
export const getAllStatusForAlerts = (page = 1, filters) => {

    let fetchRecipients = instance.get(`/api/statuses`, {
        params: {
            page: page,
            perPage: 1000000,
            filters: filters
        }
    });

    return new Promise((resolve, reject) => {
        fetchRecipients.then(res => {
            if (res && res.data) {
                resolve(res.data)
            }
        })
    })
};
export const createStatus = payload => {

    let createNewRecipient = instance.post(`/api/statuses`, payload);

    return new Promise((resolve, reject) => {
        createNewRecipient.then(res => {
            resolve(res)
        })
    })
};
export const updateStatus = (id, payload) => {

    let updRecipient = instance.put(`/api/statuses/${id}`, payload);

    return new Promise((resolve, reject) => {
        updRecipient.then(res => {
            resolve(res)
        })
    })
};


export const deleteStatus = id => {

    let delRecipient = instance.delete(`/api/statuses/${id}`);

    return new Promise((resolve, reject) => {
        delRecipient.then(res => {
            resolve(res)
        })
    })
};

export const getNotificationTemplates = (page = 1, filters) => {

    let fetchRecipients = instance.get(`/api/templates`, {
        params: {
            page: page,
            perPage: 10,
            filters: filters
        }});

    return new Promise((resolve, reject) => {
        fetchRecipients.then(res => {
            if (res && res.data) {
                resolve(res.data)
            }
        })
    })
};
export const getAllNotificationTemplatesForAlerts = (page = 1, filters) => {

    let fetchRecipients = instance.get(`/api/templates`, {
        params: {
            page: page,
            perPage: 1000000,
            filters: filters
        }});

    return new Promise((resolve, reject) => {
        fetchRecipients.then(res => {
            if (res && res.data) {
                resolve(res.data)
            }
        })
    })
};
export const createNotificationTemplate = payload => {

    let createNewRecipient = instance.post(`/api/templates`, payload);

    return new Promise((resolve, reject) => {
        createNewRecipient.then(res => {
            resolve(res)
        })
    })
};
export const deleteNotificationTemplate = id => {

    let delRecipient = instance.delete(`/api/templates/${id}`);

    return new Promise((resolve, reject) => {
        delRecipient.then(res => {
            resolve(res)
        })
    })
};
export const updateNotificationTemplate = (id, payload) => {

    let updRecipient = instance.put(`/api/templates/${id}`, payload);

    return new Promise((resolve, reject) => {
        updRecipient.then(res => {
            resolve(res)
        })
    })
};
export const getTriggers = (page = 1, filters) => {

    let fetchRecipients = instance.get(`/api/triggers`, {
        params: {
            page: page,
            perPage: 10,
            filters: filters
        }});

    return new Promise((resolve, reject) => {
        fetchRecipients.then(res => {
            if (res && res.data) {
                resolve(res.data)
            }
        })
    })
};
export const getAllTriggersForAlert = (page = 1, filters) => {

    let fetchRecipients = instance.get(`/api/triggers`, {
        params: {
            page: page,
            perPage: 1000000,
            filters: filters
        }});

    return new Promise((resolve, reject) => {
        fetchRecipients.then(res => {
            if (res && res.data) {
                resolve(res.data)
            }
        })
    })
};
export const createTrigger = payload => {

    let createNewRecipient = instance.post(`/api/triggers`, payload);

    return new Promise((resolve, reject) => {
        createNewRecipient.then(res => {
            resolve(res)
        })
    })
};

export const updateTrigger = (id, payload) => {

    let updRecipient = instance.put(`/api/triggers/${id}`, payload);

    return new Promise((resolve, reject) => {
        updRecipient.then(res => {
            resolve(res)
        })
    })
};
export const deleteTrigger = id => {

    let delRecipient = instance.delete(`/api/triggers/${id}`);

    return new Promise((resolve, reject) => {
        delRecipient.then(res => {
            resolve(res)
        })
    })
};

export const getAlerts = (page = 1, filters) => {
    let fetchRecipients = instance.get(`/api/alerts`, {
        params: {
            page: page,
            perPage: 10,
            filters: filters
        }});

    return new Promise((resolve, reject) => {
        fetchRecipients.then(res => {
            if (res && res.data) {
                resolve(res.data)
            }
        })
    })
};
export const createAlerts = payload => {

    let createAlerts = instance.post(`/api/alerts`, {name:payload.name, templateId: payload.template});


    return new Promise((resolve, reject) => {
        createAlerts.then(res => {
            resolve(res.data.data)
        })
    })
};

export const syncAlerts = (id, payload) => {
    let syncTriggers = instance.put(`/api/alerts/${id}/triggers/sync`, {data:payload.triggers});
    let syncStatuses = instance.put(`/api/alerts/${id}/statuses/sync`, {data:payload.status});
    let syncRecipients = instance.put(`/api/alerts/${id}/recipients/sync`, {data:payload.recipients});

    return Promise.all([syncTriggers, syncStatuses, syncRecipients])
        .then(res => {
            return res;
        })

};

export const updateAlerts = (id, payload) => {

    let createAlerts = instance.put(`/api/alerts/${id}`, {name:payload.name, templateId: payload.template});


    return new Promise((resolve, reject) => {
        createAlerts.then(res => {
            resolve(res.data.data)
        })
    })
};

export const deleteAlerts = id => {

    let delRecipient = instance.delete(`/api/alerts/${id}`);

    return new Promise((resolve, reject) => {
        delRecipient.then(res => {
            resolve(res)
        })
    })
};

export const getAlertLogs = (page = 1, filters) => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let clientZone = "";
    if (!!tz && tz !== "") {
        clientZone = tz
    } else {
        clientZone = "UTC"
    }
    let fetchAlertLogs = instance.get(`/api/alerts/logs`, {
        params: {
            page: page,
            perPage: 10,
            filters: filters,
            timezone: clientZone
        }});

    return new Promise((resolve, reject) => {
        fetchAlertLogs.then(res => {
            if (res && res.data) {
                resolve(res.data)
            }
        })
    })
};

export const deleteAlertLog = (selectedAlarms) => {

    let deleteLog = instance.delete(`/api/alerts/logs`, {
        params: {
            log_ids: selectedAlarms.toString()
        }
    });

    return new Promise((resolve, reject) => {
        deleteLog.then(res => {
            resolve(res)
        })
    })
};

export const closeAlertLog = (selectedAlarms) => {
    const selected = selectedAlarms.toString();

    let closeLog = instance.put(`/api/alerts/logs?log_ids=${selected}`);

    return new Promise((resolve, reject) => {
        closeLog.then(res => {
            resolve(res)
        })
    })
};
