import React from 'react';
import {Menu, Dropdown, Button, Modal} from "antd";

import './DataLogerItem.scss'
import Translate from "../../../../../Translate/Translate";
const confirm = Modal.confirm;

class DataLoggerItem extends React.Component {

    state = {
      showModal: false
    };

    showDeleteConfirm = () => {
        const {props} = this;
        confirm({
            title: <Translate string={"GLOBAL_SETTINGS.CONFIRM_DELETE_DATALOGGER_HEADER"}/>,
            content: <Translate string={"GLOBAL_SETTINGS.CONFIRM_DELETE_DATALOGGER_SECTION"}/>,
            okText: <Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"} />,
              //  "sss",
            okType: 'danger',
            cancelText: <Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>,
            onOk() {
                props.onRemove()
            },
            onCancel() {},
        });
    };

    menu = (e) => {
        const {props} = this;
        return (<Menu>
            <Menu.Item>
                <Button type='button' onClick={props.handleDownloadDataLoggers}><Translate string={"GLOBAL.DOWNLOAD"}/></Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='button' onClick={this.handleEditOnClick}><Translate string={"GLOBAL.EDIT"}/></Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='button' className={'text-red'} onClick={this.showDeleteConfirm}><Translate string={"GLOBAL_SETTINGS.REMOVE"}/></Button>
            </Menu.Item>
        </Menu>)
    };

    render() {

        const {props} = this;

        return (
            <React.Fragment>
                <div className="data-logger">
                    <div className="data-logger-content flex">
                        <div className="data-logger-action" id={`area${props.dropdownIndex}`}>
                            <Dropdown trigger={["click"]} overlay={() => this.menu(props)}
                                      getPopupContainer={() => document.getElementById(`area${props.dropdownIndex}`)} placement="bottomLeft">
                                <button className={'button-icon'}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </Dropdown>
                        </div>
                        <div className="data-logger-content-wrap">
                            <div className="data-logger-content-first-row flex flex-align-items-center">
                                <div className="data-logger-row-wrap">
                                    <div className="data-logger-row-item">
                                        <span><Translate string={"GLOBAL.DATA_LOGGER_NAME"}/></span>
                                        <p className={props.name ? '' : 'no-data-label'}>{props.name ? props.name : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                    </div>
                                    <div className="data-logger-row-item">
                                        <span><Translate string={"GLOBAL.LOCATION"}/></span>
                                        <p className={props.where ? '' : 'no-data-label'}>{props.where ? props.where : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                    </div>
                                    <div className="data-logger-row-item">
                                        <span><Translate string={"GLOBAL_SETTINGS.CONNECTION_TYPE_1"}/></span>
                                        <p className={props.connection.mqtt ? '' : 'no-data-label'}>{props.connection.mqtt ? "MQTT" : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                    </div>
                                    <div className="data-logger-row-item">
                                        <span><Translate string={"GLOBAL_SETTINGS.CONNECTION_TYPE_2"}/></span>
                                        <p className={props.connection.ftp ? '' : 'no-data-label'}>{props.connection.ftp ? "FTP" : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="data-logger-content-second-row">
                                <div className="data-logger-row-wrap">
                                    <div className="data-logger-row-item">
                                        <span><Translate string={"GLOBAL.SERIAL_NUMBER"}/></span>
                                        <p className={props.serialNumber ? '' : 'no-data-label'}>{props.serialNumber ? props.serialNumber : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                    </div>
                                    <div className="data-logger-row-item">
                                        <span><Translate string={"GLOBAL.RESPONSIBILITY"}/></span>
                                        <p className={props.responsibility ? '' : 'no-data-label'}>{props.responsibility ? props.responsibility : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                    </div>
                                    <div className="data-logger-row-item">
                                        <span><Translate string={"GLOBAL.DESCRIPTION"}/></span>
                                        <p className={props.description ? '' : 'no-data-label'}>{props.description ? props.description : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    handleEditOnClick = () => {
        let mqttId;
        let ftpId;
        const {openModal}= this.props;
        const {getMqtt,getFtp,connection}= this.props;
        if(connection && connection.length !== 0){

                if(connection.mqtt){
                    mqttId = connection.mqtt.id
                }
                if(connection.ftp){
                    ftpId = connection.ftp.id
                }

        }
        if(mqttId && typeof mqttId === 'number'){
            getMqtt(mqttId);
        }
        if(ftpId && typeof ftpId === "number"){
            getFtp(ftpId);
        }
        openModal()
    };
}

export default DataLoggerItem;
