import React from "react";

function Chevron(props) {
    return (
        <svg
            className={props.className}
            width="17"
            height="9"
            viewBox="0 0 17 9"
            fill="none">
            <path
                d="M16.8261 0.173916C16.5918 -0.0579681 16.211 -0.0579682 15.9767 0.173916L8.50822 7.57971L1.02509 0.173913C0.790781 -0.0579709 0.410035 -0.0579709 0.175729 0.173913C-0.0585764 0.405797 -0.0585765 0.782609 0.175729 1.01449L8.06889 8.82609C8.18605 8.94203 8.33249 9 8.49357 9C8.64001 9 8.8011 8.94203 8.91825 8.82609L16.8114 1.0145C17.0604 0.782612 17.0604 0.4058 16.8261 0.173916Z"
                fill="black"/>
        </svg>
    );
}

export default Chevron;