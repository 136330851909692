import React                    from 'react';
import {Component}              from 'react'
import {connect}                from "react-redux";

import {Button,Checkbox}        from 'antd'

import {energyAnalysisActions}  from '../../../actions';
import * as types               from '../../../actions/actionTypes';

import './EnergyAnalysisFooter.scss';
import {showNotification} from "../../../config/constants";
import Translate from "../../Translate/Translate"
class EnergyAnalysisFooter extends Component {

    handleSubmitClick = (history) =>{
        const {
            handleClickOnCreate
        } = this.props;
        handleClickOnCreate(history);

       showNotification('success',<Translate string={"ENERGY_ANALYSIS.ANALYSIS SENT"}/>,<Translate string={"ENERGY_ANALYSIS.YOUR_ANALYSIS_HAS_BEEN_SENT_TO_SERVER"}/>);

    }

    render() {
        const {
            history,
            arrayOfCheckedNodes,
            energyAnalysisList,
            persistent,
            handlePersistentCheckbox
        } = this.props;

        const {
            handleSubmitClick
        } = this;

        return (
            <div className="flex footer-wrapper border-top ">
                <Checkbox
                    checked={persistent}
                    disabled
                    onChange={() => handlePersistentCheckbox()}
                >
                    <Translate string={"GLOBAL.PERSISTENT"}/>
                </Checkbox>
                <div className="buttons-wrapper flex">
                    <Button
                        disabled={!(arrayOfCheckedNodes.length > 0 && energyAnalysisList.length > 0 )}
                        onClick={() => handleSubmitClick(history)}
                    >
                        <Translate string={"GLOBAL.CREATE"}/>
                    </Button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    handleClickOnCreate      : (history) => dispatch(energyAnalysisActions.createEnergyAnalysis(history)),
    handlePersistentCheckbox : () => dispatch({type: types.CHANGE_PERSISTENT_VALUE})
});

const mapStateToProps = state => ({
    arrayOfCheckedNodes      : state.tree.arrayOfCheckedNodes,
    energyAnalysisList       : state.energyAnalysis.energyAnalysisList,
    persistent               : state.energyAnalysis.persistent
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnergyAnalysisFooter);
