import React                                      from 'react';
import { Button, Dropdown, Icon }                 from 'antd'
import propTypes                                  from 'prop-types';

import { FILTER_PANEL_ANALYSES  }                 from '../../../../config/constants';

import './FilterTypeSelector.scss';

const FilterTypeSelector = (props) => {
  return (
    <div className="sidebar-right-filter-header flex flex-align-items-center ">
      <div className="sidebar-right-analyses-tab-wrapper flex flex-align-items-center  ">
        <p className="sidebar-right-header-title">
          {props.checkedProbe
            ? props.checkedProbe.name
            : 'No checked probe'}
        </p>
        <Dropdown
          overlay={DropdownMenu(props.handleFilterType)}
          trigger={['click']}
          placement="bottomRight"
          disabled={!props.checkedProbe}
        >
          <Button
              className="syna-add-filter-icon-button"
          >
            <Icon
                type="plus"
                theme="outlined"
                style={{ fontSize: '22px' }}
            />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

const DropdownMenu = handleFilterType => (
  <div className="dropdown-wrapper-new-filter-type">
    {
      FILTER_PANEL_ANALYSES.map((analysis,index) => (
          <Button
              key={index}
              disabled={!analysis.isActive}
              onClick={() => handleFilterType(analysis)}
              className={'flex'}
              style={{width: '100%',
                border: 0 ,
                borderRadius: 0 }}
          >
            <p>{analysis.displayName}</p>
          </Button>
      ))
    }
  </div>
);

FilterTypeSelector.propTypes = {
  handleFilterAnalysis: propTypes.func
};

export default FilterTypeSelector;
