import React from 'react';
import {Button, Checkbox, Dropdown, Menu, Modal} from "antd";
import Translate from "../../../../Translate/Translate";

import './NotificationTemplatesItem.scss';

const NotificationTemplatesItem = props => {
    const menu = (e) => {

        return (<Menu>
            <Menu.Item>
                <Button type='button' onClick={()=>props.editNotificationTemplate(props)}><Translate string={"GLOBAL.EDIT"}/></Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='button' onClick={showDeleteConfirm}><Translate string={"GLOBAL.DELETE"}/></Button>
            </Menu.Item>
        </Menu>)
    };

    const showDeleteConfirm = () => {

        Modal.confirm({
            title: <Translate string={"ALERTING.DELETE_NOTIFICATION_TEMPLATE_CONFIRM_TITLE"} />,
            content: <Translate string={"ALERTING.DELETE_NOTIFICATION_TEMPLATE_CONFIRM_CONTENT"} />,
            okText: <Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"} />,
            okType: 'danger',
            cancelText: <Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>,
            onOk() {
                props.onDelete(props.id)
            },
            onCancel() {
            },
        });
    };


    return (
        <div className='alerts-item flex'>
            <div className="alerts-action" id={`area${props.itemIndex}`}>
                <Dropdown trigger={["click"]} overlay={() => menu(props)}
                          getPopupContainer={() => document.getElementById(`area${props.itemIndex}`)}
                          placement="bottomLeft">
                    <button className={'button-icon'}>
                        <span/>
                        <span/>
                        <span/>
                    </button>
                </Dropdown>
            </div>
            <div className="alerts-data">
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"GLOBAL.NAME"}/></label>
                    <div className='flex flex-align-items-center'>
                        <p className={'alerts-status'}> {props.name}</p>
                    </div>
                </div>
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.MESSAGE"}/></label>
                    <p className={'alerts-status'}> {props.message}</p>
                </div>
                <div className="alerts-data-group flex flex-direction-column">
                    <label><Translate string={"ALERTING.TYPE"}/></label>
                    <p><Checkbox checked={props.sms}><Translate string={"ALERTING.SMS"}/></Checkbox> <Checkbox checked={props.email}><Translate string={"ALERTING.E_MAIL"}/></Checkbox></p>
                </div>
            </div>
        </div>
    );
};

export default NotificationTemplatesItem;