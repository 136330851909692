import React from 'react';
import {Button, Icon} from "antd";
import Translate from "../../../Translate/Translate";

const DashboardEmptyPlot = (props) => {
    return (
        <div className={`layout-col-${props.place}-6 flex flex-align-items-center flex-justify-content-center empty-field`}>
            <Button onClick={() => {props.addNewWidget()}}>
                <Icon type={'plus'}/> <br/>
                <Translate string={"DASHBOARD.NEW_WIDGET"}/>
            </Button>
        </div>
    );
};

export default DashboardEmptyPlot;
