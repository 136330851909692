import React,{ Component }       from "react"
import { connect }               from "react-redux";

import { InputText }             from "../../../../../../FormInput/FormInput";
import { globalSettingsActions } from "../../../../../../../actions";
import { Icon }                  from "antd";
import Translate from "../../../../../../Translate/Translate";
import "./SidebarAcquisitionDataloggerFilter.scss"


class SidebarAcquisitionDataloggerFilter extends Component {

    state = {
        filterObject:{}
    }

    render() {
        const {
            state : { filterObject },
            handleOnSubmit, handleOnChange } = this ;
        return (
            <form className="sidebar-form-filter" onSubmit={handleOnSubmit}>
                <div className="sidebar-filters-header">
                    <div
                        className="sidebar-filters-header-wrap flex flex-justify-content-space-between flex-align-items-center">
                        <h1><Translate string={"GLOBAL.FILTERS"}/></h1>
                        <div className="sidebar-filters-header-action">
                            <button className="btn btn-filter btn-border-black" type={'submit'}>
                                <Icon type="filter" theme="filled"/><Translate string={"GLOBAL.APPLY"}/>
                            </button>
                        </div>
                    </div>

                </div>
                <div className="sidebar-filters-body">
                    <InputText
                        label={<Translate string={"GLOBAL.DATA_LOGGER_NAME"}/>}
                        name={'dataLoggerName'}
                        handleOnPressEnter={this.onPressEnter}
                        handleOnChange={handleOnChange}
                        value={filterObject.dataLoggerName}
                    />
                    <InputText
                        label={<Translate string={"GLOBAL.LOCATION"}/>}
                        name={'where'}
                        handleOnPressEnter={this.onPressEnter}
                        handleOnChange={handleOnChange}
                        value={filterObject.where}
                    />
                </div>
            </form>
        )
    };

    onPressEnter = () => {
        const { props: { handleFilterDataLogger } , state: { filterObject }} = this;
        handleFilterDataLogger(filterObject);

    };

    handleOnSubmit = (event) => {
        event.preventDefault();
        this.onPressEnter();
    };

    handleOnChange = (event) => {
        const { target: { name, value } } = event ;
        const { state : { filterObject } } = this;
        this.setState({
            filterObject: {
                ...filterObject,
                [name]:value
            }
        })
    };
}

const mapStateToProps = state => ({
    dataLoggerFilterObject: state.globalSettings.dataLoggerFilterObject
});

const mapDispatchToProps = dispatch => ({
    handleFilterDataLogger: (filterObject) => dispatch(globalSettingsActions.FilterDataLogger(filterObject)),

});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarAcquisitionDataloggerFilter);