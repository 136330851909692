import React, {Component} from "react";
import Translate from "../../../../Translate/Translate";
import './Csv.scss'

import {connect} from 'react-redux';

import {Button, Checkbox, Icon, Pagination, Tooltip, notification} from 'antd';
import {globalSettingsActions, uploadCSVActions} from "../../../../../actions";
import {handleDownloadProps, TranslateText} from "../../../../../config/constants";
import * as globalSettingActions from "../../../../../actions/globalSettings";
import UploadModal from "../../../../UploadModal/UploadModal";

class Csv extends Component {

    constructor(props){
        super(props);
        this.state={
            visible:false
        }
    }
    componentDidMount() {
        const {GetProbesForCSV, selectedId} = this.props;
        GetProbesForCSV(selectedId);
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.selectedId !== this.props.selectedId){
            const {GetProbesForCSV,selectedId} = this.props;
            GetProbesForCSV(selectedId);
        }
        if(this.props.csvFilterObject !== prevProps.csvFilterObject){
            this.props.UnCheckAllCheckedCsvProbes()
        }
    }

    render() {

        const {csvProbes, checkedCsvProbes,csvProbesMetaData, HandleCheckedCsvProbe, HandleCheckAllCsvProbes, DownloadTemplate, isGettingProbeSeries, uploadFileList
       } = this.props;
        const checkedAll = csvProbes && csvProbes.every((probeCsv)=> checkedCsvProbes.includes(probeCsv.id)) ;
      
        return (

            csvProbesMetaData ?

        <div className={"csv-wrapper"}>
            <div className={"csv-main-checkbox"}>
                <Checkbox onChange={e => HandleCheckAllCsvProbes(e.target.checked)} checked={checkedAll} />
                <span className="selected-probes" style={{marginLeft:"10px"}}><Translate string={"GLOBAL.SELECT_ALL"}/></span>
                {checkedCsvProbes.length > 0 &&  <span className="selected-probes" style={{marginLeft:"10px"}}><Translate string={"GLOBAL.SELECTED"}/> {checkedCsvProbes.length} - {csvProbesMetaData.totalRecords}</span>}
                
                {/* <span className="showing-per-page"><Translate string={"GLOBAL.SHOWING"}/>
                 {csvProbesMetaData.totalRecords < 10 ? csvProbesMetaData.totalRecords
                    :
                    (csvProbesMetaData.perPage * csvProbesMetaData.previousPage === csvProbes.length  ?
                        csvProbesMetaData.perPage * csvProbesMetaData.previousPage : csvProbesMetaData.perPage * csvProbesMetaData.previousPage + csvProbes.length)} 
                        - {csvProbesMetaData.totalRecords}</span> */}
            </div>
            <div className={"csv-main-content"}>
                {
                   csvProbes && csvProbes.map((probe, index) => {

                        const defaultChecked = this.defaultChecked(probe.id);

                        const ellipsis = (string, limit) => {
                            return string.length > limit ?
                                `${string.substring(0,limit)}...` : string;
                        };

                        return (
                            <div className={"csv-main-content-order"} key={'csv-key-' + index}>
                                <div className={"csv-checkbox"}>
                                    <Checkbox onChange={e => HandleCheckedCsvProbe(probe.id, e.target.checked)} checked={defaultChecked} />
                                </div>
                                <div>
                                    <label htmlFor="probeName"><Translate string={"GLOBAL.NAME"}/></label>
                                    <div className={"csv-probe-name"}>
                                        <p className={probe.name ? '' : 'no-data-label'}>{
                                            probe.name ? ellipsis(probe.name, 25) : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                    </div>
                                </div>
                                <div className={"csv-path"}>
                                    <label htmlFor="path"><Translate string={"GLOBAL.PATH"}/></label>
                                    <p className={probe.path ? '' : 'no-data-label'}>{probe.path ? probe.path : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                </div>
                                <div className={"csv-unit"}>
                                    <label htmlFor="unit"><Translate string={"GLOBAL.UNIT"}/></label>
                                    <p className={probe.unit ? '' : 'no-data-label'}>{probe.unit ? probe.unit : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                </div>
                                <div className={"csv-data-type"}>
                                    <label htmlFor="dataType"><Translate string={"GLOBAL.DATA_TYPE"}/></label>
                                    <p>{probe.dataType ? probe.dataType : <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className={"csv-footer"}>
                <div className={'footer-download-upload-buttons'}>
                    <Button type='button'
                            className='btn btn-border-green'
                            onClick={event => {
                                event.preventDefault();
                                this.toggleUploadModal();
                            }}>
                        <Icon type="cloud-upload" theme="outlined" style={{ fontSize: '17px'}}/><Translate string={"GLOBAL.IMPORT"}/>
                    </Button>
                    <Button type='button'
                            className='btn btn-border-green'
                            onClick={event => {
                                event.preventDefault();
                                 DownloadTemplate(require('../../../../../assets/template/ProbesWithSeriesTemplate.csv'));
                            }}>
                        <Icon type="cloud-download" theme="outlined" style={{ fontSize: '17px'}}/><Translate string={"GLOBAL.TEMPLATE"}/>
                    </Button>
                </div>
                <div className={'footer-pagination'}>
                    <Pagination
                        defaultCurrent={1}
                        total={csvProbesMetaData.totalRecords}
                        onChange={this.onChangePage}
                        size={'small'}
                        current={csvProbesMetaData.currentPage}
                    />
                </div>
                <div className={'footer-save-add-buttons'}>
                    <Tooltip placement="top" title={checkedCsvProbes.length === 0 ? <Translate string={"FILTERING.NO_SELECTED_PROBE"}/> : ''}>
                        <Button onClick={() => this.handleDownloadSeriesButton()} disabled={isGettingProbeSeries || checkedCsvProbes.length === 0}>
                            <Icon type="arrow-down" style={{fontSize: '17px'}}/>
                            <Translate string={"GLOBAL.EXPORT_SELECTED"}/>
                        </Button>
                    </Tooltip>
                </div>
                <UploadModal
                    title={<Translate string={"GLOBAL.UPLOAD_PROBE"}/>}
                    uploadFlag={"treeSetup"}
                    visible={this.state.visible}
                    uploadFiles={uploadFileList}
                    callBack={this.toggleUploadModal}
                />
            </div>
        </div>
                : null
    )}
    toggleUploadModal = () => {
        this.setState({
            ...this.state,
            visible: !this.state.visible
        });
    };
    onChangePage = pageNumber => {
        const { FilterCsv } = this.props
        FilterCsv(this.props.csvFilterObject,pageNumber)
    };

    defaultChecked = probeId => {
        const { checkedCsvProbes } = this.props ;
        return checkedCsvProbes.includes(probeId);
    };

    handleDownloadProbesToCSV = () => {

        const{props:{ExportSelectedProbes, checkedCsvProbes, selectedId, ExportAcquisitionProbes}} = this;

        let data = {
            folderId :selectedId,
            name     : 'csvExport'
        };

        if(checkedCsvProbes && checkedCsvProbes.length > 0){
            handleDownloadProps("csvExport",ExportSelectedProbes,checkedCsvProbes)
        }
        else
            ExportAcquisitionProbes(data, 'csv')
    }

    handleDownloadSeriesButton = () => {

        const{props:{checkedCsvProbes, selectedId, DownloadProbeSeries, handleDownloadSeries}} = this;

        notification.success({
            message: TranslateText("GLOBAL.NOTIFICATION"),
            description: TranslateText("GLOBAL.PREPARING_CSV"),
            style: {
                width: 400,
                wordBreak: "break-word"
            },
            duration: 3
        });

        DownloadProbeSeries(checkedCsvProbes, selectedId, handleDownloadSeries);
    }
}

const mapStateToProps = state => ({
    csvProbes: state.globalSettings.csvProbes,
    selectedId: state.globalSettings.selectedId,
    checkedCsvProbes: state.globalSettings.checkedCsvProbes,
    csvProbesMetaData: state.globalSettings.csvProbesMetaData,
    isGettingProbeSeries: state.globalSettings.isGettingProbeSeries,
    csvFilterObject: state.globalSettings.csvFilterObject
});

const mapDispatchToProps = dispatch => ({
    DownloadTemplate     : (pathFile)        => dispatch(globalSettingsActions.DownloadTemplate(pathFile)),
    GetProbesForCSV: (folderId) => dispatch(globalSettingsActions.GetProbesForCSV(folderId)),
    HandleCheckedCsvProbe: (csvProbeId, checked) => dispatch(globalSettingsActions.HandleCheckedCsvProbe(csvProbeId, checked)),
    HandleCheckAllCsvProbes : (checkAll)         => dispatch(globalSettingsActions.HandleCheckAllCsvProbes(checkAll)),
    PaginationForCsv: (page, folderId) => dispatch(globalSettingsActions.PaginationForCsv(page, folderId)),
    ExportSelectedProbes: (exportName) => dispatch(globalSettingsActions.ExportSelectedProbes(exportName)),
    ExportAcquisitionProbes      : (data, type)  => dispatch(globalSettingActions.ExportAcquisitionProbes(data, type)),
    uploadFileList              : (fileList)           => dispatch(uploadCSVActions.uploadCSVFile(fileList)),
    DownloadProbeSeries : (checkedFtpProbes, selectedId, handleDownloadSeries) => dispatch(globalSettingsActions.DownloadProbeSeries(checkedFtpProbes, selectedId, handleDownloadSeries)),
    handleDownloadSeries : (disabledState) => dispatch(globalSettingsActions.handleDownloadSeries(disabledState)),
    FilterCsv : (csvFilterObject,page) => dispatch(globalSettingsActions.FilterCsv(csvFilterObject,page)),
    UnCheckAllCheckedCsvProbes:()=>dispatch(globalSettingsActions.UnCheckAllCheckedCsvProbes())
});

export default connect(mapStateToProps, mapDispatchToProps)(Csv);


