import React from 'react';

const AlertsSetupNoData = (props) => {
    return (
        <div className='flex flex-align-items-center flex-justify-content-center flex-direction-column' style={{height: '100%'}}>
            <h3>{`${props.title}`}</h3>
            <p>{`${props.subtitle}`}</p>
            <button style={{marginTop: '30px'}} onClick={()=> props.isButtonClick()} className='btn btn-green'> {`${props.button}`}</button>
        </div>
    );
};

export default AlertsSetupNoData;