import React, {Component} from "react";
import Translate from "../../../../Translate/Translate";

import {Button, Checkbox, Icon, Pagination, Tooltip, notification} from 'antd';
import {globalSettingsActions} from '../../../../../actions'
import {connect} from 'react-redux';
import {handleDownloadProps, TranslateText} from "../../../../../config/constants";
import UploadModal from "../../../../UploadModal/UploadModal";
import * as globalSettingActions from "../../../../../actions/globalSettings";
import {uploadProbe} from "../../../../../actions/uploadProbe";
import FtpDlProbeNameInput from "./FtpDlProbeNameInput/FtpDlProbeNameInput";

import './Ftp.scss'

class Ftp extends Component {

    constructor(props){
        super(props);
        this.state={
            visible:false,
            ftpProbesWithFtpConnection: []
        }
    }

    componentDidMount() {
        const {GetProbesForFTP,selectedId} = this.props;
        GetProbesForFTP(selectedId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {GetProbesForFTP,selectedId, ftpProbes} = this.props;
        if(prevProps.selectedId !== selectedId){
            GetProbesForFTP(selectedId);
        }
        if (ftpProbes !== prevProps.ftpProbes){
            let ftpProbesWithFtpConnection =ftpProbes.filter(probe => {
               return probe.dataLogger && probe.dataLogger.connections && probe.dataLogger.connections.ftp && probe
            });

            this.setState({ftpProbesWithFtpConnection:ftpProbesWithFtpConnection})  
        }
        if(this.props.ftpFilterObject !== prevProps.ftpFilterObject){
          this.props.UnCheckAllCheckedFtpProbes()
        }
    };

    onBlurHandler = (probe) => {
        const {UpdateAcqusitionSetupFtpProbe} = this.props;
        UpdateAcqusitionSetupFtpProbe({dlProbeName: probe.dlProbeName}, probe.id);
        notification.success({
            message: TranslateText("GLOBAL_SETTINGS.PLEASE_RESTART_CONSUMER"),
            description: `${TranslateText("GLOBAL.DATA_LOGGER")}: ${probe.dataLogger.name}.  ${TranslateText("GLOBAL.SOURCE_TYPE")}: ${probe.connectionType}`,
            style: {
                width: 400,
                wordBreak: "break-word"
            },
            duration: 0
        });
    };

    render() {

        const {props:{ftpProbes, ftpProbesMetaData, checkedFtpProbes, HandleCheckAllFtpProbes, HandleCheckedFtpProbe, DownloadTemplate, isGettingProbeSeries},state:{ftpProbesWithFtpConnection}} = this;
        const checkedAll = ftpProbes && ftpProbes.every((probeFtp)=> checkedFtpProbes.includes(probeFtp.id))
        return (

            ftpProbesMetaData ?

            <div className={"ftp-wrapper"}>
                <div className={"ftp-main-checkbox"}>
                    <Checkbox onChange={e => HandleCheckAllFtpProbes(e.target.checked,ftpProbesWithFtpConnection)} checked={checkedAll} />
                    <span className="selected-probes" style={{marginLeft:"10px"}}><Translate string={"GLOBAL.SELECT_ALL"}/></span>
                    {checkedFtpProbes.length > 0 &&  <span className="selected-probes" style={{marginLeft:"10px"}}> Selected {checkedFtpProbes.length} - {ftpProbesMetaData.totalRecords}</span>}
                    
                    {/* <span className="showing-per-page"><Translate string={"GLOBAL.SHOWING"}/> {ftpProbesWithFtpConnection.length < 10 ? ftpProbesMetaData.totalRecords
                        :(ftpProbesMetaData.perPage * ftpProbesMetaData.previousPage === ftpProbes.length ?
                            ftpProbesMetaData.perPage * ftpProbesMetaData.previousPage : ftpProbesMetaData.perPage * ftpProbesMetaData.previousPage + ftpProbes.length)}
                             - {ftpProbesMetaData.totalRecords}</span> */}
                </div>
                <div className={"ftp-main-content"}>
                    {// eslint-disable-next-line
                        ftpProbes && ftpProbes.map((probe, index) => {
                            if(probe.dataLogger && probe.dataLogger.connections && probe.dataLogger.connections.ftp) {
                                const defaultChecked = this.defaultChecked(probe.id);

                                const ellipsis = (string, limit) => {

                                    return string.length > limit ?
                                        `${string.substring(0, limit)}...` : string;
                                };

                                return (
                                    <div key={`ftp-probe-${index}`} className={"ftp-main-content-order"}>
                                        <div className={"ftp-checkbox"}>
                                            <Checkbox onChange={e => HandleCheckedFtpProbe(probe.id, e.target.checked)}
                                                      checked={defaultChecked}/>
                                        </div>
                                        <div className={"ftp-probe-name"}>
                                            <label htmlFor="probeName"><Translate string={"GLOBAL.NAME"}/></label>
                                            <p className={probe.name ? '' : 'no-data-label'}>{probe.name ? ellipsis(probe.name, 25) :
                                                <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                        </div>
                                        <div className={"ftp-path"}>
                                            <label htmlFor="path"><Translate string={"GLOBAL.PATH"}/></label>
                                            <p className={probe.dataLogger && probe.dataLogger.connections.ftp && probe.dataLogger.connections.ftp.uploadFolder ? '' : 'no-data-label'}>{probe.dataLogger && probe.dataLogger.connections.ftp && probe.dataLogger.connections.ftp.uploadFolder ? ellipsis((probe.dataLogger && probe.dataLogger.connections.ftp.uploadFolder) || '') :
                                                <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                        </div>
                                        <div className={"ftp-unit"}>
                                            <label htmlFor="unit"><Translate string={"GLOBAL.UNIT"}/></label>
                                            <p className={probe.unit ? '' : 'no-data-label'}>{probe.unit ? probe.unit :
                                                <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                        </div>
                                        <div className={"ftp-data-type"}>
                                            <label htmlFor="dataType"><Translate string={"GLOBAL.DATA_TYPE"}/></label>
                                            <p className={probe.dataType ? '' : 'no-data-label'}>{probe.dataType ? probe.dataType :
                                                <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                        </div>
                                        <div className={"ftp-datalogger-name"}>
                                            <label htmlFor="dataloggerName"><Translate
                                                string={"GLOBAL.DATA_LOGGER_NAME"}/></label>
                                            <p className={probe.dataLogger && probe.dataLogger.name ? '' : 'no-data-label'}>{probe.dataLogger && probe.dataLogger.name ? probe.dataLogger.name :
                                                <Translate string={"GLOBAL.NO_DATA"}/>}</p>
                                        </div>
                                        <div className={"ftp-custom-probe-name"}>
                                            <FtpDlProbeNameInput probe={probe} index={index}
                                                                 onBlur={this.onBlurHandler}/>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }

                </div>
                <div className={"ftp-footer"}>
                    <div className={'footer-download-upload-buttons'}>
                    </div>
                    <div className={'footer-pagination'}>
                        <Pagination
                            defaultCurrent={1}
                            total={ftpProbesMetaData.totalRecords }
                            onChange={this.onChangePage}
                            size={'small'}
                            current={ftpProbesMetaData.currentPage}
                        />
                    </div>
                    <div className={'footer-save-add-buttons'}>
                        <Tooltip placement="top" title={checkedFtpProbes.length === 0 ? <Translate string={"FILTERING.NO_SELECTED_PROBE"}/> : ''}>
                            <Button onClick={() => this.handleDownloadSeriesButton()} disabled={isGettingProbeSeries || checkedFtpProbes.length === 0}>
                                <Icon type="arrow-down" style={{fontSize: '17px'}}/>
                                <Translate string={"GLOBAL.EXPORT_SELECTED"}/>
                            </Button>
                        </Tooltip>
                    </div>
                    <UploadModal
                        title={<Translate string={"GLOBAL.UPLOAD_PROBE"}/>}
                        uploadFlag={"probeFtp"}
                        visible={this.state.visible}
                        uploadFiles={this.props.uploadProbe}
                        callBack={this.toggleUploadModal}
                        DownloadTemplate = {() => DownloadTemplate(require('../../../../../assets/template/ProbesUploadTemplate.csv'))}
                    />
                </div>
            </div> : null
        )
    };
    toggleUploadModal = () => {
        this.setState({
            ...this.state,
            visible: !this.state.visible
        });
    };
    onChangePage = pageNumber => {
        const {FilterFtp} = this.props
        FilterFtp(this.props.ftpFilterObject, pageNumber)
    };

    defaultChecked = probeId => {

        const { checkedFtpProbes } = this.props ;

        return checkedFtpProbes.includes(probeId);
    };
    handleDownloadProbesToCSV = () => {

        const{props:{ExportSelectedProbes, checkedFtpProbes, selectedId, ExportAcquisitionProbes}} = this;

        let data = {
            folderId :selectedId,
            name     : 'ftpExport'
        };

        if(checkedFtpProbes && checkedFtpProbes.length > 0){
            handleDownloadProps("ftpExport",ExportSelectedProbes,checkedFtpProbes)
        }
        else
            ExportAcquisitionProbes(data, 'ftp')
    }

    handleDownloadSeriesButton = () => {

        const{props:{checkedFtpProbes, selectedId, DownloadProbeSeries, handleDownloadSeries}} = this;
        
        notification.success({
            message: TranslateText("GLOBAL.NOTIFICATION"),
            description: TranslateText("GLOBAL.PREPARING_CSV"),
            style: {
                width: 400,
                wordBreak: "break-word"
            },
            duration: 3
            
        });

        DownloadProbeSeries(checkedFtpProbes, selectedId, handleDownloadSeries);
    }
}

const mapStateToProps = state => ({
    ftpProbes: state.globalSettings.ftpProbes,
    ftpProbesMetaData: state.globalSettings.ftpProbesMetaData,
    checkedFtpProbes: state.globalSettings.checkedFtpProbes,
    selectedId: state.globalSettings.selectedId,
    isGettingProbeSeries: state.globalSettings.isGettingProbeSeries,
    ftpFilterObject:state.globalSettings.ftpFilterObject,

});

const mapDispatchToProps = dispatch => ({
    DownloadTemplate     : (pathFile)        => dispatch(globalSettingsActions.DownloadTemplate(pathFile)),
    GetProbesForFTP: (folderId) => dispatch(globalSettingsActions.GetProbesForFTP(folderId)),
    PaginationForFtp: (page, folderId) => dispatch(globalSettingsActions.PaginationForFtp(page, folderId)),
    HandleCheckAllFtpProbes: (checkAll,ftpProbes) => dispatch(globalSettingsActions.HandleCheckAllFtpProbes(checkAll,ftpProbes)),
    HandleCheckedFtpProbe: (ftpProbeId, checked) => dispatch(globalSettingsActions.HandleCheckedFtpProbe(ftpProbeId, checked)),
    ExportSelectedProbes: (exportName) => dispatch(globalSettingsActions.ExportSelectedProbes(exportName)),
    ExportAcquisitionProbes      : (data, type)  => dispatch(globalSettingActions.ExportAcquisitionProbes(data, type)),
    uploadProbe          : (fileList,flag)        => dispatch(uploadProbe(fileList,flag)),
    UpdateAcqusitionSetupFtpProbe : (probe, probeId) => dispatch(globalSettingsActions.UpdateAcqusitionSetupFtpProbe(probe, probeId)),
    DownloadProbeSeries : (checkedFtpProbes, selectedId, handleDownloadSeries) => dispatch(globalSettingsActions.DownloadProbeSeries(checkedFtpProbes, selectedId, handleDownloadSeries)),
    handleDownloadSeries : (disabledState) => dispatch(globalSettingsActions.handleDownloadSeries(disabledState)),
    FilterFtp : (ftpFilterObject,page) => dispatch(globalSettingsActions.FilterFtp(ftpFilterObject,page)),
    UnCheckAllCheckedFtpProbes:()=>dispatch(globalSettingsActions.UnCheckAllCheckedFtpProbes())
});

export default connect(mapStateToProps, mapDispatchToProps)(Ftp);
