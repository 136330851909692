import React from "react";
import {Icon} from 'antd';

const FilterTestTitle = (props) => {

    const handleOnDeleteClick = () => {
        props.removeFilterFromParentList(props.filter.probeId);
        props.removeTestFilter(props.filter);
    };
    return (
        <span className="tree-node-child">
                <span className="tree-node-child-title">{props.filter.name}</span>
                <span>
                    <Icon
                        type="delete"
                        onClick={handleOnDeleteClick}
                        style={{fontSize: "14px"}}
                    />
                </span>
            </span>
    );
}
export default FilterTestTitle;
