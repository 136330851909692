import React, {useEffect, useState} from 'react';
import {Button, Icon, Input, Pagination} from "antd";
import {ChromePicker} from "react-color";
import {createStatus, deleteStatus, getStatus, updateStatus} from "../../../../api/alerting/alerting";
import StatusItem from './StatusItem/StatusItem'
import Modal from "../../../../elements/Modal/Modal";
import {ModalClose, TranslateText} from "../../../../config/constants";
import AlertsSetupNoData from "../AlertsSetupNoData/AlertsSetupNoData";
import Translate from '../../../Translate/Translate';

import './AlertSetupStatus.scss'
import {LoaderBar} from "../AlertSetupLoader/AlertSetupLoader";


const AlertSetupStatus = () => {
    const [isModalOpen, toggleModal] = useState(false);
    const [color, setColor] = useState('#ff0000');
    const [colorPicker, setColorPicker] = useState(false);
    const [data, setData] = useState([]);
    const [status, setStatus] = useState(null);
    const [editData, setEditData] = useState(null);
    const [editColor, setEditColor] = useState('#ff0000');
    const [loader, setLoader] = useState(true);
    const [type, setType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(20);
    const [search, setSearch] = useState({text: ''});
    const [isSearch, setIsSearch] = useState(false);
    const [isNameCorrect, setIsNameCorrect] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);

    useEffect(() => {
        getAllStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(isSearch){
            getAllStatus();
            setIsSearch(false);
            searchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search]);

    const getAllStatus = (perPage, filters) => {
        setLoader(true);
        getStatus(perPage, filters || {name: search.text}).then(res => {
            if (res && res.data) {
                setData(res.data);
                setPagination(res.meta);
                setTimeout(() => {
                    setLoader(false);
                    setLoadingSearch(false);
                }, 1000)
            }
        })
    };

    const createNewStatus = () => {

        setLoading(true);

        if(status && status.name && status.name.length > 0) {
            let payload = {
                name: status.name,
                colour: color,
                severity: data.length + 1
            };
            createStatus(payload)
                .then(() => {
                    toggleModal(false);
                    setTimeout(() => {
                        getAllStatus();
                        setLoading(false);
                    }, 500);
                    closeModal()
                });
        } else {
            setIsNameCorrect(false);
            setLoading(false);
        }
    };

    const updateSelectedStatus = () => {
        setLoading(true);
        if(isNameCorrect) {
            let payload = {
                name: editData.name,
                colour: editColor,
                severity: editData.severity
            };
            updateStatus(editData.id, payload)
                .then(() => {
                    toggleModal(false);
                    setTimeout(() => {
                        getAllStatus();
                        setLoading(false);
                    }, 500);
                    closeModal()
                });
        } else {
            setLoading(false);
        }
    };

    const delStatus = (e) => {
        setLoading(true);
        if (e) {
            deleteStatus(e)
                .then(() => {
                    setTimeout(() => {
                        getAllStatus();
                        setLoading(false);
                        setIsSearch(false)
                    }, 500);
                })
        }
    };

    const editStatus = (payload) => {
        setEditData(payload);
        setEditColor(payload.color);
        setTimeout(() => {
            toggleModal(true)
        }, 100)
    };

    const onChange = (e) => {
        type === 'edit' ? setEditData({...editData, [e.target.name]: e.target.value}) : setStatus({...editData, [e.target.name]: e.target.value});
        e.target.name === 'name' && e.target.value.length === 0 ? setIsNameCorrect(false) : setIsNameCorrect(true);
    };

    const isMoved = (position, index) => {
        let newDataSeverity = data;
        newDataSeverity[index].severity = position;
        setData(newDataSeverity);
    };

    const handleChangeComplete = (color) => {
        type === 'new' ? setColor(color.hex) : setEditColor(color.hex)
    };

    const openColorPicker = () => {
        setColorPicker(true);
    };

    const hideColorPicker = () => {
        setColorPicker(false);
    };

    const closeModal = () => {
        toggleModal(false);
        setEditData(null);
        setType(null);
        setStatus(null);
        setIsNameCorrect(true);
    };

    const searchData = () => {
        if (search) {
            setLoadingSearch(true);
            setTimeout(() => {
                getAllStatus();
                setLoadingSearch(false);
            }, 500)
        }
    };
    const onChangePage = e => {
        getAllStatus(e);
    };
    const isChange = (e) => {
        setSearch({...search, text: e.target.value});
        if (e.target.value.length === 0){
            setIsSearch(true)
        }
    };

    return (
        <div className='alert-setup-status flex flex-direction-column'>
            <div className="alert-setup-status-header flex flex-justify-content-space-between flex-align-items-center">
                <div className="alert-setup-status-search flex flex-align-items-center flex-justify-content-space-between">
                    <Input
                        name={"unit"}
                        type={"text"}
                        placeholder={TranslateText("GLOBAL.SEARCH")}
                        autoComplete='off'
                        allowClear
                        onChange={isChange}
                        className='alerting-search-input'
                        onPressEnter={!loadingSearch && searchData}
                    />
                    <div className="form-input flex flex-direction-column alerting-alerts-header-cta">
                        <Button type="primary" onClick={searchData} icon="search" disabled={loadingSearch || search.text.length === 0}><Translate string={"GLOBAL.SEARCH"}/></Button>
                    </div>
                </div>
                {loadingSearch && <div className="alerting-list-loader">
                    <Icon type="loading" style={{color: '#2980B9'}}/>
                </div>}
            </div>
            <div className="alert-setup-status-content">
                <div className="alerts-list" style={{ height: '100%' }} >

                    {loader ?
                        <LoaderBar/> :
                        data && data.length > 0 ?
                            data.map((item, index) => (
                                <StatusItem key={index}
                                            severity={item.severity}
                                            name={item.name}
                                            color={item.colour}
                                            itemIndex={index}
                                            onPossitionChange={(position) => isMoved(position, index)}
                                            total={data.length + 1}
                                            id={item.id}
                                            onDelete={delStatus}
                                            editStatus={(status) => {
                                                editStatus(status);
                                                setType('edit')
                                            }}
                                />)) : search.text.length !== 0 ? <div></div> :
                            <AlertsSetupNoData title={'You have not created any status yet'}
                                               subtitle={'Add your first status'}
                                               button={'Add new status'}
                                               isButtonClick={() => {
                                                   toggleModal(!isModalOpen);
                                                   setType('new');
                                               }}
                                               description={''}
                            />
                    }
                </div>
            </div>
            <footer className='flex'>
                <div className="footer-wrap flex-align-items-center flex-justify-content-center modal-status">
                    <div className="empty-field"/>
                    <div className="alert-setup-pagination flex flex-justify-content-center">
                        {data && data.length > 0 && <Pagination
                                defaultCurrent={1}
                                total={pagination.totalRecords}
                                onChange={onChangePage}
                                size={'small'}
                                current={pagination.currentPage}
                                defaultPageSize={pagination.perPage}
                            />}
                        </div>
                    <div className="alert-setup-action flex flex-justify-content-end">
                        <Button className="add-button btn btn-green"
                                type='button'
                                icon={'plus'}
                                onClick={() => {
                                    toggleModal(!isModalOpen);
                                    setType('new')
                                }}
                        />
                    </div>
                    <Modal isOpen={isModalOpen} toggle={toggleModal} size={'450px'}>
                        <form className="form-alerts">
                            <div className="form-wrap">
                                <div className="form-header flex flex-justify-content-space-between">
                                    <h1 className="form-header-title">
                                        {type === 'edit' ? <Translate string={"ALERTING.EDIT_STATUS"}/> : <Translate string={"ALERTING.NEW_STATUS"}/>}
                                    </h1>
                                    <ModalClose className={'modal-close'} onClick={closeModal}/>
                                </div>
                                <div className="form-body flex">
                                    <div
                                        className="form-body-wrap flex flex-align-items-center flex-justify-content-center">
                                        <div className="form-input">
                                            <div className="flex flex-justify-content-space-between">
                                                <label><Translate string={"ALERTING.STATUS_NAME"}/>*</label>
                                                {!isNameCorrect && <span className="errMessage"> 
                                                    <Translate string={"GLOBAL_SETTINGS.ERROR_MESSAGE_NAME"}/>
                                                </span>}
                                            </div>
                                            <Input allowClear
                                                   name={'name'}
                                                   placeholder={TranslateText("ALERTING.EDIT_STATUS_NAME")}
                                                   value={
                                                       status ? status.name :
                                                           editData && editData.name ? editData.name : ''
                                                   }
                                                   onChange={onChange}
                                                   autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-input color-picker">
                                            <label><Translate string={"ALERTING.COLOR"}/></label>
                                            <div className={'switch'} onClick={openColorPicker}>
                                                <span className={'color'}
                                                      style={{backgroundColor: `${type === 'new' ? color : editColor}`}}/>
                                            </div>
                                            {
                                                colorPicker ?
                                                <div className={'popover'}>
                                                    <div className={'cover'} onClick={hideColorPicker}/>
                                                    <ChromePicker disableAlpha={true} color={
                                                        type === 'new' ? color : editColor
                                                    } onChangeComplete={handleChangeComplete}/>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form-action">
                                    {
                                        loading
                                            ? <button type='button'><Icon type="loading" style={{color: '#fff'}}/></button>
                                            : type === 'edit' ?
                                        <button type='button' onClick={updateSelectedStatus}><Translate string={"ALERTING.UPDATE_STATUS"}/></button> :
                                        <button type='button' onClick={createNewStatus}>+ <Translate string={"ALERTING.NEW_STATUS"}/></button>
                                    }
                                </div>
                            </div>
                        </form>
                    </Modal>
                </div>
            </footer>
        </div>
    );
};

export default AlertSetupStatus;
