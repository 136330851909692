import React, {Component} from 'react';
import {Icon, Table} from 'antd';
import Translate from "../Translate/Translate";
import './ReportData.css';

const reportDataColumns = [
  {
    title: <Translate string={"GLOBAL.PROBE_NAME"}/>,
    dataIndex: 'probe.name',
    width:'15%',
    key: 'probeName'
  },
  {
    title: <Translate string={"GLOBAL.LABEL"}/>,
    width:'10%',
    dataIndex: 'probe.label',
    key: 'label'
  },
  {
    title: <Translate string={"GLOBAL.UNIT"}/>,
    width:'5%',
    dataIndex: 'probe.unit',
    key: 'unit'
  },
  {
    title: <Translate string={"GLOBAL.MIN"}/>,
    width:'5%',
    dataIndex: 'minValue',
    key: 'minValue'
  },
  {
    title: <Translate string={"GLOBAL.MAX"}/>,
    width:'5%',
    dataIndex: 'maxValue',
    key: 'maxValue'
  },
  {
    title: <Translate string={"GLOBAL.AVG"}/>,
    width:'5%',
    dataIndex: 'avgValue',
    key: 'avgValue'
  },
  {
    title: <Translate string={"GLOBAL.MEDIAN"}/>,
    width:'5%',
    dataIndex: 'medianValue',
    key: 'medianValue'
  },
  {
    title: <Translate string={"GLOBAL.PROBE_PATH"}/>,
    width:100,
    dataIndex: 'probePath',
    key: 'probePath',
    render: text => (
        <span title={text}>{text}</span>
    )
  }


];

const reportSubDataColumns = [
  {
    title:''
  },
  {
    title: <Translate string={"GLOBAL.ID"}/>,
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: <Translate string={"GLOBAL.PROBE_NAME"}/>,
    dataIndex: 'probeName',
    key: 'probeName'
  },
  {
    title: <Translate string={"GLOBAL.LABEL"}/>,
    dataIndex: 'label',
    key: 'label'
  },
  {
    title: <Translate string={"GLOBAL.UNIT"}/>,
    dataIndex: 'unit',
    key: 'unit'
  },
  {
    title: <Translate string={"GLOBAL.PROBE_PATH"}/>,
    dataIndex: 'probePath',
    key: 'probePath'
  }
];
let testData = [
  {
    id: Math.round(Math.random()*100),
    probeName: 'Screem',
    unit: 'on',
    label: 'S2www-33',
    probePath: 500,
    key:34,
  },
  {
    id: Math.round(Math.random()*100),
    probeName: 'Screem',
    unit: 'off',
    label: 'Add3434-coo',
    probePath: 500,
    key:32,
  }

];

class ReportData extends Component {
  render() {
    return <div className="syna-report-probe-wrap">{this.reportTable()}</div>;
  }

  reportTable = () => {
    const {reportData} = this.props;

    let parsedReports = reportData.map(item => ({
      key         : `${item.probe.name}-${item.probe.id}`,
      probe       : item.probe,
      probeId     : item.probe.id,
      probePath   : item.probe.path,
      avgValue    : item.report.avgValue ? item.report.avgValue.toFixed(3) : 0,
      maxValue    : item.report.maxValue ? item.report.maxValue.toFixed(3) : 0,
      medianValue : item.report.medianValue ? item.report.medianValue.toFixed(3) : 0,
      minValue    : item.report.minValue ? item.report.minValue.toFixed(3) : 0
    }));

    return (
        <React.Fragment>
          {
                <Table
                    columns={reportDataColumns}
                    rowKey={record => record.probe.id.toString()}
                    dataSource={parsedReports}
                    bordered={true}
                    size="middle"
                    pagination={false}
                />

          }
        </React.Fragment>
    );
  };
  renderSubTable = () => {
    return (
        <div className='syna-report-subtable'>
          <Table
              key={record => record.id.toString()}
              columns={reportSubDataColumns}
              dataSource={testData}
              pagination={Boolean(false)}
          />
        </div>
    );
  }
  customExpandIcon = (props)=> {
    let type;
    if (parseFloat(props.record.avgValue) < 0.5) {
      type = 'calculator';
    } else {
      type = 'file';
    }
    return (
        <Icon
            onClick={e => this.expandRow(props,e)}
            type={type}
        />
    );
  }
  expandRow = (props,e) => {
    if(parseFloat(props.record.avgValue) < 0.5)
      props.onExpand(props.record, e);
  }
}

export default ReportData;
