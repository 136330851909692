import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NewViewerCard, ViewerCard} from "../ViewerCards/ViewerCards";
import {ModalContent} from "../ModalContent/ModalContent";
import * as viewerActions from '../../../actions/viewer';
import {SortableContainer, SortableElement, sortableHandle} from "react-sortable-hoc";
import {Icon,Modal} from "antd";

import "./ViewersHome.scss";
import arrayMove from "array-move";
import {closeModalMessage, resetParamsForConfirmMessage} from "../../../config/constants";

const confirm = Modal.confirm;
const DragHandle = sortableHandle(() => <span className={'viewer-drag-handle'}><Icon type='drag'/></span>);

const SortableItem = SortableElement(({value, index, HandleOnPropertiesClick,onCardClick, HandleOnDelete, HandleOnDuplicateClick}) => (

    <ViewerCard
        viewer={value}
        key={`view ${index}`}
        index={index}
        onDelete={HandleOnDelete}
        onPropertiesClick={HandleOnPropertiesClick}
        onDuplicateClick={HandleOnDuplicateClick}
        onCardClick={onCardClick}
        DragHandles={<DragHandle />}
    />

));

const ViewerList = SortableContainer(({items, openModal, onPropertiesClick, onDelete, onDuplicate, onCardClick}) => {
    return (
        <div className={'viewer-list-grid'}>
            <NewViewerCard onCreate={openModal}/>
            {
                items.map((value,index) => (
                    <SortableItem key={`item-${index}`}  index={index} value={value}
                                  HandleOnPropertiesClick={onPropertiesClick}
                                  HandleOnDelete={onDelete}
                                  onCardClick={onCardClick}
                                  HandleOnDuplicateClick={onDuplicate}
                    />
                ))
            }
        </div>
    )
});

class ViewersHome extends Component {

    state = {
        visible: false,
        currentView: null,
        update: false
    };

    componentDidMount() {
        const {props: {getAllViewers}} = this;
        getAllViewers();
    }

    toggleCreateModal = () => {

        const {visible} = this.state;
        if(localStorage.getItem("dataInAppIsChanged") === "true"){
            return confirm({
                title: closeModalMessage.title,
                content: closeModalMessage.content,
                okText: closeModalMessage.okText,
                okType: 'danger',
                cancelText: closeModalMessage.cancelText,
                onOk: () => {
                    resetParamsForConfirmMessage();
                    this.setState({
                        visible: !visible,
                        currentView: null,
                        update: false
                    })
                }
            });
        }
        this.setState({
            visible: !visible,
            currentView: null,
            update: false
        })
    };

    handleSubmit = (viewer) => {
        const {state: {currentView}, handleUpdateViewer, handleCreateViewer} = this;
        resetParamsForConfirmMessage();
        viewer.options = JSON.stringify(viewer.options);
        currentView && currentView.id ? handleUpdateViewer(viewer) : handleCreateViewer(viewer);
    };

    deleteViewer = (id) => {
        const {props: {deleteViewer}} = this;
        deleteViewer(id);
    };

    handleProperties = (view) => {
        this.setState({
            currentView: view,
            visible: true,
            update: true
        })
    };

    handleCreateViewer = (viewer) => {
        const {props: {createViewer}, clearState} = this;
        createViewer(viewer);
        clearState();
    };
    handleUpdateViewer = (viewer, viewId) => {
        const {props: {updateViewer}, clearState} = this;
        updateViewer(viewer, viewId);
        clearState();
    };
    handleDuplicate = (view) => {
        const {props: {cloneViewer}} = this;
        cloneViewer(view.id);
    };
    clearState = () => {
        this.setState({
            visible: false,
            currentView: null,
            update: false
        })
    };
    showDetails = (viewerId) => {
        this.props.history.push(`/viewers/details?id=${viewerId}`);
    };

    onDragEnd = (result) => {

        const {props: {viewers, UpdateViewerPosition}} = this;
        const {newIndex, oldIndex} = result;
        let updateIndexViewersList  = [];

        let payload = arrayMove(viewers, oldIndex, newIndex);

        let isChangedArr = viewers && payload && viewers.filter((item,index) => item.id !== payload[index].id);
        if(isChangedArr.length !== 0){
            payload.map((item, index)=>updateIndexViewersList.push({viewId: item.id, viewIndex: index, viewType: 'chart'}));
            UpdateViewerPosition(updateIndexViewersList, payload);
        }


    };

    render() {
        const {
            state: {visible, currentView, update},
            props: {viewers},
            handleSubmit, deleteViewer, onDragEnd, handleProperties, toggleCreateModal, handleDuplicate, showDetails
        } = this;
        return (
            <React.Fragment>
                <div className="main-viewer viewer flex">
                    <div className="main-viewer-wrap">
                        <div className="bg-white viewer-container">
                            <div className="viewer-list-wrap">

                                <ViewerList axis={'xy'}
                                               useDragHandle
                                               items={viewers}
                                               onSortEnd={onDragEnd}
                                               openModal={toggleCreateModal}
                                               onPropertiesClick={handleProperties}
                                               onDelete={deleteViewer}
                                               onDuplicate={handleDuplicate}
                                               onCardClick={showDetails}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    visible && <ModalContent
                        show={visible}
                        onClose={toggleCreateModal}
                        handleClickOnSubmit={handleSubmit}
                        view={currentView}
                        forUpdate={update}
                    />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    viewers: state.viewer.viewers
});

const mapDispatchToProps = dispatch => ({
    getAllViewers: () => dispatch(viewerActions.getAllViewers()),
    createViewer: (payload) => dispatch(viewerActions.createViewer(payload)),
    deleteViewer: (viewerId) => dispatch(viewerActions.deleteViewer(viewerId)),
    cloneViewer : (viewerId) => dispatch(viewerActions.cloneViewer(viewerId)),
    updateViewer: (payload) => dispatch(viewerActions.updateChartViewer(payload)),
    UpdateViewerPosition: (payload, updatedList) => dispatch(viewerActions.UpdateViewerPosition(payload, updatedList))

})


export default connect(mapStateToProps, mapDispatchToProps)(ViewersHome);
