import React, {Component}               from 'react';
import {Route, Switch}                  from "react-router-dom";
import {connect}                        from "react-redux";

import GlobalSettingsSidebar            from "./GlobalSettingsSetup/GlobalSettingsSidebar/GlobalSettingsSidebar";
import GlobalSettingsAcquisitionSetup   from "./GlobalSettingsSetup/GlobalSettingsAcquisitionSetup/GlobalSettingsAcquisitionSetup";
import GlobalSettingsProbeSetup         from "./GlobalSettingsSetup/GlobalSettingsProbeSetup/GlobalSettingsProbeSetup";
import GlobalSettingsHeader             from "./GlobalSettingsHeader/GlobalSettingsHeader";
import GlobalSettingsTreeSetup          from "./GlobalSettingsSetup/GlobalSettingsTreeSetup/GlobalSettingsTreeSetup";
import TreeSetupSidebar                 from "./GlobalSettingsSetup/GlobalSettingsTreeSetup/TreeSetupSidebar/TreeSetupSidebar";
import Translate from "../Translate/Translate"

import 'react-sortable-tree/style.css';
import './GlobalSettings.scss';

import {onClickDataInAppIsChanged} from  "../../config/constants"

import {Breadcrumb}                     from "antd";
import {Link}                           from "react-router-dom";

const Item = Breadcrumb.Item;

const routes = [
    {
        path: "/global-settings/tree-setup",
        exact: true,
        sidebar: (history) => <TreeSetupSidebar                 history= { history } />,
        header:  (name)    => <GlobalSettingsHeader             treeName= { name }    title={<Translate string={"GLOBAL.GLOBAL_SETTINGS"}/>} />,
        main:    (history) => <GlobalSettingsTreeSetup          history= { history }  title={<Translate string={"GLOBAL.GLOBAL_SETTINGS"}/>}/>
    },
    {
        path: "/global-settings/probe-setup",
        exact: true,
        sidebar: (history) => <GlobalSettingsSidebar            history  = { history } />,
        header:  (name)    => <GlobalSettingsHeader             treeName = { name }    title={<Translate string={"GLOBAL.PROBE_SETUP"}/>}  />,
        main:    (history) => <GlobalSettingsProbeSetup         history  = { history } title={<Translate string={"GLOBAL.PROBE_SETUP"}/>} />
    },
    {
        path: "/global-settings/acquisition-setup",
        exact:true,
        sidebar: (history) => <GlobalSettingsSidebar            history  = { history } />,
        header:  (name)    => <GlobalSettingsHeader             treeName = { name }    title={<Translate string={"GLOBAL.ACQUISITION_SETUP"}/>} />,
        main:    (history) => <GlobalSettingsAcquisitionSetup   history  = { history } title={<Translate string={"GLOBAL.ACQUISITION_SETUP"}/>} />
    }
];

class GlobalSettings extends Component {

    render() {
        const { props: { handleSidebar, isModalOpen, selectedTreeObj, location: { pathname }, history} } = this;

        let treeName = "";
        if(selectedTreeObj) {
            treeName = selectedTreeObj.name;
        }
        let breadCrumbs= "";

        switch (pathname) {
            case "/global-settings/tree-setup":
                if ( isModalOpen ) {
                    breadCrumbs=<Translate string={"GLOBAL_SETTINGS.CREATE_NEW_TREE_PATH"}/>;
                }
                else {
                    breadCrumbs = <Breadcrumb>
                        <Item>
                            <Link to={"/global-settings/tree-setup"}>
                                <Translate string={"GLOBAL.TREE_SETUP"}/>
                            </Link>
                        </Item>
                        <Item>
                            { treeName }
                        </Item>
                    </Breadcrumb>
                }
                break;
            case "/global-settings/probe-setup":
                breadCrumbs = <Breadcrumb>
                    <Item>
                        <Link to = { "/global-settings/tree-setup" } onClick={(e)=> {onClickDataInAppIsChanged(e,'/global-settings/tree-setup',history)}}>
                            <Translate string={"GLOBAL.TREE_SETUP"}/>
                        </Link>
                    </Item>
                    <Item>
                        <Link to = { "/global-settings/probe-setup" } >
                            <Translate string={"GLOBAL.PROBE_SETUP"}/>
                        </Link>
                    </Item>
                    <Item>
                        { treeName }
                    </Item>
                </Breadcrumb>
                break;
            case "/global-settings/acquisition-setup":
                breadCrumbs = <Breadcrumb>
                    <Item>
                        <Link to = { "/global-settings/tree-setup" } >
                            <Translate string={"GLOBAL.TREE_SETUP"}/>
                        </Link>
                    </Item>
                    <Item>
                        <Link to = { "/global-settings/probe-setup" } >
                            <Translate string={"GLOBAL.PROBE_SETUP"}/>
                        </Link>
                    </Item>
                    <Item>
                        <Link to = { "/global-settings/acquisition-setup" } >
                            <Translate string={"GLOBAL.ACQUISITION_SETUP"}/>
                        </Link>
                    </Item>
                    <Item>
                        { treeName }
                    </Item>
                </Breadcrumb>
                break;
            default:
                breadCrumbs = <Translate string={"GLOBAL_SETTINGS.DATA_ACQUISITION_SETTING"}/> ;
                break;
        }

        return(
            <Switch>
                <React.Fragment>
                    <div className='flex global-settings flex-direction-column'>
                        {
                            routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    render={() => route.header(breadCrumbs)}
                                />
                            ))}
                        <div
                            className={
                                handleSidebar ?
                                    "global-settings-body flex js-handle-global-settings-sidebar"
                                    :
                                    'global-settings-body flex'
                            }
                        >
                            <div className="sidebar-wrap">
                                {
                                    routes.map((route, index) => (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            render={() => route.sidebar(this.props.history)}
                                        />
                                    ))}
                            </div>
                            <div
                                className={
                                    handleSidebar ?
                                        "global-settings-content bg-white tree-view-container js-content"
                                        :
                                        "global-settings-content bg-white tree-view-container"
                                }
                            >
                                {
                                    routes.map((route, index) => (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            render={() => route.main(this.props.history)}
                                        />
                                    ))}
                            </div>

                        </div>
                    </div>
                </React.Fragment>
            </Switch>
        )
    }
}

const mapStateToProps = state => ({
    handleSidebar     : state.globalSettings.handleSidebar,
    selectedTreeObj   : state.dataStorage.sortData[0],
    isModalOpen       : state.globalSettings.handleModalNewTree
});

export default connect(mapStateToProps)(GlobalSettings);