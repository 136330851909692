import {connect}                                        from 'react-redux';
import React,{Component}                                from 'react';

import {DatePicker, Input }                             from 'antd';


import {calendarRange }                                 from '../../../config/constants';
import * as types                                       from '../../../actions/actionTypes';
import {energyAnalysisActions}                          from '../../../actions';

import './AnalysisCommonContent.scss'
import Translate from "../../Translate/Translate";

const {RangePicker} = DatePicker;

class AnalysisCommonContent extends Component {

    render() {
        const {
            selectedAnalysisIndex,
            yAxisName,
            dataType,
            unit,
            energyVec,
            changeYAxisName,
            changeDataType,
            changeUnit,
            changeEnergyVector,
            energyAnalysisList,
            updateAnalysis
        } = this.props;
        const selectedEnergyAnalysis =
            energyAnalysisList[selectedAnalysisIndex];
        return(
            <div className="common-content-wrapper">
                <div className="right-part">
                    <RangePicker
                        ranges={calendarRange}
                        value={selectedEnergyAnalysis && selectedEnergyAnalysis.dateRange}
                        allowClear={false}
                        disabled={!selectedEnergyAnalysis}
                        format="YYYY MMM DD"
                        onChange={dateRange => {
                            updateAnalysis(
                                energyAnalysisList,
                                {
                                    ...selectedEnergyAnalysis,
                                    dateRange
                                },
                                selectedAnalysisIndex
                            );
                        }}
                    />
                </div>
                <p className="name-section"><Translate string={"ENERGY_ANALYSIS.OUTPUT_DEFINE"}/>:</p>
                <div className="outputs-wrapper">
                    <div className="input-margin-right output-section">
                        <p className="label"><Translate string={"GLOBAL.DATA_TYPE"}/>:</p>
                        <Input
                            type="text"
                            value={ dataType }
                            disabled={!selectedEnergyAnalysis}
                            onChange={event => {
                                changeDataType(event.target.value)
                            }}
                        />
                    </div>
                    <div className="output-section">
                        <p className="label"><Translate string={"GLOBAL.UNIT"}/>:</p>
                        <Input
                            type="text"
                            value={ unit }
                            disabled={!selectedEnergyAnalysis}
                            onChange={event => {
                                changeUnit(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="outputs-wrapper">
                    <div className="input-margin-right output-section">
                        <p className="label"><Translate string={"ENERGY_ANALYSIS.Y"}/>:</p>
                        <Input
                            type="text"
                            value={ yAxisName }
                            disabled={!selectedEnergyAnalysis}
                            onChange={event => {
                                changeYAxisName(event.target.value);
                            }}
                        />
                    </div>
                    <div className="output-section">
                        <p className="label"><Translate string={"GLOBAL.ENERGY_VECTOR"}/>:</p>
                        <Input
                            type="text"
                            value={ energyVec }
                            disabled={!selectedEnergyAnalysis}
                            onChange={event => {
                                changeEnergyVector(event.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({

    energyAnalysisList    : state.energyAnalysis.energyAnalysisList,
    selectAnalysisMenu    : state.energyAnalysis.selectAnalysisMenu,
    energyAnalysisName    : state.energyAnalysis.energyAnalysisName,
    selectedAnalysisIndex : state.energyAnalysis.selectedAnalysisIndex,

    yAxisName             : state.energyAnalysis.yAxisName,
    dataType              : state.energyAnalysis.dataType,
    unit                  : state.energyAnalysis.unit,
    energyVec             : state.energyAnalysis.energyVec,

});

const mapDispatchToProps = dispatch => ({

    changeYAxisName       : (yAxisName)                   => dispatch({type: types.CHANGE_Y_AXIS_NAME , yAxisName : yAxisName}),
    changeDataType        : (dataType)                    => dispatch({type: types.CHANGE_DATA_TYPE   , dataType  : dataType}),
    changeUnit            : (unit)                        => dispatch({type: types.CHANGE_UNIT        , unit      : unit}),
    changeEnergyVector    : (energyVec)                   => dispatch({type: types.CHANGE_ENERGY_VECTOR , energyVec:energyVec}),
    updateAnalysis        : (analysisList,analysis,index) => dispatch(energyAnalysisActions.updateAnalysis(analysisList,analysis,index))

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalysisCommonContent);
