import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {closeRedIcon} from "../../assets/img/svg/globalSettingsSVG"

import {Icon, Input, Button, Modal as AntModal} from "antd";
import Translate from "../Translate/Translate";
import './Modal.scss'
import {globalSettingsActions} from "../../actions";
import {closeModalMessage, resetParamsForConfirmMessage, TranslateText} from "../../config/constants";

const confirm=AntModal.confirm;

class Modal extends Component {

    state = {
            name: "",
            description: "",
            icon: 'plus',
            errorStatus: {
              name:false
            },
            disabled:false,
            status: this.props.clone ? <Translate string={"GLOBAL.CLONE_STRUCTURE"}/> : <Translate string={"GLOBAL.NEW_TREE_STRUCTURE"}/>
    };

    modalRef = React.createRef();

    render() {
        const {
            props : { statusOfFolderCreator, clone, show},
            state : { name, description, icon, disabled, errorStatus },
            handleClickOutsideModal, modalRef, handleInputChange, onSave
        } = this;

        if(!show) {
            return null;
        }
        const buttonStatusText = clone ? <Translate string={"GLOBAL.CLONE_STRUCTURE"}/> : <Translate string={"GLOBAL.NEW_TREE_STRUCTURE"}/> ;
        const text=clone ? <Translate string={"GLOBAL_SETTINGS.CLONE_FOLDER"}/> : <Translate string={"GLOBAL_SETTINGS.CREATE_NEW_FOLDER"}/>
        const inputNameMaxLength = 40;
        return (
            <div className="backdrop-modal flex flex-align-items-center flex-justify-content-center" onClick={handleClickOutsideModal}>
                <div className="modal" ref={modalRef}>
                    <div className="modal-header">
                        <div className="flex flex-justify-content-space-between">
                            <p className={"modal-header-title black"}>{text}</p>
                            <Icon component={closeRedIcon} className='close-modal-icon' onClick={() => this.handleCloseOnIcon()}/>
                        </div>
                    </div>
                    <div className="create-modal-content">
                        <div className="create-modal-content-form">
                            <div className="create-modal-content-form-wrap">
                                <div className="form-input-field">
                                    <div className="input-label-with-counter flex flex-justify-content-space-between flex-align-items-center">
                                    <label><Translate string={"GLOBAL.NAME"}/>*
                                    <span className={!errorStatus.name ? "hide-span" : "show-span"}><Translate string={"GLOBAL_SETTINGS.ERROR_MESSAGE_NAME"}/></span>
                                    </label>
                                    <span className={this.state.name.length === inputNameMaxLength ? 'form-input-count form-input-count-error' : 'form-input-count'}
                                    >{`${this.state.name.length}/${inputNameMaxLength}`}</span>
                                    </div>
                                    <Input autoComplete='off' type='text' name='name'
                                           value={name}
                                           onChange={handleInputChange}
                                           size="large"
                                           placeholder={TranslateText("GLOBAL.ENTER_THE_NAME")}
                                           maxLength={40}
                                    />
                                </div>
                                <div className="form-input-field">
                                    <label><Translate string={"GLOBAL.DESCRIPTION"}/></label>
                                    <Input.TextArea
                                        placeholder={TranslateText("GLOBAL.ENTER_DESCRIPTION")}
                                        name='description'
                                        onChange={handleInputChange}
                                        rows='6'
                                        value={description}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"create-modal-footer"}>
                        <Button key={'create'} disabled={disabled} className={'create-new-dashboard-button bg-green'} onClick={onSave}>
                            <Icon
                            type={statusOfFolderCreator.length > 0 ? statusOfFolderCreator[0].icon : icon}
                            theme="outlined"/> {statusOfFolderCreator.length > 0 ? statusOfFolderCreator[0].status : buttonStatusText}
                        </Button>

                    </div>

                </div>
            </div>
        );
    }

    handleInputChange = e => {
        this.setState({[e.target.name]: e.target.value},  this.handleModalWhenPressBrowserBackButton);
        this.handleValidatorForInputs(e.target.value,e.target.name);

    };

    handleModalWhenPressBrowserBackButton = () => {
        this.state.name !== "" || this.state.description !== "" ? localStorage.setItem("dataInAppIsChanged","true") : localStorage.setItem("dataInAppIsChanged","false");
    };

    handleValidatorForInputs = (value,name) => {
        if(name === "name" && value.length > 0){
            this.setState({errorStatus:{name:false}});
            return;
        }
    };

    handleCloseOnIcon = () => {
        const {state: {name, description},props: {onClose}} = this;

        if(name || description){
            return confirm({
                    title: closeModalMessage.title,
                    content: closeModalMessage.content,
                    okText: closeModalMessage.okText,
                    okType: 'danger',
                    cancelText: closeModalMessage.cancelText,
                    onOk: () => {
                        resetParamsForConfirmMessage();
                        this.setState({name:'',description:null,errorStatus: {name:false}});
                        onClose();
                    }
                });
        }
        onClose();
    };

    onSave = () => {
        const {
            props:{ CreateNewFolder,clone,CloneFolder },
            state: {name, description}
        } = this;
        if(name) {
            clone ?
                this.setState({
                    icon: "loading",
                    status: "Cloning...",
                    disabled: true
                })
                :
                this.setState({
                    icon: "loading",
                    status: "Saving...",
                    disabled: true
                });

            const folder = {
                name: clone ? `${name}_clone`: name,
                description: description
            };
            resetParamsForConfirmMessage();
            if (!clone) {
                CreateNewFolder(folder);
            }
            else if (clone) {
                CloneFolder(folder)
            }

            setTimeout(() =>
                this.setState({
                    name: '',
                    description: null,
                    disabled: false,
                    icon: 'plus',
                    status: clone ? <Translate string={"GLOBAL.CLONE_STRUCTURE"}/> : <Translate string={"GLOBAL.NEW_TREE_STRUCTURE"}/>
                }), 2200);
            return;
        };
        this.setState({errorStatus: {name: true}});
    };

    handleClickOutsideModal = (event) => {
        const {state: {name, description}, props: { onClose }, modalRef} = this;
        const modal = modalRef.current;
        if (!modal.contains(event.target)){
            if(name || description){
                return confirm({
                    title: closeModalMessage.title,
                    content: closeModalMessage.content,
                    okText: closeModalMessage.okText,
                    okType: 'danger',
                    cancelText: closeModalMessage.cancelText,
                    onOk: () => {
                        resetParamsForConfirmMessage();
                        this.setState({name:'',description:null,errorStatus: {name:false}});
                        onClose();
                    }
                });
            }
            onClose();
        }
    }
}

Modal.propTypes = {
    onClose     : PropTypes.func.isRequired,
    show        : PropTypes.bool,
    children    : PropTypes.node
};

const mapStateToProps = state => ({
    StatusOnSaveNewFolder : state.globalSettings.StatusOnSaveNewFolder,
    statusOfFolderCreator : state.globalSettings.statusOfFolderCreator,
    clone                 : state.globalSettings.handleCloneModal
});

const mapDispatchToProps = dispatch => ({
    CreateNewFolder      : (folder) => dispatch(globalSettingsActions.CreateNewFolder(folder)),
    CloneFolder          : (folder) => dispatch(globalSettingsActions.CloneFolder(folder))
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);