import * as types from "./actionTypes";

import {LAYOUT, TranslateText} from "../config/constants";
import instance from '../config/axiosConf';
import {message} from "antd"

export const ModalForCreateNewDashboard = () => dispatch => {

    dispatch({
        type: types.HANDLE_MODAL_FOR_CREATE_NEW_DASHBOARD,
        HandleModalForCreateNewDashboard: true
    });

};

export const ModalForInfoOfSelectedDashboard = () => dispatch => {
    dispatch({
        type: types.HANDLE_MODAL_FOR_INFO_OF_SELECTED_DASHBOARD,
        ModalForInfoOfDashboard: true,
        DashboardBackdrop: true
    });
};

export const DestroyBackdropModalAndAllModal = () => dispatch => {

    dispatch({
        type: types.DESTROY_BACKDROP_AND_ALL_MODAL,
        ModalForInfoOfDashboard: false,
        HandleModalForSelectLayout: false,
        DashboardBackdrop: false
    });
};

export const ModalForSelectLayout = () => dispatch => {

    dispatch({
        type: types.HANDLE_MODAL_FOR_SELECT_LAYOUT_ON_DASHBOARD,
        HandleModalForSelectLayout: true,
        DashboardBackdrop: true
    });
};

export const handleLeftSidebarOnDashboardView = () => dispatch => {

    dispatch({
        type: types.HANDLE_SIDEBAR_FOR_PROBES_ON_DASHBOARD_VIEW,
        handleSidebarOnDashboardView: true
    });
};

export const SetDashboardViewLayout = id => dispatch => {
    if(id){

        const activeDashboardLayout = LAYOUT.filter(item =>  item.id === id);

        dispatch({
            type: types.HANDLE_SELECTED_DASHBOARD_VIEW,
            activeDashboardViewLayout: activeDashboardLayout
        });

    }else{

        let activeDashboardLayout = LAYOUT[0];

        dispatch({
            type: types.HANDLE_SELECTED_DASHBOARD_VIEW,
            activeDashboardViewLayout: [activeDashboardLayout]
        });

    }
};

export const GetAllDashboards = () => async dispatch => {

    const response = await instance.get('/api/dashboards', {
        params: {
            perPage : 1000
        }
    });

    if(response && response.data){
        dispatch({
            type           : types.GET_ALL_DASHBOARDS,
            dashboardsList : response.data.data
        });
    }
};

export const CreateNewDashboard = ( dashboard ) => async (dispatch,getState) =>{

    const { dashboard: { dashboardsList } } = getState();
    const updatedList = [dashboard,...dashboardsList];
    dispatch({
        type          : types.UPDATE_DASHBOARDS_LIST,
        dashboardsList : updatedList
    });

    //send sorted dashboard
    let newUpdatedPayload = [];
    updatedList.map((item, index) => newUpdatedPayload.push({dashId: item.id, dashIndex: index}))
    instance.put(`/api/dashboards/sort`,{data: newUpdatedPayload});
};

export const CloneDashboard = ( dashId ) => async (dispatch,getState) =>{

    const { dashboard: { dashboardsList } } = getState();

    const response = await instance.put(`api/dashboards/clone/${dashId}`);

    if(response && response.data){
        const updatedList = [response.data,...dashboardsList];
        dispatch({
            type          : types.UPDATE_DASHBOARDS_LIST,
            dashboardsList : updatedList
        });
        message.success(TranslateText("GLOBAL.DASHBOARD_CLONED"));

        //send sorted dashboard
        let newUpdatedPayload = [];
        updatedList.map((item, index) => newUpdatedPayload.push({dashId: item.id, dashIndex: index}))
        instance.put(`/api/dashboards/sort`,{data: newUpdatedPayload});
    }
};

export const DeleteDashboard = ( dashboardId ) => async (dispatch, getState) => {

    const { dashboard : { dashboardsList } } = getState();
    const dashboardIndex = [];

    await instance.delete(`/api/dashboards/${dashboardId}`);

    let updatedList = dashboardsList.filter(dashboard => dashboard.id !== dashboardId);
    updatedList.map((item, index)=>dashboardIndex.push({dashId: item.id, dashIndex: index}));

    dispatch(UpdateDashboardPosition(dashboardIndex, updatedList,true));
    message.success(TranslateText("GLOBAL.DASHBOARD_DELETED"));

};

export const UpdateDashboardViewIndex = (payload) => (dispatch) => {
    dispatch({
        type           : types.UPDATE_DASHBOARD_VIEWER_LIST_USE_DND,
        viewerList     : payload
    })
};

export const UpdateDashboardPosition = (payload, updatedList, isCallInDeleteDashboard) => async (dispatch) =>{
    dispatch({
        type           : types.GET_ALL_DASHBOARDS,
        dashboardsList : updatedList
    });
    const response = await instance.put(`/api/dashboards/sort`,{data: payload});
    if (response && response.data === "" ){
        !isCallInDeleteDashboard && message.success(TranslateText("GLOBAL.DASHBOARD_CHANGE_POSITION"));
    }
};

export const updateViewersPosition = (payload) => async(dispatch) => {
    const response = await instance.put(`/api/dashboards/views/sort`,{data: payload});

    if ( response && response.data){
    }
};

export const UpdateDashboard = ( updatedDashboard ) => async (dispatch,getState) =>{

    const { dashboard: { dashboardsList } } = getState();
    const updatedList            = [...dashboardsList];
    const indexOfOldValue        = dashboardsList.findIndex(dashboard => dashboard.id === updatedDashboard.id);
    updatedList[indexOfOldValue] = updatedDashboard;

    dispatch({
        type          : types.UPDATE_DASHBOARDS_LIST,
        dashboardsList : updatedList
    });
};
export const DetachViewerFromDashboard = (viewerId, dashId) => async () => {

    await instance.put(`/api/dashboards/views/detach`, {
        viewId : viewerId,
        viewType : "chart",
        dashId : dashId
    });
};

export const GetViewerSeries = (viewerId, start_time, end_time) => async () => {

    let params = {};

    if (start_time) {
        params.start_time = start_time
    }

    if (end_time) {
        params.end_time = end_time
    }

    return instance.get(`/api/series/chart/${viewerId}`, {params});
};

export const updateDashboard = ( payload )  => {


    return instance.put(`/api/dashboards/${payload.id}`,payload);

};

export const createNewDashboard = (payload) => {
    return  instance.post('/api/dashboards',payload);
};

export const ClearViewerDetachList = () => (dispatch,getState) => {
    dispatch({
        type : types.LOCAL_DETACH_VIEWER,
        viewers : getState().dashboard.viewerList,
        viewersForDetach : []
    })
};

export const ClearActiveLayout = () => (dispatch) => {
    dispatch({
        type: types.HANDLE_SELECTED_DASHBOARD_VIEW,
        activeDashboardViewLayout: []
    });
};

export const HandleDashboardChanges = (newState) => (dispatch) => {
    dispatch({
        type: types.HANDLE_DASHBOARD_CHANGES,
        isDashboardChanged: newState
    })
}
