// App actions
export const HANDLE_RIGHT_SIDEBAR_VISIBILITY            = 'HANDLE_RIGHT_SIDEBAR_VISIBILITY';
export const HANDLE_RIGHT_ENERGY_SIDEBAR_VISIBILITY     = 'HANDLE_RIGHT_ENERGY_SIDEBAR_VISIBILITY';
export const HANDLE_LEFT_SIDEBAR_VISIBILITY             = 'HANDLE_LEFT_SIDEBAR_VISIBILITY';
export const HANDLE_MODAL                               = 'HANDLE_MODAL';
export const HANDLE_LANGUAGE_PICKER                     = 'HANDLE_LANGUAGE_PICKER';

// Tree actions

export const HANDLE_LOADING_SPINNER                     = 'HANDLE_LOADING_SPINNER';
export const HANDLE_PROBE_VALUE                         = 'HANDLE_PROBE_VALUE';
export const GET_TREE_DATA                              = 'GET_TREE_DATA';
export const HANDLE_SELECTED_NODE                       = 'HANDLE_SELECTED_NODE';
export const HANDLE_CHART_BY_PROBES_ID                  = 'HANDLE_CHART_BY_PROBES_ID';
export const HANDLE_VISIBILITY                          = 'HANDLE_VISIBILITY';
export const FETCHING_TREE_DATA                         = 'FETCHING_TREE_DATA';
export const HANDLE_TREE_DATA                           = 'HANDLE_TREE_DATA';
export const HANDLE_CONTEXT_MENU                        = 'HANDLE_CONTEXT_MENU';
export const HANDLE_CHECKED_NODES                       = 'HANDLE_CHECKED_NODES';
export const HANDLE_CHECKED_NODES_IN_VIEWS_DETAILS_LEFT_SIDEBAR = 'HANDLE_CHECKED_NODES_IN_VIEWS_DETAILS_LEFT_SIDEBAR';
export const HANDLE_START_SCREEN                        = 'HANDLE_START_SCREEN';

// Data storage actions
export const HANDLE_DATA_STRUCTURE                      = 'HANDLE_DATA_STRUCTURE';
export const UPDATE_DATA_STRUCTURE_NAME                 = 'UPDATE_DATA_STRUCTURE_NAME';
export const HANDLE_ACTIVE_DATA_STRUCTURE               = 'HANDLE_ACTIVE_DATA_STRUCTURE';
export const UPDATE_DATA_STRUCTURE_TYPE                 = 'UPDATE_DATA_STRUCTURE_TYPE';
export const UPDATE_DATA_STRUCTURE_DESC                 = 'UPDATE_DATA_STRUCTURE_DESC';
export const UPDATE_DATA_STRUCTURE_TAG                  = 'UPDATE_DATA_STRUCTURE_TAG';
export const UPDATE_DATA_STRUCTURE_ID                   = 'UPDATE_DATA_STRUCTURE_ID';
export const STATUS_DATA_STRUCTURE                      = 'STATUS_DATA_STRUCTURE';

// Upload .csv file actions
export const HANDLE_FILE_LIST                           = 'HANDLE_FILE_LIST';
export const REMOVE_FILE_LIST                           = 'REMOVE_FILE_LIST';
export const HANDLE_UPLOADING_FILELIST                  = 'HANDLE_UPLOADING_FILELIST';
export const HANDLE_MULTI_UPLOAD                        = 'HANDLE_MULTI_UPLOAD';

// UploadProbe actions
export const CLEAR_PROBE_FILE_LIST                      = 'CLEAR_PROBE_FILE_LIST';
export const HANDLE_PROBE_FILE_LIST                     = 'HANDLE_PROBE_FILE_LIST';

//Chart actions
export const HANDLE_CHART_DATE_RANGE                    = 'HANDLE_CHART_DATE_RANGE';
export const GET_CHART_DATA                             = 'GET_CHART_DATA';
export const HANDLE_REMOVE_REPORTS                      = 'HANDLE_REMOVE_REPORTS';
export const HANDLE_CHART_LOADER                        = 'HANDLE_CHART_LOADER';
export const HANDLE_RESET_FILTER                        = 'HANDLE_RESET_FILTER';
export const HANDLE_RESET_FILTER_ALL                    = 'HANDLE_RESET_FILTER_ALL';
export const HANDLE_CHART_TYPE_BAR                      = 'HANDLE_CHART_TYPE_BAR';
export const GET_PROBE_BY_ID                            = 'GET_PROBE_BY_ID';
export const REMOVE_PROBE_LINE_BY_ID                    = 'REMOVE_PROBE_LINE_BY_ID';
export const GET_FILTER_BY_ID                           = 'GET_FILTER_BY_ID';
export const REMOVE_FILTER_LINE_BY_ID                   = 'REMOVE_FILTER_LINE_BY_ID'
export const GET_ANALYSIS_BY_ID                         = 'GET_ANALYSIS_BY_ID';
export const REMOVE_ANALYSIS_LINE_BY_ID                 = 'REMOVE_ANALYSIS_LINE_BY_ID';
export const ADD_PROBE_ID                               = 'ADD_PROBE_ID';
export const ADD_FILTER_ID                              = 'ADD_FILTER_ID';
export const ADD_ANALYSIS_ID                            = 'ADD_ANALYSIS_ID';
export const CLEAR_ALL_LOADED_DATA                      = 'CLEAR_ALL_LOADED_DATA';

// Filter Analyses
export const ADD_FILTER_ANALYSES                        = 'ADD_FILTER_ANALYSES';
export const CHANGE_FILTER_NAME                         = 'CHANGE_FILTER_NAME';
export const HANDLE_PERSISTENT_SELECTED_FITLER          = 'HANDLE_PERSISTENT_SELECTED_FITLER';

// Dashboard actions
export const HANDLE_REPORT_DATA                         = 'HANDLE_REPORT_DATA';
export const HANDLE_REPORT_LOADER                       = 'HANDLE_REPORT_LOADER';
export const CLEAR_REPORT_DATA                          = 'CLEAR_REPORT_DATA';

// Energy analysis
export const ADD_ANALYSIS                               = 'ADD_ANALYSIS';
export const CHANGE_ANALYSIS_NAME                       = 'CHANGE_ANALYSIS_NAME';
export const CHANGE_PERSISTENT_VALUE                    = 'CHANGE_PERSISTENT_VALUE';
export const SELECT_ANALYSIS                            = 'SELECT_ANALYSIS_FITLER';
export const HANDLE_SELECT_ANALYSIS_MENU                = 'HANDLE_SELECT_ANALYSIS_MENU';
export const HANDLE_DESELECT_ANALYSIS_MENU              = 'HANDLE_DESELECT_ANALYSIS_MENU';

export const CHANGE_Y_AXIS_NAME                         = 'CHANGE_Y_AXIS_NAME';
export const CHANGE_DATA_TYPE                           = 'CHANGE_DATA_TYPE';
export const CHANGE_UNIT                                = 'CHANGE_UNIT';
export const CHANGE_ENERGY_VECTOR                       = 'CHANGE_ENERGY_VECTOR';

export const POPULATE_ANALYSIS_PARAMETERS               = 'POPULATE_ANALYSIS_PARAMETERS';
export const UPDATE_ANALYSIS                            = 'UPDATE_ANALYSIS';
export const REMOVE_ANALYSIS                            = 'REMOVE_ANALYSIS';

// userAction
export const HANDLE_USER_AUTHORIZATION                  = 'HANDLE_USER_AUTHORIZATION';
export const SET_USERNAME                               = 'SET_USERNAME';
export const SET_EMAIL                                  = 'SET_EMAIL';
export const HANDLE_CLEAR_STORE                         = 'HANDLE_CLEAR_STORE';

export const TEST_FILTER_CREATED                        = 'TEST_FILTER_CREATED';
export const TEST_FILTER_PLOTTED                        = 'TEST_FILTER_PLOTTED';

export const PUT_FILTER_IN_DATASTRUCTURE                = 'PUT_FILTER_IN_DATASTRUCTURE';
export const CHANGE_FILTER_PERSISTENT_VALUE             = 'CHANGE_FILTER_PERSISTENT_VALUE';

//Global settings
export const HIDE_MODAL_FOR_TREE_ON_BACK_BUTTON             = "HIDE_MODAL_FOR_TREE_ON_BACK_BUTTON";
export const HIDE_MODAL_NEW_FOLDER                          = 'HIDE_MODAL_NEW_FOLDER';
export const MODAL_SUCCESS_MESSAGE                          = 'MODAL_SUCCESS_MESSAGE';
export const HANDLE_GLOBAL_SETTINGS_SIDEBAR                 = 'HANDLE_GLOBAL_SETTINGS_SIDEBAR';
export const HANDLE_LOADER_ON_DELETE                        = 'HANDLE_LOADER_ON_DELETE';
export const HANDLE_SORT_DATA_BY_ID                         = 'HANDLE_SORT_DATA_BY_ID';
export const APP_IS_STARTED_FIRST_TIME                      = 'APP_IS_STARTED_FIRST_TIME';

export const FINISH_GLOBAL_SETTINGS                         = 'FINISH_GLOBAL_SETTINGS';
export const HANDLE_DATALOGGER_MODAL                        = 'HANDLE_DATALOGGER_MODAL';

export const DELETE_NODE                                    = 'DELETE_NODE';
export const CHANGE_BRANCH_NAME                             = 'CHANGE_BRANCH_NAME';
export const ADD_NEW_BRANCH                                 = 'ADD_NEW_BRANCH';
export const HANDLE_START_CREATE_NEW_CARD                   = 'HANDLE_START_CREATE_NEW_CARD';
export const SET_CLONE_FOLDER                               = 'SET_CLONE_FOLDER';
export const REMOVE_CLONE_FOLDER                            = 'REMOVE_CLONE_FOLDER';
export const GET_ALL_DATALOGGERS                            = 'GET_ALL_DATALOGGERS';
export const UPDATE_DATALOGGER                              = 'UPDATE_DATALOGGER';
export const GET_DATALOGGER_MQTT_TAB_BY_ID                  = 'GET_DATALOGGER_MQTT_TAB_BY_ID';
export const GET_DATALOGGER_FTP_TAB_BY_ID                   = 'GET_DATALOGGER_FTP_TAB_BY_ID';
export const CHANGE_SORT_DATA                               = 'CHANGE_SORT_DATA';

export const HANDLE_PROBE_PAGINATION                        = 'HANDLE_PROBE_PAGINATION';
export const LOAD_PROBES_FOR_SELECTED_FOLDER                = 'LOAD_PROBES_FOR_SELECTED_FOLDER';
export const HANDLE_CHECKED_PROBES_IN_PROBE_SETUP           = 'HANDLE_CHECKED_PROBES_IN_PROBE_SETUP';
export const HANDLE_CHECK_ALL_PROBES_IN_PROBE_SETUP         = 'HANDLE_CHECK_ALL_PROBES_IN_PROBE_SETUP';
export const GET_CSV_PROBES                                 = 'GET_CSV_PROBES';
export const GET_FTP_PROBES                                 = 'GET_FTP_PROBES';
export const GET_MQTT_PROBES                                = 'GET_MQTT_PROBES';


export const KEEP_SELECTED_ID                           = 'KEEP_SELECTED_ID';
export const GET_ALL_DISTINCT_FILTER_PARAMS             = 'GET_ALL_DISTINCT_FILTER_PARAMS';
export const CREATE_NEW_DATA_LOGGER                     = 'CREATE_NEW_DATA_LOGGER';
export const LAST_CREATED_DATALOGGER                    = 'LAST_CREATED_DATALOGGER';
export const CREATE_FILTER_OBJECT                       = 'CREATE_FILTER_OBJECT';
export const CLOSE_MODAL_FOR_NEW_DATA_LOGGER            = 'CLOSE_MODAL_FOR_NEW_DATA_LOGGER';

export const HANDLE_DELETE_DATALOGGER                           = 'HANDLE_DELETE_DATALOGGER';
export const LOAD_FOLDER_PATHS                                  = 'LOAD_FOLDER_PATHS';

export const CREATE_PROBE_FILTER                                = 'CREATE_PROBE_FILTER'
export const CREATE_DATA_LOGGER_FILTER                          = 'CREATE_DATA_LOGGER_FILTER';
export const CREATE_FTP_FILTER                                  = 'CREATE_FTP_FILTER';
export const CREATE_CSV_FILTER                                  = 'CREATE_CSV_FILTER';
export const CREATE_MQTT_FILTER                                 = 'CREATE_MQTT_FILTER';

export const HANDLE_CHECKED_CSV_PROBES                          = 'HANDLE_CHECKED_CSV_PROBES';
export const HANDLE_CHECKED_MQTT_PROBES                         = 'HANDLE_CHECKED_MQTT_PROBES';
export const HANDLE_CHECKED_FTP_PROBES                          = 'HANDLE_CHECKED_FTP_PROBES';

export const HANDLE_CHECK_ALL_CSV_PROBES                        = 'HANDLE_CHECK_ALL_CSV_PROBES';
export const HANDLE_CHECK_ALL_MQTT_PROBES                       = 'HANDLE_CHECK_ALL_MQTT_PROBES';
export const HANDLE_CHECK_ALL_FTP_PROBES                        = 'HANDLE_CHECK_ALL_FTP_PROBES';

export const HANDLE_CSV_PAGINATION                              = 'HANDLE_CSV_PAGINATION';
export const HANDLE_FTP_PAGINATION                              = 'HANDLE_FTP_PAGINATION';
export const HANDLE_MQTT_PAGINATION                             = 'HANDLE_MQTT_PAGINATION';

export const UPDATE_PROBE                                       = 'UPDATE_PROBE';
export const UPDATE_ACQUSTION_SETUP_MQTT_PROBE                  = 'UPDATE_ACQUSTION_SETUP_MQTT_PROBE';
export const UPDATE_ACQUISTION_SETUP_FTP_PROBE                  = 'UPDATE_ACQUISTION_SETUP_FTP_PROBE';
export const UPDATE_MQTT_PASSWORD                               = 'UPDATE_MQTT_PASSWORD';
export const UPDATE_FTP_PASSWORD                                = 'UPDATE_FTP_PASSWORD';
export const CREATE_NEW_PROBE                                   = 'CREATE_NEW_PROBE';
export const DELETE_PROBE                                       = 'DELETE_PROBE';
export const HANDLE_DATA_LOGGERS_PAGINATION                     = 'HANDLE_DATA_LOGGERS_PAGINATION';

export const GET_ACQUISITION_SIDEBAR_TAB_KEY                    = 'GET_ACQUISITION_SIDEBAR_TAB_KEY';
export const CLEAR_ALL_CHECKED_PROBES                           = 'CLEAR_ALL_CHECKED_PROBES';

export const MESSAGE_RECEIVED_FROM_CHANEL                         = 'MESSAGE_RECEIVED_FROM_CHANEL';

export const GET_ALL_VIEWERS_FOR_DASHBOARD                      = 'GET_ALL_VIEWERS_FOR_DASHBOARD';
export const UPDATE_DASHBOARD_VIEWER                            = 'UPDATE_DASHBOARD_VIEWER';
export const UPDATE_DASHBOARD_VIEWER_LIST                       = 'UPDATE_DASHBOARD_VIEWER_LIST';
export const GET_ALL_VIEWERS                                    = 'GET_ALL_VIEWERS';
export const CREATE_NEW_VIEWER                                  = 'CREATE_NEW_VIEWER';
export const UPDATE_VIEWER_LIST                                 = 'UPDATE_VIEWER_LIST';
export const UPDATE_DASHBOARD_VIEWER_LIST_USE_DND               = 'UPDATE_DASHBOARD_VIEWER_LIST_USE_DND';
export const HANDLE_IS_ADD_NEW_PROBE_IN_PROBE_SETUP             = 'HANDLE_IS_ADD_NEW_PROBE_IN_PROBE_SETUP';

//Dashboard
export const HANDLE_MODAL_FOR_CREATE_NEW_DASHBOARD              = 'HANDLE_MODAL_FOR_CREATE_NEW_DASHBOARD';
export const HIDE_MODAL_FOR_DASHBOARD_ON_BACK_BUTTON            = 'HIDE_MODAL_FOR_DASHBOARD_ON_BACK_BUTTON';
export const HANDLE_DATE_RANGE_FOR_NEW_DASHBOARD                = 'HANDLE_DATE_RANGE_FOR_NEW_DASHBOARD';

//Viewer
export const HANDLE_SIDEBAR_FOR_PROBES_ON_VIEWER                = "HANDLE_SIDEBAR_FOR_PROBES_ON_VIEWER";
export const HANDLE_MODAL_FOR_INFO_OF_SELECTED_DASHBOARD        = 'HANDLE_MODAL_FOR_INFO_OF_SELECTED_DASHBOARD';
export const HANDLE_MODAL_FOR_SELECT_LAYOUT_ON_DASHBOARD        = 'HANDLE_MODAL_FOR_SELECT_LAYOUT_ON_DASHBOARD';
export const HANDLE_SIDEBAR_FOR_PROBES_ON_DASHBOARD_VIEW        = 'HANDLE_SIDEBAR_FOR_PROBES_ON_DASHBOARD_VIEW';
export const HANDLE_SELECTED_DASHBOARD_VIEW                     = 'HANDLE_SELECTED_DASHBOARD_VIEW';
export const UPDATE_SELECTED_DASHBOARD_CALENDAR_DATE            = 'UPDATE_SELECTED_DASHBOARD_CALENDAR_DATE';
export const UPDATE_GLOBAL_SLIDER_AND_CALENDAR_ON_DASHBOARD     = 'UPDATE_GLOBAL_SLIDER_AND_CALENDAR_ON_DASHBOARD';
export const DESTROY_BACKDROP_AND_ALL_MODAL                     = 'DESTROY_BACKDROP_AND_ALL_MODAL';
export const GET_ALL_DASHBOARDS                                 = 'GET_ALL_DASHBOARDS';
export const UPDATE_DASHBOARDS_LIST                             = 'UPDATE_DASHBOARDS_LIST';
export const GET_ALL_DASHBOARD_LAYOUTS                          = 'GET_ALL_DASHBOARD_LAYOUTS';

export const HANDLE_MODAL_FOR_INFO_OF_VIEWER                    = 'HANDLE_MODAL_FOR_INFO_OF_VIEWER';
export const DESTROY_BACKDROP_AND_ALL_MODAL_VIEWER              = 'DESTROY_BACKDROP_AND_ALL_MODAL_VIEWER';
export const HANDLE_SIDEBAR_FOR_SETTINGS_ON_VIEWER              = 'HANDLE_SIDEBAR_FOR_SETTINGS_ON_VIEWER';
export const HANDLE_MODAL_FOR_SELECT_DASHBOARD                  = 'HANDLE_MODAL_FOR_SELECT_DASHBOARD';

export const UPDATE_VIEWER_START_TIME                           = 'UPDATE_VIEWER_START_TIME';
export const UPDATE_VIEWER_END_TIME                             = 'UPDATE_VIEWER_END_TIME';
export const SET_CURRENT_VIEWER                                 = 'SET_CURRENT_VIEWER';
export const LOCAL_DETACH_VIEWER                                = 'LOCAL_DETACH_VIEWER';

export const DELETE_VIEWER                                      = 'DELETE_VIEWER' ;
export const UPDATE_VIEWER                                      = 'UPDATE_VIEWER';

export const UPDATE_APPLY                                       = 'UPDATE_APPLY';
export const HANDLE_DASHBOARD_CHANGES                           = 'HANDLE_DASHBOARD_CHANGES';

export const HANDLE_GETING_SERIES_STATE                         = 'HANDLE_GETING_SERIES_STATE';