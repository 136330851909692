import React, {useEffect, useState} from 'react'
import "./ViewersDetailsRightSideBar.scss";
import {Icon} from "antd";
import {ColorPickerInput, InputSelect, InputText} from "../../../FormInput/FormInput"
import {isJson, lineStyles} from '../../../../config/constants';
import Translate from "../../../Translate/Translate";
import {Separator} from "react-contexify";

const ViewersDetailsRightSideBar = (props) => {

    const [probeOptions,setProbeOptions] = useState(null);
    const [isProbeOptionsChanged, setProbeOptionsChanged] = useState(false);
    const [currentProbeId,setCurrentProbeId] = useState(null);
    const [isOpenTitle,setOpenTitle] = useState(true);
    const [isOpenProbeStyle,setOpenProbeStyle] = useState(false);
    const {probesList, updateProbeStyle, propsViewerProbes, setProbeStyleRightSideBar, titleRightSideBar,
        treeLeftSideBar, headerViewDetails} = props;

    const [axisName,setAxisName] = useState();
    const isScatterType = (propsViewerProbes.options && propsViewerProbes.options.chartType === 'scatter') || false;
    const isColumnType = (propsViewerProbes.options && propsViewerProbes.options.chartType === 'column') || false;

    // componentDidUpdate change probe
    useEffect(() => {
            const currentProbe = probesList.find(probe => probe.id === currentProbeId);
            let options;
            if (currentProbe && currentProbe.options) {
                options = isJson(currentProbe.options) ? JSON.parse(currentProbe.options) : currentProbe.options;
            } else
                options = {};
        const yAxisName = options && options.yAxis ? options.yAxis.name : null;
            setProbeOptions(options);

            setAxisName(yAxisName);
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentProbeId]);

    //componentDidUpdate change probe options
    useEffect(() => {

        if(isProbeOptionsChanged) {
            const currentProbe = probesList && {...probesList.find(probe => probe.id === currentProbeId)};
            const notChangedCurrentProbe = propsViewerProbes.probes && {...propsViewerProbes.probes.find(probe => probe.id === currentProbeId)};
            const filteredProbes = probesList && [...probesList.filter(probe => probe.id !== currentProbeId)];

            if (currentProbe) {
                currentProbe.options = {...probeOptions};
                currentProbe.changed = true;
            }
            let areOptionsChanged;
            if(notChangedCurrentProbe !== null) {
                  areOptionsChanged = checkIsChangedOptions(currentProbe, notChangedCurrentProbe);
            }
            if ([].concat.apply([], areOptionsChanged).includes(true) === true) {
                if(titleRightSideBar === false && treeLeftSideBar === false && headerViewDetails === false) {
                    localStorage.setItem("dataInAppIsChanged", "true")
                }
                setProbeStyleRightSideBar(true)
            } else {
                if(titleRightSideBar === false && treeLeftSideBar === false && headerViewDetails === false) {
                    localStorage.setItem("dataInAppIsChanged", "false");
                }
                setProbeStyleRightSideBar(false)
            }
            const newProbeLists=[...filteredProbes,currentProbe];
            updateProbeStyle(currentProbeId, probeOptions,newProbeLists);
            setProbeOptionsChanged(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isProbeOptionsChanged, updateProbeStyle, probeOptions, currentProbeId, probesList]);

    const checkIsChangedOptions = (currentProbe,notChangedCurrentProbe) => {

        const optionsKeys = Object.keys(currentProbe.options);
        const yAxisKeys = currentProbe.options && currentProbe.options["yAxis"] ? Object.keys(currentProbe.options["yAxis"]) : [];
        return optionsKeys.map((optionKey) => {
            if (typeof currentProbe.options[optionKey] === "object") {
                return yAxisKeys.map(yAxisKey => {
                    if (notChangedCurrentProbe.options && notChangedCurrentProbe.options[optionKey]) {
                        return notChangedCurrentProbe.options[optionKey][yAxisKey] !== currentProbe.options[optionKey][yAxisKey]
                    }
                    return !(currentProbe.options[optionKey][yAxisKey].length === 0 || currentProbe.options[optionKey][yAxisKey] === undefined);
                })
            }
            return (notChangedCurrentProbe.options && notChangedCurrentProbe.options[optionKey]) !== currentProbe.options[optionKey]
        });
    };
    const yAxisHandler = (event) => {
        event.preventDefault();
        setProbeOptions({...probeOptions,yAxis: {...probeOptions.yAxis,[event.target.name]:event.target.value}});
        setProbeOptionsChanged(true);
    };

    const lineColorHandler = (event) => {
        setProbeOptions({...probeOptions,lineColor:event.hex});
        setProbeOptionsChanged(true);
    };

    const lineStyleHandler = (name,value) => {
        setProbeOptions({...probeOptions,[name]:value});
        setProbeOptionsChanged(true);
    };

    const pointColorHandler = (color, event) => {
        isScatterType ? setProbeOptions({...probeOptions,pointColor:color.hex, lineColor:color.hex}) : setProbeOptions({...probeOptions,pointColor:color.hex});
        setProbeOptionsChanged(true);
    };

    const handleChangeAxisName = (event) => {
        event.preventDefault();
        setAxisName(event.target.value);
    };
    
    return(
        <div className={'right-sidebar-wrap'} >
            <div style={{height :'100%', position:"absolute",overflowY: 'auto', width:"100%", padding: "10px"}}>
                <div className={"right-sidebar-title"}>
                    <div className={"flex flex-align-items-center flex-justify-content-space-between right-sidebar-title-header"} onClick ={() => setOpenTitle(!isOpenTitle)}>
                        <h2>Title</h2>
                        <Icon type={isOpenTitle ? 'up' : 'down'}/>
                    </div>
                    {
                        isOpenTitle &&
                        <div className="right-sidebar-filters-body sidebar-filters-body" >
                            <InputText
                                label={<Translate string={"GLOBAL.NAME"}/>}
                                name={'name'}
                                handleOnChange={props.changeViewerPropsHandler}
                                value={props.viewerName}
                            />
                            <InputText
                                label={<Translate string={"GLOBAL.DESCRIPTION"}/>}
                                name={'description'}
                                handleOnChange={props.changeViewerPropsHandler}
                                value={props.viewerDescriprion}
                            />
                        </div>
                    }
                </div>
                <div style={{margin : '10px 0',padding:'2px 0'}}>
                    <Separator />
                </div>
                <div className={"right-sidebar-probes-styles"}>
                    <div className={"flex flex-align-items-center flex-justify-content-space-between right-sidebar-title-header"} onClick={() => setOpenProbeStyle(!isOpenProbeStyle)}>
                        <h2><Translate string={"VIEWERS.PROBE_STYLE"}/></h2>
                        <Icon type={isOpenProbeStyle ? 'up' : 'down'} />
                    </div>

                    {
                        isOpenProbeStyle &&
                        <div className={props.probesList ? "right-sidebar-filters-body sidebar-filters-body" : "right-sidebar-filters-body sidebar-filters-body disabledbutton"}>
                            <InputSelect
                                label={<Translate string={"VIEWERS.PROBE"}/>}
                                name={'probe'}
                                onChangeSelect={(_,probeId) => setCurrentProbeId(probeId)}
                                optionValues={props.probesList}
                                value={currentProbeId}
                            />
                        </div>
                    }
                </div>
                {
                    isOpenProbeStyle &&
                    <React.Fragment>
                            <div
                                className={"flex flex-align-items-center flex-justify-content-space-between right-sidebar-title-header"}>
                                <span className={"spanInProbestypeTitle"}><Translate string={"VIEWERS.PROBE_STYLE_OPTIONS"}/></span>
                            </div>
                            <div className={ currentProbeId ? "right-sidebar-filters-body sidebar-filters-body" : "right-sidebar-filters-body sidebar-filters-body disabledbutton"}>
                                {isScatterType 
                                    ? <InputSelect
                                        label={<Translate string={"VIEWERS.LINE_COLOR"}/>}
                                        disabled={isScatterType}
                                    />
                                    : <ColorPickerInput
                                        label={<Translate string={"VIEWERS.LINE_COLOR"}/>}
                                        name={'lineColor'}
                                        handleColorOnColordataPicker={lineColorHandler}
                                        hexColor={probeOptions.lineColor}
                                    />
                                }
                                <InputSelect
                                    label={<Translate string={"VIEWERS.LINE_STYLE"}/>}
                                    name={'lineStyle'}
                                    onChangeSelect={(name, value) => lineStyleHandler(name, value)}
                                    optionValues={lineStyles}
                                    value={probeOptions.lineStyle}
                                    disabled={isScatterType || isColumnType}
                                />
                                {isColumnType 
                                    ? <InputSelect
                                        label={<Translate string={"VIEWERS.POINT_COLOR"}/>}
                                        disabled={isColumnType}
                                    />
                                    : <ColorPickerInput
                                        label={<Translate string={"VIEWERS.POINT_COLOR"}/>}
                                        name={'pointColor'}
                                        handleColorOnColordataPicker={pointColorHandler}
                                        hexColor={probeOptions.pointColor}
                                        disabled={isColumnType}
                                    />

                                }
                            </div>
                            <div
                                className={"flex flex-align-items-center flex-justify-content-space-between right-sidebar-title-header"}>
                                <span className={"spanInProbestypeTitle"}><Translate string={"VIEWERS.Y-AXIS"}/></span>
                            </div>
                            <div className={ currentProbeId ? "right-sidebar-filters-body sidebar-filters-body" : "right-sidebar-filters-body sidebar-filters-body disabledbutton"}>
                                <InputText
                                    label={<Translate string={"GLOBAL.NAME"}/>}
                                    name={'name'}
                                    handleOnChange={handleChangeAxisName}
                                    handleOnBlur={yAxisHandler}
                                    value={axisName}
                                />
                                <InputText
                                    label={<Translate string={"VIEWERS.MIN"}/>}
                                    name={'min'}
                                    handleOnChange={yAxisHandler}
                                    value={probeOptions && probeOptions.yAxis && probeOptions.yAxis.min}
                                />
                                <InputText
                                    label={<Translate string={"VIEWERS.MAX"}/>}
                                    name={'max'}
                                    handleOnChange={yAxisHandler}
                                    value={probeOptions && probeOptions.yAxis && probeOptions.yAxis.max}
                                />
                            </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
export default ViewersDetailsRightSideBar;
