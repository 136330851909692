import React, {Component} from 'react';

import './DashboardViewLeftSidebar.scss';
import SidebarTree from "../../../Tree/TreeV2";

class DashboardViewLeftSidebar extends Component{
    render() {
        const {props:{probesOnDashboard, loadedFiltersId}}=this;
        return(
            <React.Fragment>
                <div className="dashboard-view-sidebar-container bg-white">
                    <SidebarTree
                        readOnly={true}
                        probesOnDashboard={probesOnDashboard}
                        selectable={false}
                        loadedFiltersId={loadedFiltersId}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default DashboardViewLeftSidebar;
