import {useEffect, useRef, useState} from "react";
import {connect} from 'react-redux'
import {Button, DatePicker, Icon, Modal, message} from "antd";
import {createNewDashboard, updateDashboard} from "../../../actions/dashboard";
import {Input} from "antd";
import {calendarRange, closeModalMessage, resetParamsForConfirmMessage, TranslateText} from "../../../config/constants";
import React from "react";
import moment from "moment";
import './DashboardModal.scss'
import {dashboard} from "../../../actions";
import Translate from "../../Translate/Translate"

const confirm= Modal.confirm;
const RangePicker = DatePicker.RangePicker;

const DashboardModalComponent = (props) => {
    const [localIsVisible, setLocalVisible] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [dateRange, setDateRange] = useState([moment().subtract(1, 'month'), moment()]);

    const errorMessage = useRef(null);
    const nameRef = useRef(null);

    const inputNameMaxLength = 40;
    const inputDescMaxLength = 1000;

    const {isVisible} = props;
    const headerText = props.isUpdate ? <Translate string={"GLOBAL.UPDATE_DASHBOARD"} /> : <Translate string={"GLOBAL.NEW_DASHBOARD"} />;
    const {dashboard} = props;

    const GenerateFooterButton = () => {
        return (
            <Button key={'create'} className={'create-new-dashboard-button bg-green'}
                    htmlType={'submit'} onClick={handleOnSubmit}>
                <Icon type={props.update ? "sync" : "plus"}
                      theme="outlined"/>{props.isUpdate ? <Translate string={"GLOBAL.UPDATE_DASHBOARD"} /> : <Translate string={"GLOBAL.NEW_DASHBOARD"} />}
            </Button>
        )
    };
    useEffect(() => {
        setLocalVisible(isVisible);
    }, [isVisible]);
    useEffect(() => {
        if(props.isUpdate){
            name !== dashboard.name || description !== dashboard.description
                ? localStorage.setItem("dataInAppIsChanged","true")
                : localStorage.setItem("dataInAppIsChanged","false");
            return;
        }
        name !== "" || description !== ""  ?
            localStorage.setItem("dataInAppIsChanged","true")
            : localStorage.setItem("dataInAppIsChanged","false");
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name,description]);

    useEffect(() => {
        if (dashboard && dashboard.id) {
            setName(dashboard.name);
            setDescription(dashboard.description);
            setDateRange([moment(dashboard.from), moment(dashboard.to)]);
        }
    }, [dashboard,isVisible]);
    const clearState = () => {
        setName('');
        setDescription('');
        setDateRange([moment().subtract(1, 'month'), moment()]);
    };
    const handleOnSubmit = (event) => {
        event.preventDefault();
        if(name) {
            const payload =
                {
                    name: name,
                    description: description,
                    from: dateRange[0].format('YYYY-MM-DD'),
                    to: dateRange[1].format('YYYY-MM-DD'),
                    index : props.lastIndex + 1
                };
            if (props.isUpdate) {
                payload.id = dashboard.id;
                updateDashboard(payload).then(response => {
                    clearState();
                    resetParamsForConfirmMessage();
                    message.success(TranslateText("GLOBAL.UPDATE_DASHBOARD"));
                    props.HandleSubmitDashboardForm(response.data.data);
                });
            } else {
                createNewDashboard(payload).then(response => {
                    clearState();
                    message.success(TranslateText("DASHBOARD.SAVED"));
                    props.HandleSubmitDashboardForm(response.data.data,props.CreateNewDashboard);
                });
            }
        } else{
            showErrorMessage();
        }
    };
    const showErrorMessage = () => {
        errorMessage.current.innerHTML = TranslateText("GLOBAL.ERROR_MESSAGE_NAME");
        nameRef.current.input.classList.add('error-input');
    };
    const hideErrorMessage = () => {
        errorMessage.current.innerHTML = '';
        errorMessage.current.classList.remove('error-input');
        nameRef.current.input.classList.remove('error-input');
    };
    const handleNameChange = (event) => {

        if (name && name.length) {
            hideErrorMessage();
        }
        setName(event.target.value);
    };

    const handleOnBlur = (event) => {
        const value = event.target.value;
        const classList = event.target.classList ;
        if(!value && !classList.contains('error-input')) {
            showErrorMessage();
        }
    };
    const handleOnClose = () => {
        const {ModalForCreateNewDashboard, closeModal, ClearDashbaordHomeState} =props;
        if(localStorage.getItem("dataInAppIsChanged") === "true"){
            return confirm({
                title: closeModalMessage.title,
                content: closeModalMessage.content,
                okText: closeModalMessage.okText,
                okType: 'danger',
                cancelText: closeModalMessage.cancelText,
                onOk: () => {

                    resetParamsForConfirmMessage();
                    clearState();
                    ClearDashbaordHomeState();
                    ModalForCreateNewDashboard();
                    setLocalVisible(false);
                    closeModal && closeModal();
                }
            });
        }
        closeModal && closeModal();
        ModalForCreateNewDashboard();
        clearState();
        ClearDashbaordHomeState();
    }
    return (
        <Modal
            visible={localIsVisible}
            closable={false}
            className={'dashboard-modal'}
            getContainer={() => document.getElementById("dashboard-modal")}
            footer={[
                GenerateFooterButton()
            ]}
        >
            <div className="create-new-dashboard-header">
                <div className="create-new-dashboard-header flex flex-justify-content-space-between">
                    <p className={"create-new-dashboard-header-title black"}>{headerText}</p>
                    <Icon type={"close"} theme="outlined" onClick={() => handleOnClose()}/>
                </div>
            </div>
            <form className="create-new-dashboard-content">
                <div className="create-new-dashboard-content-form">
                    <div className="create-new-dashboard-content-form-wrap">
                        <div className="form-input-field">
                            <div
                                className="input-label-with-counter flex flex-justify-content-space-between flex-align-items-center">
                                <label><Translate string={"GLOBAL.NAME"}/><span style={{color: 'red'}}>*</span>
                                    <span id={'display-error-message'} ref={errorMessage}/>
                                </label>
                                <span
                                    className={name.length === inputNameMaxLength ? 'form-input-count form-input-count-error' : 'form-input-count'}>
                                    {
                                        `${name.length}/${inputNameMaxLength}`
                                    }
                                </span>
                            </div>
                            <Input autoComplete='off' type='text' name='name'
                                   value={name}
                                   onChange={handleNameChange}
                                   size="large"
                                   placeholder={TranslateText("GLOBAL.ENTER_THE_NAME")}
                                   maxLength={40}
                                   onBlur={handleOnBlur}
                                   ref={nameRef}
                            />
                        </div>
                        <div className="form-input-field">
                            <div
                                className="input-label-with-counter flex flex-justify-content-space-between flex-align-items-center">
                                <label><Translate string={"GLOBAL.DESCRIPTION"}/></label>
                                <span
                                    className={description.length === inputDescMaxLength ? 'form-input-count form-input-count-error' : 'form-input-count'}>
                                    {
                                        `${description.length}/${inputDescMaxLength}`
                                    }
                                </span>
                            </div>
                            <Input.TextArea
                                placeholder={TranslateText("GLOBAL.ENTER_DESCRIPTION")}
                                name='description'
                                onChange={event => setDescription(event.target.value)}
                                rows='6'
                                value={description}
                                maxLength={1000}
                            />
                        </div>
                        <div className="form-input-field">
                            <label><Translate string={"GLOBAL.DATE_RANGE"}/></label>
                            <RangePicker
                                size={'large'}
                                ranges={calendarRange}
                                allowClear={false}
                                format={'YYYY MMM DD'}
                                value={dateRange}
                                className={'dashboard-range-picker'}
                                onChange={dates => setDateRange(dates)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    )
};

const mapDispatchToProps = dispatch => ({
    ModalForCreateNewDashboard: () => dispatch(dashboard.ModalForCreateNewDashboard()),
    CreateNewDashboard: (payload) => dispatch(dashboard.CreateNewDashboard(payload))
})
export const DashboardModal = connect(null,
    mapDispatchToProps)(DashboardModalComponent)
