import React from 'react';
import {Icon, Dropdown, Menu, Button, Modal} from "antd";
import Translate from "../../Translate/Translate";
import './DashboardCards.scss';


export const NewDashboardCard = (props) => {
    return (
        <div className="item item-new-dashboard">
            <div className="item-wrap flex flex-justify-content-center flex-align-items-center"
                 onClick={props.onCreate}>
                <div
                    className="item-title flex flex-direction-column flex-justify-content-center flex-align-items-center">
                    <Icon type="plus" theme="outlined"/>
                    <Translate string={"GLOBAL.NEW_DASHBOARD"}/>
                </div>
            </div>
        </div>
    );
};
export const DashboardCard = (props) => {
    const {
        dashboard, DragHandles,
        onPropertiesClick, onDelete, onDuplicate
    } = props;

    const menu = () => {
        return (<Menu>
            <Menu.Item key="1" onClick={() => onPropertiesClick(dashboard)}> <Translate string={"GLOBAL.PROPERTIES"}/></Menu.Item>
            <Menu.Item key="2" onClick={() => onDuplicate(dashboard)}><Translate string={"GLOBAL.DUPLICATE"}/></Menu.Item>
            <Menu.Item key="3"><Translate string={"GLOBAL.PERMISSION"}/></Menu.Item>
            <Menu.Item key="4" className={'text-red'} onClick={showDeleteConfirm}><Translate string={"GLOBAL.DELETE"}/></Menu.Item>
        </Menu>)
    };
    const showDeleteConfirm = () => {

        Modal.confirm({
            title: <Translate string={"DASHBOARD.DELETE_CONFIRM_TITLE"} />,
            content: <Translate string={"DASHBOARD.DELETE_CONFIRM_CONTENT"} />,
            okText: <Translate string={"GLOBAL.CONFIRM_DELETE_OK_BUTTON_TEXT"} />,
            okType: 'danger',
            cancelText: <Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>,
            onOk() {
                onDelete(dashboard.id)
            },
            onCancel() {
            },
        });
    };
    return (
        <div className={props.status ? "item dashboard-structure selected" : "item dashboard-structure"}>
            <div className="item-drag">
                {DragHandles}
            </div>
            <div className="item-wrap flex flex-direction-column flex-justify-content-center"
                 onClick={props.onClick}>
                <div
                    className="item-dashboard-title flex flex-align-items-center flex-justify-content-center"
                    style={{WebkitBoxOrient: 'vertical', MozBoxOrient: 'vertical'}}>
                    <svg width="28" height="24" viewBox="0 0 28 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24.7333 7.33332V4.06665H21.4667V0.799988H0V16.6667H3.26667V19.9333H6.53333V23.2H28V7.33332H24.7333ZM0.933333 15.7333V1.73332H20.5333V4.06665H3.26667V15.7333H0.933333ZM4.2 19V16.6667V4.99999H21.4667H23.8V7.33332H6.53333V19H4.2ZM27.0667 22.2667H7.46667V19.9333V8.26665H24.7333H27.0667V22.2667Z"
                            fill="white"/>
                    </svg>
                    <p>{dashboard.name}</p>
                </div>
                <div className="item-description">
                    {dashboard.description && dashboard.description.length > 80 ? dashboard.description.substring(0, 80) + "..." : dashboard.description}
                </div>
            </div>
            <div className="item-options" id={`areas-${dashboard.index}-${dashboard.id}`}>
                <Dropdown trigger={['click']} overlay={() => menu()}
                          getPopupContainer={() => document.getElementById(`areas-${dashboard.index}-${dashboard.id}`)}
                          placement="bottomLeft">
                    <Button type={'button'} className='icon-wrap'>
                        <span></span>
                        <span></span>
                        <span></span>
                    </Button>

                </Dropdown>
            </div>
        </div>
    );
};
