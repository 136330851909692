import React, { Component }                   from 'react';
import { connect }                            from 'react-redux';

import {Button }                              from 'antd';

import EnergyAnalysisHeader                   from './EnergyAnalysisHeader/EnergyAnalysisHeader';
import EnergyAnalysisTypeContainer            from './EnergyAnalysisTypeContainer/EnergyAnalysisTypeContainer';
import EnergyAnalysisNameSection              from './EnergyAnalysisNameSection/EnergyAnalysisNameSection';
import SelectedAnalysisContainer              from './SelectedAnalysisContainer/SelectedAnalysisContainer';
import EnergyAnalysisFooter                   from './EnergyAnalysisFooter/EnergyAnalysisFooter';
import EnergyAnalysisToolContainer            from './EnergyAnalysisToolContainer/EnergyAnalysisToolContainer';
import AnalysisCommonContent                  from './AnalysisCommonContent/AnalysisCommonContent';
import AnalysisMenu                           from './AnalysisMenu/AnalysisMenu';

import { energyAnalysisActions }              from '../../actions';
import * as types                             from '../../actions/actionTypes';
import Translate  from "../Translate/Translate"
import './EnergyAnalysis.scss';

class EnergyAnalysis extends Component {

  componentDidMount(){

    const { history } = this.props;

    // Listen for changes to the current location.
    this.unlisten = history.listen((location, action) => {

      if(location.search){
        const searchParam = location.search;
        const analysisId = searchParam.replace('?','').split('=')[1];
        if(!isNaN(analysisId)) {
          // populateAnalysisParameters(parseInt(analysisId));
        }
      }

    });
  }

  componentWillUnmount() {
    const {
      handleDeSelectAnalysisMenu
    } = this.props;

    handleDeSelectAnalysisMenu();

  }



  render() {
    const {
      history,
      selectedAnalysisIndex,
      handleDeSelectAnalysisMenu,
      energyAnalysisList,
      selectAnalysisMenu
    } = this.props;

    if (!selectAnalysisMenu)
      return (
        <div className="viewer-wrap">
          <AnalysisMenu/>
        </div>
      );

    const selectedEnergyAnalysis =
      energyAnalysisList[selectedAnalysisIndex];

    return (
        <>
          <div className="energy-sidebar">
            <div className="right-container">
              <div className="content flex flex-align-items-center">
                <EnergyAnalysisHeader/>
                <EnergyAnalysisTypeContainer/>
              </div>
              <EnergyAnalysisNameSection/>
              <SelectedAnalysisContainer/>
              <EnergyAnalysisFooter history={history} />
            </div>
          </div>
          <div className="energy-left-container">
            <div className="header-left-part">
            <Button
                onClick={handleDeSelectAnalysisMenu}
                type="primary"
                icon="arrow-left"
            />
            <p>
              { selectedEnergyAnalysis ? selectedEnergyAnalysis.displayName : <Translate string={"ENERGY_ANALYSIS.NO_ANALYSIS_TYPE_SELECTED"}/>}
            </p>
              </div>
            <div className="common-content">
              <AnalysisCommonContent/>
              <EnergyAnalysisToolContainer />
            </div>
          </div>
        </>
    );
  }
}

const mapStateToProps = state => ({

  energyAnalysisList         : state.energyAnalysis.energyAnalysisList,
  selectAnalysisMenu         : state.energyAnalysis.selectAnalysisMenu,
  selectedAnalysisIndex      : state.energyAnalysis.selectedAnalysisIndex,
  energyAnalysisDateRange    : state.energyAnalysis.energyAnalysisDateRange

});

const mapDispatchToProps = dispatch => ({

  handleDeSelectAnalysisMenu : ()           => dispatch({type: types.HANDLE_DESELECT_ANALYSIS_MENU}),
  populateAnalysisParameters : (analysisId) => dispatch(energyAnalysisActions.populateAnalysisView(analysisId))

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyAnalysis);
