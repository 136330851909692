import React, { Component } from 'react';

import TopBar from '../TopBar/TopBar';
import MainContent from '../MainContent/MainContent';

import { connect } from 'react-redux';
import {treeActions, dashboard, appActions} from '../../actions';
import * as userActions from '../../actions/user';
import {Redirect,Route} from 'react-router-dom';
import GlobalSettings from "../GlobalSettings/GlobalSettings";

import {LocaleContext} from '../Translate/locale-context';

import 'antd/dist/antd.css';
import {Spin} from "antd";
import './App.scss';


class App extends Component {

  componentDidMount() {
    this.props.isStart();
    this.props.handleTreeData();
    this.props.GetAllDashboards();
    this.props.GetUserSettings();

  }
    render() {
    localStorage.setItem("lastUrl", `${window.location.pathname}${window.location.search}`);
    const {sidebarLeftVisible: visibility, history,
        renderedAnalysis, status, loader, treeStructure, match, currentLanguage } = this.props;
    let makeRoute=null;

    let redirectOnTree=null;
    if(!status){
      makeRoute=(
          <Route
              path="/global-settings/tree-setup"
              exact={true}
              component={GlobalSettings}
          />
      )
    }
    if(treeStructure && treeStructure.length ===0){
      redirectOnTree=(
          <Redirect  to="/global-settings/tree-setup"/>
      )
    }
    let shouldRedirect = match.url === window.location.pathname;

    return (
        <LocaleContext.Provider value={currentLanguage}>
            {status || !loader ?
      <div className={
          visibility
            ? 'root-container flex flex-direction-column sidebar-animation hide-sidebar'
            : 'root-container flex flex-direction-column sidebar-animation'
        }
      >
        <TopBar logoutUser={this.props.logoutUser} history={history} preferredLanguage={currentLanguage}/>
        {status ?    <div className={ status ? 'flex main-content-wrap root-container-app' : 'main-content-wrap'}>
          { status ? <MainContent history={history} analysisView={renderedAnalysis}/> : <GlobalSettings history={history}/>}
        </div> :  makeRoute}
        {shouldRedirect && redirectOnTree}
        { this.props.loading ? <Spin size={'large'} /> : null }
      </div> : null}
        </LocaleContext.Provider>
    );
  };
}


const mapStateToProps = state => ({
    sidebarLeftVisible: state.app.sidebarLeftVisible,
    currentLanguage: state.app.currentLanguage,
    status: state.tree.status,
    isAuthorize: state.user.isAuthorize,
    renderedAnalysis: state.energyAnalysis.selectAnalysisMenu,
    loadingSpinner: state.tree.loadingSpinner,
    treeStructure: state.tree.structure,
    loader: state.tree.loader
});

const mapDispatchToProps = dispatch => ({
    logoutUser          : (history) => dispatch(userActions.logoutUser(history)),
    handleTreeData      : ()        => dispatch(treeActions.handleTreeData(false)),
    deleteSelectedNode  : ()        => dispatch(treeActions.deleteSelectedNode()),
    isStart             : ()        => dispatch(treeActions.isStart()),
    GetAllDashboards    : ()        => dispatch(dashboard.GetAllDashboards()),
    GetUserSettings   : ()        => dispatch(appActions.GetUserSettings())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
