import React, {useEffect, useState} from 'react'
import "./ModalFtpTab.scss"

import {Switch, Icon, Button, Input, Tooltip, Popconfirm} from "antd";
import {Refresh} from "../../../../../../../assets/img/svg/globalSettingsSVG";
import instance from "../../../../../../../config/axiosConf";
import Translate from "../../../../../../Translate/Translate";

const ModalFtpTab = ({item, onUpdate, onSave, setGeneratedPassword, fieldValidator, validatorObj, exportDataLoggerInfo, clipboradField, dataLogger}) => {
    const [consumerStatus, setConsumerStatus] = useState(false);

    useEffect(() => {
        instance.get(`/api/dataloggers/${dataLogger.id}/consumer/status?conn=ftp`)
            .then(response => {
                if(
                    response.data.status === 2 ||
                    response.data.status === 3 ||
                    response.data.message === 'running' ||
                    response.data.message === 'starting'
                ){
                    setConsumerStatus(true)
                }
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const toggleConsumer = () => {
        consumerStatus ? instance.get(`/api/dataloggers/${dataLogger.id}/consumer/stop?conn=ftp`)
            : instance.get(`/api/dataloggers/${dataLogger.id}/consumer/start?conn=ftp`);

        setConsumerStatus(!consumerStatus)
    };
    
    const handleDownloadSSL = () => {
        const path = require('../../../../../../../assets/ssl/SSL_FTP_certificate.cer');
        const a = document.createElement("a");
        a.href = path;
        a.download = `SSL_FTP_synapsees_certificate.cer`;
        a.click();
        a.remove();
    }

    return (
        <React.Fragment>
            <div className="modal-form-ftp">
                <div className="row-form flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.CONNECTION_ID"}/></label>
                        <Input autoComplete="off" className="modal-form-input" type="number" name="port" value={item.id}
                               disabled={true}/>

                    </div>
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.HOST"}/></label>
                        <Input autoComplete="off" className="modal-form-input" disabled={true}
                               style={validatorObj.host && validatorObj.host === true ? {"color": "red"} : null}
                               type="text" name="ip"
                               value={item.host} onBlur={(e) => {
                            fieldValidator({name: e.target.name, value: e.target.value})
                        }} onChange={(event) => {
                            onUpdate(event.target.name, event.target.value)
                        }}/>
                    </div>
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.PORT"}/></label>
                        <Input autoComplete="off" className="modal-form-input" type="number" disabled={true} name="port"
                               value={item.port} onBlur={(event) => {
                            fieldValidator({name: event.target.name, value: event.target.value})
                        }} onChange={(event) => {
                            onUpdate(event.target.name, event.target.value)
                        }}/>
                    </div>
                </div>
                <div className="row-form flex-justify-content-space-between">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={'GLOBAL.USER'}/></label>
                        <Input autoComplete="off" className="modal-form-input" disabled={true}
                               prefix={
                                   <Tooltip placement="left" title={<Translate string={"GLOBAL.CLICK_TO_COPY"}/>}>
                                       {
                                           item.username && item.username.length !== 0 &&
                                           <Icon type="copy"
                                                 onClick={() => {
                                                     clipboradField(item.username)
                                                     }
                                                 }
                                           />
                                       }
                                   </Tooltip>}
                               type="text" name="username" value={item.username} onChange={(event) => {
                            onUpdate(event.target.name, event.target.value)
                        }}/>

                    </div>
                    <div className="modal-form-column ftp-password-column">
                        <label className="modal-form-label">
                            <Translate string={"GLOBAL.GENERATE_PASSWORD"}/> 
                            <Popconfirm placement="top" title={<div><b><Translate string={"GLOBAL.WARNING"}/></b>
                                                            <br/>
                                                            <p><Translate string={"GLOBAL.THIS_WILL_CHANGE_ALL_PASSWORDS"}/><br/>
                                                            <Translate string={"GLOBAL.CANT_SEE_OLD_PASSWORD"}/><br/>
                                                            <Translate string={"GLOBAL.DO_YOU_STILL_WANT_CHANGE_PASSWORD"}/>
                                                            </p></div>}
                                                            okText={<Translate string={"GLOBAL.MODALS_OK_TEXT"}/>}
                                                            cancelText={<Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>}
                                                            onConfirm={()=>setGeneratedPassword()}>
                                <Icon component={Refresh} />
                            </Popconfirm>
                        </label>
                        <p className={"ftp-password"}>
                            <Tooltip placement="left" title={<Translate string={"GLOBAL.CLICK_TO_COPY"}/>}>
                                {
                                    item.password && item.password.length !== 0 &&
                                    <Icon type="copy" className={"ftp-password-copy-icon"}
                                          onClick={() => {
                                              clipboradField(item.password)
                                              }
                                          }
                                    />
                                }
                            </Tooltip>
                            {item.password}
                        </p>
                    </div>
                </div>
                <div className="row-form flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.UPLOAD_FOLDER"}/></label>
                        <Input autoComplete="off" className="modal-form-input" type="text" disabled={true}
                               name="uploadFolder" value={item.uploadFolder} onChange={(event) => {
                            onUpdate(event.target.name, event.target.value)
                        }}/>
                    </div>
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.SSL"}/></label>
                        <label className="label toggle">
                            <Switch checked={item.ssl} name="ssl" onClick={(checked, event) => {
                                onUpdate(event.target.name, checked)
                            }}/>
                        </label>
                    </div>
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.PASSIVE_MODE"}/></label>
                        <label className="label toggle">
                            <Switch checked={item.passiveMode} name="passiveMode" onClick={(checked, event) => {
                                onUpdate(event.target.name, checked)
                            }}/>
                        </label>
                    </div>
                </div>
                <div className="row-form flex-justify-content-left">
                    <div className="modal-form-column">
                        <div className="data-logger-btn-test">
                            <label className="modal-form-label"><Translate string={"GLOBAL_SETTINGS.CONSUMER_STATUS"}/></label>
                            <Button className={consumerStatus ? 'data-logger-consumer stop' : 'data-logger-consumer'}
                                    type="link" onClick={toggleConsumer} shape={'round'} size={'small'}
                                    htmlType={'button'}>
                                <Icon
                                    type={consumerStatus ? 'pause-circle' : 'play-circle'}/> {consumerStatus ? <Translate string={"GLOBAL_SETTINGS.STOP_CONSUMER"}/> : <Translate string={"GLOBAL_SETTINGS.START_CONSUMER"}/>}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-button-ftp">
            {item.ssl && <Button className={'btn btn-border-green'} type="button" onClick={handleDownloadSSL} >
                    <Icon type={'arrow-down'} /><Translate string={"GLOBAL.DOWNLOAD_SSL"}/>
                </Button>}
                <Button className={'btn btn-border-green'} type="button" onClick={exportDataLoggerInfo}>
                    <Icon type={'arrow-down'}/><Translate string={"GLOBAL.DOWNLOAD"}/>
                </Button>
                <Button className={'btn btn-border-green'} type="button" onClick={onSave}>
                    <Icon type={'save'}/><Translate string={"GLOBAL.SAVE"}/>
                </Button>
            </div>
        </React.Fragment>
    )
}


export default ModalFtpTab;
