import React, {useEffect, useState} from 'react';
import {Button, Icon, Input, Pagination, Select} from "antd";
import {LoaderBar} from "../AlertSetupLoader/AlertSetupLoader";
import AlertsItem from "./AlertsItem/AlertsItem"
import AlertsSetupNoData from "../AlertsSetupNoData/AlertsSetupNoData";
import Modal from "../../../../elements/Modal/Modal";
import {ModalClose, TranslateText} from "../../../../config/constants";
import {
    createAlerts, deleteAlerts, syncAlerts,
    getAlerts,updateAlerts,getAllNotificationTemplatesForAlerts,
    getAllTriggersForAlert,getAllRecipientsForAlerts,getAllStatusForAlerts
} from "../../../../api/alerting/alerting";
import Translate from '../../../Translate/Translate';

import './AlertSetupAlerts.scss';

const {Option} = Select;

const AlertSetupAlerts = () => {
    const [isModalOpen, toggleModal] = useState(false);
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState({
        name: '',
        recipients: [],
        status: [],
        triggers: [],
        template: null
    });
    const [alerts, setAlerts] = useState({
        name: '',
        recipients: [],
        status: [],
        triggers: [],
        template: null
    });
    const [loader, setLoader] = useState(true);
    const [type, setEditType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({text: ''});
    const [isSearch, setIsSearch] = useState(false);

    const [triggers, setTriggers] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [isFormValid, setIsFormValid] = useState(true);


    useEffect(() => {
        getAllAlerts();
        getAllTriggers();
        getAllRecipients();
        getAllStatuses();
        getAllNotificationTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(isSearch){
            getAllAlerts();
            setIsSearch(false);
            searchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search]);


    const getAllAlerts = (page, filters) => {
        setLoader(true);
        getAlerts(page, filters || {name: search.text}).then(res => {
            if (res && res.data) {
                setData(res.data);
                setPagination(res.meta);
                setTimeout(() => {
                    setLoader(false);
                    setLoadingSearch(false);
                }, 1000)
            }
        })

    };
    const getAllTriggers = () => {

        getAllTriggersForAlert().then(res => {
            if (res && res.data) {
                setTriggers(res.data);
                setTimeout(() => setLoader(false), 1000)
            }
        })

    };
    const getAllRecipients = () => {

        getAllRecipientsForAlerts().then(res => {
            if (res && res.data) {
                setRecipients(res.data);
                setTimeout(() => setLoader(false), 1000)
            }
        })

    };
    const getAllStatuses = () => {

        getAllStatusForAlerts().then(res => {
            if (res && res.data) {
                setStatuses(res.data);
                setTimeout(() => setLoader(false), 1000)
            }
        })

    };
    const getAllNotificationTemplates = () => {

        getAllNotificationTemplatesForAlerts().then(res => {
            if (res && res.data) {
                setTemplates(res.data);
                setTimeout(() => setLoader(false), 1000)
            }
        })

    };


    const createNewAlert = () => {
        setIsFormValid(true);
        if(alerts.name.length > 0 && alerts.triggers.length > 0) {
            setLoading(true);
    
            createAlerts(alerts)
                .then((res) => {
                    syncAlerts(res.id, alerts)
                        .then(() => {
                            toggleModal(false);
                            setTimeout(() => {
                                getAllAlerts();
                                setLoading(false);
                            }, 500);
                            closeModal();
                        })
                });
        } else {
            setIsFormValid(false);
        }

    };

    const updateSelectedAlert = () => {
        if(editData.name.length > 0 && editData.triggers.length > 0) {
            setLoading(true);
    
            let payload = {
                name: editData.name,
                recipients: editData.recipients,
                status: editData.status,
                triggers: editData.triggers,
                template: editData.template,
            };
    
            updateAlerts(editData.id, payload)
                .then((res) => {
                    syncAlerts(res.id, payload)
                        .then(() => {
                            toggleModal(false);
                            setTimeout(() => {
                                getAllAlerts();
                                setLoading(false);
                            }, 500);
                            closeModal();
                        })
                });
        } else {
            setIsFormValid(false);
        }
    };

    const delAlert = (e) => {
        setLoading(true);
        if (e) {
            deleteAlerts(e)
                .then(() => {
                    setTimeout(() => {
                        getAllAlerts();
                        setLoading(false);
                        setIsSearch(false);
                    }, 500);
                })
        }
    };

    const editAlert = (payload) => {
        setEditData({
            name: payload.name,
            recipients: payload.recipients.data ? payload.recipients.data.map(rec => rec.id) : [],
            status: payload.status.data ? payload.status.data.map(status => status.id) : [],
            triggers: payload.triggers.data ? payload.triggers.data.map(trigger => trigger.id) : [],
            template: payload.template,
            id: payload.id
        });
        setLoading(true);
        setTimeout(() => {
            toggleModal(true);
            setLoading(false);
        }, 500)
    };

    const onChange = (e) => {
        type === 'edit' ? setEditData({...editData, [e.target.name]: e.target.value}) :
            setAlerts({...alerts, [e.target.name]: e.target.value});
        setIsFormValid(true);
    };
    const onChangeSelect = (e, action) => {
        if (e === undefined){
            setEditData({...editData,[action]:null})
            setIsFormValid(true);  
        }else{
            action === 'status'?
            
                type === 'edit' ? setEditData({...editData, [action]: [e]}) :
                setAlerts({...alerts, [action]: [e]})
                : 
                type === 'edit' ? setEditData({...editData, [action]: e}) :
                setAlerts({...alerts, [action]: e})
            
        setIsFormValid(true);  
        }
    };

    const closeModal = () => {
        toggleModal(false);
        setEditData({
            name: '',
            recipients: [],
            status: [],
            triggers: [],
            template: null
        });
        setEditType(null);
        setAlerts({
            name: '',
            recipients: [],
            status: [],
            triggers: [],
            template: null
        });
        setIsFormValid(true);
    };

    const isChange = (e) => {
        setSearch({...search, text: e.target.value});
        if (e.target.value.length === 0){
            setIsSearch(true);
        }
    };

    const searchData = () => {
        if (search) {
            setLoadingSearch(true);
            setTimeout(() => {
                getAllAlerts();
                setLoadingSearch(false);
            }, 500)
        }
    };
    
    const onChangePage = e => {
        getAllAlerts(e);
    };
    return (
        <div className='alert-setup-alerts flex flex-direction-column'>
            <div
                className="alert-setup-alerts-header flex flex-justify-content-space-between flex-align-items-center">
                <div className="alert-setup-alerts-search  flex flex-align-items-center flex-justify-content-space-between">
                    <Input
                        name={"unit"}
                        type={"text"}
                        placeholder={TranslateText("GLOBAL.SEARCH")}
                        autoComplete='off'
                        allowClear
                        onChange={isChange}
                        className='alerting-search-input'
                        onPressEnter={!loadingSearch ? searchData : null}
                    />
                    <div className="form-input flex flex-direction-column alerting-alerts-header-cta">
                        <Button type="primary" onClick={searchData} icon="search" disabled={loadingSearch || search.text.length === 0}><Translate string={"GLOBAL.SEARCH"}/></Button>
                    </div>
                </div>
                {loadingSearch && <div className="alerting-list-loader">
                    <Icon type="loading" style={{fontSize:'26px', color: '#2980B9'}} />
                </div>}
            </div>
            <div className="alert-setup-alerts-content">
                <div className="alerts-list" style={{height: '100%'}}>
                    {loader ?
                        <LoaderBar/> :
                            data && data.length > 0 ? data.map((item, index) => {
                                 const templateName = item.templateId === null || templates.hasOwnProperty(item.templateId) ? "" : templates.filter(template => template.id === item.templateId)[0].name;
                                return <AlertsItem
                                    allItems={item}
                                    notification={templateName}
                                    key={index}
                                    name={item.name}
                                    recipients={item.recipients}
                                    message={item.message}
                                    template={item.templateId}
                                    itemIndex={index}
                                    total={data.length}
                                    triggers={item.triggers}
                                    status={item.statuses}
                                    id={item.id}
                                    onDelete={delAlert}
                                    onEdit={(status) => {
                                        setEditType('edit');
                                        editAlert(status);
                                    }}
                                />
                            }) : search.text.length !== 0 ? <div></div> :
                            <AlertsSetupNoData title={'You have not created any alerts template'}
                                               subtitle={'Add your first alert template'}
                                               button={'Add new alert'}
                                               isButtonClick={() => {
                                                setEditType('new');
                                                toggleModal(!isModalOpen);
                                               }}
                                               description={''}
                            />
                    }
                </div>
            </div>
            <footer className='flex'>
                <div className="footer-wrap flex-align-items-center flex-justify-content-center modal-status">
                    <div className="empty-field"/>
                    <div className="alert-setup-pagination flex flex-justify-content-center">
                        {data && data.length > 0 && <Pagination
                            defaultCurrent={1}
                            total={pagination.totalRecords}
                            onChange={onChangePage}
                            size={'small'}
                            current={pagination.currentPage}
                            defaultPageSize={pagination.perPage}
                        />}
                    </div>
                    <div className="alert-setup-action flex flex-justify-content-end">
                        <Button className="add-button btn btn-green"
                                type='button'
                                icon={'plus'}
                                onClick={() => {
                                    toggleModal(!isModalOpen);
                                    setEditType('new')
                                }}
                        />
                    </div>
                    <Modal isOpen={isModalOpen} toggle={toggleModal} size={'450px'}>
                        <form className="form-template">
                            <div className="form-wrap">
                                <div className="form-header flex flex-justify-content-space-between">
                                    <h1 className="form-header-title">
                                        {type === 'edit' ? <Translate string={"ALERTING.EDIT_ALERT"}/> : <Translate string={"ALERTING.NEW_ALERT"}/>}
                                    </h1>
                                    <ModalClose className={'modal-close'} onClick={closeModal}/>
                                </div>
                                <div className="form-body flex">
                                    <div
                                        className="form-body-wrap flex flex-align-items-center flex-justify-content-center flex-direction-column">
                                        {!isFormValid && <div className="form-input error-message">
                                            <span className="warning-text"><Translate string={"ALERTING.ALL_FIELDS_MUST_BE_FILLED"} /></span>
                                        </div> }
                                        <div className="form-input">
                                            <label><Translate string={"GLOBAL.NAME"}/>*</label>
                                            <Input allowClear
                                                   name={'name'}
                                                   placeholder={TranslateText("GLOBAL.NAME")}
                                                   value={
                                                       alerts && alerts.name ? alerts.name :
                                                           editData && editData.name ? editData.name : ''
                                                   }
                                                   onChange={onChange}
                                                   autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-input">
                                            <label><Translate string={"ALERTING.TRIGGERS"}/>*</label>
                                            <Select
                                                mode="multiple"
                                                style={{width: '100%'}}
                                                placeholder={TranslateText("ALERTING.TRIGGERS")}
                                                onChange={(e) => onChangeSelect(e, 'triggers')}
                                                value={type === 'new' ? alerts.triggers : editData.triggers}
                                            >
                                                {triggers && triggers.map((trigger, index) => <Option key={index}
                                                                                                      value={trigger.id}>{trigger.name}</Option>
                                                )}
                                            </Select>

                                        </div>
                                        <div className="form-input">
                                            <label><Translate string={"ALERTING.RECIPIENTS"}/></label>
                                            <Select
                                                mode="multiple"
                                                style={{width: '100%'}}
                                                placeholder={TranslateText("ALERTING.RECIPIENTS")}
                                                name={'recipients'}
                                                onChange={(e) => onChangeSelect(e, 'recipients')}
                                                value={type === 'new' ? alerts.recipients : editData.recipients}
                                            >
                                                {recipients && recipients.map((recipient, index) => <Option key={index}
                                                                                                            value={recipient.id}>{recipient.name}</Option>
                                                )}
                                            </Select>
                                        </div>
                                        <div className="form-input">
                                            <label><Translate string={"GLOBAL.STATUS"}/></label>
                                            <Select
                                                allowClear
                                                style={{width: '100%'}}
                                                placeholder={TranslateText("GLOBAL.STATUS")}
                                                value={type === 'new' ? alerts.status : editData.status}
                                                name={'status'}
                                                onChange={(e) => onChangeSelect(e, 'status')}
                                            >
                                                {statuses && statuses.map((status, index) => <Option key={index}
                                                                                                     value={status.id}>{status.name}</Option>
                                                )}
                                            </Select>
                                        </div>
                                        <div className="form-input">
                                            <label><Translate string={"ALERTING.NOTIFICATION_TEMPLATES"}/></label>
                                            <Select
                                                style={{width: '100%'}}
                                                allowClear
                                                value={alerts && alerts.template ? alerts.template :
                                                    editData && editData.template ? editData.template : []}
                                                placeholder={TranslateText("ALERTING.NOTIFICATION_TEMPLATES")}
                                                name={'template'}
                                                onChange={(e) => onChangeSelect(e, 'template')}
                                            >
                                                {templates && templates.map((template, index) =>
                                                    <Option key={index} value={template.id}>{template.name}</Option>
                                                )}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-action">
                                    {loading
                                        ? <button type='button'><Icon type="loading" style={{color: '#fff'}}/></button>
                                        : type === 'edit'
                                                ? <button type='button' onClick={updateSelectedAlert}>
                                                    <Translate string={"ALERTING.UPDATE_ALERT"}/>
                                                </button>
                                                : <button type='button' onClick={createNewAlert}>
                                                    + <Translate string={"ALERTING.NEW_ALERT"}/>
                                                </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </Modal>
                </div>
            </footer>
        </div>
    );
};

export default AlertSetupAlerts;