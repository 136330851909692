import React, {useState,useEffect} from 'react';
import {PropTypes} from 'prop-types';
import Translate from "../../../../../Translate/Translate";
import {Input} from "antd";
import {TranslateText} from "../../../../../../config/constants";

const FtpDlProbeNameInput = (props) => {
    const {probe, index, onBlur} = props;
    const [probeData, setProbeData] = useState({});
    const onChangeHandler = (event) => {
        setProbeData({
            ...probeData,
            dlProbeName : event.target.value
        })
    };

    const onBlurHandler = () => {
        onBlur(probeData);
    };
    useEffect(() => {
        setProbeData(probe);
    },[probe])
    return (
        <div>
            <label htmlFor="dlProbeName"><Translate string={"GLOBAL_SETTINGS.DATA_LOGGER_PROBE_NAME"}/></label>
            <Input
            type="text"
            name={"dlProbeName-"+index}
            className="mqtt-item-input"
            placeholder={TranslateText("GLOBAL_SETTINGS.ENTER_DL_PROBE_NAME")}
            value={probeData.dlProbeName ? probeData.dlProbeName : ""}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            />
        </div>
    )
};

FtpDlProbeNameInput.propTypes = {
    probe : PropTypes.object,
    index : PropTypes.number,
    onBlur : PropTypes.func
};

export default FtpDlProbeNameInput;

