import * as types from '../actions/actionTypes';

const initialState = {
  currentLanguage: 'it',
  sidebarLeftVisible: false,
  sidebarRightVisible: false,
  sidebarRightEnergyVisible: false,
  modalVisible: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_LANGUAGE_PICKER:
      return { ...state, currentLanguage: action.currentLanguage };
    case types.HANDLE_LEFT_SIDEBAR_VISIBILITY:
      return { ...state, sidebarLeftVisible: !state.sidebarLeftVisible };
    case types.HANDLE_RIGHT_SIDEBAR_VISIBILITY:
      return { ...state, sidebarRightVisible: !state.sidebarRightVisible };
    case types.HANDLE_RIGHT_ENERGY_SIDEBAR_VISIBILITY:
      return {
        ...state,
        sidebarRightEnergyVisible: !state.sidebarRightEnergyVisible
      };
    case types.HANDLE_MODAL:
      return { ...state, modalVisible: !state.modalVisible };
    default:
      return state;
  }
};
