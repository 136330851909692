import React, {useState, useRef, useEffect} from "react"
import './ModalMqttTab.scss'
import instance from '../../../../../../../config/axiosConf';
import Translate from "../../../../../../Translate/Translate"
import {Switch, Input, Select, Button, Icon, Tooltip, Popconfirm } from 'antd';

import {Refresh} from "../../../../../../../assets/img/svg/globalSettingsSVG";
import {config as getConfig} from "../../../../../../../config/config";
import Paho from "paho-mqtt";

const Option = Select.Option;


let client = null;

const ModalMqttTab= (props) => {

    const [dlMessage, setDlMessage] = useState("");
    const [connectionStatus, setConnectionStatus] = useState(false);
    const [consumerStatus, setConsumerStatus] = useState(false);
    const textAreaRef = useRef();
    const spreadCodeAndUserName=`${JSON.parse(localStorage.getItem("userInfo")).code}:${props.item.username}`;

    useEffect(()=>{
        instance.get(`/api/dataloggers/${props.dataLogger.id}/consumer/status?conn=mqtt`)
            .then(response=>{
                if(
                    response.data.status === 2 ||
                    response.data.status === 3 ||
                    response.data.message === 'running' ||
                    response.data.message === 'starting'
                ){
                    setConsumerStatus(true)
                }
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const toggleConsumer = () =>{
        consumerStatus ? instance.get(`/api/dataloggers/${props.dataLogger.id}/consumer/stop?conn=mqtt`)
            : instance.get(`/api/dataloggers/${props.dataLogger.id}/consumer/start?conn=mqtt`);

        setConsumerStatus(!consumerStatus)

    };

    const testDataLoggers = () => {

        if(connectionStatus) {
            client.disconnect();
            setConnectionStatus(false);
            const lastMessage = textAreaRef.current.value.split("\n")[0];
            setDlMessage(lastMessage);
            return;
        }

        if(props.item.token){
            const userInfo = localStorage.getItem("userInfo");
            const userInfoData = JSON.parse(userInfo);
            const baseUrl = getConfig().SOCKET_HOST;
            const rabbitUrl = `${baseUrl}`;
            const port = getConfig().PORT;
            const wsProtocol = getConfig().WS_PROTOCOL;

            client = new Paho.Client(
                rabbitUrl,
                port,
                "/ws",
                "myclientid_" + parseInt(Math.random() * 100, 10)
            );

            client.onConnectionLost = responseObject => {
                console.log(responseObject);
            };

            const onSuccessfulConnection = () => {
                client.subscribe(`${props.item.token}/#`, {qos: 0});
                setConnectionStatus(true);
            };

            let options = {
                timeout: 30,
                userName: userInfoData.code + ":" + userInfoData.username,
                password: userInfoData.rmqPassword,
                keepAliveInterval: 30,
                useSSL: wsProtocol === "wss",
                onSuccess: onSuccessfulConnection,
                onFailure: message => {
                    console.log("CONNECTION FAILURE - " + message.errorMessage);
                }
            };

            client.onMessageArrived = message => {
                let newMessageString = message.destinationName+" "+message.payloadString + " \n";

                if(textAreaRef.current){
                    newMessageString += textAreaRef.current.value
                }

                setDlMessage(newMessageString);
            };

            client.connect(options);
        }
    };

    const handleDownloadSSL = () => {
        const path = require('../../../../../../../assets/ssl/SSL_FTP_certificate.cer');
        const a = document.createElement("a");
        a.href = path;
        a.download = `SSL_MQTT_synapsees_certificate.cer`;
        a.click();
        a.remove();
    }

    return(
        <React.Fragment>
            <div className="modal-form-mqtt">
                <div className="row-form flex flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.CONNECTION_ID"}/></label>
                        <p>{props.item.id}</p>
                    </div>
                    <div className="modal-form-column flex">
                        <div className="modal-form-column-ip-port modal-mqtt-double-input">
                            <div className="modal-form-column-ip-port-row">
                                <label className="modal-form-label"><Translate string={"GLOBAL.HOST"}/></label>
                                <p>
                                    <Tooltip placement="left" title={<Translate string={"GLOBAL.CLICK_TO_COPY"}/>}>
                                        {
                                            props.item.host && props.item.host.length !==0 &&
                                            <Icon type="copy" className={"copy-icon"}
                                                  onClick={() => { props.clipboradField(props.item.host)}
                                                  }
                                            />
                                        }
                                    </Tooltip>
                                    {props.item.host}
                                </p>
                            </div>
                        </div>
                        <div className="modal-form-column-ip-port modal-mqtt-double-input">
                            <div className="modal-form-column-ip-port-row">
                                <label className="modal-form-label"><Translate string={"GLOBAL.PORT"}/></label>
                                <p>
                                    <Tooltip placement="left" title={<Translate string={"GLOBAL.CLICK_TO_COPY"}/>}>
                                        {
                                            props.item.port && props.item.port.length !==0 &&
                                            <Icon type="copy" className={"copy-icon"}
                                                  onClick={() => { props.clipboradField(props.item.port)}
                                                  }
                                            />
                                        }
                                    </Tooltip>
                                    {props.item.port}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-form flex flex-justify-content-space-between">
                    <div className="username-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.USER"}/></label>
                        <Tooltip placement="left" title={<Translate string={"GLOBAL.CLICK_TO_COPY"}/>}>
                            <p title={props.item.username} className={"username-text"}>
                                <Tooltip placement="left" title={<Translate string={"GLOBAL.CLICK_TO_COPY"}/>}>
                                    {
                                        props.item.username && props.item.username.length !==0 &&
                                            <Icon type="copy" className={"username-icon"}
                                                  onClick={() => { props.clipboradField(spreadCodeAndUserName)}
                                                  }
                                            />
                                    }
                                </Tooltip>
                                {spreadCodeAndUserName}
                            </p>
                        </Tooltip>
                    </div>
                    <div className="password-column">
                            <label className="modal-form-label">
                                <Translate string={"GLOBAL.PASSWORD"}/>
                                <Popconfirm placement="top" title={<div><b><Translate string={"GLOBAL.WARNING"}/></b>
                                                            <br/>
                                                            <p><Translate string={"GLOBAL.THIS_WILL_CHANGE_ALL_PASSWORDS"}/><br/>
                                                            <Translate string={"GLOBAL.CANT_SEE_OLD_PASSWORD"}/><br/>
                                                            <Translate string={"GLOBAL.DO_YOU_STILL_WANT_CHANGE_PASSWORD"}/>
                                                            </p></div>}
                                                            okText={<Translate string={"GLOBAL.MODALS_OK_TEXT"}/>}
                                                            cancelText={<Translate string={"GLOBAL.CONFIRM_DELETE_CANCEL_BUTTON_TEXT"}/>}
                                                            onConfirm={()=>props.generateNewMqttPassword()}>
                                    <Icon component={Refresh} />
                                </Popconfirm>
                            </label>
                            <p className={"mqtt-password"}>
                                <Tooltip placement="left" title={<Translate string={"GLOBAL.CLICK_TO_COPY"}/>}>
                                    {
                                        props.item.password && props.item.password.length !==0 &&
                                            <Icon type="copy" className={"mqtt-password-icon"}
                                                  onClick={() => { props.clipboradField(props.item.password)}
                                                  }
                                            />
                                    }
                                </Tooltip>
                                {props.item.password}</p>
                    </div>
                </div>
                <div className="row-form flex flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.QUALITY_OF_SERVICE"}/></label>
                        <Select
                            placeholder="Set"
                            style={{ width: '100px'}}
                            value={ (props.item.qos && props.item.qos.toString()) || "Set ..."}
                            showSearch
                            optionFilterProp={"children"}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={value =>  props.onChangeSelectOptionMqtt("qos",value)}

                        >
                            <Option value="0">0</Option>
                            <Option value="1">1</Option>
                        </Select>
                    </div>
                    <div className="modal-form-column">
                        <div className="modal-form-column-ka-add flex">
                            <div className="modal-mqtt-double-input">
                                <label className="modal-form-label"><Translate string={"GLOBAL.KEEP_ALIVE"}/></label>
                                <div className="modal-form-input-right-side-icon">
                                    <Input autoComplete="off" className="modal-form-input" type={"number"} suffix={"sec"} name={"keepAlive"}  value={props.item.keepAlive} min={0} onChange={(e) => {props.onUpdate(e.target.name,e.target.value)}}
                                    />
                                </div>
                            </div>
                            <div className="modal-mqtt-double-input">
                                <label className="modal-form-label"><Translate string={"GLOBAL.ANALOG_DEADBAND_DELAY"}/></label>
                                <div className="modal-form-input-right-side-icon">
                                    <Input autoComplete="off" className="modal-form-input" type={"number"} suffix={"sec"} name={"AnalogDeadBandDelay"} min={0} value={props.item.AnalogDeadBandDelay} onChange={(e) => {props.onUpdate(e.target.name,e.target.value)}}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row-form flex flex-justify-content-center">
                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.CHANNEL"}/></label>
                        <Input autoComplete="off" className="modal-form-input channel-input" type="text" name="channel" value={props.item.channel} onChange={(event) => {props.onUpdate(event.target.name,event.target.value)}}/>
                    </div>
                    <div className="modal-form-column">
                        <div className="modal-form-column-pa-lo-ssl">
                            <div className="modal-form-column-pa-lo-ssl-row">
                                <label className="modal-form-label"><Translate string={"GLOBAL.PAYLOAD"}/></label>
                                <Switch checked={props.item.checkForPayload} name="checkForPayload" onClick={(checked,event) => {props.onUpdate(event.target.name, checked)}}/>
                            </div>
                            <div className="modal-form-column-pa-lo-ssl-row">
                                <label className="modal-form-label"><Translate string={"GLOBAL.LOG"}/></label>
                                <Switch checked={props.item.checkForLog} name="checkForLog" onClick={(checked,event) => {props.onUpdate(event.target.name, checked)}}/>
                            </div>
                            <div className="modal-form-column-pa-lo-ssl-row">
                                <label className="modal-form-label"><Translate string={"GLOBAL.SSL"}/></label>
                                <Switch  checked={props.item.ssl} name="ssl" onClick={(checked,event) => {props.onUpdate(event.target.name, checked)}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-form" style={{"display": "none"}}>
                        <label className="modal-form-label"><Translate string={"GLOBAL_SETTINGS.CHANNEL_PREVIEW"}/></label>
                        <p>{props.item.channelPreview}</p>
                </div>

                <div className="row-form flex row-form-text">

                    <div className="modal-form-column">
                        <label className="modal-form-label"><Translate string={"GLOBAL.STATUS"}/></label>
                        <textarea className="modal-form-textarea" readOnly ref={textAreaRef} value={dlMessage} rows={10}> </textarea>

                        <div className="modal-form-mqtt-test">
                            <div className="modal-mqtt-cta flex flex-align-items-center">
                                <Button type={'button'}
                                        shape={'round'}
                                        size={'small'}
                                        htmlType={'button'}
                                        onClick={testDataLoggers}
                                        className={'data-logger-btn-test'}
                                >
                                    <Icon type={'redo'} spin={connectionStatus} /> {connectionStatus ? <Translate string={"GLOBAL_SETTINGS.RUNNING"}/> : <Translate string={"GLOBAL_SETTINGS.TEST"}/>}
                                </Button>
{}
                                <Button type="link"
                                        shape={'round'}
                                        size={'small'}
                                        htmlType={'button'}
                                        onClick={toggleConsumer}
                                        className={consumerStatus ? 'data-logger-consumer stop':'data-logger-consumer'}
                                >
                                    <Icon type={consumerStatus ? 'pause-circle' : 'play-circle'}  /> {consumerStatus ? <Translate string={"GLOBAL_SETTINGS.STOP_CONSUMER"}/> : <Translate string={"GLOBAL_SETTINGS.START_CONSUMER"}/>}
                                </Button>
                            </div>


                            {/*<div className="last-message flex flex-direction-column">*/}
                            {/*    <label className="modal-form-label">Last message</label>*/}
                            {/*    <p style={{fontSize: '10px'}}>{lastMessage}</p>*/}
                            {/*</div>*/}
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal-button-ftp">
                {props.item.ssl && <Button className={'btn btn-border-green'} type="button" onClick={handleDownloadSSL} ><Icon type={'arrow-down'} /><Translate string={"GLOBAL.DOWNLOAD_SSL"}/></Button>}
                <Button className={'btn btn-border-green'} type="button" onClick={props.exportDataLoggerInfo} ><Icon type={'arrow-down'} /><Translate string={"GLOBAL.DOWNLOAD"}/></Button>
                <Button className={'btn btn-border-green'} type="button" onClick={props.onSave}><Icon type={'save'}/><Translate string={"GLOBAL.SAVE"}/></Button>
            </div>
        </React.Fragment>
    )
};

export default ModalMqttTab;
