import * as types from '../actions/actionTypes';

const initialState = {
    viewers: [],
    currentSelectedViewer: null,
    handleSidebarOnViewer: true,
    ModalForInfoOfSelectedViewer: false,
    ViewerBackdrop: false,
    handleSettingsOnViewer: false,
    HandleModalForDashboardList: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_VIEWERS :
            return {
                ...state,
                viewers: action.viewers
            };
        case types.CREATE_NEW_VIEWER:
            return {
                ...state,
                viewers: action.viewers
            };
        case types.HANDLE_SIDEBAR_FOR_PROBES_ON_VIEWER:
            return {
                ...state,
                handleSidebarOnViewer: !state.handleSidebarOnViewer
            };
        case types.DESTROY_BACKDROP_AND_ALL_MODAL_VIEWER:
            return {
                ...state,
                ModalForInfoOfSelectedViewer: false,
                HandleModalForDashboardList: false,
                ViewerBackdrop: false
            };
        case types.HANDLE_MODAL_FOR_INFO_OF_VIEWER:
            return {
                ...state,
                ModalForInfoOfSelectedViewer: !state.ModalForInfoOfSelectedViewer,
                ViewerBackdrop: !state.ViewerBackdrop
            };
        case types.HANDLE_SIDEBAR_FOR_SETTINGS_ON_VIEWER:
            return {
                ...state,
                handleSettingsOnViewer: !state.handleSettingsOnViewer
            };
        case types.HANDLE_MODAL_FOR_SELECT_DASHBOARD:
            return {
                ...state,
                HandleModalForDashboardList: !state.HandleModalForDashboardList,
                ViewerBackdrop: !state.ViewerBackdrop
            };
        case types.DELETE_VIEWER:
            return {
                ...state,
                viewers : action.viewers
            }
        case types.UPDATE_VIEWER:
            return {
                ...state,
                viewers : action.viewers
            }
        case types.UPDATE_VIEWER_LIST:
            return {
                ...state,
                viewers: action.updatedViewerList
            }
        case types.SET_CURRENT_VIEWER:
            return {
                ...state,
                currentSelectedViewer: action.currentSelectedViewer
            }
        default:
            return state;
    }
}
